import { Link } from 'react-router-dom';

import styles from './PurchaseCompletionLayout.module.scss';

interface PurchaseCompletionLayoutProps {
  imageUrl: string;
  text: string;
  button?: React.ReactNode;
  extraLink?: string | (() => void);
  extraLinkText?: string;
}

function PurchaseCompletionLayout({
  imageUrl,
  text,
  button,
  extraLink,
  extraLinkText,
}: PurchaseCompletionLayoutProps): JSX.Element {
  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    if (extraLink && typeof extraLink !== 'string') {
      extraLink();
    }
  };

  return (
    <div className={styles.mainContainer}>
      <img src={imageUrl} alt="project illustration" className={styles.testImage} />
      <div className={styles.title}>{text}</div>
      {button ? <div className={styles.button}>{button}</div> : null}
      {extraLink && extraLinkText ? (
        typeof extraLink === 'string' ? (
          <Link to={extraLink} className={styles.linkTitle}>
            {extraLinkText}
          </Link>
        ) : (
          <div onClick={handleClick} className={styles.linkTitle}>
            {extraLinkText}
          </div>
        )
      ) : null}
    </div>
  );
}

export default PurchaseCompletionLayout;
