import helperBaseStyles from 'src/theme/atoms/HelperModal';
import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';
import { WEBSITE_TESTS_RESULTS_METHODOLOGY } from 'src/utils/constants';

import styles from './TestSummaryHelperModal.module.scss';
import phone1Img from './phone1.png';
import phone2Img from './phone2.png';
import phone3Img from './phone3.png';
import testResultsImg from './results.png';

interface TestSummaryHelperModalProps {
  onClose: () => void;
}

function TestSummaryHelperModal({ onClose }: TestSummaryHelperModalProps): JSX.Element {
  return (
    <HelperModal
      lightSection={
        <div className={styles.header}>
          <h2 className={styles.title}>What should I do?</h2>
          <p className={helperBaseStyles.text}>
            Preview how your test will look to real people. If you want to edit anything just go back to the previous
            step.
          </p>
        </div>
      }
      darkSection={
        <div className={styles.modalContent}>
          <div className={styles.quizPhones}>
            <img src={phone1Img} className={styles.phone1Img} alt="phone" />
            <div className={styles.quizExplanationBox}>
              <p>
                This is what the tester will see. Each tester will see only one idea. We do this to keep the results
                clear.
              </p>
              <a href={WEBSITE_TESTS_RESULTS_METHODOLOGY} target="_blank" rel="noreferrer">
                More here
              </a>{' '}
            </div>
            <img src={phone2Img} className={styles.phone2Img} alt="phone" />
            <img src={phone3Img} className={styles.phone3Img} alt="phone" />
          </div>
          <div className={styles.testResults}>
            <div className={styles.testResultsExplanationBox}>
              <p>
                And this is what you will see. You will get results and responses in a simple dashboard. Easy to see
                which ideas are resonating and share with your stakeholders.
              </p>
            </div>
            <img src={testResultsImg} className={styles.testResultsImg} alt="Test results" />
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
}

export default TestSummaryHelperModal;
