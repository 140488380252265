import React from 'react';

import useImageUpload from 'src/theme/atoms/ImageUpload/useImageUpload';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Spinner from 'src/theme/atoms/loaders/Spinner/Spinner';

import styles from './ImageUpload.module.scss';

interface ImageUploadProps {
  onUploadStart: () => void;
  onSuccess: (imageSrc: string) => void;
  onDelete: () => Promise<void>;
  url?: string;
}

function ImageUpload({ onUploadStart, onSuccess, onDelete, url }: ImageUploadProps): JSX.Element {
  const { isLoading, imageSrc, showUnsplashModal, triggerInput, UtilElement } = useImageUpload({
    onUploadStart,
    onSuccess,
    onDelete,
    url,
  });

  const buttons = (
    <>
      <div className={styles.btnsGroup}>
        <Button color="secondary" size="s" label="upload" className={styles.button} onClick={triggerInput} />
        <Button color="secondary" size="s" label="gallery" className={styles.button} onClick={showUnsplashModal} />
      </div>
      <p className={styles.subtitle}>{'This is the image that will be\npaired with your ideas.'}</p>
    </>
  );

  const loadingView = (
    <div className={styles.noImage}>
      <Spinner />
    </div>
  );

  const imageLoadedView = (
    <>
      <img src={imageSrc} alt="roadmap thumbnail" className={styles.image} />
      {buttons}
    </>
  );

  const noImageLoadedView = (
    <div className={styles.noImage}>
      <p className={styles.header}>Add an image</p>
      {buttons}
    </div>
  );

  return (
    <div className={styles.root}>
      {isLoading ? loadingView : imageSrc ? imageLoadedView : noImageLoadedView}
      {UtilElement}
    </div>
  );
}

export default ImageUpload;
