import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';
import { Organization, OrganizationUser } from 'src/organizations/Organizations.types';
import { SnackbarProps } from 'src/theme/atoms/snackbar/Snackbar';
import handleRequestError from 'src/utils/handleRequestError';

class OrganizationSettingsStore {
  organization: Organization;

  isFetchingUsers = false;
  isUserAssignmentModalOpen = false;
  isAssigningUserToOrganization = false;

  users: OrganizationUser[] = [];
  shouldRedirectToDasboard = false;
  snackbarConfig: {
    message: string;
    type: SnackbarProps['type'];
  } = {
    message: '',
    type: 'success',
  };
  showModalUpgradeSubscription = false;
  userEmailToDeassign = '';

  constructor(organization: Organization) {
    this.organization = organization;
    makeAutoObservable(this);
  }

  handleGetOrganizationUsers = (): void => {
    this.isFetchingUsers = true;

    rootStore.organizationsStore
      .getOrganizationUsers(this.organization.id)
      .then((users) => {
        this.users = users;
      })
      .catch((err) => {
        const { message } = handleRequestError({
          error: err,
          defaultMessage: "Couldn't fetch organization users. Try again later.",
        });

        this.snackbarConfig = {
          message,
          type: 'error',
        };
      })
      .finally(() => {
        this.isFetchingUsers = false;
      });
  };

  handleOrganizationDelete = (): void => {
    rootStore.organizationsStore
      .deleteOrganization(this.organization.id)
      .then(() => {
        rootStore.organizationsStore.changeCurrentOrganization(null);
        this.shouldRedirectToDasboard = true;
      })
      .catch((err) => {
        const { message } = handleRequestError({
          error: err,
          defaultMessage: "Couldn't delete organization. Try again later.",
          config: {
            400: 'You cannot remove organization which has assigned users.',
          },
        });

        this.snackbarConfig = {
          message,
          type: 'error',
        };
      });
  };

  handleAssignUserToOrganization = (email: string): void => {
    this.isAssigningUserToOrganization = true;

    rootStore.organizationsStore
      .assignUserToOrganization(this.organization.id, email)
      .then(() => {
        this.isUserAssignmentModalOpen = false;
        this.snackbarConfig = {
          message: 'User successfully assigned to the organization',
          type: 'success',
        };

        this.users = [...this.users, { email, id: '', photoUrl: '', name: '' }];
      })
      .catch((err) => {
        if (err?.response?.status === 402) {
          this.showModalUpgradeSubscription = true;
        } else {
          const { message } = handleRequestError({
            error: err,
            defaultMessage: "Couldn't add team member. Try again later",
            config: {
              // 402: `Please upgrade to a paid Foundations subscription if you would like to add more than 3 members.`,
              // 402: 'You exceeded the maximum number of users for your organization subscription plan',
              409: 'A user with this email has been already assigned to the organization',
            },
          });

          this.snackbarConfig = {
            message,
            type: 'error',
          };
        }
      })
      .finally(() => {
        this.isAssigningUserToOrganization = false;
      });
  };

  handleUserDeassignFromOrganization = (email: string): void => {
    this.userEmailToDeassign = email;

    rootStore.organizationsStore
      .deassignUserFromOrganization(this.organization.id, email)
      .then(() => {
        this.users = this.users.filter((user) => user.email !== email);
        this.snackbarConfig = {
          message: 'User successfully removed from the organization',
          type: 'success',
        };
      })
      .catch((err) => {
        const { message } = handleRequestError({
          error: err,
          defaultMessage: "Couldn't remove user from the organization. Try again later",
        });

        this.snackbarConfig = {
          message,
          type: 'error',
        };
      })
      .finally(() => {
        this.userEmailToDeassign = '';
      });
  };

  openAssignUserModal = (): void => {
    this.isUserAssignmentModalOpen = true;
  };

  closeAssignUserModal = (): void => {
    this.isUserAssignmentModalOpen = false;
  };

  closeSnackbar = (): void => {
    this.snackbarConfig.message = '';
  };

  closeModalUpgradeSubscription = (): void => {
    this.showModalUpgradeSubscription = false;
  };
}

export default OrganizationSettingsStore;
