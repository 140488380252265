import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Navigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';

import { paths } from './paths.const';

dayjs.extend(isSameOrBefore);

interface AuthCheckerProps {
  children?: JSX.Element;
  pageType?: 'public' | 'private' | 'publicAndPrivate';
  isInvalid?: boolean;
}

function AuthChecker({ children, pageType, isInvalid = false }: AuthCheckerProps): JSX.Element {
  const { authStore } = useStores();
  const { firebaseAuth, loading } = authStore;

  const isLoggedIn = firebaseAuth.currentUser;

  if ((!loading && isLoggedIn && pageType === 'public') || (!loading && isLoggedIn && isInvalid)) {
    return <Navigate to={paths.organizationInitialize()} />;
  } else if ((!loading && !isLoggedIn && pageType === 'private') || (!loading && !isLoggedIn && isInvalid)) {
    return <Navigate to={paths.login()} />;
  } else if (children) {
    return children;
  } else {
    throw new Error('Couldn`t find any route to redirect');
  }
}

export default AuthChecker;
