import clsx from 'clsx';

import baseStyles, { HelperModalTopbar } from 'src/theme/atoms/HelperModal';
import Modal from 'src/theme/atoms/modal/Modal';

import styles from './RoadmapDetailsHelperModal.module.scss';

interface RoadmapDetailsHelperModalProps {
  onClose: () => void;
}

function RoadmapDetailsHelperModal({ onClose }: RoadmapDetailsHelperModalProps): JSX.Element {
  return (
    <Modal className={clsx(baseStyles.modal, styles.root)} onClose={onClose}>
      <div className={baseStyles.lightSection}>
        <HelperModalTopbar title="What do I do now?" onClose={onClose} />
        <div className={styles.header}>
          <h2 className={styles.title}>
            You did the work of creating a roadmap thats backed by logic. Now what do you do with it?
          </h2>
        </div>
      </div>

      <div className={baseStyles.darkSection}>
        <p className={baseStyles.text}>
          Make sure to check that all your feature groupings look right. Are you surprised by the way the sequence?
          Clicking into each row will give you a recommendation and show you what inputs you entered to create these
          recommendations and the features.
        </p>
        <p className={baseStyles.text}>
          You can always go back and edit any of your inputs, groupings, or features. Any changes you make will impact
          your output. You might want to duplicate your roadmap if you're unsure of your output or if you'd like to
          revise your answers with your team and compare roadmaps.
        </p>
        <p className={baseStyles.text}>
          Once you're happy with your roadmap, you can share it. Show your board of directors, investors, and/or
          executive team your roadmap with reasoning and recommendations layered in. You can bring it to your
          engineering or design teams, discuss goals, and make adjustments together. When you share your roadmap,
          viewers can not edit it without you.
        </p>
        <p className={baseStyles.text}>
          You might want to duplicate your roadmap so you can edit with your team when disagreements come about. Perhaps
          you're even just carrying over features from your last quarter's roadmap, and you'd like to start with a copy
          so you can assess your progress against your previous roadmap.
        </p>
        <p className={baseStyles.text}>
          We also believe it's essential to meet users where they are at and allow people to leave the tool with their
          data and build their roadmap deeper. We fully anticipate people taking this and breaking it apart in Jira or
          another sprint-building tool.
        </p>
      </div>
    </Modal>
  );
}

export default RoadmapDetailsHelperModal;
