import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import TestStepHeader from 'src/Tests/TestStepHeader/TestStepHeader';
import { paths } from 'src/app/routes/paths.const';
import { PaymentSources } from 'src/plans/Plans.types';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import { WEBSITE_TESTS_RESULTS_METHODOLOGY } from 'src/utils/constants';
import copyTextToClipboard, { snackbarCopyLinkData } from 'src/utils/copyToClipboard';

import OrganizationPlansStore from '../../organizations/OrganizationPlans/OrganizationPlans.store';
import { useOrganization } from '../../organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import { PaymentStatus } from '../../plans/Plans.types';
import PurchaseConfirmationModal from '../PurchaseConfirmationModal';
import { ExternalAudienceCostResponse } from '../Test.types';
import { useTest } from '../TestLayout';
import styles from './AudienceCint.module.scss';

function AudienceCint(): JSX.Element {
  const organization = useOrganization();
  const { test, changeTest } = useTest();

  if (!test) throw new Error('No test');

  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState<{ msg: string; type: 'success' | 'error' }>();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(undefined);

  const { testStore, plansStore, authStore } = useStores();
  const [store] = useState(() => new OrganizationPlansStore(organization));

  const isOwner = organization?.createdBy === authStore.firebaseAuth.currentUser?.uid;

  useEffect(() => {
    const reqs: Promise<ExternalAudienceCostResponse | PaymentSources>[] = [];
    if (test.cost === undefined) {
      reqs.push(testStore.getExternalAudienceCost(organization.id, test.id));
    }
    if (isOwner && test.cardsCount === undefined) {
      reqs.push(plansStore.getPaymentSources(organization.id));
    }

    if (reqs.length) {
      setIsLoading(true);

      Promise.all(reqs)
        .then((responses) => {
          const responsesObject = responses.reduce(function (result, current) {
            return Object.assign(result, current);
          }, {});
          changeTest({ ...test, ...responsesObject });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const onCloseManageSubscriptions = (): void => {
    setIsLoading(true);
    plansStore
      .getPaymentSources(organization.id)
      .then((response) => {
        changeTest({ ...test, cardsCount: response.cardsCount });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refreshTestUntilResolved = (): void => {
    setTimeout(() => {
      testStore.getTest(organization.id, test.id).then((response) => {
        if (response.externalAudienceState === 'paymentsucceeded') {
          setPaymentStatus('success');
          changeTest({ ...test, ...response });
        } else if (response.externalAudienceState === 'paymentfailed') {
          setPaymentStatus('fail');
          changeTest({ ...test, ...response });
        } else if (response.externalAudienceState === 'requested') {
          setPaymentStatus('couldntContactChargebee');
          changeTest({ ...test, ...response });
        } else {
          // setPaymentStatus('success'); // TODO remove when BE is ready
          // return; // TODO remove when BE is ready
          refreshTestUntilResolved();
        }
      });
    }, 10000);
  };

  const processPayment = (): Promise<void> => {
    setPaymentStatus('waiting');
    return testStore
      .processPayment(organization.id, test.id, test)
      .then(refreshTestUntilResolved)
      .catch(() => setPaymentStatus('fail'));
  };

  const handleShareLinkCopy = async (linkType: 'linkToCintPayment' | 'linkForRespondents'): Promise<void> => {
    setSnackbar(undefined);

    const shareLink =
      linkType === 'linkToCintPayment'
        ? `${window.location.host}${paths.audienceCint(organization.id, test.id)}`
        : `${window.location.host}${paths.quiz(test.id)}`;

    try {
      await copyTextToClipboard(shareLink);
      setSnackbar(snackbarCopyLinkData.linkCopySuccess);
    } catch (e) {
      setSnackbar(snackbarCopyLinkData.linkCopyFail);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <TestStepHeader testName={test.name} isBottomDivider={false} showAddCollaborators={false} />
      <div className={styles.testAudienceContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.titleTop}>Your test is ready.</div>
          <h2 className={styles.testAudienceHeader}>
            You’ll need {test.totalRespondentsCount} individual testers to validate your{' '}
            {test.inputParameters?.ideasCount} idea
            {test.inputParameters && test.inputParameters.ideasCount > 1 ? 's' : ''}.
          </h2>
          <div className={[styles.titleBottom, styles.readMoreTitle].join(' ')}>
            Why {test.totalRespondentsCount} responses for your test?{' '}
            <a
              href={WEBSITE_TESTS_RESULTS_METHODOLOGY}
              target="_blank"
              rel="noreferrer"
              className={styles.undelinedTitleBottom}
            >
              Read more about our methodology
            </a>
            {isOwner && (
              <Link
                className={[styles.undelinedTitleBottom, styles.titleLowerBottom].join(' ')}
                to={paths.audienceShareLink(organization.id, test.id)}
              >
                You can always send this link to people without a panel.
              </Link>
            )}
          </div>
        </div>
        <div className={styles.purchaseTestersBox}>
          <div className={styles.priceBox}>
            {isOwner ? (
              <>
                <div className={styles.price}>${test.cost?.toFixed(2)}</div>
                <div className={styles.packageInfo}>
                  {test.inputParameters?.ideasCount} idea
                  {test.inputParameters?.ideasCount && test.inputParameters?.ideasCount > 1 ? 's' : ''} +{' '}
                  {test.totalRespondentsCount} user
                  {test.totalRespondentsCount && test.totalRespondentsCount > 1 ? 's' : ''}
                </div>
                {test.cardsCount && test.cardsCount > 0 ? (
                  <Button
                    disabled={paymentStatus === 'waiting' || paymentStatus === 'success'}
                    onClick={processPayment}
                    label="PURCHASE TESTERS"
                    color="primary"
                    size="l"
                    className={styles.purchaseBtn}
                  />
                ) : (
                  <Button
                    label="ADD CARD TO PURCHASE TESTERS"
                    color="secondary"
                    size="l"
                    className={styles.purchaseBtn}
                    onClick={() => store.manageSubscriptions(onCloseManageSubscriptions)}
                  />
                )}
              </>
            ) : (
              <div className={styles.notOwner}>
                <div className={clsx(styles.boxSection, styles.withBorderBottom)}>
                  We offer audience that will validate your ideas in less than 24h, but only the account's owner can
                  make the payment{test.cost && ` ($${test.cost?.toFixed(2)})`}.
                  <Button
                    label="Copy link for the owner to pay"
                    color="primary"
                    size="s"
                    onClick={() => handleShareLinkCopy('linkToCintPayment')}
                  />
                  Send the link to the owner so they will be able to finalize the payment.
                </div>
                <div className={clsx(styles.boxSection, styles.withBorderBottom)}>
                  Meanwhile, you can provide the test's answers by using your own audience!
                  <Button
                    label="Copy testing link"
                    color="primary"
                    size="s"
                    onClick={() => handleShareLinkCopy('linkForRespondents')}
                  />
                  Send the link to your own respondents so they let you know what they think of your ideas.
                </div>
                <div className={styles.boxSection}>
                  If needed in the future, you will find both links in the test results view.
                  <Button
                    label="Go to test results"
                    color="primary"
                    size="s"
                    onClick={() => navigate(paths.testResults(organization.id, test.id))}
                  />
                </div>
              </div>
            )}
          </div>
          {isOwner && (
            <Link
              className={[styles.testForFree, styles.undelinedTitleBottom].join(' ')}
              to={paths.audienceShareLink(organization.id, test.id)}
            >
              Test with your audience for free
            </Link>
          )}
        </div>
      </div>
      {Boolean(paymentStatus) && (
        <PurchaseConfirmationModal paymentStatus={paymentStatus} onClose={() => setPaymentStatus(undefined)} />
      )}

      <Snackbar
        type={snackbar?.type || 'success'}
        message={snackbar?.msg}
        onClose={() => setSnackbar(undefined)}
        autoclose
      />
    </>
  );
}

export default observer(AudienceCint);
