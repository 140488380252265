import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { arrow } from 'src/theme/img';

import styles from './BigArrowBtnsBox.module.scss';

interface DataItem {
  id: string;
  copy: string;
  img?: string;
  link: string;
  disabled?: boolean;
}

interface BigArrowBtnsBoxProps {
  gap?: number;
  data: DataItem[];
  itemClassName?: string;
}

function BigArrowBtnsBox({ gap, data, itemClassName }: BigArrowBtnsBoxProps): JSX.Element {
  return (
    <div className={styles.root}>
      {data.map((el, i) => (
        <Link
          key={el.id}
          className={clsx(styles.link, el.disabled && styles.linkDisabled, itemClassName)}
          to={el.disabled ? '#' : el.link}
          style={gap && i !== 0 ? { marginTop: gap + 'px' } : {}}
        >
          {el.disabled && <div className={styles.accent}>Coming soon</div>}
          <div className={styles.data}>
            {el.img && (
              <div className={styles.imgBox}>
                <img src={el.img} alt="" className={styles.img} />
              </div>
            )}
            <div className={styles.copy}>{el.copy}</div>
          </div>
          <div className={styles.arrow}>
            <img src={arrow} alt="arrow right" className={styles.img} />
          </div>
        </Link>
      ))}
    </div>
  );
}

export default observer(BigArrowBtnsBox);
