import clsx from 'clsx';

import baseStyles from 'src/theme/atoms/HelperModal';
import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';

import styles from './WhoseAudienceHelperModal.module.scss';

interface WhoseAudienceHelperModalProps {
  onClose: () => void;
}

function WhoseAudienceHelperModal({ onClose }: WhoseAudienceHelperModalProps): JSX.Element {
  return (
    <HelperModal
      onClose={onClose}
      lightSection={
        <div className={styles.root}>
          <p className={styles.title}>More about audience</p>
          <p className={clsx(baseStyles.text, styles.subtitle)}>
            Each person in an audience has the opportunity to see and provide feedback on one idea per test. We
            intentionally do not show one person more than one idea per test. We are looking for an immediate gut
            reaction from that person on whether that idea is interesting to them and why it is or is not interesting.
          </p>
          <p className={baseStyles.secondaryText}>
            Our threshold for audience volume is at the idea level and applied across the entire test. Each idea is
            shown an equal number of times to individual people. Thirty people must respond to an idea for the idea to
            have reached a sufficient audience volume to be considered viable. So if you want to test 10 ideas, for
            example, you'd need 300 responses to validate the entire test confidently.
          </p>
          <p className={baseStyles.secondaryText}>
            Sometimes you have a high enough traffic volume coming to your website or social pages to test your ideas.
            In this case, you embed the Foundations test link and watch responses roll in.
          </p>
          <p className={baseStyles.secondaryText}>
            Other times, you're starting fresh, discovering, or having low or no audience. In this case, you can use the
            Foundation's audience to connect with real users. When you use the Foundation's audience, we will provide a
            slight buffer of responses to ensure your test results are valid as quickly as possible. Returning to the
            ten idea example, Foundations audience will provide 330 reactions in most cases in less than 24 hours.
          </p>
        </div>
      }
    />
  );
}

export default WhoseAudienceHelperModal;
