import { TestState } from 'src/Tests/Test.types';

import iconDraft from './assets/icon-draft.png';
import iconRunning from './assets/icon-running.png';
import iconStopped from './assets/icon-stopped.png';

type StateIcon = {
  [S in TestState]: string;
};

interface TestStateIconProps {
  state: TestState;
  className?: string;
}

function TestStateIcon({ state, className }: TestStateIconProps): JSX.Element {
  const stateIcon: StateIcon = {
    draft: iconDraft,
    running: iconRunning,
    stopped: iconStopped,
    finishedsuccessfully: iconStopped,
    finishedwithinvaliddistribution: iconStopped,
  };

  return <img src={stateIcon[state]} alt="test state icon" className={className} />;
}

export default TestStateIcon;
