import insightsIcon from './assets/icon-insights.png';
import roadmapIcon from './assets/icon-roadmap.png';
import testsIcon from './assets/icon-tests.png';
import roadmapImages from './assets/roadmap';
import testsImages from './assets/tests';
import { HowToModalView } from './types';

export interface ListItem {
  text: string[];
  altText: string;
}

export interface SectionDataItem {
  title1: string;
  title2: string;
  icon: string;
  view: HowToModalView;
  images: string[];
  list: ListItem[];
}

export interface SectionData {
  tests: SectionDataItem;
  roadmap: SectionDataItem;
  insights: SectionDataItem;
}

const sectionData: SectionData = {
  tests: {
    title1: 'Test to validate if you have an idea worth deeper investment ',
    title2:
      'Test ideas with real people to quickly validate the good ideas worth deeper investment ' +
      'while invalidating the ideas not worth more time or effort.',
    icon: testsIcon,
    view: HowToModalView.Testing,
    images: testsImages,
    list: [
      {
        text: ['Define what you are solving and for whom'],
        altText: 'test creation preview',
      },
      {
        text: ['Brainstorm ideas'],
        altText: 'ideas preview',
      },
      {
        text: ['Add a photo'],
        altText: 'uploading images preview',
      },
      {
        text: ['Share with real people'],
        altText: 'quiz preview',
      },
      {
        text: [
          'At the end',
          "You'll get quantifiable results on which ideas performed well and which didn’t - you’ll also get " +
            'qualitative insight into why people were or were not interested in each idea.',
          'The aim is to see who has the problem you’re trying to solve and how many people value the solution ' +
            'you’re offering. Then you don’t have to guess on which ideas make the most sense for investing more ' +
            'time and energy toward planning and building.',
        ],
        altText: 'finished test preview',
      },
    ],
  },
  roadmap: {
    title1: 'A plan built around user needs PLUS competition and feasibility',
    title2:
      'Evaluate your options for what to build taking competitive market dynamics, user adoption, ' +
      'and level of effort into consideration. Update your plan as you learn more through testing.',
    icon: roadmapIcon,
    view: HowToModalView.Roadmap,
    images: roadmapImages,
    list: [
      {
        text: ['Start with user outcomes'],
        altText: 'features preview',
      },
      {
        text: ['Add features as you think of them'],
        altText: 'features preview',
      },
      {
        text: ['Understand market dynamics', 'Does the user do this today? If so, how?'],
        altText: 'understanding targets',
      },
      {
        text: ['Evaluate your solution', 'How much of your customer base will adopt this feature set?'],
        altText: 'solution evaluation preview',
      },
      {
        text: ['Define ability to build', 'How long will it take you to build this feature set?'],
        altText: 'time estimation preview',
      },
      {
        text: [
          'At the end',
          "You'll get a plan that contains your logic for why you should or shouldn't build certain things. " +
            'It encapsulates your reasoning in a way that can easily be presented to everyone - from the board of ' +
            'directors to your tech leads.',
          'The aim is to gain alignment at every level, grounding everyone in market dynamics, user needs, and ' +
            'building feasibility. Then you can take your plan and break it apart into your favorite sprint planning ' +
            'and development tools or continue to refine and circulate your plan to stakeholders.',
        ],
        altText: 'roadmap example',
      },
    ],
  },
  insights: {
    title1: 'Deepen your insights into of known competition and emerging market trends and dynamics ',
    title2:
      'Keep an ear to the market with targeted data that informs not only what you’re planning ' +
      'to build and why but also inspires what you test next.',
    icon: insightsIcon,
    view: HowToModalView.Insights,
    images: [], // TODO when designs are ready
    list: [], // ^^^
  },
};

export default sectionData;
