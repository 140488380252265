import clsx from 'clsx';
import { ReactNode, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import IdeaState from 'src/Tests/IdeaState.store';
import { IDEA_CARD_DND_ITEM } from 'src/utils/constants';

import { IdeaCardDnDItem } from '../../IdeaCardDnDItem.type';
import styles from './IdeaCardDnDWrapper.module.scss';

interface IdeaCardDnDWrapperProps {
  ideaState: IdeaState;
  index: number;
  children: ReactNode;
  canDrag: boolean;
  move: (dragSourceIndex: number, dropTargetIndex: number) => void;
  onDrop?: (id: string) => void;
}

function IdeaCardDnDWrapper({
  ideaState,
  index,
  children,
  canDrag,
  move,
  onDrop,
}: IdeaCardDnDWrapperProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, dragPreview] = useDrag<IdeaCardDnDItem, void, { isDragging: boolean }>({
    type: IDEA_CARD_DND_ITEM,
    item: () => ({
      id: ideaState.id,
      index,
      prevIndex: null,
      ideaState,
      ref,
    }),
    isDragging: (monitor) => monitor.getItem().id === ideaState.id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => canDrag,
  });

  const [, drop] = useDrop<IdeaCardDnDItem, void, { isOver: boolean }>({
    accept: IDEA_CARD_DND_ITEM,
    hover: (item, monitor) => {
      if (!ref.current) return;
      if (ideaState.id === item.id) return;
      if (!ideaState.idea) return;

      const dragSourceIndex = item.index;
      const dropTargetIndex = index;

      const dropTargetParams = ref.current.getBoundingClientRect();
      const mousePosition = monitor.getClientOffset();
      if (!mousePosition) return;

      const buffor = 50;

      if (
        mousePosition.x > dropTargetParams.left + buffor &&
        mousePosition.x < dropTargetParams.right - buffor &&
        mousePosition.y < dropTargetParams.bottom - buffor &&
        mousePosition.y > dropTargetParams.top + buffor
      ) {
        move(dragSourceIndex, dropTargetIndex);

        if (item.prevIndex === null) item.prevIndex = item.index;
        item.index = dropTargetIndex;
      }
    },
    drop: (item) => {
      const isSameSlot = item.prevIndex === item.index;

      if (onDrop && item.prevIndex !== null && !isSameSlot) {
        onDrop(item.id);
      }

      item.prevIndex = null;
    },
    canDrop: () => !!ideaState.idea,
  });

  useEffect(() => {
    drag(drop(ref));
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div className={clsx(styles.root, isDragging && styles.placeholder)} ref={ref}>
      <div className={clsx(styles.inner, isDragging && styles.hidden)}>{children}</div>
    </div>
  );
}

export default IdeaCardDnDWrapper;
