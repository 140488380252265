import { initializeApp } from '@firebase/app';
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  UserCredential,
  updateProfile,
  signOut,
  getAuth,
  Auth as FirebaseAuth,
} from '@firebase/auth';
import { action, makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';
import { history } from 'src/app/routes/Router';
import { paths } from 'src/app/routes/paths.const';
import { firebaseConfig } from 'src/firebase';

import {
  ErrorPasswordIsTooShort,
  ErrorEmailFormatIsInvalid,
  ErrorConfirmPassword,
  NameFormatIsInvalid,
} from './errors';

interface SignUpData {
  email: string;
  password: string;
  name: string;
}

class AuthStore {
  firebaseAuth: FirebaseAuth = getAuth(initializeApp(firebaseConfig));
  isCurrentUserFetched = false;
  isAdmin: boolean | undefined = undefined;

  rootStore: RootStore;
  loading: boolean;

  constructor(rootStore: RootStore) {
    this.loading = false;
    this.rootStore = rootStore;

    this.firebaseAuth.onAuthStateChanged(() => {
      if (this.firebaseAuth.currentUser) {
        return this.firebaseAuth.currentUser.getIdTokenResult().then((tokenResult) => {
          const isAdmin =
            tokenResult.claims &&
            Array.isArray(tokenResult.claims.roles) &&
            (tokenResult.claims.roles.includes('admin') || tokenResult.claims.roles.includes('Admin'));
          this.isAdmin = isAdmin;

          this.isCurrentUserFetched = true;
        });
      } else {
        this.isCurrentUserFetched = true;
      }
    });

    makeAutoObservable(this, {
      logout: action.bound,
    });
  }

  createAccountWithEmail({ email, password, name }: SignUpData): Promise<UserCredential | void> {
    this.loading = true;
    return createUserWithEmailAndPassword(this.firebaseAuth, email.trim(), password.trim())
      .then((response) => {
        updateProfile(response.user, { displayName: name.trim() }).catch((error) => {
          console.log(error);
          this.loading = false;
        });

        return name.trim();
      })
      .then(() => {
        this.loading = false;
      });
  }

  updateName(name: string): Promise<void> {
    if (!this.firebaseAuth.currentUser) throw new Error('No user data');

    this.loading = true;
    return updateProfile(this.firebaseAuth.currentUser, { displayName: name.trim() }).finally(
      () => (this.loading = false)
    );
  }

  signInWithEmail(email: string, password: string): Promise<UserCredential> {
    this.loading = true;
    return signInWithEmailAndPassword(this.firebaseAuth, email, password).then((res) => {
      this.loading = false;
      return res;
    });
  }

  signInWithGoogle(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(this.firebaseAuth, provider);
  }

  logout(): Promise<void | null> {
    return signOut(this.firebaseAuth).then(() => {
      this.rootStore.organizationsStore.resetOrganizationStore();
      return history.replace(paths.login());
    });
  }

  requestNewPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.firebaseAuth, email, { url: `${location.origin}${paths.login()}` });
  }

  public isNameValid = (name: string): boolean => {
    const normalizedName = name.trim();
    return !NameFormatIsInvalid.isSatisfiedBy(normalizedName);
  };

  public isEmailValid = (email: string): boolean => {
    const normalizedEmail = email.trim();
    return !ErrorEmailFormatIsInvalid.isSatisfiedBy(normalizedEmail);
  };

  public isPasswordLengthValid = (password: string): boolean => {
    const normalizedPassword = password.trim();
    return ErrorPasswordIsTooShort.isSatisfiedBy(normalizedPassword);
  };

  public isPasswordConfirmed = (password: string, confirmPassword: string): boolean => {
    const normalizedPassword = password.trim();
    const normalizedConfirmPassword = confirmPassword.trim();

    return ErrorConfirmPassword.isSatisfiedBy(normalizedPassword, normalizedConfirmPassword);
  };
}

export default AuthStore;
