import clsx from 'clsx';

import { CustomerAdoptionRate } from 'src/utils/types/FeatureRelated';

import styles from './AdoptionRate.module.scss';

const POINTS_COUNT = 5;

interface AdoptionRateProps {
  value?: CustomerAdoptionRate;
  onClick: (rate: CustomerAdoptionRate) => void;
}

function AdoptionRate({ value, onClick }: AdoptionRateProps): JSX.Element {
  const getRateByI = (i: number): CustomerAdoptionRate => {
    switch (i) {
      case 0:
        return '0';
      case 1:
        return '25';
      case 2:
        return '50';
      case 3:
        return '75';
      default:
        return '100';
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.rate}>
        <div className={styles.lineBox}>
          <div
            className={styles.line}
            style={{
              width: `${Number(value || 0)}%`,
            }}
          />
        </div>
        <div className={styles.points}>
          {Array.from(Array(POINTS_COUNT)).map((x, i) => {
            const rate = getRateByI(i);

            return (
              <button
                key={i}
                className={clsx(styles.point, value !== undefined && Number(rate) <= Number(value) && styles.filled)}
                onClick={() => onClick(rate)}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.legend}>
        <div className={styles.legendDesktop}>
          <p>
            Less than 25% <br />
            of customers
          </p>
          <p>Greater than 50%</p>
          <p>Greater than 90%</p>
        </div>
        <div className={styles.legendMobile}>
          <p>{'<10%'}</p>
          <p>50%</p>
          <p>{'>90%'}</p>
        </div>
      </div>
    </div>
  );
}

export default AdoptionRate;
