import { makeAutoObservable } from 'mobx';

import getDuplicatedName from 'src/utils/getDuplicatedName';
import { CustomError } from 'src/utils/types/CustomError';

import { Idea } from './Test.types';
import TestEditionStore from './TestEdition/TestEdition.store';

interface Callbacks {
  onCreate?: (idea: Idea) => void;
  onDuplicate?: (idea: Idea) => void;
  onUpdate?: (idea: Idea) => void;
  onDelete?: (id: string) => void;
  onDeassign?: (id: string) => void;
  onCancel?: (id: string) => void;
  onEdit?: (id: string) => void;
}

type ActionOnDeletion = 'deassign' | 'delete';

class IdeaState {
  testEditionStore: TestEditionStore;
  callbacks: Callbacks;
  ideaGroupId: string | null = null;
  idea: Idea | null = null;

  id = '';
  text = '';

  state: 'default' | 'selected' | 'edited' | 'deleted' | 'removed' = 'default';
  actionStatus: 'default' | 'saving' | 'deleting' | 'deassigning' | 'duplicating' = 'default';
  isSaved = false;

  actionOnDeletion: ActionOnDeletion;

  constructor(
    testEditionStore: TestEditionStore,
    idea: Idea | null,
    ideaGroupId: string | null,
    callbacks: Callbacks,
    actionOnDeletion: ActionOnDeletion
  ) {
    this.testEditionStore = testEditionStore;
    this.callbacks = callbacks;
    this.ideaGroupId = ideaGroupId;
    this.actionOnDeletion = actionOnDeletion;

    if (idea) {
      this.idea = idea;

      this.id = idea.id;
      this.text = idea.text;

      this.isSaved = true;
    } else {
      this.state = 'edited';
    }

    makeAutoObservable(this);
  }

  changeName = (value: string): void => {
    this.text = value;
  };

  toggleSelect = (): void => {
    if (this.state !== 'edited') {
      this.state = this.state === 'default' ? 'selected' : 'default';
    }
  };

  edit = (): void => {
    this.state = 'edited';

    this.callbacks.onEdit && this.callbacks.onEdit(this.id);
  };

  cancel = (): void => {
    if (!this.idea) throw new Error('No idea');

    this.text = this.idea.text;
    this.state = 'default';
    this.actionStatus = 'default';

    this.callbacks.onCancel && this.callbacks.onCancel(this.id);
  };

  deassign = (): void => {
    if (!this.ideaGroupId || !this.callbacks.onDeassign) {
      throw new Error(
        'deassign method requires ideaGroupId and onDeassign callback to be provided by IdeaState constructor'
      );
    }

    this.actionStatus = 'deassigning';

    this.testEditionStore
      .deassignIdeaFromIdeaGroup(this.ideaGroupId, this.id)
      .then(() => {
        this.callbacks.onDeassign && this.callbacks.onDeassign(this.id);
      })
      .catch((err: CustomError) => {
        alert(err.message);
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };

  delete = (): Promise<void> => {
    this.actionStatus = 'deleting';

    return this.testEditionStore
      .deleteIdea(this.id, this.ideaGroupId)
      .then(() => {
        this.callbacks.onDelete && this.callbacks.onDelete(this.id);
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      })
      .finally(() => {
        this.actionStatus = 'default';
        this.state = 'deleted';
      });
  };

  save = (text?: string): Promise<void> => {
    if (this.isSaved) {
      return this.update(text);
    } else {
      if (!this.ideaGroupId) {
        return this.create();
      } else {
        return this.createAndAssignToGroup(this.ideaGroupId);
      }
    }
  };

  duplicate = (): Promise<void> => {
    this.actionStatus = 'duplicating';

    const alreadyExistingIdeasTexts = this.testEditionStore.ideas.map((el) => el.text);

    return this.testEditionStore
      .createIdea({
        text: getDuplicatedName(alreadyExistingIdeasTexts, this.text),
        valueProp: '',
      })
      .then((duplicatedIdea) => {
        this.callbacks.onDuplicate && this.callbacks.onDuplicate(duplicatedIdea);
      })
      .catch(() => {
        alert('Something went wrong');
        throw new Error('Something went wrong');
      })
      .finally(() => {
        this.actionStatus = 'default';
        this.state = 'default';
      });
  };

  updateByIdea = (idea: Idea): void => {
    this.text = idea.text;
    this.actionStatus = 'default';
    this.state = 'default';
  };

  changeCallbacks = (callbacks: Callbacks): void => {
    this.callbacks = callbacks;
  };

  changeIdeaGroupId = (ideaGroupId: string): void => {
    this.ideaGroupId = ideaGroupId;
  };

  private create = (): Promise<void> => {
    this.actionStatus = 'saving';

    const text = this.text.trim();

    return this.testEditionStore
      .createIdea({
        text,
        valueProp: text,
      })
      .then((createdIdea) => {
        this.idea = createdIdea;
        this.text = text;

        this.id = createdIdea.id;
        this.isSaved = true;
        this.state = 'default';

        this.callbacks.onCreate && this.callbacks.onCreate(createdIdea);
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };

  private createAndAssignToGroup = (ideaGroupId: string): Promise<void> => {
    this.actionStatus = 'saving';

    return this.testEditionStore
      .createIdeaAndAssignToIdeaGroup(ideaGroupId, {
        text: this.text,
        valueProp: '',
      })
      .then((createdIdea) => {
        this.idea = createdIdea;

        this.id = createdIdea.id;
        this.isSaved = true;
        this.state = 'default';

        this.callbacks.onCreate && this.callbacks.onCreate(createdIdea);
      })
      .catch(() => {
        alert('Something went wrong');
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };

  private update = (value?: string): Promise<void> => {
    this.actionStatus = 'saving';

    const text = value ? value.trim() : this.text.trim();

    return this.testEditionStore
      .updateIdea(this.id, {
        text,
        valueProp: text,
      })
      .then((updatedIdea) => {
        this.idea = updatedIdea;
        this.text = text;

        this.isSaved = true;
        this.state = 'default';

        this.callbacks.onUpdate && this.callbacks.onUpdate(updatedIdea);
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };
}

export default IdeaState;
