import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState, useRef } from 'react';
import ReactSlider from 'react-slider';

import { ReactComponent as ArrowDownIcon } from 'src/theme/svg/arrow-down.svg';
import useOnClickOutside from 'src/utils/hooks/useOnClickOutside';

import styles from './DropdownWithSlider.module.scss';

interface DropdownWithSliderProps {
  title: string;
  unit?: string;
  smallestPossibleMin?: string;
  selectedMin: number | undefined;
  selectedMax: number | undefined;
  handleMinChange: (val: number | undefined) => void;
  handleMaxChange: (val: number | undefined) => void;
  defaultMin: number;
  defaultMax: number;
  minAgeRange: number;
  isIdeaManagementView?: boolean;
}

function DropdownWithSlider({
  title,
  unit,
  selectedMin,
  selectedMax,
  handleMinChange,
  handleMaxChange,
  defaultMin,
  defaultMax,
  minAgeRange,
  isIdeaManagementView,
}: DropdownWithSliderProps): JSX.Element {
  const [menuVisible, setMenuVisible] = useState(false);

  const dropdownRef = useRef(null);
  const listRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setMenuVisible(false));

  const handleSliderChange = ([min, max]): void => {
    handleMinChange(min);
    handleMaxChange(max);
  };

  return (
    <div className={styles.root} ref={dropdownRef}>
      <div
        className={clsx(
          styles.container,
          menuVisible && styles.isOpen,
          isIdeaManagementView && styles.containerIdeasManagementView
        )}
        onClick={() => setMenuVisible(!menuVisible)}
      >
        <div className={styles.text}>
          <div className={styles.titleLine}>
            <div
              className={clsx(
                styles.title,
                // (menuVisible || (selectedMin && (selectedMin !== defaultMin || selectedMax !== defaultMax))) &&
                !isIdeaManagementView && styles.titleSmall,
                !(selectedMin || menuVisible) && isIdeaManagementView && styles.titleIdeasManagementView,
                // ((selectedMin && (selectedMin !== defaultMin || selectedMax !== defaultMax)) || menuVisible) &&
                isIdeaManagementView && styles.titleSmallIdeasManagementView
              )}
            >
              {title}
            </div>
          </div>
          {/* {(menuVisible || (selectedMin && (selectedMin !== defaultMin || selectedMax !== defaultMax))) && ( */}
          <div
            className={clsx(
              styles.selectedItemLine,
              isIdeaManagementView && styles.selectedItemLineIdeasManagementView
            )}
          >
            {menuVisible
              ? 'Min + Max'
              : selectedMin && (selectedMin !== defaultMin || selectedMax !== defaultMax)
              ? `${selectedMin || ''} ${selectedMax ? '- ' + selectedMax : ''}`
              : 'Any'}
          </div>
          {/* )} */}
        </div>
        <div className={clsx(styles.arrow, menuVisible && styles.arrowUpsideDown)}>
          <ArrowDownIcon />
        </div>
      </div>
      <div
        ref={listRef}
        className={clsx(
          styles.details,
          { [styles.visible]: menuVisible },
          isIdeaManagementView && styles.detailsIdeasManagementView
        )}
      >
        <div className={styles.slider}>
          <ReactSlider
            min={defaultMin}
            max={defaultMax}
            minDistance={minAgeRange}
            value={[selectedMin || defaultMin, selectedMax || defaultMax]}
            thumbClassName={styles.thumb}
            trackClassName={styles.track}
            onChange={handleSliderChange}
            renderThumb={(props) => <div {...props} />}
          />
        </div>
        <div className={styles.values}>
          <div className={clsx(styles.valueBox, isIdeaManagementView && styles.valueBoxIdeasManagementView)}>
            <div className={styles.firstLine}>Min</div>
            <div className={styles.secondLine}>
              {selectedMin || defaultMin} {unit}
            </div>
          </div>
          <div className={clsx(styles.valueBox, isIdeaManagementView && styles.valueBoxIdeasManagementView)}>
            {' '}
            <div className={styles.firstLine}>Max</div>
            <div className={styles.secondLine}>
              {selectedMax || defaultMax} {unit}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DropdownWithSlider);
