import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import IdeaState from 'src/Tests/IdeaState.store';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { TextArea } from 'src/theme/atoms/inputs';
import Modal from 'src/theme/atoms/modal/Modal';
import { ReactComponent as DeleteIcon } from 'src/theme/svg/bin.svg';
import { ReactComponent as Spinner } from 'src/theme/svg/spinner.svg';

import styles from './IdeaEditionModal.module.scss';

interface IdeaEditionModalProps {
  ideaState: IdeaState;
  onClose: () => void;
}

function IdeaEditionModal({ ideaState, onClose }: IdeaEditionModalProps): JSX.Element {
  const nameInputRef = useRef<HTMLTextAreaElement | null>(null);

  const [text, setText] = useState(ideaState.text);

  const canSave = ideaState.text !== text && text.trim() !== '';

  useEffect(() => {
    if (nameInputRef.current) {
      const nameInput = nameInputRef.current;
      const end = nameInput.value.length;
      nameInput.setSelectionRange(end, end);
      nameInput.focus();
    }
  }, []);

  const handleSubmit = (): void => {
    ideaState.save(text).then(() => {
      onClose();
    });
  };

  const handleDelete = (): void => {
    ideaState.delete().then(() => {
      onClose();
    });
  };

  return (
    <Modal className={styles.modal} onClose={onClose} shouldCloseOnClickOutside={false}>
      <div className={styles.nameFieldBox}>
        <TextArea
          forwardRef={nameInputRef}
          color="black"
          size="s"
          label="Idea name"
          name="name"
          placeholder="How do you help x person do y?"
          value={text}
          className={styles.nameField}
          textFieldClassName={styles.textarea}
          onChange={setText}
          disableEnter
          onEnter={canSave ? handleSubmit : undefined}
        />
      </div>
      <div className={styles.actionsBottomBar}>
        <div>
          <button className={styles.deleteBtn} disabled={ideaState.actionStatus === 'deleting'} onClick={handleDelete}>
            {ideaState.actionStatus === 'deleting' ? <Spinner className={styles.spinner} /> : <DeleteIcon />}
          </button>
        </div>
        <div className={styles.right}>
          <Button label="cancel" color="black" size="s" onClick={onClose} />
          <Button
            label="save"
            color="secondary"
            size="s"
            loading={ideaState.actionStatus === 'saving'}
            onClick={handleSubmit}
            disabled={!canSave}
          />
        </div>
      </div>
    </Modal>
  );
}

export default observer(IdeaEditionModal);
