import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { Organization } from 'src/organizations/Organizations.types';
import AddCollabolatorModal from 'src/theme/atoms/AddCollabolatorModal/AddCollabolatorModal';
import Avatar from 'src/theme/atoms/avatar/Avatar';
import Button from 'src/theme/atoms/buttons/Button/Button';
import DropdownMenu from 'src/theme/atoms/dropdownMenu/DropdownMenu';
import { ADD_TEAM_MEMBERS_BTN } from 'src/utils/constants';

import styles from './MobileMenu.module.scss';

interface MobileMenuProps {
  organization?: Organization;
}

const MobileMenu = ({ organization }: MobileMenuProps): JSX.Element => {
  const { authStore, organizationsStore } = useStores();
  const { logout } = authStore;
  const { currentOrganization, organizations } = organizationsStore;
  const { isAdmin } = authStore;
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [isAddCollaboratorsModalOpen, setIsAddCollaboratorsModalOpen] = useState(false);
  const [organizationsLinks, setOrganizationsLinks] = useState<JSX.Element | JSX.Element[]>(<></>);

  const getOrganizationsLinks = (): JSX.Element | JSX.Element[] => {
    return organization && organizations.length > 1 ? (
      organizations
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        .map((org) => {
          return (
            <li key={org.id}>
              <Link to={paths.organizationDashboard(org.id)} className={styles.headerLink}>
                {organization.id === org.id ? '> ' : ''}org. {org.name}
              </Link>
            </li>
          );
        })
    ) : (
      <></>
    );
  };

  useEffect(() => {
    setOrganizationsLinks(getOrganizationsLinks());
  }, []);

  const isOwner = organization?.createdBy === authStore.firebaseAuth.currentUser?.uid;

  const organizationBasedItemLinks = organization && (isOwner || isAdmin) && (
    <>
      <li>
        <Link to={paths.organizationPlans(organization.id)} className={styles.headerLink}>
          {useLocation().pathname.split('/')[3] === 'plans' ? '> ' : ''}your plan
        </Link>
      </li>
      <li>
        <Link to={paths.organizationSettings(organization.id)} className={styles.headerLink}>
          {useLocation().pathname.split('/')[3] === 'settings' ? '> ' : ''}account settings
        </Link>
      </li>
      {(isAdmin || isOwner) && (
        <li className={styles.pointer} onClick={() => setIsAddCollaboratorsModalOpen(true)}>
          {ADD_TEAM_MEMBERS_BTN}
        </li>
      )}
      {isAdmin && (
        <li>
          <Link to={paths.admin()} className={styles.headerLink} target="_blank" rel="noopener noreferrer">
            admin panel
          </Link>
        </li>
      )}
    </>
  );

  return (
    <div className={styles.root}>
      {menuVisible ? (
        <Button label="Close" size="m" color="secondary" onClick={() => setMenuVisible(false)} />
      ) : (
        <div className={styles.hamburger} onClick={() => setMenuVisible(true)} />
      )}
      <DropdownMenu visible={menuVisible}>
        <ul>
          {organizationBasedItemLinks}

          {currentOrganization && (
            <li>
              <Avatar title={currentOrganization.name} inDropdown isOrganizationAvatar />
            </li>
          )}
          {/* <li>
            <Avatar title={firebaseAuth.currentUser?.displayName || firebaseAuth.currentUser?.email || ''} inDropdown />
          </li> */}
          <li className={styles.pointer} onClick={logout}>
            Logout
          </li>
          {organizationsLinks}
        </ul>
      </DropdownMenu>
      {isAddCollaboratorsModalOpen && <AddCollabolatorModal onClose={() => setIsAddCollaboratorsModalOpen(false)} />}
    </div>
  );
};

export default MobileMenu;
