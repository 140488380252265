import clsx from 'clsx';
import React, { Dispatch, SetStateAction } from 'react';

import arrow from 'src/theme/img/arrow.png';

import styles from './HowToModal.module.scss';
import diagram from './assets/diagram.png';
import sectionData, { SectionDataItem } from './const';
import { HowToModalView } from './types';

interface MainSectionProps {
  setView: Dispatch<SetStateAction<HowToModalView>>;
  scrollRef: React.RefObject<HTMLDivElement>;
}

function MainSection({ setView, scrollRef }: MainSectionProps): JSX.Element {
  const handleChangeView = (view: HowToModalView): void => {
    setView(view);
    scrollRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderSection = ([sectionName, sectionData]: [string, SectionDataItem]): JSX.Element => (
    <div
      key={sectionName}
      className={clsx(styles.sectionOnList, sectionName === 'insights' ? '' : styles.isLink)}
      onClick={() => (sectionName === 'insights' ? null : handleChangeView(sectionData.view))}
    >
      <div className={styles.topRow}>
        <img className={styles.icon} src={sectionData.icon} alt={sectionName + ' icon'} />
        {sectionName === 'insights' ? (
          <i>Coming soon</i>
        ) : (
          <div className={styles.arrowBtn}>
            <img src={arrow} alt="arrow" />
          </div>
        )}
      </div>
      <p className={styles.title}>{sectionData.title1}</p>
      <p className={styles.subtitle}>{sectionData.title2}</p>
    </div>
  );

  return (
    <div className={styles.main}>
      <div className={styles.intro}>
        <p>Our software helps you codify what to build and why through a repeatable cycle:</p>
        <img className={styles.diagram} src={diagram} alt="diagram" />
      </div>
      <div className={styles.sectionsList}>{Object.entries(sectionData).map(renderSection)}</div>
    </div>
  );
}

export default MainSection;
