import { observer } from 'mobx-react-lite';
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';

import { ReactComponent as CloseIcon } from 'src/theme/svg/close.svg';

import styles from './Tags.module.scss';

interface TagsProps {
  value: string[];
  placeholder: string;
  onChange: (value: string[]) => void;
}

function Tags({ value, placeholder, onChange }: TagsProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setText(e.target.value);
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmedText = text.trim();

      if (!trimmedText) return;

      if (value.includes(trimmedText)) {
        return alert("You've already added this tag");
      }

      onChange([trimmedText, ...value]);
      setText('');
    }
  };

  const handleRemove = (tag: string): void => {
    onChange(value.filter((t) => t !== tag));
  };

  const handleInputFocus = (): void => {
    inputRef.current?.focus();
  };

  return (
    <div className={styles.root} onClick={handleInputFocus}>
      <input
        ref={inputRef}
        name="Tag name"
        placeholder={!value.length ? placeholder : 'Add more'}
        className={styles.input}
        value={text}
        onChange={handleChange}
        onKeyDown={handleEnter}
      />
      {value.length > 0 && (
        <>
          {value.map((tag) => (
            <div key={tag} className={styles.tag}>
              <span>{tag}</span>
              <div>
                <button onClick={() => handleRemove(tag)}>
                  <CloseIcon />
                </button>
              </div>
            </div>
          ))}
        </>
      )}
      {value.length === 0 && <span className={styles.tip}>'Enter' to add</span>}
    </div>
  );
}

export default observer(Tags);
