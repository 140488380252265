import clsx from 'clsx';

import ButtonGroup from 'src/theme/atoms/buttonGroup/ButtonGroup';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { ReactComponent as ArrowDownIcon } from 'src/theme/svg/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'src/theme/svg/arrow-up.svg';
import { TimeIntervalType } from 'src/utils/types/FeatureRelated';

import styles from './TimeEstimation.module.scss';

interface TimeEstimationProps {
  type?: TimeIntervalType;
  count: number;
  onTypeChange: (type: TimeIntervalType) => void;
  onCountChange: (count: number) => void;
}

function TimeEstimation({ type, count, onTypeChange, onCountChange }: TimeEstimationProps): JSX.Element {
  return (
    <div className={styles.root}>
      <div>
        <p className={styles.title}>Would it be</p>
        <ButtonGroup orientation="horizontal" className={styles.buttonGroup} dividerClass={styles.divider}>
          <Button
            label="days"
            color="secondary"
            size="m"
            onClick={() => onTypeChange('days')}
            className={clsx(styles.typeBtn, type === 'days' && styles.selected)}
          />
          <Button
            label="weeks"
            color="secondary"
            size="m"
            onClick={() => onTypeChange('weeks')}
            className={clsx(styles.typeBtn, type === 'weeks' && styles.selected)}
          />
          <Button
            label="months"
            color="secondary"
            size="m"
            onClick={() => onTypeChange('months')}
            className={clsx(styles.typeBtn, type === 'months' && styles.selected)}
          />
        </ButtonGroup>
      </div>
      {type && (
        <div className={styles.countBox}>
          <p className={styles.title}>How many {type}...</p>
          <div className={styles.counter}>
            <span>{count}</span>

            <div className={styles.counterArrows}>
              <button onClick={() => onCountChange(count + 1)}>
                <ArrowUpIcon />
              </button>
              <button onClick={() => onCountChange(count - 1)} disabled={count === 1}>
                <ArrowDownIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TimeEstimation;
