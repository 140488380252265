import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import RoadmapEditionStore from './RoadmapEdition.store';
import { RoadmapEditionContext } from './RoadmapEditionContext';

function RoadmapEdition(): JSX.Element {
  const { organizationId, roadmapId } = useParams();
  if (!organizationId) throw new Error('No organization id');
  if (!roadmapId) throw new Error('No roadmap id');

  const { roadmapStore } = useStores();
  const [roadmapEditionStore, setRoadmapEditionStore] = useState<RoadmapEditionStore | null>(() => null);

  useEffect(() => {
    roadmapStore.getRoadmap(organizationId, roadmapId).then((roadmap) => {
      setRoadmapEditionStore(new RoadmapEditionStore(roadmapStore, organizationId, roadmap));
    });
  }, []);

  if (!roadmapEditionStore) return <Loader />;

  return (
    <RoadmapEditionContext.Provider value={roadmapEditionStore}>
      <Outlet />
    </RoadmapEditionContext.Provider>
  );
}

export default observer(RoadmapEdition);
