import { createContext, useContext } from 'react';

import TestEditionStore from './TestEdition.store';

export const TestEditionContext = createContext<TestEditionStore | undefined>(undefined);

export const useTestEditionStore = (): TestEditionStore => {
  const testFormStore = useContext(TestEditionContext);
  if (testFormStore === undefined) {
    throw new Error('useTestEditionStore must be used within TestEditionContext');
  }
  return testFormStore;
};
