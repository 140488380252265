export type CursorPosition = { x: number; y: number };

const getCursorPositionBasedOnEvent = (e: DragEvent | TouchEvent): CursorPosition => {
  const isTouchEvent = 'changedTouches' in e;

  if (isTouchEvent) {
    return { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
  } else {
    return { x: e.clientX, y: e.clientY };
  }
};

export default getCursorPositionBasedOnEvent;
