import clsx from 'clsx';
import { useMemo } from 'react';

import { Test } from 'src/Tests/Test.types';
import { Answer, Idea } from 'src/Tests/Test.types';
import { SummaryTest } from 'src/theme/atoms/TestSummary/TestSummary.types';
import Button from 'src/theme/atoms/buttons/Button/Button';
import env from 'src/utils/env';

import { MAYBE_THRESHOLD, YES_THRESHOLD } from '../const';
import styles from './IdeaDetails.module.scss';

interface AnswerCountBarProps {
  count: number;
  allCount: number;
  barClassName: string;
  label: string;
}

const AnswerCountBar = ({ count, allCount, barClassName, label }: AnswerCountBarProps): JSX.Element => {
  const percentRounded = allCount === 0 ? '0%' : Math.round((count / allCount) * 100) + '%';

  return (
    <div className={styles.response}>
      <div className={styles.barWrapper}>
        <div className={styles.barBg} />
        {percentRounded !== '0%' && <div className={barClassName} style={{ width: percentRounded }} />}
      </div>
      <p className={styles.percent}>{percentRounded}</p>
      <p className={styles.text}>{label}</p>
      <p className={styles.number}>({count})</p>
    </div>
  );
};

interface IdeaDetailsProps {
  idea: Idea;
  test: SummaryTest | Test;
  onShowResponses?: () => void;
  className?: string;
}

function IdeaDetails({ idea, onShowResponses, className, test }: IdeaDetailsProps): JSX.Element {
  if (!test) throw new Error('No test found');

  const answerCounts = useMemo(() => {
    const answers: Answer[] = test.answers.filter((ans) => ans.ideaId === idea.id);

    return {
      yes: answers.reduce((n, ans: Answer) => n + (ans.isApproving ? 1 : 0), 0),
      no: answers.reduce((n, ans: Answer) => n + (!ans.isApproving ? 1 : 0), 0),
      all: answers.length,
    };
  }, [test]);

  const getAnswersRange = ({ no, maybe, yes, idk }): string => {
    return env.howManyRespondentsPerIdeaNeeded > answerCounts.all
      ? idk
      : answerCounts.yes / answerCounts.all >= YES_THRESHOLD
      ? yes
      : answerCounts.yes / answerCounts.all >= MAYBE_THRESHOLD
      ? maybe
      : no;
  };

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.counter}>
        <div className={styles.dotAndComment}>
          <div
            className={clsx(
              styles.dot,
              styles[
                getAnswersRange({
                  no: 'red',
                  maybe: 'yellow',
                  yes: 'green',
                  idk: 'grey',
                })
              ]
            )}
          />
          <div className={styles.comment}>
            {getAnswersRange({
              no: 'No',
              maybe: 'Maybe',
              yes: 'Yes',
              idk: 'Not enough responses',
            })}
          </div>
        </div>
        {onShowResponses ? (
          <Button color="secondary" size="m" label="show responses" onClick={onShowResponses} />
        ) : (
          <div />
        )}
        <div className={styles.howManyResponses}>
          <div className={styles.text}>RESPONSES</div>
          <div className={styles.number}>{answerCounts.all}</div>
        </div>
        <div className={styles.howManyNeeded}>
          <div className={styles.text}>WHAT YOU NEED</div>
          <div className={styles.number}>{env.howManyRespondentsPerIdeaNeeded}</div>
        </div>
      </div>
      <p className={styles.desc}>{idea.text}</p>
      <div className={styles.responses}>
        <AnswerCountBar count={answerCounts.yes} allCount={answerCounts.all} barClassName={styles.barYes} label="Yes" />
        <AnswerCountBar count={answerCounts.no} allCount={answerCounts.all} barClassName={styles.barNo} label="No" />
      </div>
    </div>
  );
}

export default IdeaDetails;
