import { makeAutoObservable } from 'mobx';

import IdeaState from 'src/Tests/IdeaState.store';
import { Idea } from 'src/Tests/Test.types';

import TestEditionStore from '../TestEdition.store';

class IdeasManagementStore {
  testEditionStore: TestEditionStore;

  ideaStates: IdeaState[] = [];

  constructor(testEditionStore: TestEditionStore) {
    this.testEditionStore = testEditionStore;

    this.ideaStates = [
      this.initializeIdeaState(null),
      ...testEditionStore.ideas.map((idea) => this.initializeIdeaState(idea)),
    ];

    makeAutoObservable(this);
  }

  private initializeIdeaState = (idea: Idea | null): IdeaState => {
    return new IdeaState(
      this.testEditionStore,
      idea,
      null,
      {
        onEdit: this.closeOtherIdeas,
        onDelete: this.deleteIdea,
        onCreate: this.addIdea,
        onDuplicate: this.addDuplicatedIdea,
      },
      'delete'
    );
  };

  private getIdeaStateIndexById = (id: string): number => {
    const index = this.ideaStates.findIndex((fs) => fs.id === id);
    if (index === -1) throw new Error('No idea index found');
    return index;
  };

  get ideasCount(): number {
    return this.ideaStates.length - 1;
  }

  closeOtherIdeas = (): void => {
    //
  };

  addIdea = (): void => {
    const idea = this.initializeIdeaState(null);
    this.ideaStates.unshift(idea);
  };

  deleteIdea = (id: string): void => {
    const index = this.getIdeaStateIndexById(id);
    this.ideaStates.splice(index, 1);
  };

  addDuplicatedIdea = (): void => {
    //
  };

  moveIdea = (dragSourceIndex: number, newIndex: number): void => {
    const deletedIdeaState = this.ideaStates.splice(dragSourceIndex, 1)[0];
    this.ideaStates.splice(newIndex, 0, deletedIdeaState);
  };

  saveIdeasOrder = (): void => {
    const ideaGroupIds = this.ideaStates.filter((fgs) => fgs.id).map((fgs) => fgs.id);
    this.testEditionStore.updateIdeasSequence(ideaGroupIds);
  };
}

export default IdeasManagementStore;
