import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useStores } from 'src/RootStore';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { Input } from 'src/theme/atoms/inputs';
import Modal from 'src/theme/atoms/modal/Modal';

import styles from './CollectName.module.scss';

interface CollectNameProps {
  onSuccess: () => void;
  onFail: () => void;
}

function CollectName({ onSuccess, onFail }: CollectNameProps): JSX.Element {
  const { authStore } = useStores();

  const [name, setName] = useState<string>('');

  const saveName = (): void => {
    authStore
      .updateName(name)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFail();
      });
  };

  return (
    <Modal>
      <div className={styles.modalInner}>
        <div className={styles.modalHeader}>
          <p className={styles.modalTitle}>What is your name?</p>
        </div>
        <div className={styles.modalForm}>
          <Input
            color="white"
            name="name"
            label="What is your name?"
            placeholder=""
            size="l"
            type="text"
            onChange={(val) => setName(val)}
            onEnter={name ? saveName : undefined}
            value={name}
          />
          <div className={styles.modalHelper}>
            We have not collected your name yet, so please provide it now. We will use it for your profile only.
          </div>
          <div className={styles.modalActions}>
            <Button label="Save" color="primary" size="l" onClick={saveName} disabled={!name} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default observer(CollectName);
