import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { BasicTest } from 'src/Tests/Test.types';
import { paths } from 'src/app/routes/paths.const';
import { BasicRoadmap } from 'src/roadmap/Roadmap.types';
import SubscriptionLimitsExceededModal from 'src/theme/atoms/SubscriptionLimitsExceededModal/SubscriptionLimitsExceededModal';
import Delete from 'src/theme/atoms/delete/Delete';
import Duplicate from 'src/theme/atoms/duplicate/Duplicate';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import { useModal } from 'src/utils/hooks/useModal';

import ProjectCard from './ProjectCard/ProjectCard';
import styles from './ProjectsLayout.module.scss';

type RoadmapProjectType = {
  originalItem: BasicRoadmap;
  type: 'roadmap';
};

type TestProjectType = {
  originalItem: BasicTest;
  type: 'test';
};

export type Project = RoadmapProjectType | TestProjectType;

interface ProjectsLayoutProps {
  organizationId: string;
  items: BasicRoadmap[] | BasicTest[];
  onReqSuccess: () => void;
}

export type ModalAction = 'duplicate' | 'delete' | null;

function ProjectsLayout({ organizationId, items, onReqSuccess }: ProjectsLayoutProps): JSX.Element {
  const projects = items.map((el: BasicRoadmap | BasicTest): Project => {
    if ('problem' in el) {
      return { originalItem: el, type: 'test' };
    } else {
      return { originalItem: el, type: 'roadmap' };
    }
  });
  const [selectedItem, setSelectedItem] = useState<Project | null>(null);

  const [modalAction, setModalAction] = useState<ModalAction>(null);
  const [isSubscriptionLimitExceededModalOpen, setIsSubscriptionLimitExceededModalOpen] = useState(false);
  const [isUneditableTestInfoOpen, setIsUneditableTestInfoOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const { closeModal } = useModal();

  const onCardMenuClick = (item: Project): void => {
    setSelectedItem(item);
  };

  const handleModalClose = (): void => {
    setModalAction(null);
    setSelectedItem(null);
    closeModal();
  };

  const handleReqSuccess = (): void => {
    setModalAction(null);
    setSelectedItem(null);
    onReqSuccess();
  };

  return (
    <div className={styles.root}>
      {projects.map((project) => (
        <ProjectCard
          key={project.originalItem.id}
          project={project}
          link={
            project.type === 'roadmap'
              ? paths.roadmapDetails(organizationId, project.originalItem.id)
              : project.originalItem.state === 'draft'
              ? '#'
              : paths.testResults(organizationId, project.originalItem.id)
          }
          onMenuClick={onCardMenuClick}
          changeModalAction={(action) => setModalAction(action)}
          showUneditableTestInfo={() => setIsUneditableTestInfoOpen(true)}
        />
      ))}
      {selectedItem && (
        <Delete
          isModalOpen={modalAction === 'delete'}
          organizationId={organizationId}
          itemId={selectedItem.originalItem.id}
          itemName={selectedItem.originalItem.name}
          itemType={selectedItem.type}
          onModalClose={handleModalClose}
          onSuccess={handleReqSuccess}
          onFail={() => setSnackbarMessage('Project has not been deleted. Please try again.')}
        />
      )}
      {selectedItem && (
        <Duplicate
          isModalOpen={modalAction === 'duplicate'}
          organizationId={organizationId}
          itemId={selectedItem.originalItem.id}
          itemName={selectedItem.originalItem.name}
          itemType={selectedItem.type}
          onClose={handleModalClose}
          onSuccess={handleReqSuccess}
          onFail={() => setSnackbarMessage('Project has not been duplicated. Please try again.')}
          onDuplicate402Error={() => setIsSubscriptionLimitExceededModalOpen(true)}
        />
      )}
      <Snackbar type="error" message={snackbarMessage} onClose={() => setSnackbarMessage('')} autoclose />
      <SubscriptionLimitsExceededModal
        isOpen={isSubscriptionLimitExceededModalOpen}
        onClose={() => setIsSubscriptionLimitExceededModalOpen(false)}
      />
      {isUneditableTestInfoOpen && (
        <Snackbar
          type="info"
          message="You can't edit a test that's not in draft state."
          onClose={() => setIsUneditableTestInfoOpen(false)}
          autoclose
        />
      )}
    </div>
  );
}

export default observer(ProjectsLayout);
