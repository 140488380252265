import Quiz from './Quiz';
import { useQuiz } from './QuizLayout';

function TakeQuiz(): JSX.Element {
  const { quiz } = useQuiz();

  return <Quiz quiz={quiz} />;
}

export default TakeQuiz;
