import clsx from 'clsx';
import { Link } from 'react-router-dom';

import styles from '../base.module.scss';
import { ButtonLinkProps } from '../buttons.types';
import getButtonBaseClasses from '../getButtonBaseClasses';

function ButtonLink({
  to,
  label,
  color,
  size,
  className,
  startIcon,
  endIcon,
  maxWidth,
  ignoreIconStyling,
  onClick,
  ...rest
}: ButtonLinkProps): JSX.Element {
  const buttonBaseClasses = getButtonBaseClasses({
    color,
    size,
    className,
    maxWidth,
    ignoreIconStyling,
  });

  return (
    <Link className={clsx(styles.root, buttonBaseClasses)} onClick={onClick} to={to} {...rest}>
      <div className={clsx(styles.content)}>
        <span className={clsx(styles.icon, styles.startIcon, { visible: startIcon })}>{startIcon}</span>
        <span className={styles.label}>{label}</span>
        <span className={clsx(styles.icon, styles.endIcon, { visible: endIcon })}>{endIcon}</span>
      </div>
    </Link>
  );
}

export default ButtonLink;
