import { ReactNode, KeyboardEvent } from 'react';

import Container from 'src/theme/atoms/container/Container';
import Input from 'src/theme/atoms/inputs/Input/Input';
import { InputType } from 'src/theme/atoms/inputs/types';
import { CustomError } from 'src/utils/types/CustomError';

import styles from './SignupField.module.scss';

type Props = {
  title?: ReactNode | string;
  name: string;
  type: InputType;
  helperText?: string;
  placeholder: string;
  value?: string;
  error?: CustomError;
  maxLength?: number;
  hasAdvanceText?: boolean;
  socialSection?: ReactNode;
  confirmPasswordSection?: ReactNode;
  additionalContent?: ReactNode;
  onChange?: (value: string) => void;
  onEnterPress?: (value: KeyboardEvent<HTMLInputElement>) => void;
};

const SignupField = ({
  title,
  name,
  type,
  helperText,
  placeholder,
  onChange,
  onEnterPress,
  error,
  maxLength,
  value,
  hasAdvanceText,
  socialSection,
  confirmPasswordSection,
  additionalContent,
}: Props): JSX.Element => {
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.outerWrapper}>
          <div className={styles.wrapper}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.field}>
              <Input
                name={name}
                type={type}
                className={styles.input}
                helperText={helperText}
                advanceText={hasAdvanceText && value && !error ? 'Hit enter to advance' : ''}
                error={error}
                maxLength={maxLength}
                color="white"
                size="s"
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onEnter={onEnterPress}
              />
            </div>
            {socialSection}
            {confirmPasswordSection}
            {additionalContent}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SignupField;
