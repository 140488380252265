import debounce from 'lodash.debounce';
import { makeAutoObservable } from 'mobx';

import FeatureState from 'src/roadmap/FeatureState.store';
import RoadmapEditionStore from 'src/roadmap/RoadmapEdition/RoadmapEdition.store';
import getRandomId from 'src/utils/getRandomId';
import { CustomError } from 'src/utils/types/CustomError';
import { Feature, FeatureGroup } from 'src/utils/types/FeatureRelated';

import FeatureGroupsCreationStore from './FeatureGroupsCreation.store';

interface Callbacks {
  onCreate: () => void;
  onDelete: (id: string) => void;
  onDuplicateFG: (fg: FeatureGroup, id: string) => void;
}

class FeatureGroupState {
  roadmapEditionStore: RoadmapEditionStore;
  parentStore: FeatureGroupsCreationStore;
  callbacks: Callbacks;
  featureGroup: FeatureGroup | null = null;

  localId = '';
  id = '';
  name = '';
  isEditing = false;
  status: 'default' | 'deleting' | 'saving' | 'duplicating' = 'default';
  state: 'default' | 'deleted' | 'duplicated' = 'default';
  featureStates: FeatureState[] = [];

  error: CustomError | null = null;
  seq: number | undefined;

  constructor(
    roadmapEditionStore: RoadmapEditionStore,
    parentStore: FeatureGroupsCreationStore,
    featureGroup: FeatureGroup | null,
    callbacks: Callbacks
  ) {
    this.roadmapEditionStore = roadmapEditionStore;
    this.callbacks = callbacks;
    this.parentStore = parentStore;

    if (featureGroup) {
      this.featureGroup = featureGroup;

      this.localId = featureGroup.id;
      this.id = featureGroup.id;
      this.name = featureGroup.name;
      this.seq = featureGroup.seq;

      this.featureStates = featureGroup.features
        .map((f) => this.initializeFeatureState(f))
        .sort((a, b) => {
          if (a?.feature?.seq !== undefined && b?.feature?.seq !== undefined) {
            return a.feature.seq - b.feature.seq;
          } else {
            return 0;
          }
        });
    } else {
      this.localId = getRandomId();
    }

    makeAutoObservable(this);
  }

  private initializeFeatureState = (feature: Feature | null): FeatureState => {
    return new FeatureState(
      this.roadmapEditionStore,
      feature,
      this.id,
      {
        onEdit: this.parentStore.closeOtherFeatures,
        onDelete: this.deleteFeature,
        onDuplicate: this.addDuplicatedFeature,
      },
      'delete'
    );
  };

  addDuplicatedFeature = (duplicatedFeature: Feature): void => {
    const duplicatedF = this.initializeFeatureState(duplicatedFeature);
    this.parentStore.featureStates.splice(1, 0, duplicatedF);
  };

  saveNameChange = debounce((name: string): void => {
    this.error = null;

    if (!name) {
      this.error = {
        message: "User need can't be empty",
      };
      return;
    }

    this.roadmapEditionStore
      .updateFeatureGroup(this.id, {
        name: name.trim(),
      })
      .then((updatedFg) => {
        this.featureGroup = updatedFg;
      })
      .catch((err: CustomError) => {
        this.error = err;
      });
  }, 500);

  changeName = (value: string): void => {
    this.error = null;
    this.name = value;

    if (this.id) {
      this.saveNameChange(value);
    }
  };

  delete = (): void => {
    this.status = 'deleting';

    this.roadmapEditionStore
      .deleteFeatureGroup(this.id)
      .then(() => {
        this.state = 'deleted';

        document.addEventListener(
          'animationend',
          () => {
            this.callbacks.onDelete(this.id);
          },
          { once: true }
        );
      })
      .catch((err: CustomError) => {
        alert(err.message);
      })
      .finally(() => {
        this.status = 'default';
      });
  };

  create = (): Promise<void> => {
    this.error = null;
    this.status = 'saving';

    return this.roadmapEditionStore
      .createFeatureGroup(this.name)
      .then((createdFg) => {
        this.id = createdFg.id;
        this.featureGroup = createdFg;

        this.callbacks.onCreate();
      })
      .catch((err: CustomError) => {
        this.error = err;
        throw this.error;
      })
      .finally(() => {
        this.status = 'default';
      });
  };

  incrementSeq = (): void => {
    this.seq && this.seq++;
  };

  changeSeq = (seq: number): void => {
    if (this.featureGroup) {
      this.seq = seq;
    }
  };

  duplicate = (): Promise<void> => {
    this.error = null;
    this.status = 'duplicating';

    return this.roadmapEditionStore
      .duplicateFeatureGroup(this.id)
      .then((duplicatedFg) => {
        this.state = 'duplicated';
        this.callbacks.onDuplicateFG(duplicatedFg, this.id);

        document.addEventListener(
          'animationend',
          () => {
            this.state = 'default';
          },
          { once: true }
        );
      })
      .catch((err: CustomError) => {
        this.error = err;
        throw this.error;
      })
      .finally(() => {
        this.status = 'default';
      });
  };

  insertFeatureOnIndex = (startFeatureIndex: number, featureState: FeatureState): void => {
    this.revalidateFeatureState(featureState);
    this.featureStates.splice(startFeatureIndex, 0, featureState);
  };

  extractFeatureFromIndex = (startFeatureIndex: number): FeatureState => {
    const deletedFeatures = this.featureStates.splice(startFeatureIndex, 1);
    return deletedFeatures[0];
  };

  pushFeature = (featureState: FeatureState): void => {
    this.revalidateFeatureState(featureState);
    this.featureStates.push(featureState);
  };

  /* Use this function when feature was dragged from "Features" column and dropped into any other column.
  It is necessary because featureState that was initially initialized inside "Features" column has
  different properties than feature initialized inside any other column. */
  private revalidateFeatureState = (featureState: FeatureState): void => {
    featureState.changeFeatureGroupId(this.id);
    featureState.changeCallbacks({
      onEdit: this.parentStore.closeOtherFeatures,
      onDelete: this.deleteFeature,
      onDuplicate: this.addDuplicatedFeature,
    });
  };

  private deleteFeature = (id: string): void => {
    const fId = this.featureStates.findIndex((f) => f.id === id);
    if (fId !== -1) this.featureStates.splice(fId, 1);
  };

  startEditing = (): void => {
    this.isEditing = true;
  };

  stopEditing = (): void => {
    this.isEditing = false;
  };
}

export default FeatureGroupState;
