import { FeatureCardColumn } from 'src/roadmap/Roadmap.types';
import useWindowSize from 'src/utils/hooks/useWindowSize';

import FeatureState from '../FeatureState.store';
import {
  BREAKPOINT_FOR_1_COLUMNS_OF_FETURES,
  BREAKPOINT_FOR_2_COLUMNS_OF_FETURES,
  BREAKPOINT_FOR_3_COLUMNS_OF_FETURES,
} from './constants';

const useFeatureColumns = (featureCards: FeatureState[], skipFirstColumn: boolean): FeatureCardColumn[] => {
  const [windowWidth] = useWindowSize();
  const columnsCount =
    windowWidth < BREAKPOINT_FOR_1_COLUMNS_OF_FETURES
      ? 1
      : windowWidth < BREAKPOINT_FOR_2_COLUMNS_OF_FETURES
      ? 2
      : windowWidth < BREAKPOINT_FOR_3_COLUMNS_OF_FETURES
      ? 3
      : 4;

  let counter = 0;
  const columns: {
    id: string;
    items: FeatureState[];
  }[] = Array.from(Array(columnsCount)).map((x, i) => ({
    id: `${i}`,
    items: [],
  }));

  featureCards.forEach((featureCard, i) => {
    if (i === 0) counter = columnsCount === 1 ? 0 : skipFirstColumn ? 1 : 0;
    columns[counter].items.push(featureCard);
    counter++;
    if (counter > columnsCount - 1) counter = 0;
  });

  return columns;
};

export default useFeatureColumns;
