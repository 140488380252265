import { autoUpdate, offset, useFloating } from '@floating-ui/react-dom';
import { observer } from 'mobx-react-lite';

import FeatureState from 'src/roadmap/FeatureState.store';
import TutorialTooltip from 'src/theme/atoms/TutorialTooltip/TutorialTooltip';
import { ColumnBox } from 'src/theme/atoms/groupCreation';

import FeaturesDropArea from '../FeaturesDropArea/FeaturesDropArea';
import styles from './FeaturesColumn.module.scss';

export const FEATURES_COLUMN_ID = 'FeaturesColumn';

interface FeaturesColumnProps {
  featureStates: FeatureState[];
  moveFeatureToDifferentColumn: (
    dragSourceIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ) => number;
  moveFeatureToDifferentSlot: (
    dragSourceIndex: number,
    dropTargetIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ) => void;
  onDrop: (index: number, columnIndex: number) => void;
  currentTutorialStep: number;
  changeTutorialStep: (step: number) => void;
  endTutorial: () => void;
  horizontalScrollRef: React.RefObject<HTMLElement>;
}

function FeaturesColumn({
  featureStates,
  moveFeatureToDifferentColumn,
  moveFeatureToDifferentSlot,
  onDrop,
  currentTutorialStep,
  changeTutorialStep,
  endTutorial,
  horizontalScrollRef,
}: FeaturesColumnProps): JSX.Element {
  // used for keeping the tutorial tooltip on top and anchored to an appropriate element
  const { x, y, reference, floating, strategy } = useFloating({
    strategy: 'fixed',
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: -80,
      }),
    ],
  });

  const tutorialTooltip = (
    <TutorialTooltip
      currentStep={currentTutorialStep}
      ownStep={0}
      stepsCount={2}
      headerText={'You can start by'}
      mainText={'Adding in features like “login” or “onboarding flow.” You can drag and drop the cards, edit, delete.'}
      onClose={endTutorial}
      changeStep={changeTutorialStep}
      floatingRef={floating}
      floatingStyle={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      horizontalScrollRef={horizontalScrollRef}
    />
  );

  return (
    <ColumnBox>
      <div className={styles.root} id={FEATURES_COLUMN_ID} ref={reference}>
        {tutorialTooltip}
        <div className={styles.content}>
          <h3 className={styles.title}>Features</h3>
        </div>
        <FeaturesDropArea
          columnIndex={-1}
          featureStates={featureStates}
          moveFeatureToDifferentColumn={moveFeatureToDifferentColumn}
          moveFeatureToDifferentSlot={moveFeatureToDifferentSlot}
          onDrop={onDrop}
        />
      </div>
    </ColumnBox>
  );
}

export default observer(FeaturesColumn);
