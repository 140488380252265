import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { Idea } from 'src/Tests/Test.types';
import TestStepHeader from 'src/Tests/TestStepHeader/TestStepHeader';
import { history } from 'src/app/routes/Router';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import TestResponsesSummary from 'src/theme/atoms/TestSummary/TestResponsesSummary/TestResponsesSummary';
import TestSummary from 'src/theme/atoms/TestSummary/TestSummary';
import { BACK_TO_HOME_BTN } from 'src/utils/constants';

import { useTest } from '../TestLayout';

dayjs.extend(relativeTime);

function TestResults(): JSX.Element {
  const { testStore } = useStores();

  const organization = useOrganization();
  const { test, changeTest } = useTest();

  const [showIdea, setShowIdea] = useState<Idea | undefined>(undefined);
  const [testResultsShareLinkId, setTestResultsShareLinkId] = useState('');

  if (!test) throw new Error('No test found');

  if (test.state === 'draft') {
    return <Navigate to={paths.organizationDashboard(organization.id)} />;
  }

  useEffect(() => {
    testStore.getShareLink(organization.id, test.id).then((id) => {
      setTestResultsShareLinkId(id);
    });
  }, []);

  const handleTestStatusChange = async (): Promise<void> => {
    const nextState = test.state === 'running' ? 'stopped' : 'running';
    await testStore.updateTest(organization.id, test.id, { state: nextState });
    const updatedTest = await testStore.getTest(organization.id, test.id);
    changeTest(updatedTest);
  };

  return (
    <div>
      <TestStepHeader
        testName={test.name}
        title="Results"
        subtitle="Be sure to let your test run for long with enough respondents."
        helpButtonConfig={{
          label: !showIdea ? BACK_TO_HOME_BTN : 'Back to test resuts',
          action: () =>
            !showIdea ? history.replace(paths.organizationDashboard(organization.id)) : setShowIdea(undefined),
        }}
        // actionButtonConfig={{ label: 'What do i do now', action: () => console.log() }} // TODO
        isBottomDivider={false}
      />
      {!showIdea ? (
        <TestSummary
          summaryTest={test}
          onShowResponsesClick={setShowIdea}
          testResultsShareLinkId={testResultsShareLinkId}
          onChangeTest={handleTestStatusChange}
          isUpdatingTest={testStore.isUpdatingTest}
        />
      ) : (
        <TestResponsesSummary summaryTest={test} idea={showIdea} />
      )}
    </div>
  );
}

export default observer(TestResults);
