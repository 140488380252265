import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';
import { ChartFeatureGroup, SummaryRoadmap } from 'src/roadmap/RoadmapDetails/RoadmapDetails.types';
import { Feature, FeatureGroup } from 'src/utils/types/FeatureRelated';

import { SnackbarProps } from '../snackbar/Snackbar';

export interface Tile {
  id: string;
  index: number;
  el: HTMLDivElement | null;
  fg: ChartFeatureGroup;
  type: 'development' | 'research';
}

const getFeaturesWithNoGroup = (featureGroups: FeatureGroup[], features: Feature[]): Feature[] => {
  const idsOfGroupedFeatures = featureGroups.flatMap((fg) => fg.features.map((f) => f.id));
  return features.filter((f) => !idsOfGroupedFeatures.includes(f.id));
};

const offset = 3;
const weeksColumnWidth = 127;

class RoadmapSummaryStore {
  organizationId: string | undefined;
  roadmapId: string | undefined;

  featureGroupsDontBuild: FeatureGroup[];
  featuresWithNoGroup: Feature[];

  tiles: Tile[] = [];

  currentlyDraggedTileId: string | null = null;
  prevClientX: number | null = null;
  translateX = 0;
  chartWeeks: number;

  isReseting = false;

  snackbarConfig: {
    message: string;
    type: SnackbarProps['type'];
  } = {
    message: '',
    type: 'success',
  };

  constructor(summaryRoadmap: SummaryRoadmap, organizationId: string | undefined, roadmapId: string | undefined) {
    this.featureGroupsDontBuild = summaryRoadmap.featureGroups.filter((fg) => fg.chartPriority >= 6);
    this.featuresWithNoGroup = getFeaturesWithNoGroup(summaryRoadmap.featureGroups, summaryRoadmap.features);

    const chartFeatureGroups = summaryRoadmap.featureGroups
      .map((fg) => ({ ...fg, chartWeeksForward: fg.chartWeeksForward || 0 }))
      .filter((fg): fg is ChartFeatureGroup => fg.chartWeekStart !== undefined && fg.chartWeekEnd !== undefined)
      .sort((a, b) => a.chartPriority - b.chartPriority);

    this.tiles = chartFeatureGroups.map((fg, i) => ({
      id: fg.id,
      index: i,
      el: null,
      fg,
      type: fg.chartPriority < 4 ? 'development' : 'research',
    }));

    const chartWeekEnds = summaryRoadmap.featureGroups
      .filter((fg) => fg.chartPriority < 6)
      .map((fg) => fg.chartWeekEnd)
      .filter((el): el is number => el !== undefined);

    this.chartWeeks = chartWeekEnds.length ? Math.max(...chartWeekEnds) - 1 : 0;

    this.organizationId = organizationId;
    this.roadmapId = roadmapId;

    makeAutoObservable(this);
  }

  get tilesDevelopment(): Tile[] {
    return this.tiles.filter((tile) => tile.type === 'development');
  }

  get tilesResearch(): Tile[] {
    return this.tiles.filter((tile) => tile.type === 'research');
  }

  get isTilesPlacementChanged(): boolean {
    return this.tiles.some((tile) => tile.fg.chartWeeksForward !== 0);
  }

  assignTileRef = (id: string, el: HTMLDivElement | null): void => {
    if (!el) return;
    const tileIndex = this.tiles.findIndex((tile) => tile.id === id);
    if (tileIndex === -1) throw new Error('No tile to assign ref found');
    this.tiles[tileIndex].el = el;
  };

  dragTileByMouseMove = (e: MouseEvent): void => {
    if (!this.currentlyDraggedTileId) throw new Error('No dragged tile');
    const draggedTileIndex = this.tiles.findIndex((tile) => tile.id === this.currentlyDraggedTileId);
    if (draggedTileIndex === -1) throw new Error('No tile found');

    const draggedTile = this.tiles[draggedTileIndex];
    if (!draggedTile.el) throw new Error('No dragged tile DOM element found');

    const { clientX } = e;
    const diff = this.prevClientX !== null ? clientX - this.prevClientX : 0;
    this.prevClientX = clientX;
    this.translateX = this.translateX + diff;

    const chartData = this.getChartData();

    const tileAboveDraggedEl = this.tiles[draggedTile.index - 1];
    if (!tileAboveDraggedEl.el) throw new Error('No tile DOM element found');
    const tileAboveDraggedElParams = tileAboveDraggedEl.el.getBoundingClientRect();

    for (let i = draggedTile.index; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      const tileEl = tile.el as HTMLDivElement;
      const tileElParams = tileEl.getBoundingClientRect();

      if (i === draggedTile.index) {
        const hasOverflowedTileAboveOnLeft = tileElParams.left + diff < tileAboveDraggedElParams.left + offset;

        const hasOverflowedTileAboveOnRight =
          tileElParams.left - chartData.params.left - weeksColumnWidth + diff >
          (tileAboveDraggedEl.fg.chartWeekEnd - tileAboveDraggedEl.fg.chartWeeksForward) * chartData.columnWidth -
            offset;

        if (hasOverflowedTileAboveOnLeft) {
          const startColumn = tile.fg.chartWeekStart - tile.fg.chartWeeksForward;
          const minColumns = tileAboveDraggedEl.fg.chartWeekStart - tileAboveDraggedEl.fg.chartWeeksForward;
          const columnCount = startColumn - minColumns;

          this.translateX = -(columnCount * chartData.columnWidth) + offset;
        } else if (hasOverflowedTileAboveOnRight) {
          const maxColumns = tileAboveDraggedEl.fg.chartWeekEnd - tileAboveDraggedEl.fg.chartWeeksForward + 1;
          const startColumn = tile.fg.chartWeekStart - tile.fg.chartWeeksForward;
          const columnCount = maxColumns - startColumn;

          this.translateX = columnCount * chartData.columnWidth - offset;
        }
      }

      tileEl.style.transform = `translateX(${this.translateX}px)`;
    }
  };

  stopDraggingTile = (): void => {
    if (!this.currentlyDraggedTileId) return;
    const tileIndex = this.tiles.findIndex((tile) => tile.id === this.currentlyDraggedTileId);
    if (tileIndex === -1) throw new Error('No tile index found');

    const chartData = this.getChartData();

    for (let i = tileIndex; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      if (!tile.el) throw new Error('No tile DOM element found');

      const tileElParams = tile.el.getBoundingClientRect();
      const tileRelativeLeft = tileElParams.left - chartData.params.left - weeksColumnWidth;
      const tileWeekStart = tileRelativeLeft > 0 ? Math.floor(tileRelativeLeft / chartData.columnWidth) + 1 : 1;
      const chartWeeksForward = tile.fg.chartWeekStart - tileWeekStart;

      if (chartWeeksForward !== tile.fg.chartWeeksForward) {
        tile.fg.chartWeeksForward = chartWeeksForward;

        if (!this.organizationId || !this.roadmapId) throw new Error('No organizationId or roadmapId');

        rootStore.roadmapStore
          .updateFeatureGroup(this.organizationId, this.roadmapId, tile.fg.id, {
            name: tile.fg.name,
            chartWeeksForward,
          })
          .catch(() => {
            this.snackbarConfig = {
              message: "Couldn't save new feature group placement. Please refresh the page and try again.",
              type: 'error',
            };
          });
      }

      tile.el.style.transform = `translateX(0px)`;
    }

    this.prevClientX = null;
    this.translateX = 0;
    this.currentlyDraggedTileId = null;

    document.body.style.cursor = 'default';

    document.removeEventListener('mouseup', this.stopDraggingTile);
    document.removeEventListener('mousemove', this.dragTileByMouseMove);
  };

  getChartData = (): { params: DOMRect; left: number; width: number; columnWidth: number } => {
    const chartEl = document.getElementById('chart');
    if (!chartEl) throw new Error('No chart DOM element found');

    const params = chartEl.getBoundingClientRect();
    const left = params.left + weeksColumnWidth;
    const width = params.width - weeksColumnWidth;
    const columnWidth = width / this.chartWeeks;

    return {
      params,
      left,
      width,
      columnWidth,
    };
  };

  startDraggingTile = (id: string): void => {
    this.currentlyDraggedTileId = id;

    document.body.style.cursor = 'grabbing';

    document.addEventListener('mouseup', this.stopDraggingTile);
    document.addEventListener('mousemove', this.dragTileByMouseMove);
  };

  resetFeatureGroupsPlacementOnChart = (): void => {
    const tilesWithWeeksForward = this.tiles.filter((tile) => tile.fg.chartWeeksForward > 0);
    const promises = tilesWithWeeksForward.map((tile) => {
      if (!this.organizationId || !this.roadmapId) throw new Error('No organizationId or roadmapId');
      return rootStore.roadmapStore.updateFeatureGroup(this.organizationId, this.roadmapId, tile.fg.id, {
        name: tile.fg.name,
        chartWeeksForward: 0,
      });
    });

    this.isReseting = true;

    Promise.all(promises)
      .then(() => {
        this.tiles.forEach((tile) => {
          tile.fg.chartWeeksForward = 0;
        });
      })
      .catch(() => {
        this.snackbarConfig = {
          message: "Couldn't reset feature groups placement on chart. Try again later.",
          type: 'error',
        };
      })
      .finally(() => {
        this.isReseting = false;
      });
  };

  closeSnackbar = (): void => {
    this.snackbarConfig.message = '';
  };
}

export default RoadmapSummaryStore;
