import { observer } from 'mobx-react-lite';
import { useDrop } from 'react-dnd';

import FeatureState from 'src/roadmap/FeatureState.store';
import { FEATURE_CARD_DND_ITEM } from 'src/utils/constants';

import FeatureCardDnD from '../FeatureCardDnD/FeatureCardDnD';
import { FeatureCardDnDItem } from '../FeatureCardDnDItem.type';
import styles from './FeaturesDropArea.module.scss';

export const FEATURES_DROP_AREA_CONTENT_ID = 'featuresDropAreaContent';

interface FeaturesDropAreaProps {
  columnIndex: number;
  featureStates: FeatureState[];
  moveFeatureToDifferentColumn: (
    dragSourceIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ) => number;
  moveFeatureToDifferentSlot: (
    dragSourceIndex: number,
    dropTargetIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ) => void;
  onDrop: (index: number, columnIndex: number) => void;
}

function FeaturesDropArea({
  columnIndex,
  featureStates,
  moveFeatureToDifferentColumn,
  moveFeatureToDifferentSlot,
  onDrop,
}: FeaturesDropAreaProps): JSX.Element {
  const [, drop] = useDrop<FeatureCardDnDItem, void, { isOver: boolean; canDrop: boolean }>({
    accept: FEATURE_CARD_DND_ITEM,
    hover: (item) => {
      if (item.columnIndex === columnIndex) return;

      const newIndex = moveFeatureToDifferentColumn(item.index, item.columnIndex, columnIndex);

      if (item.prevIndex === null) item.prevIndex = item.index;
      if (item.prevColumnIndex === null) item.prevColumnIndex = item.columnIndex;
      item.index = newIndex;
      item.columnIndex = columnIndex;
    },
    drop: (item) => {
      const isSameSlot = item.prevColumnIndex === item.columnIndex && item.prevIndex === item.index;

      if (item.prevColumnIndex !== null && !isSameSlot) {
        onDrop(item.prevColumnIndex, columnIndex);
      }

      item.prevIndex = null;
      item.prevColumnIndex = null;
    },
  });

  return (
    <div className={styles.root}>
      {featureStates.length > 0 && (
        <div className={styles.contentContainer}>
          <div id={FEATURES_DROP_AREA_CONTENT_ID} className={styles.content}>
            {featureStates.map((fs, index) => (
              <div key={fs.id} className={styles.featureCardBox}>
                <FeatureCardDnD
                  columnIndex={columnIndex}
                  index={index}
                  featureState={fs}
                  move={moveFeatureToDifferentSlot}
                  onDrop={onDrop}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div ref={drop} className={styles.dropTarget} />
    </div>
  );
}

export default observer(FeaturesDropArea);
