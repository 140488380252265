import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { FEATURE_GROUP_COLUMN_DND_ITEM } from 'src/utils/constants';

import FeatureGroupState from '../../FeatureGroupState.store';
import { FeatureGroupColumnDnDItem } from '../FeatureGroupColumnDnDItem.type';
import styles from './FeatureGroupColumnDnDWrapper.module.scss';

interface FeatureGroupColumnDnDWrapperProps {
  index: number;
  featureGroupState: FeatureGroupState;
  children: ReactNode;
  isNameInputFocused: boolean;
  move: (dragSourceIndex: number, dropTargetIndex: number) => void;
  onDrop?: (id: string) => void;
}

function FeatureGroupColumnDnDWrapper({
  index,
  featureGroupState: fgs,
  children,
  isNameInputFocused,
  move,
  onDrop,
}: FeatureGroupColumnDnDWrapperProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const { id } = fgs;

  const [, drop] = useDrop<FeatureGroupColumnDnDItem, void, { isOver: boolean }>({
    accept: FEATURE_GROUP_COLUMN_DND_ITEM,
    hover: (item, monitor) => {
      if (!ref.current) return;
      if (id === item.id) return;
      if (!fgs.featureGroup) return;

      const dragSourceIndex = item.index;
      const dropTargetIndex = index;

      const dropTargetParams = ref.current.getBoundingClientRect();
      const dropTargetWidth = dropTargetParams.right - dropTargetParams.left;
      const dropTargetOffset = dropTargetWidth / 3;
      const mousePosition = monitor.getClientOffset();
      if (!mousePosition) return;

      const hoverClientX = mousePosition.x - dropTargetParams.left;

      const cannotDropOnTheLeft = dragSourceIndex < dropTargetIndex && hoverClientX < dropTargetOffset;
      if (cannotDropOnTheLeft) return;

      const shouldDropAbove = dragSourceIndex > dropTargetIndex && hoverClientX > dropTargetWidth - dropTargetOffset;
      if (shouldDropAbove) return;

      move(dragSourceIndex, dropTargetIndex);

      if (item.prevIndex === null) item.prevIndex = item.index;
      item.index = dropTargetIndex;
    },
    drop: (item) => {
      const isSameSlot = item.prevIndex === item.index;

      if (onDrop && item.prevIndex !== null && !isSameSlot) {
        onDrop(item.id);
      }

      item.prevIndex = null;
    },
    canDrop: () => !!fgs.featureGroup,
  });

  const [{ isDragging }, drag, dragPreview] = useDrag<FeatureGroupColumnDnDItem, void, { isDragging: boolean }>({
    type: FEATURE_GROUP_COLUMN_DND_ITEM,
    item: () => ({
      id,
      index,
      prevIndex: null,
      featureGroupState: fgs,
      ref,
    }),
    isDragging: (monitor) => monitor.getItem().id === id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return (
        !!fgs.featureGroup &&
        !isNameInputFocused &&
        fgs.featureStates.map((el) => el.state).filter((el) => el === 'edited').length === 0
      );
    },
  });

  useEffect(() => {
    drag(drop(ref));
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    //Fix for DnD bug on firefox - set draggable to false so the cursor can be put in the correct place (doesn't work for first click)
    if (ref.current) {
      const isDraggable =
        !isNameInputFocused && fgs.featureStates.map((el) => el.state).filter((el) => el === 'edited').length === 0;
      ref.current.setAttribute('draggable', `${isDraggable}`);
    }
  }, [isNameInputFocused, fgs.featureStates.map((el) => el.state).filter((el) => el === 'edited').length === 0]);

  return (
    <div className={clsx(styles.root, isDragging && styles.placeholder)} ref={ref}>
      <div className={clsx(styles.inner, isDragging && styles.hidden)}>{children}</div>
    </div>
  );
}

export default observer(FeatureGroupColumnDnDWrapper);
