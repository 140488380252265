import { observer } from 'mobx-react-lite';
import { useState, useRef, useEffect } from 'react';

import { useStores } from 'src/RootStore';
import TestStepHeader from 'src/Tests/TestStepHeader/TestStepHeader';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import Button from 'src/theme/atoms/buttons/Button/Button';
import ButtonLink from 'src/theme/atoms/buttons/ButtonLink/ButtonLink';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import { WEBSITE_TESTS_RESULTS_METHODOLOGY, BACK_TO_HOME_BTN } from 'src/utils/constants';
import copyTextToClipboard, { snackbarCopyLinkData } from 'src/utils/copyToClipboard';

import { PaymentStatus } from '../../plans/Plans.types';
import PurchaseAudienceCintModal from '../PurchaseAudienceCintModal';
import PurchaseConfirmationModal from '../PurchaseConfirmationModal';
import { useTest } from '../TestLayout';
import styles from './AudienceShareLink.module.scss';
import AudienceShareLinkHelperModal from './AudienceShareLinkHelperModal/AudienceShareLinkHelperModal';

function AudienceShareLink(): JSX.Element {
  const organization = useOrganization();
  const { testStore, authStore } = useStores();

  const { test, changeTest } = useTest();
  if (!test) throw new Error('No test');

  const isOwner = organization?.createdBy === authStore.firebaseAuth.currentUser?.uid;

  const [snackbar, setSnackbar] = useState<{ msg: string; type: 'success' | 'error' }>();
  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(undefined);
  const [isPurchaseAudienceCintModalOpen, setIsPurchaseAudienceCintModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const shareLinkInputRef = useRef<HTMLInputElement>(null);

  const shareLink = `${window.location.host}/quizzes/${test.id}`;

  useEffect(() => {
    if (test.cost === undefined) {
      setIsLoading(true);
      testStore
        .getExternalAudienceCost(organization.id, test.id)
        .then((response) => {
          changeTest({ ...test, ...response });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const handleShareLinkCopy = async (): Promise<void> => {
    try {
      await copyTextToClipboard(shareLink);
      setSnackbar(snackbarCopyLinkData.linkCopySuccess);
    } catch (e) {
      setSnackbar(snackbarCopyLinkData.linkCopyFail);
    }
  };

  const refreshTestUntilResolved = (): void => {
    setTimeout(() => {
      testStore.getTest(organization.id, test.id).then((response) => {
        changeTest({ ...test, ...response });
        if (response.externalAudienceState === 'paymentsucceeded') {
          setPaymentStatus('success');
        } else if (response.externalAudienceState === 'paymentfailed') {
          setPaymentStatus('fail');
        } else if (response.externalAudienceState === 'requested') {
          setPaymentStatus('couldntContactChargebee');
          changeTest({ ...test, ...response });
        } else {
          setPaymentStatus('success'); // TODO remove when BE is ready
          return; // TODO remove when BE is ready
          refreshTestUntilResolved();
        }
      });
    }, 10000);
  };

  const processPayment = (): Promise<void> => {
    setIsPurchaseAudienceCintModalOpen(false);
    setPaymentStatus('waiting');
    return testStore
      .processPayment(organization.id, test.id, test)
      .then(refreshTestUntilResolved)
      .catch(() => setPaymentStatus('fail'));
  };

  const helperModal = isHelperModalOpen && (
    <AudienceShareLinkHelperModal
      shareLinkInputRef={shareLinkInputRef}
      shareLink={shareLink}
      onClose={() => setIsHelperModalOpen(false)}
      copyShareLink={handleShareLinkCopy}
    />
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <TestStepHeader testName={test.name} isBottomDivider={false} showAddCollaborators={false} />
      <div className={styles.shareLinkContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>Your test is ready.</div>
          <h2 className={styles.shareLinkHeader}>
            All you need to do is share your link. Get {test.totalRespondentsCount}{' '}
            {test.totalRespondentsCount && test.totalRespondentsCount > 1 ? 'people' : 'person'} to validate your{' '}
            {test.inputParameters?.ideasCount} idea
            {test.inputParameters && test.inputParameters.ideasCount > 1 ? 's' : ''}.
          </h2>
          <div className={styles.readMoreTitle}>
            Why {test.totalRespondentsCount} response
            {test.totalRespondentsCount && test.totalRespondentsCount > 1 ? 's' : ''} for your test?{' '}
            <a
              href={WEBSITE_TESTS_RESULTS_METHODOLOGY}
              target="_blank"
              rel="noreferrer"
              className={styles.undelinedSubtitle}
            >
              Read more about our methodology
            </a>
          </div>
        </div>
        <div className={styles.root}>
          <div className={styles.rootContent}>
            <div className={styles.linkBox}>
              <input ref={shareLinkInputRef} color="white" name="shareLink" type="text" value={shareLink} disabled />
              <Button
                label="Copy link"
                color="primary"
                size="l"
                className={styles.saveBtn}
                onClick={handleShareLinkCopy}
              />
            </div>
            <div className={styles.actions}>
              <Button label="What do i do now?" size="l" color="secondary" onClick={() => setIsHelperModalOpen(true)} />
              <ButtonLink
                label={BACK_TO_HOME_BTN}
                to={paths.organizationDashboard(organization.id)}
                size="l"
                color="secondary"
              />
            </div>
            {isOwner && (
              <div onClick={() => setIsPurchaseAudienceCintModalOpen(true)} className={styles.testWithCintCTA}>
                Test with our audience
              </div>
            )}
          </div>

          <Snackbar
            type={snackbar?.type || 'success'}
            message={snackbar?.msg}
            onClose={() => setSnackbar(undefined)}
            autoclose
          />
          {helperModal}
        </div>
      </div>
      {paymentStatus === undefined && isPurchaseAudienceCintModalOpen && (
        <PurchaseAudienceCintModal
          onClose={() => setIsPurchaseAudienceCintModalOpen(false)}
          processPayment={processPayment}
          paymentStatus={paymentStatus}
        />
      )}
      {Boolean(paymentStatus) && (
        <PurchaseConfirmationModal paymentStatus={paymentStatus} onClose={() => setPaymentStatus(undefined)} />
      )}
    </>
  );
}

export default observer(AudienceShareLink);
