import { makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';
import { SummaryRoadmap } from 'src/roadmap/RoadmapDetails/RoadmapDetails.types';

import * as requests from './NoAuthRoadmap.requests';

class NoAuthRoadmapStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getRoadmapFromShareLink = (roadmapId: string): Promise<SummaryRoadmap> => {
    return requests.getRoadmapByLink(roadmapId).then((res) => {
      return res.data;
    });
  };
}

export default NoAuthRoadmapStore;
