import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

import IdeaState from 'src/Tests/IdeaState.store';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { TextArea } from 'src/theme/atoms/inputs';

import styles from './IdeaCard.module.scss';
import IdeaCardDnDWrapper from './IdeaCardDnDWrapper/IdeaCardDnDWrapper';

interface IdeaCardProps {
  index: number;
  ideaState: IdeaState;
  onClick: (ideaState: IdeaState) => void;
  onDrop: (id: string) => void;
  moveToDifferentSlot: (dragSourceIndex: number, dropTargetIndex: number) => void;
}

function IdeaCard({ index, ideaState, onClick, onDrop, moveToDifferentSlot }: IdeaCardProps): JSX.Element {
  const nameInputRef = useRef<HTMLTextAreaElement | null>(null);

  const isCreated = !!ideaState.idea;
  const canSave = ideaState.text.trim() !== '';
  const canDrop = !!ideaState.idea;

  return (
    <IdeaCardDnDWrapper
      index={index}
      ideaState={ideaState}
      canDrag={canDrop}
      move={moveToDifferentSlot}
      onDrop={onDrop}
    >
      <div
        className={clsx(styles.card, isCreated && styles.created, !isCreated && styles.open)}
        onClick={isCreated ? () => onClick(ideaState) : undefined}
      >
        <div className={styles.nameFieldBox}>
          {isCreated ? (
            <p className={styles.text}>{ideaState.text}</p>
          ) : (
            <TextArea
              forwardRef={nameInputRef}
              color="black"
              size="s"
              label="Idea name"
              name="name"
              placeholder="How do you help x person do y?"
              value={ideaState.text}
              className={styles.nameField}
              textFieldClassName={styles.textarea}
              onChange={ideaState.changeName}
              disableEnter
              onEnter={canSave && !isCreated ? ideaState.save : undefined}
            />
          )}
        </div>

        {!isCreated && (
          <div className={clsx(styles.bottomActionsBar, canSave && styles.open)}>
            <Button
              label="Add"
              color="secondary"
              size="s"
              className={styles.addBtn}
              onClick={() => ideaState.save()}
              loading={ideaState.actionStatus === 'saving'}
            />
          </div>
        )}

        {isCreated && <div className={styles.preview} />}
      </div>
    </IdeaCardDnDWrapper>
  );
}

export default observer(IdeaCard);
