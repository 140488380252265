import { AuthError } from '@firebase/auth';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import ExplainTeamMember from 'src/theme/atoms/ExplainTeamMember/ExplainTeamMember';
import Button from 'src/theme/atoms/buttons/Button/Button';
import ButtonLink from 'src/theme/atoms/buttons/ButtonLink/ButtonLink';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import { ADD_TEAM_MEMBERS_BTN } from 'src/utils/constants';

import { getAuthErrorMessage } from '../../auth/errors';
import OrganizationPageLayout from '../OrganizationPageLayout/OrganizationPageLayout';
import { useOrganization } from '../OrganizationRouteLayout/OrganizationRouteLayout.context';
import { OrganizationUser } from '../Organizations.types';
import AssignUserModal from './AssignUserModal/AssignUserModal';
import styles from './OrganizationSettings.module.scss';
import OrganizationSettingsStore from './OrganizationSettings.store';

interface SectionProps {
  title: string;
  children: JSX.Element;
}

function OrganizationSettings(): JSX.Element {
  const organization = useOrganization();
  const { authStore } = useStores();
  const { firebaseAuth } = authStore;

  const [store] = useState(() => new OrganizationSettingsStore(organization));
  const {
    shouldRedirectToDasboard,
    snackbarConfig,
    isFetchingUsers,
    users,
    userEmailToDeassign,
    isUserAssignmentModalOpen,
    isAssigningUserToOrganization,
    handleGetOrganizationUsers,
    // handleOrganizationDelete,
    handleUserDeassignFromOrganization,
    handleAssignUserToOrganization,
    openAssignUserModal,
    closeAssignUserModal,
    closeSnackbar,
    showModalUpgradeSubscription,
    closeModalUpgradeSubscription,
  } = store;

  useEffect(() => {
    handleGetOrganizationUsers();
  }, []);

  const [snackbar, setSnackbar] = useState<{ message: string; type: 'success' | 'error' | 'info' }>(snackbarConfig);

  if (shouldRedirectToDasboard) return <Navigate to={paths.organizationDashboard(organization.id)} />;

  const handleChangePasswordClick = (): void => {
    if (!authStore.firebaseAuth.currentUser?.email) throw new Error('No email');

    authStore
      .requestNewPassword(authStore.firebaseAuth.currentUser.email)
      .then(() =>
        setSnackbar({
          type: 'success',
          message: 'A password reset link was sent to your email. Follow the instructions in the message.',
        })
      )
      .catch((err: AuthError) => {
        alert(getAuthErrorMessage(err.code));
      });
  };

  const isViewingUser = (user: OrganizationUser): boolean => firebaseAuth.currentUser?.email === user.email;

  const renderUser = (user: OrganizationUser, isViewingUser: boolean): JSX.Element => {
    const isDisabled = userEmailToDeassign === user.email;

    return (
      <li key={user.id} className={styles.textItem}>
        <div className={styles.content}>{user.email}</div>
        {!isViewingUser && (
          <a
            color="secondary"
            className={clsx(styles.delete, isDisabled && styles.disabled)}
            onClick={() => handleUserDeassignFromOrganization(user.email)}
          >
            Remove
          </a>
        )}
      </li>
    );
  };

  const userList = (
    <div key="userListWrapper">
      <h3 className={styles.modalSubtitle1}>Organization's team members</h3>
      <ul key="userList" className={styles.userList}>
        {users // place the viewing user at the beginning of the list
          .slice()
          .sort((user) => (isViewingUser(user) ? -1 : 0))
          .map((user) => renderUser(user, isViewingUser(user)))}
      </ul>
      <Button label={ADD_TEAM_MEMBERS_BTN} color="secondary" size="l" onClick={openAssignUserModal} />
    </div>
  );

  const Section = ({ title, children }: SectionProps): JSX.Element => (
    <div className={styles.section}>
      <h2 className={styles.title}>{title}</h2>
      {children}
    </div>
  );

  return (
    <OrganizationPageLayout>
      <div className={styles.root}>
        <Section title="Account settings">
          <div className={styles.settings}>
            <div className={styles.textItem}>
              <div className={styles.content}>{firebaseAuth.currentUser?.email}</div>
            </div>
            <div className={styles.pwdWrapper}>
              <div className={styles.textItem}>
                <div className={styles.content}>{'•'.repeat(20)}</div>
              </div>
              <Button color="secondary" size="s" label="Change password" onClick={handleChangePasswordClick} />
            </div>
          </div>
        </Section>

        <div className={styles.rule} />

        <Section title="Billing and plan">
          <div className={styles.plan}>
            <h4>Your subscrpition plan is {organization.subscriptionPricingId}</h4>
            <h4>Status: {organization.subscriptionStatus}</h4>
            <ButtonLink
              className={styles.btn}
              color="secondary"
              label="update billing and plan information"
              size="l"
              to={paths.organizationPlans(organization.id)}
            />
          </div>
        </Section>

        <div className={styles.rule} />

        <Section title="Team">
          <>
            <ExplainTeamMember />
            <div className={styles.team}>{isFetchingUsers ? <Loader /> : userList}</div>
          </>
        </Section>

        {isUserAssignmentModalOpen && (
          <AssignUserModal
            isSubmitting={isAssigningUserToOrganization}
            onSubmit={handleAssignUserToOrganization}
            onClose={closeAssignUserModal}
            snackbarMessage={snackbarConfig.message}
            onCloseSnackbar={closeSnackbar}
            showModalUpgradeSubscription={showModalUpgradeSubscription}
            onCloseModalUpgradeSubscription={closeModalUpgradeSubscription}
          />
        )}

        <Snackbar type={snackbar.type} message={snackbar.message} onClose={closeSnackbar} autoclose={false} />
      </div>
    </OrganizationPageLayout>
  );
}

export default observer(OrganizationSettings);
