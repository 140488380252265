import { ReactComponent as Logo } from 'src/theme/svg/logo.svg';

import Button from 'src/theme/atoms/buttons/Button/Button';
import styles from './QuizSummary.module.scss';

function QuizSummary(): JSX.Element {
  return (
    <div className={styles.root}>
      <Logo />
      <p className={styles.header}>{'Thanks for \nyour response'}</p>
      <div className={styles.rule} />
      <div className={styles.text}>
        <p className={styles.text1}>Have ideas you wanna test?</p>
        <p className={styles.text2}>Foundations is a platform for helping people build better things.</p>
      </div>
      <Button
        color="primary"
        size="l"
        label="learn more"
        className={styles.button}
        onClick={() => window.location.replace('https://www.buildfoundations.co/tests')}
      />
    </div>
  );
}

export default QuizSummary;
