import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { Idea } from 'src/Tests/Test.types';
import { useTestEditionStore } from 'src/Tests/TestEdition/TestEditionContext';
import TestStepHeader from 'src/Tests/TestStepHeader/TestStepHeader';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import { Quiz } from 'src/quiz';
import { QuizDto } from 'src/quiz/Quiz.types';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { ReactComponent as Arrow } from 'src/theme/svg/arrow-left.svg';

import styles from './TestSummary.module.scss';
import TestSummaryHelperModal from './TestSummaryHelperModal/TestSummaryHelperModal';

function makePreviewQuiz(idea: Idea, imageUrl?: string): QuizDto {
  return {
    idea: {
      createdAt: '',
      id: '',
      modifiedAt: '',
      testId: '',
      text: idea.text,
      valueProp: '',
    },
    isAnswered: false,
    imageUrl,
  };
}

function TestSummary(): JSX.Element {
  const testEditionStore = useTestEditionStore();
  const { test, ideas } = testEditionStore;

  const { id: organizationId } = useOrganization();

  if (ideas.length === 0) {
    return <Navigate to={paths.testIdeasManagement(organizationId, test.id)} />;
  }

  const [currIdeaIndex, setCurrIdeaIndex] = useState(0);
  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);

  function showPrevIdea(): void {
    setCurrIdeaIndex(currIdeaIndex === 0 ? ideas.length - 1 : currIdeaIndex - 1);
  }

  function showNextIdea(): void {
    setCurrIdeaIndex(currIdeaIndex === ideas.length - 1 ? 0 : currIdeaIndex + 1);
  }

  const helperModal = isHelperModalOpen && <TestSummaryHelperModal onClose={() => setIsHelperModalOpen(false)} />;

  return (
    <div className={styles.root}>
      <TestStepHeader testName={test.name} isBottomDivider={false} />
      <div className={styles.content}>
        <Button
          label="What should I do?"
          size="l"
          color="secondary"
          onClick={() => setIsHelperModalOpen(true)}
          className={styles.openHelperModalBtn}
        />

        <div className={styles.titles}>
          <div className={styles.title}>Summary</div>
          <div className={styles.subtitle}>
            See what they will see
            <br />
            (1 of {ideas.length} ideas, each user sees 1 idea)
          </div>
        </div>
        <div className={styles.previewContainer}>
          {ideas.length > 1 && <Arrow className={styles.leftArrow} onClick={showPrevIdea} />}
          <Quiz isPreview quiz={makePreviewQuiz(ideas[currIdeaIndex], test.imageUrl)} />
          {ideas.length > 1 && <Arrow className={styles.rightArrow} onClick={showNextIdea} />}

          {ideas.length > 1 && (
            // shows up only on `screen-xxs`
            <div className={styles.tinyScreenArrowGrp}>
              <Arrow className={styles.leftArrow} onClick={showPrevIdea} />
              <Arrow className={styles.rightArrow} onClick={showNextIdea} />
            </div>
          )}
        </div>
      </div>

      {helperModal}
    </div>
  );
}

export default observer(TestSummary);
