import {
  ReactNode,
  useState,
  Children,
  isValidElement,
  useEffect,
  cloneElement,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';

import env from 'src/utils/env';

import ActionBottomBar from '../actionBottomBar/ActionBottomBar';
import styles from './Steps.module.scss';

interface StepsProps {
  children: ReactNode[];
  disabled?: boolean;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}

function Steps({ children, onPrevClick, onNextClick, disabled, currentStep, setCurrentStep }: StepsProps): JSX.Element {
  const { step } = useParams();
  const [baseTranslation, setBaseTranslation] = useState<number>(0);

  const count: number = children.length;
  const countStep = (value: number): void => setCurrentStep(value);

  useEffect(() => {
    if (step) setCurrentStep(parseInt(step));
  }, []);

  useEffect(() => {
    setBaseTranslation((currentStep - 1) * 100);
    window.scrollTo(0, 0);
  }, [currentStep]);

  const prev = (): void => {
    currentStep === 1
      ? onPrevClick
        ? onPrevClick()
        : window.location.replace(env.landingPage)
      : countStep(currentStep - 1);
  };

  const next = (): void => {
    onNextClick?.();

    currentStep !== children.length && countStep(currentStep + 1);
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {Children.map<ReactNode, ReactNode>(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              ...child.props,
              style: {
                transform: `translateX(-${baseTranslation}%)`,
              },
            });
          }
        })}
      </div>
      <ActionBottomBar
        labelForPrev={`Introduction: ${currentStep} of ${count}`}
        progressBarPercentage={(currentStep / children.length) * 100}
        nextBtnDisabled={disabled}
        onPrev={prev}
        onNext={next}
      />
    </div>
  );
}

export default Steps;
