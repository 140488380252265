import { AxiosResponse } from 'axios';

import api from 'src/api';

import { Checkout, PortalSession, Plan, PaymentSources } from './Plans.types';

export function getPlans(): Promise<AxiosResponse<Plan[]>> {
  return api.get('/subscriptions/plans');
}

export function checkoutPlan(organizationId: string, planId: string): Promise<AxiosResponse<Checkout>> {
  return api.get(`/organizations/${organizationId}/checkout/${planId}`);
}

export function getPortalSession(organizationId: string): Promise<AxiosResponse<PortalSession>> {
  return api.get(`/organizations/${organizationId}/portal-session`);
}

export function getPaymentSources(organizationId: string): Promise<AxiosResponse<PaymentSources>> {
  return api.get(`/organizations/${organizationId}/payment-sources`);
}
