import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import baseStyles from 'src/theme/atoms/HelperModal';
import Modal from 'src/theme/atoms/modal/Modal';
import arrow from 'src/theme/img/arrow.png';

import styles from './HowToModal.module.scss';
import MainSection from './MainSection';
import Section from './Section';
import sectionData from './const';
import { HowToModalView } from './types';

interface HowToModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const sectionView = (view, setView, scrollRef): JSX.Element =>
  ({
    [HowToModalView.Main]: <MainSection setView={setView} scrollRef={scrollRef} />,
    [HowToModalView.Testing]: <Section sectionDataItem={sectionData.tests} />,
    [HowToModalView.Roadmap]: <Section sectionDataItem={sectionData.roadmap} />,
    [HowToModalView.Insights]: <Section sectionDataItem={sectionData.insights} />,
  }[view]);

function HowToModal({ isOpen, onClose }: HowToModalProps): JSX.Element {
  const [view, setView] = useState<HowToModalView>(HowToModalView.Main);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleClose = (): void => {
    setView(HowToModalView.Main);
    onClose();
  };

  const handleBackClick = (): void => {
    if (view === HowToModalView.Main) {
      handleClose();
    } else {
      setView(HowToModalView.Main);
      scrollRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className={clsx(baseStyles.modal, styles.root)}
      onClose={handleClose}
      scrollRef={scrollRef}
      shouldCloseOnClickOutside
    >
      <header className={styles.header}>
        <img className={styles.arrowBtn} src={arrow} alt="arrow" onClick={handleBackClick} />
      </header>
      {sectionView(view, setView, scrollRef)}
    </Modal>
  );
}

export default HowToModal;
