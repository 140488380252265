import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';

import * as requests from './Test.requests';
import {
  BasicTest,
  Test,
  IdeaGroup,
  Idea,
  IdeaGroupWithoutIdeas,
  FetchIdeaGroupsParams,
  FetchIdeasParams,
  CreateTestDto,
  CreateIdeaGroupDto,
  CreateIdeaDto,
  UpdateTestDto,
  UpdateIdeaGroupDto,
  UpdateIdeaDto,
  ReorderIdeasDto,
  ReorderIdeaGroupsDto,
  ExternalAudienceCostResponse,
  CintCategories,
  ProcessPaymentResponse,
} from './Test.types';

class TestStore {
  rootStore: RootStore;

  tests: BasicTest[] = [];

  cintCategories: CintCategories | undefined = undefined;

  loading = false;

  isCreatingTest = false;
  isUpdatingTest = false;
  isDeletingTest = false;
  isDuplicatingTest = false;

  isCreatingIdea = false;
  isCreatingMultipleIdeas = false;
  isUpdatingIdea = false;
  isDeletingIdea = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getTests = (organizationId: string): Promise<BasicTest[]> => {
    this.loading = true;
    return requests.fetchTests(organizationId).then((res): BasicTest[] => {
      const sortedTests = res.data.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1));
      this.tests = sortedTests;
      this.loading = false;

      return sortedTests;
    });
  };

  getTest = (organizationId: string, testId: string): Promise<Test> => {
    return requests.getTest(organizationId, testId).then((res) => {
      return res.data;
    });
  };

  fetchTest = (organizationId: string, testId: string): Promise<BasicTest> => {
    return requests.fetchTests(organizationId, { id: testId }).then((res) => {
      if (res.data.length === 0) throw new Error('Test not found');
      return res.data[0];
    });
  };

  createTest = (organizationId: string, dto: CreateTestDto): Promise<BasicTest> => {
    this.isCreatingTest = true;

    return requests
      .createTest(organizationId, dto)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isCreatingTest = false;
      });
  };

  duplicateTest = (organizationId: string, testId: string, name: string): Promise<void> => {
    this.isDuplicatingTest = true;

    return requests
      .duplicateTest(organizationId, testId, name)
      .then(() => {
        return;
      })
      .finally(() => {
        this.isDuplicatingTest = false;
      });
  };

  updateTest = (organizationId: string, testId: string, dto: UpdateTestDto): Promise<BasicTest> => {
    this.isUpdatingTest = true;

    return requests
      .updateTest(organizationId, testId, dto)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isUpdatingTest = false;
      });
  };

  getCintCategories = (): Promise<CintCategories> | CintCategories => {
    if (this.cintCategories) {
      return this.cintCategories;
    } else {
      return requests.getCintCategories().then((res) => {
        const indexOfUS = res.data.audienceCountry.findIndex((el) => el.name === 'USA');
        const indexOfCanada = res.data.audienceCountry.findIndex((el) => el.name === 'Canada');

        const moveCountry = (arr: { id: string; name: string }[], from: number, to: number): void => {
          arr.splice(to, 0, arr.splice(from, 1)[0]);
        };

        moveCountry(res.data.audienceCountry, indexOfUS, 0);
        moveCountry(res.data.audienceCountry, indexOfCanada, 1);

        this.cintCategories = res.data;
        return res.data;
      });
    }
  };

  deleteTest = (organizationId: string, testId: string): Promise<void> => {
    this.isDeletingTest = true;

    return requests.deleteTest(organizationId, testId).then(() => {
      this.isDeletingTest = false;
    });
  };

  fetchIdeas = (organizationId: string, testId: string, params?: FetchIdeasParams): Promise<Idea[]> => {
    return requests.fetchIdeas(organizationId, testId, params).then((res) => {
      return res.data;
    });
  };

  createIdea = (organizationId: string, testId: string, dto: CreateIdeaDto): Promise<Idea> => {
    this.isCreatingIdea = true;

    return requests.createIdea(organizationId, testId, dto).then((res) => {
      this.isCreatingIdea = false;
      return res.data;
    });
  };

  createMultipleIdeas = (organizationId: string, testId: string, dto: CreateIdeaDto[]): Promise<Idea[]> => {
    this.isCreatingMultipleIdeas = true;

    return requests
      .createMultipleIdeas(organizationId, testId, dto)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isCreatingMultipleIdeas = false;
      });
  };

  updateIdea = (organizationId: string, testId: string, featureId: string, dto: UpdateIdeaDto): Promise<Idea> => {
    this.isUpdatingIdea = true;

    return requests.updateIdea(organizationId, testId, featureId, dto).then((res) => {
      this.isUpdatingIdea = false;
      return res.data;
    });
  };

  deleteIdea = (organizationId: string, testId: string, featureId: string): Promise<void> => {
    this.isDeletingIdea = true;

    return requests.deleteIdea(organizationId, testId, featureId).then(() => {
      this.isDeletingIdea = false;
    });
  };

  reorderIdeas = (organizationId: string, testId: string, items: ReorderIdeasDto): Promise<Idea[]> => {
    return requests.reorderIdeas(organizationId, testId, items).then((res) => {
      return res.data;
    });
  };

  fetchIdeaGroups = (organizationId: string, testId: string, params?: FetchIdeaGroupsParams): Promise<IdeaGroup[]> => {
    return requests.fetchIdeaGroups(organizationId, testId, params).then((res) => {
      return res.data;
    });
  };

  fetchIdeaGroup = (organizationId: string, testId: string, id: string): Promise<IdeaGroup> => {
    return requests.fetchIdeaGroups(organizationId, testId, { id }).then((res) => {
      if (!res.data[0]) throw new Error('No idea group found');
      return res.data[0];
    });
  };

  createIdeaGroup = (
    organizationId: string,
    testId: string,
    dto: CreateIdeaGroupDto
  ): Promise<IdeaGroupWithoutIdeas> => {
    return requests.createIdeaGroup(organizationId, testId, dto).then((res) => {
      return res.data;
    });
  };

  updateIdeaGroup = (
    organizationId: string,
    testId: string,
    featureGroupId: string,
    dto: UpdateIdeaGroupDto
  ): Promise<IdeaGroupWithoutIdeas> => {
    return requests.updateIdeaGroup(organizationId, testId, featureGroupId, dto).then((res) => {
      return res.data;
    });
  };

  duplicateIdeaGroup = (
    organizationId: string,
    testId: string,
    ideaGroupId: string
  ): Promise<IdeaGroupWithoutIdeas> => {
    return requests.duplicateIdeaGroup(organizationId, testId, ideaGroupId).then((res) => {
      return res.data;
    });
  };

  reassignIdeasToIdeaGroup = (
    organizationId: string,
    testId: string,
    featureGroupId: string,
    featureIds: string[]
  ): Promise<void> => {
    return requests.reassignIdeasToIdeaGroup(organizationId, testId, featureGroupId, featureIds).then(() => {
      return;
    });
  };

  assignIdeaToIdeaGroup = (
    organizationId: string,
    testId: string,
    featureGroupId: string,
    featureId: string
  ): Promise<void> => {
    return requests.assignIdeaToIdeaGroup(organizationId, testId, featureGroupId, featureId).then(() => {
      return;
    });
  };

  deassignIdeaFromIdeaGroup = (
    organizationId: string,
    testId: string,
    featureGroupId: string,
    featureId: string
  ): Promise<void> => {
    return requests.deassignIdeaFromIdeaGroup(organizationId, testId, featureGroupId, featureId).then(() => {
      return;
    });
  };

  reorderIdeaGroups = (organizationId: string, testId: string, items: ReorderIdeaGroupsDto): Promise<IdeaGroup[]> => {
    return requests.reorderIdeaGroups(organizationId, testId, items).then((res) => {
      return res.data;
    });
  };

  getShareLink = (organizationId: string, testId: string): Promise<string> => {
    return requests.getShareLink(organizationId, testId).then((res) => {
      return res.data.id;
    });
  };

  getExternalAudienceCost = (organizationId: string, testId: string): Promise<ExternalAudienceCostResponse> => {
    return requests.getExternalAudienceCost(organizationId, testId).then((res) => {
      return { ...res.data, cost: res.data.cost / 100 };
    });
  };

  processPayment = (organizationId: string, testId: string, test: Test): Promise<ProcessPaymentResponse> => {
    if (test.externalAudienceState === 'unused') {
      return this.updateTest(organizationId, testId, {
        externalAudienceState: 'requested',
        state: 'running',
      }).then(() => {
        return requests.processPayment(organizationId, testId).then((res) => {
          return { ...res.data };
        });
      });
    } else {
      return requests.processPayment(organizationId, testId).then((res) => {
        return { ...res.data };
      });
    }
  };
}

export default TestStore;
