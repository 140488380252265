import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import ReactTooltip from 'react-tooltip';

import { ChartFeatureGroup } from 'src/roadmap/RoadmapDetails/RoadmapDetails.types';

import styles from './FeatureGroupTile.module.scss';
import { ReactComponent as DragIcon } from './drag-vertical.svg';

const lengthOfTileText = 35;

interface FeatureGroupTileProps {
  index: number;
  featureGroup: ChartFeatureGroup;
  isNotActive: boolean;
  isDragging: boolean;
  className?: string;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onStartDragging?: () => void;
  onRef: (el: HTMLDivElement | null) => void;
}

function FeatureGroupTile({
  index,
  featureGroup,
  isNotActive,
  isDragging,
  className = '',
  onClick,
  onMouseEnter,
  onMouseLeave,
  onStartDragging,
  onRef,
}: FeatureGroupTileProps): JSX.Element {
  const gridArea = `${index + 2} / ${featureGroup.chartWeekStart + 1 - featureGroup.chartWeeksForward} / ${
    index + 3
  } / ${featureGroup.chartWeekEnd + 1 - featureGroup.chartWeeksForward}`;

  return (
    <>
      <ReactTooltip id={`tooltip-${featureGroup.id}`} />
      <div
        ref={onRef}
        className={styles.root}
        style={{
          gridArea,
        }}
        onMouseEnter={!isDragging ? onMouseEnter : undefined}
        onMouseLeave={!isDragging ? onMouseLeave : undefined}
      >
        <div
          className={clsx(
            styles.tile,
            styles['chartPriority' + featureGroup.chartPriority],
            isNotActive && styles.notActive,
            className
          )}
          onClick={onClick}
        >
          <div
            className={styles.tileContent}
            data-class={styles.reactTooltip}
            data-tip={featureGroup.name.length > lengthOfTileText && window.innerWidth > 767 ? featureGroup.name : ''}
            data-for={`tooltip-${featureGroup.id}`}
          >
            <span className={styles.text}>{featureGroup.name}</span>
          </div>
        </div>
        {onStartDragging && (
          <div className={clsx(styles.dragIconBox, isDragging && styles.grabbing)} onMouseDown={onStartDragging}>
            <DragIcon />
          </div>
        )}
      </div>
    </>
  );
}

export default observer(FeatureGroupTile);
