import { observer } from 'mobx-react-lite';

import FeatureState from 'src/roadmap/FeatureState.store';
import useFeatureColumns from 'src/roadmap/utils/useFeatureColumns';

import FeatureCard from '../FeatureCard/FeatureCard';
import styles from './FeaturesManagement.module.scss';

interface FeaturesManagementProps {
  featureStates: FeatureState[];
}

function FeaturesManagement({ featureStates }: FeaturesManagementProps): JSX.Element {
  const featureColumns = useFeatureColumns(featureStates, false);

  const list = featureStates.length > 0 && (
    <div className={styles.list}>
      {featureColumns.map((column) => (
        <div key={column.id} className={styles.column}>
          {column.items.map((fs) => (
            <FeatureCard key={fs.id} featureState={fs} editionStages234 />
          ))}
        </div>
      ))}
    </div>
  );

  const noFeaturesInformation = featureStates.length === 0 && (
    <p className={styles.noFeaturesText}>This group has no features</p>
  );

  return (
    <div className={styles.root}>
      {list}
      {noFeaturesInformation}
    </div>
  );
}

export default observer(FeaturesManagement);
