import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import Button from 'src/theme/atoms/buttons/Button/Button';
import ButtonLink from 'src/theme/atoms/buttons/ButtonLink/ButtonLink';
import Modal from 'src/theme/atoms/modal/Modal';

import styles from './SubscriptionLimitsExceededModal.module.scss';

interface SubscriptionLimitsExceededModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function SubscriptionLimitsExceededModal({ isOpen, onClose }: SubscriptionLimitsExceededModalProps): JSX.Element {
  const { authStore } = useStores();
  const { firebaseAuth } = authStore;
  const organization = useOrganization();

  const isOwner = firebaseAuth.currentUser?.uid === organization.createdBy;

  return (
    <Modal isOpen={isOpen} shouldBePadding>
      <div className={styles.content}>
        <h3>Limits exceeded</h3>
        <div className={styles.copy}>
          <div>
            Please {isOwner ? '' : 'ask the organization owner to'} upgrade to a paid Foundations subscription if you
            would like to do any of the following:
          </div>
          <ul>
            <li className={styles.bullet}>Add more than 3 members to the account</li>
            <li className={styles.bullet}>Create more than 1 Plan </li>
            <li className={styles.bullet}>Create more than 3 Tests</li>
          </ul>
        </div>
        <div className={styles.btns}>
          {isOwner ? (
            <ButtonLink label="Go to plans" to={paths.organizationPlans(organization.id)} color="primary" size="m" />
          ) : (
            <ButtonLink
              label="Go to dashboard"
              to={paths.organizationDashboard(organization.id)}
              color="primary"
              size="m"
            />
          )}
          <Button label="Close" color="secondary" size="m" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
}

export default SubscriptionLimitsExceededModal;
