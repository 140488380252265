import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';

import { useStores } from 'src/RootStore';

import { paths } from '../app/routes/paths.const';
import styles from './Quiz.module.scss';
import { QuizDto } from './Quiz.types';
import QuizElaborate from './QuizElaborate/QuizElaborate';
import QuizIntroduction from './QuizIntroduction/QuizIntroduction';
import { useQuiz } from './QuizLayout';
import phoneStatusBar from './assets/phone-status-bar.png';

enum STEPS {
  INTRODUCTION,
  ELABORATE,
}

interface QuizProps {
  isPreview?: boolean;
  quiz: QuizDto;
}

function Quiz({ isPreview, quiz }: QuizProps): JSX.Element {
  const [step, setStep] = useState<STEPS>(STEPS.INTRODUCTION);
  const [isApproving, setIsApproving] = useState(false);
  const [answer, setAnswer] = useState('');

  const { quizStore } = useStores();

  const location = useLocation();
  const isItQuizView = location.pathname.toLowerCase().split('/').includes('quizzes');
  const respondentSource = isItQuizView && useQuiz().isRespondentFromCint ? 'Cint' : undefined; // checking isItQuizView is super important because otherwise the app breaks when entering this view in test edition flow

  if (!quiz.idea) throw new Error('no idea in quiz');

  const testId = isPreview ? '_' : useParams().testId; // '_' so the next line doesn't throw
  if (!testId) throw new Error('no testId');

  const respondentId = isPreview ? '' : useQuiz().respondentId;

  const navigate = useNavigate();

  if (!isPreview && quiz.isAnswered) {
    return <Navigate to={paths.quizSummary(testId)} />;
  }

  useEffect(() => {
    setStep(STEPS.INTRODUCTION); // for previews
  }, [quiz]);

  const introOnNext = (ans: boolean): void => {
    setIsApproving(ans);
    setStep(STEPS.ELABORATE);
  };

  const quizViews = {
    [STEPS.INTRODUCTION]: <QuizIntroduction onNext={introOnNext} isPreview={isPreview} quiz={quiz} />,
    [STEPS.ELABORATE]: (
      <QuizElaborate
        isApproving={isApproving}
        isPreview={isPreview}
        answer={answer}
        onChange={(ans) => setAnswer(ans)}
      />
    ),
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    if (isPreview || !testId) return;

    quizStore
      .sendQuizAnswer(testId, {
        isApproving,
        value: answer.trim(),
        ideaId: quiz.idea.id,
        respondentId,
        respondentSource,
      })
      .then((res) => {
        if (res?.redirectUrl) {
          window.location.replace(res.redirectUrl);
        } else {
          navigate(paths.quizSummary(testId));
        }
      });
  }

  return (
    <form className={clsx(styles.root, isPreview && styles.preview)} onSubmit={handleSubmit}>
      {isPreview && <img className={styles.phoneStatusBar} src={phoneStatusBar} alt="phone status bar mockup" />}
      {quizViews[step]}
    </form>
  );
}

export default Quiz;
