import clsx from 'clsx';

import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';

import styles from './FeatureGroupCreationHelperModal.module.scss';
import example from './assets/example.png';
import preview from './assets/preview.png';

interface FeatureGroupCreationHelperModalProps {
  onClose: () => void;
}

function FeatureGroupCreationHelperModal({ onClose }: FeatureGroupCreationHelperModalProps): JSX.Element {
  return (
    <HelperModal
      onClose={onClose}
      lightSection={
        <div className={styles.lightSection}>
          <div className={styles.gridItem}>
            <div className={styles.copy1}>
              <p className={styles.header}>Start with user outcomes</p>
              <p className={styles.text}>
                Not sure exactly what to build yet - that's fine. You can stay high level, start with user outcomes, and
                get into prioritization faster. When you’re ready, drag and drop your features into the user outcome
                columns you defined. You can duplicate features to add to multiple columns.
              </p>
            </div>
          </div>
          <div className={styles.gridItem}>
            <img src={example} className={styles.img} alt="feature group example" />
          </div>
          <div className={styles.gridItem}>
            <p className={clsx(styles.header, styles.header2)}>
              Iteratively <br />
              add features.
            </p>
            <p className={styles.text}>
              Anyone on the team can add features in any order - as you think of them - collaboratively and all in one
              place. Go between defining outcomes and adding features. Any features that are not included in an
              outcome will stay in your backlog for future prioritization.
            </p>
          </div>
          <div className={styles.gridItem}>
            <img src={preview} className={clsx(styles.img, styles.img2)} alt="feature creation preview" />
          </div>
        </div>
      }
    />
  );
}

export default FeatureGroupCreationHelperModal;
