import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';

class OrganizationRouteLayoutStore {
  isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getOrganizations = (): void => {
    this.isLoading = true;

    rootStore.organizationsStore.getOrganizations().then(() => {
      this.isLoading = false;
    });
  };
}

export default OrganizationRouteLayoutStore;
