import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Answer, Idea } from 'src/Tests/Test.types';
import { Test } from 'src/Tests/Test.types';
import { paths } from 'src/app/routes/paths.const';
import TestStateIcon from 'src/theme/atoms/TestStateIcon/TestStateIcon';
import IdeaDetails from 'src/theme/atoms/TestSummary/IdeaDetails/IdeaDetails';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import copyTextToClipboard, { snackbarCopyLinkData } from 'src/utils/copyToClipboard';

import styles from './TestSummary.module.scss';
import { SummaryTest } from './TestSummary.types';

dayjs.extend(relativeTime);

interface TestSummaryProps {
  summaryTest: SummaryTest | Test;
  onShowResponsesClick: (idea: Idea) => void;
  testResultsShareLinkId?: string;
  onChangeTest?: () => void;
  isUpdatingTest?: boolean;
}

function TestSummary({
  summaryTest,
  onShowResponsesClick,
  testResultsShareLinkId,
  onChangeTest,
  isUpdatingTest,
}: TestSummaryProps): JSX.Element {
  const [snackbar, setSnackbar] = useState<{ msg: string; type: 'success' | 'error' }>();

  const navigate = useNavigate();

  const handleShowResponsesClick = (idea: Idea): void => {
    onShowResponsesClick(idea);
  };

  const handleShareLinkCopy = async (linkType: 'testing' | 'sharing results'): Promise<void> => {
    const shareLink =
      linkType === 'sharing results' && testResultsShareLinkId
        ? `${window.location.host}${paths.noAuthTest(testResultsShareLinkId)}`
        : `${window.location.host}${paths.quiz(summaryTest.id)}`;

    try {
      await copyTextToClipboard(shareLink);
      setSnackbar(snackbarCopyLinkData.linkCopySuccess);
    } catch (e) {
      setSnackbar(snackbarCopyLinkData.linkCopyFail);
    }
  };

  const getAnswersCount = (ideaId: string): Answer[] => {
    return summaryTest.answers.filter((ans) => ans.ideaId === ideaId);
  };

  const getAnswerYesCount = (ideaId: string): number => {
    return getAnswersCount(ideaId).reduce((n, ans: Answer) => n + (ans.isApproving ? 1 : 0), 0);
  };

  const getAnswerYesRatio = (ideaId: string): number => {
    return getAnswerYesCount(ideaId) / getAnswersCount(ideaId).length;
  };

  const reorderIdeasBasedOnYesRatioOrCount = (aId: string, bId: string): number => {
    const aYesRatio = getAnswerYesRatio(aId);
    const bYesRatio = getAnswerYesRatio(bId);
    return aYesRatio !== bYesRatio ? bYesRatio - aYesRatio : getAnswerYesCount(bId) - getAnswerYesCount(aId);
  };

  return (
    <div className={styles.resultsWrapper}>
      <div className={styles.results}>
        <div className={styles.leftColumn}>
          <div className={styles.stats}>
            <div className={styles.title}>Test Stats</div>
            <div className={styles.statsDataWrapper}>
              <div className={styles.time}>
                <div className={styles.statsDataTitle}>TIME RUNNING</div>
                <div className={styles.statsData}>{dayjs(summaryTest.startedAt).fromNow().replace('ago', '')}</div>
              </div>
              <div className={styles.ideas}>
                <div className={styles.statsDataTitle}>IDEAS</div>
                <div className={styles.statsData}>{summaryTest.ideas.length}</div>
              </div>
              <div className={styles.responses}>
                <div className={styles.statsDataTitle}>RESPONSES</div>
                <div className={styles.statsData}>{summaryTest.answers.length}</div>
              </div>
            </div>
            <div className={styles.statusWrapper}>
              <div className={styles.status}>
                <div className={styles.statsDataTitle}>STATUS</div>
                <div className={styles.statsData}>
                  {summaryTest.state === 'finishedsuccessfully' ||
                  summaryTest.state === 'finishedwithinvaliddistribution'
                    ? 'Finished'
                    : summaryTest.state}
                  <TestStateIcon state={summaryTest.state} className={styles.testStateIcon} />
                </div>
              </div>
              {onChangeTest &&
                summaryTest.state !== 'finishedsuccessfully' &&
                summaryTest.state !== 'finishedwithinvaliddistribution' && (
                  <Button
                    label={summaryTest.state === 'running' ? 'stop test' : 'resume test'}
                    size="s"
                    color="primary"
                    onClick={onChangeTest}
                    disabled={isUpdatingTest}
                    maxWidth
                  />
                )}
              {summaryTest.state === 'running' && (
                <Button
                  label="copy testing link"
                  size="s"
                  color="primary"
                  onClick={() => handleShareLinkCopy('testing')}
                  disabled={isUpdatingTest}
                  maxWidth
                />
              )}
              {summaryTest.externalAudienceState === 'requested' && onChangeTest && (
                <Button
                  label="use our audience"
                  size="s"
                  color="primary"
                  onClick={() => navigate(paths.audienceCint(summaryTest.organizationId, summaryTest.id))}
                  disabled={isUpdatingTest}
                  maxWidth
                />
              )}
              {onChangeTest && ( // this means it's not a no-auth view
                <Button
                  label="copy sharing results link"
                  size="s"
                  color="primary"
                  onClick={() => handleShareLinkCopy('sharing results')}
                  disabled={isUpdatingTest}
                  maxWidth
                />
              )}
            </div>
          </div>
          <div className={styles.framing}>
            <div className={styles.pic}>
              {summaryTest.imageUrl && <img src={summaryTest.imageUrl} alt="illustration of this test" />}
            </div>
            <div className={styles.data}>
              <div className={styles.title}>Test Framing</div>
              <div className={styles.testDataTitle}>AUDIENCE</div>
              <div className={styles.testData}>{summaryTest.audienceName}</div>
              <div className={styles.testDataTitle}>PROBLEM</div>
              <div className={styles.testData}>{summaryTest.problem}</div>
            </div>
          </div>
        </div>
        <div className={styles.rightColumn}>
          {summaryTest.ideas
            .sort((a, b) => reorderIdeasBasedOnYesRatioOrCount(a.id, b.id))
            .map((idea) => (
              <div key={idea.id} className={styles.ideaWrapper}>
                <IdeaDetails
                  idea={idea}
                  test={summaryTest}
                  onShowResponses={() => handleShowResponsesClick(idea)}
                  className={styles.idea}
                />
              </div>
            ))}
        </div>
      </div>
      <Snackbar
        type={snackbar?.type || 'success'}
        message={snackbar?.msg}
        onClose={() => setSnackbar(undefined)}
        autoclose
      />
    </div>
  );
}

export default observer(TestSummary);
