import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import styles from './IdeaCard.module.scss';

interface StaticIdeaCardProps {
  text: string;
  className?: string;
}

function StaticIdeaCard({ text, className }: StaticIdeaCardProps): JSX.Element {
  return (
    <div className={clsx(styles.card, styles.created, styles.static, className)}>
      <div className={styles.nameFieldBox}>
        <p className={styles.text}>{text}</p>
      </div>
      <div className={styles.preview} />
    </div>
  );
}

export default observer(StaticIdeaCard);
