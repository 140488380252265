import clsx from 'clsx';
import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import ReactFocusLock from 'react-focus-lock';
import ReactRemoveScroll from 'react-remove-scroll/dist/es5/Combination';

import styles from './Modal.module.scss';

interface ModalProps {
  isOpen?: boolean;
  children: ReactNode;
  shouldBePadding?: boolean;
  className?: string;
  onClose?: () => void;
  scrollRef?: React.RefObject<HTMLDivElement>;
  shouldCloseOnClickOutside?: boolean;
}

function Modal({
  isOpen = true,
  children,
  shouldBePadding,
  className,
  onClose,
  scrollRef,
  shouldCloseOnClickOutside,
}: ModalProps): JSX.Element | null {
  useEffect(() => {
    const closeModalOnEsacape = (e: globalThis.KeyboardEvent): void => {
      if (e.key === 'Escape') {
        onClose && onClose();
      }
    };

    document.addEventListener('keydown', closeModalOnEsacape);

    return () => {
      document.removeEventListener('keydown', closeModalOnEsacape);
    };
  }, []);

  if (isOpen) {
    const component = (
      <ReactFocusLock autoFocus={false}>
        <ReactRemoveScroll>
          <div className={styles.overlay} ref={scrollRef}>
            <div className={styles.overlayInner} onClick={shouldCloseOnClickOutside ? onClose : undefined}>
              <div
                className={clsx(styles.modal, shouldBePadding && styles.padding, className)}
                aria-modal="true"
                role="dialog"
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </div>
            </div>
          </div>
        </ReactRemoveScroll>
      </ReactFocusLock>
    );

    return createPortal(component, document.body);
  }

  return null;
}

export default Modal;
