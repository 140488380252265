import { AuthError } from '@firebase/auth';
import { observer } from 'mobx-react-lite';
import { useState, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from 'src/Header';
import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import Button from 'src/theme/atoms/buttons/Button/Button';
import ButtonLink from 'src/theme/atoms/buttons/ButtonLink/ButtonLink';
import Input from 'src/theme/atoms/inputs/Input/Input';
import Steps from 'src/theme/atoms/steps/Steps';
import { ReactComponent as GoogleIcon } from 'src/theme/svg/google.svg';
import env from 'src/utils/env';

import {
  getAuthErrorMessage,
  ErrorPasswordIsTooShort,
  ErrorEmailFormatIsInvalid,
  ErrorConfirmPassword,
} from '../errors';
import { SignupValidation } from '../models';
import styles from './Signup.module.scss';
import SignupField from './SignupField';

const STEPS = ['name', 'email', 'password'];

function Signup(): JSX.Element {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [step, setStep] = useState<number>(1);

  const [validationError, setValidationError] = useState<SignupValidation>({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const isNextBtnDisabled =
    (step === 1 && !name) ||
    (step === 2 && (!email || (!!email && !!validationError.email))) ||
    (step === 3 &&
      (!password ||
        !confirmPassword ||
        (!!password && (!!validationError.password || !!validationError.confirmPassword))));

  const handleNameChange = (value: string): void => {
    setName(value);
  };

  const handleEmailChange = (value: string): void => {
    setValidationError(
      (previous): SignupValidation => ({
        ...previous,
        email: authStore.isEmailValid(value) ? ErrorEmailFormatIsInvalid.message : '',
      })
    );
    setEmail(value);
  };

  const handlePasswordChange = (value: string): void => {
    setValidationError(
      (previous): SignupValidation => ({
        ...previous,
        password: authStore.isPasswordLengthValid(value) ? ErrorPasswordIsTooShort.message : '',
      })
    );
    setPassword(value);
  };

  const handleConfirmPasswordChange = (value: string): void => {
    setValidationError(
      (previous): SignupValidation => ({
        ...previous,
        confirmPassword: authStore.isPasswordConfirmed(password, value) ? ErrorConfirmPassword.message : '',
      })
    );
    setConfirmPassword(value);
  };

  const changeStep = (dir: string): void => {
    if (dir === 'back') {
      if (step === 1) {
        window.location.replace(env.landingPage);
      } else {
        setStep(step - 1);
      }
    } else {
      if (step !== STEPS.length) setStep(step + 1);
    }
  };

  const signUpWithEmailAndPassword = (): void => {
    authStore
      .createAccountWithEmail({ email, password, name })
      .then(() => navigate(paths.organizationCreate()))
      .catch((err: AuthError) => {
        alert(getAuthErrorMessage(err.code));
      });
  };

  const signInWithGoogle = (): void => {
    authStore
      .signInWithGoogle()
      .then(() => {
        navigate(paths.organizationCreate());
      })
      .catch((err: AuthError) => {
        alert(getAuthErrorMessage(err.code));
      });
  };

  const onNextClick = (): void => {
    changeStep('forward');

    if (step === 3) signUpWithEmailAndPassword();
  };

  const onEnterPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (step === 1 && name) setStep(2);
      else if (step === 2 && email && !validationError.email) setStep(3);
      else if (step === 3 && password && confirmPassword && !validationError.confirmPassword)
        signUpWithEmailAndPassword();
      else return;
    }
  };

  return (
    <div className={styles.root} onKeyDown={onEnterPress} tabIndex={0}>
      <Header>
        <ButtonLink label="Login" to={paths.login()} size="m" color="secondary" />
      </Header>
      <Steps
        disabled={isNextBtnDisabled}
        onPrevClick={() => changeStep('back')}
        onNextClick={onNextClick}
        currentStep={step}
        setCurrentStep={setStep}
      >
        <div>
          <SignupField
            helperText="We use it for your profile only"
            name="name"
            type="text"
            placeholder="Your name"
            onChange={handleNameChange}
            onEnterPress={onEnterPress}
            value={name}
            maxLength={30}
            hasAdvanceText
            title={
              <span>
                What name
                <br /> should we use?
              </span>
            }
          />
        </div>
        <div>
          <SignupField
            helperText="This is how you will login"
            name="email"
            type="email"
            placeholder="Email Address"
            onChange={handleEmailChange}
            onEnterPress={onEnterPress}
            value={email}
            hasAdvanceText
            error={validationError.email ? { message: validationError.email } : undefined}
            title={
              <span>
                What email should we
                <br /> use for your account?
              </span>
            }
            socialSection={
              <div className={styles.socialSection}>
                <h2>Or would you rather</h2>
                <div className={styles.social}>
                  <Button
                    label="Sign in with google"
                    size="l"
                    color="secondary"
                    className={styles.socialBtn}
                    onClick={signInWithGoogle}
                    startIcon={<GoogleIcon />}
                    ignoreIconStyling
                  />
                </div>
              </div>
            }
          />
        </div>
        <div>
          <SignupField
            helperText="Make it secure"
            name="password"
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange}
            value={password}
            hasAdvanceText={!!(confirmPassword && !validationError.confirmPassword)}
            error={validationError.password ? { message: validationError.password } : undefined}
            title={
              <span>
                Create a&nbsp;
                <br />
                password
              </span>
            }
            confirmPasswordSection={
              password.length > 0 && (
                <Input
                  name="confirmPassword"
                  type="password"
                  className={styles.input}
                  color="white"
                  size="s"
                  placeholder="Confirm Password"
                  error={validationError.confirmPassword ? { message: validationError.confirmPassword } : undefined}
                  onChange={handleConfirmPasswordChange}
                  value={confirmPassword}
                />
              )
            }
            additionalContent={
              <div className={styles.terms}>
                <p>
                  By clicking Next, you agree to terms of our{' '}
                  <a href="https://www.buildfoundations.co/privacy-policy" target="_blank" rel="noreferrer">
                    privacy policy
                  </a>{' '}
                  and{' '}
                  <a href="https://www.buildfoundations.co/software-license" target="_blank" rel="noreferrer">
                    software license
                  </a>
                  .
                </p>
              </div>
            }
          />
        </div>
      </Steps>
    </div>
  );
}

export default observer(Signup);
