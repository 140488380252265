import { ReactNode } from 'react';

import styles from './RoadmapEditionContainer.module.scss';

interface RoadmapEditionContainerProps {
  children: ReactNode;
}

function RoadmapEditionContainer({ children }: RoadmapEditionContainerProps): JSX.Element {
  return <div className={styles.root}>{children}</div>;
}

export default RoadmapEditionContainer;
