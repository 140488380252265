import { AxiosResponse } from 'axios';

import api from 'src/api';

import { QuizAnswerDto, QuizDto } from './Quiz.types';

export async function fetchQuiz(testId: string, respondentId: string): Promise<AxiosResponse<QuizDto>> {
  return api.get(`tests/${testId}/quiz`, { params: { respondentId } });
}

export async function sendQuizAnswer(
  testId: string,
  dto: QuizAnswerDto
): Promise<AxiosResponse<void | { redirectUrl: string }>> {
  return api.post(`tests/${testId}/quiz`, dto);
}
