import { createContext, useContext } from 'react';

import RoadmapEditionStore from './RoadmapEdition.store';

export const RoadmapEditionContext = createContext<RoadmapEditionStore | undefined>(undefined);

export const useRoadmapEditionStore = (): RoadmapEditionStore => {
  const roadmapFormStore = useContext(RoadmapEditionContext);
  if (roadmapFormStore === undefined) {
    throw new Error('useRoadmapEditionStore must be used within RoadmapEditionContext');
  }
  return roadmapFormStore;
};
