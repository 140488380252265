import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import SubscriptionLimitsExceededModal from 'src/theme/atoms/SubscriptionLimitsExceededModal/SubscriptionLimitsExceededModal';
import ActionBottomBar from 'src/theme/atoms/actionBottomBar/ActionBottomBar';
import { TextArea } from 'src/theme/atoms/inputs';

import ImageUpload from './ImageUpload/ImageUpload';
import styles from './RoadmapName.module.scss';

interface RoadmapNameProps {
  name: string;
  onNameChange: (name: string) => void;
  imageUrl?: string;
  onImageUrlChange: (url: string) => void | Promise<void>;
  onSave: () => Promise<void>;
}

function RoadmapName({ name, onNameChange, imageUrl, onImageUrlChange, onSave }: RoadmapNameProps): JSX.Element {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const navigate = useNavigate();

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { roadmapStore } = useStores();
  const { isCreatingRoadmap, isUpdatingRoadmap } = roadmapStore;

  const [isSubscriptionLimitExceededModalOpen, setIsSubscriptionLimitExceededModalOpen] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const handlePrev = (): void => {
    navigate(paths.organizationDashboard(organizationId));
  };

  const handleNext = (): void => {
    onSave().catch((err) => {
      if (err.response.status === 402) {
        setIsSubscriptionLimitExceededModalOpen(true);
      }
    });
  };

  const handleSuccess = async (imageSrc: string): Promise<void> => {
    await onImageUrlChange(imageSrc);
    setIsImageUploading(false);
  };

  const handleDelete = async (): Promise<void> => {
    await onImageUrlChange('');
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={styles.root}>
      <ImageUpload
        url={imageUrl}
        onUploadStart={() => setIsImageUploading(true)}
        onSuccess={handleSuccess}
        onDelete={handleDelete}
      />
      <TextArea
        forwardRef={inputRef}
        name="name"
        label="Roadmap Name"
        color="white"
        placeholder="Name your roadmap"
        helperText="We suggest naming it by year and quarter, e.g., 2022 Q4 Project Planning"
        size="l"
        value={name}
        disableEnter
        onChange={(val) => onNameChange(val)}
        onEnter={name ? handleNext : undefined}
        className={styles.input}
      />
      <ActionBottomBar
        onPrev={handlePrev}
        onNext={handleNext}
        btnDisabled={isCreatingRoadmap || isUpdatingRoadmap || isImageUploading}
        nextBtnDisabled={!name}
      />

      <SubscriptionLimitsExceededModal
        isOpen={isSubscriptionLimitExceededModalOpen}
        onClose={() => setIsSubscriptionLimitExceededModalOpen(false)}
      />
    </div>
  );
}

export default observer(RoadmapName);
