import clsx from 'clsx';

import baseStyles from 'src/theme/atoms/HelperModal';
import { featureGroupAdoptionImg } from 'src/theme/atoms/HelperModal/assets';
import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';

import styles from './FeatureGroupAdoptionHelperModal.module.scss';

interface FeatureGroupAdoptionHelperModalProps {
  onClose: () => void;
}

function FeatureGroupAdoptionHelperModal({ onClose }: FeatureGroupAdoptionHelperModalProps): JSX.Element {
  return (
    <HelperModal
      onClose={onClose}
      lightSection={
        <div>
          <div className={styles.header}>
            <h2 className={styles.title}>Evaluate your solution:</h2>
            <h2 className={styles.subtitle}>How much of your customer base will adopt this feature set?</h2>
            <p className={baseStyles.text}>
              {`Our scale for measuring adoption is % of user base. We’re asking you to consider whether each solution will be adopted by <10%, 25%, 50%, 75%, or >90% of your user base.`}
            </p>
            <p className={baseStyles.text}>
              If you have active users, please use this set. If you do not have active users please consider target
              users.
            </p>
            <p className={baseStyles.text}>
              We aren't segmenting your users in this tool (yet) so the more you can focus on active or close to active
              users the more valuable the analysis will be.
            </p>
            <p className={baseStyles.text}>
              If you really do not feel like you know how much of your active user base or target user base is
              interested in using your solution, you should consider testing with real users.
            </p>
          </div>
        </div>
      }
      darkSection={
        <div>
          <p className={clsx(baseStyles.smallText, baseStyles.question)}>
            How much of your customer base <br />
            will adopt this feature set?
          </p>
          <img src={featureGroupAdoptionImg} alt="Feature group adoption" className={styles.img} />
        </div>
      }
    />
  );
}

export default FeatureGroupAdoptionHelperModal;
