import clsx from 'clsx';

import helperBaseStyles from 'src/theme/atoms/HelperModal';
import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';

import arrowIcon from '../assets/arrow.svg';
import quizImg from '../assets/quiz.png';
import styles from './IdeasManagementHelperModal.module.scss';

interface IdeasManagementHelperModalProps {
  onClose: () => void;
}

function IdeasManagementHelperModal({ onClose }: IdeasManagementHelperModalProps): JSX.Element {
  return (
    <HelperModal
      lightSection={
        <>
          <div className={styles.header}>
            <h2 className={styles.title}>What should I do?</h2>
            <p className={clsx(helperBaseStyles.text, styles.text1)}>
              With a team, synchronously or asynchronously, or on your brainstorm all the ideas you have for how to
              solve this problem for this type of person. Go crazy.
            </p>
            <p className={helperBaseStyles.text}>
              Add an image so your users can understand your ideas. We only allow for one image so we can test a set of
              ideas with a limited set of variables.
            </p>
          </div>
        </>
      }
      darkSection={
        <>
          <div className={styles.content}>
            <div>
              <p className={styles.testersText}>
                This is what your <br /> testers will see
              </p>
            </div>
            <div>
              <img src={arrowIcon} alt="arrow" className={styles.arrow} />
            </div>
            <div>
              <img src={quizImg} alt="quiz" />
            </div>
          </div>
        </>
      }
      onClose={onClose}
    />
  );
}

export default IdeasManagementHelperModal;
