import clsx from 'clsx';
import { useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import ReactRemoveScroll from 'react-remove-scroll/dist/es5/Combination';

import useOnClickOutside from 'src/utils/hooks/useOnClickOutside';
import { FeatureGroup } from 'src/utils/types/FeatureRelated';

import styles from './FGDetails.module.scss';

const recommendations = {
  priority1: {
    entry: 'Good space, good solution - go execute.',
    subtext: "People want to solve this with your solution and you're building it fast - full speed ahead!",
  },
  priority2: {
    entry: 'Good space, good solution - speed up.',
    subtext:
      'People want to solve this with your solution but can you build it faster to establish a moat in the market? - invest more development resources here.',
  },
  priority3: {
    entry: 'Good space, good solution - execute & differentiate.',
    subtext:
      'People want to solve this with your solution but can you build it faster to catch up to the competition or better yet can you differentiate? - invest more development resources here!',
  },
  priority4: {
    entry: 'Good space, bad solution.',
    subtext:
      'People want to solve this problem just not with your current solution - invest user research resources here and do more customer discovery to understand and re-think how you can more effectively solve their problem!',
  },
  priority5: {
    entry: 'Solution in search of a problem.',
    subtext:
      "This is an idea people are interested in but they're not sure what to do with it - invest user research resources here and do more customer validation to identify the specific problem this solution solves or could solve for customers!",
  },
  priority6: {
    entry: "You can't compete.",
    subtext:
      "This space is too competitive and you aren't developing fast enough to overtake competition - re-align resources to development or research priorities!",
  },
  priority7: {
    entry: "People don't want this.",
    subtext:
      "This space is too competitive and you aren't on the right track with your solution - re-align resources to development or research priorities!",
  },
  priority8: {
    entry: 'Bad space, bad solution.',
    subtext:
      "People don't seem to care about this problem and your solution isn't interesting to them - re-align resources to development or research priorities!",
  },
};

const productTypeCopy = {
  single: 'People can solve this area in a single tool today.',
  mix: 'People are mixing and matching solutions today.',
  selfcreated: 'People are hacking solutions today.',
};

interface FGDetailsProps {
  fg: FeatureGroup;
  onClose: () => void;
}

function FGDetails({ fg, onClose }: FGDetailsProps): JSX.Element {
  const [openSection, setOpenSection] = useState<number | null>(null);
  const [blockScroll, setBlockScroll] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, () => onClose?.());

  const openOrCloseSection = (section: number): void => {
    setBlockScroll(true);
    setTimeout(() => setBlockScroll(false), 200);
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <ReactFocusLock autoFocus={false}>
      <ReactRemoveScroll>
        <div className={styles.overlay}>
          <div className={styles.modal} ref={modalRef} aria-modal="true" role="dialog">
            <div className={clsx(styles.modalInner, blockScroll && styles.blockScroll)}>
              <div className={styles.title}>{fg.name}</div>
              <div className={styles.sections}>
                <div className={styles.section}>
                  <div className={styles.sectionHeader} onClick={() => openOrCloseSection(1)}>
                    <h4>Recommendation</h4>
                    <div className={clsx(styles.icon, openSection === 1 && styles.openIcon)} />
                  </div>
                  <div className={clsx(styles.sectionContent, openSection !== 1 && styles.closedSection)}>
                    <p>{recommendations['priority' + fg.chartPriority].entry}</p>
                    <p>{recommendations['priority' + fg.chartPriority].subtext}</p>
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionHeader} onClick={() => openOrCloseSection(2)}>
                    <h4>Here's what you said</h4>
                    <div className={clsx(styles.icon, openSection === 2 && styles.openIcon)} />
                  </div>
                  <div className={clsx(styles.sectionContent, openSection !== 2 && styles.closedSection)}>
                    {fg.productType && (
                      <p>
                        {productTypeCopy[fg.productType]} They use{' '}
                        {fg.productNames?.map((el, i, arr) => (i === arr.length - 1 ? el : el + ', '))}.
                      </p>
                    )}
                    <p>
                      {fg.customerAdoptionRate === '0'
                        ? 'Less than 25'
                        : fg.customerAdoptionRate === '100'
                        ? 'More than 90'
                        : 'More than ' + fg.customerAdoptionRate}
                      % of your users or prospects would use this feature.
                    </p>
                    <p>
                      You said that it was going to take {fg.timeIntervalCount} {fg.timeIntervalType} to build.
                    </p>
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionHeader} onClick={() => openOrCloseSection(3)}>
                    <h4>Features you included</h4>
                    <div className={clsx(styles.icon, openSection === 3 && styles.openIcon)} />
                  </div>
                  <div className={clsx(styles.sectionContent, openSection !== 3 && styles.closedSection)}>
                    {fg.features.length ? (
                      fg.features.map((el) => (
                        <div key={el.id} className={styles.feature}>
                          <div className={styles.featureName}>{el.name}</div>
                          <div className={styles.featureDesc}>{el.description}</div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.noFeaturesToDisplay}>No features to display.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactRemoveScroll>
    </ReactFocusLock>
  );
}

export default FGDetails;
