import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRef, useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';

import FeatureGroupCard from 'src/roadmap/RoadmapEdition/components/FeatureGroupCard/FeatureGroupCard';
import FeaturesManagement from 'src/roadmap/RoadmapEdition/components/FeaturesManagement/FeaturesManagement';

import styles from './FeatureGroupCardAnimationWrapper.module.scss';

const basicHeight = 204;

interface FeatureGroupCardAnimationWrapperProps {
  fgState;
  showExtendedContent?: boolean;
  extendedContent?: React.ReactNode;
  children?: React.ReactNode;
}

///////////////////////////////////////////// UNUSED

function FeatureGroupCardAnimationWrapper({
  fgState,
  showExtendedContent,
  extendedContent,
  children,
}: FeatureGroupCardAnimationWrapperProps): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null);

  const [contentHeight, setContentHeight] = useState<number | 'auto'>(basicHeight);

  const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
  const [animationInProgess, setAnimationInProgess] = useState<boolean>(false);

  useEffect(() => {
    setContentHeight(contentRef.current?.offsetHeight || basicHeight);
  }, [contentRef.current?.offsetHeight]);

  const closeEdition = (): void => {
    fgState.closeFeaturesManagement();
    fgState.featureStates.map((fs) => fs.cancel());
  };

  const resizeObserver = new ResizeObserver(() => {
    contentRef.current && setContentHeight(contentRef.current?.offsetHeight);
  });

  return (
    <AnimateHeight
      duration={500}
      height={contentHeight}
      onAnimationStart={() => {
        setAnimationInProgess(true);
        setAnimationCompleted(false);
      }}
      onAnimationEnd={() => {
        setAnimationInProgess(false);
        setAnimationCompleted(true);
      }}
      className={clsx(
        styles.featureGroupWrapper,
        ((fgState.isFeaturesManagementOpen && animationCompleted) || showExtendedContent) && styles.openingCompleted,
        !fgState.isFeaturesManagementOpen && animationInProgess && styles.whileClosing
      )}
    >
      <div
        ref={contentRef}
        onFocus={() => contentRef.current && resizeObserver.observe(contentRef.current)}
        onBlur={() => contentRef.current && resizeObserver.unobserve(contentRef.current)}
      >
        <FeatureGroupCard
          key={fgState.featureGroup.id}
          name={fgState.name}
          featuresCount={fgState.featureStates.length}
          errors={{
            name: fgState.errors.name,
          }}
          extendedContent={showExtendedContent && extendedContent}
          featuresManagementContent={
            fgState.isFeaturesManagementOpen && <FeaturesManagement featureStates={fgState.featureStates} />
          }
          onEdit={fgState.openFeaturesManagement}
          onClose={closeEdition}
          onFeatureAdd={fgState.addFeature}
          onNameChange={fgState.changeName}
        >
          {children}
        </FeatureGroupCard>
      </div>
    </AnimateHeight>
  );
}

export default observer(FeatureGroupCardAnimationWrapper);
