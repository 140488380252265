import clsx from 'clsx';

import styles from './ColumnBox.module.scss';

interface ColumnBoxProps {
  children: JSX.Element;
  className?: string;
}

function ColumnBox({ children, className = '' }: ColumnBoxProps): JSX.Element {
  return <div className={clsx(styles.root, className)}>{children}</div>;
}

export default ColumnBox;
