import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { paths } from 'src/app/routes/paths.const';

import { OrganizationRouteLayoutContext } from './OrganizationRouteLayout.context';
import OrganizationRouteLayoutStore from './OrganizationRouteLayout.store';

function OrganizationRouteLayout(): JSX.Element {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const [store] = useState(() => new OrganizationRouteLayoutStore());
  const { getOrganization } = store;

  const organization = getOrganization(organizationId);

  if (!organization) return <Navigate to={paths.organizationInitialize()} />;

  return (
    <OrganizationRouteLayoutContext.Provider value={organization}>
      <Outlet />
    </OrganizationRouteLayoutContext.Provider>
  );
}

export default observer(OrganizationRouteLayout);
