import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { paths } from 'src/app/routes/paths.const';
import FGDetails from 'src/roadmap/RoadmapDetails/FGDetails/FGDetails';
import { SummaryRoadmap } from 'src/roadmap/RoadmapDetails/RoadmapDetails.types';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { FeatureGroup, Feature } from 'src/utils/types/FeatureRelated';

import Snackbar from '../snackbar/Snackbar';
import FeatureGroupTile from './FeatureGroupTile/FeatureGroupTile';
import styles from './RoadmapSummary.module.scss';
import RoadmapSummaryStore, { Tile } from './RoadmapSummary.store';

interface RoadmapSummaryProps {
  summaryRoadmap: SummaryRoadmap;
  isInteractive: boolean;
}

function RoadmapSummary({ summaryRoadmap, isInteractive }: RoadmapSummaryProps): JSX.Element {
  const { organizationId, roadmapId } = useParams();
  const navigate = useNavigate();

  const [roadmapSummaryStore] = useState(() => new RoadmapSummaryStore(summaryRoadmap, organizationId, roadmapId));
  const {
    chartWeeks,
    featureGroupsDontBuild,
    featuresWithNoGroup,
    tilesDevelopment,
    tilesResearch,
    currentlyDraggedTileId,
    isReseting,
    snackbarConfig,
    isTilesPlacementChanged,
    startDraggingTile,
    assignTileRef,
    resetFeatureGroupsPlacementOnChart,
    closeSnackbar,
  } = roadmapSummaryStore;

  const [FGDetailsOpenInModal, setFGDetailsOpenInModal] = useState<FeatureGroup | null>(null);
  const [showRecommendationForFeatureOpacity, setShowRecommendationForFeatureOpacity] = useState<string | null>(null);
  const [showRecommendationForFeatureDisplay, setShowRecommendationForFeatureDisplay] = useState<string | null>(null);
  const [hoverOnTile, setHoverOnTile] = useState<string | null>(null);

  const calculateChartWidth = (): number | undefined => {
    const windowWidth = window.innerWidth;
    const chartWidth = chartWeeks < 8 ? chartWeeks * 130 : chartWeeks * 100;
    return chartWidth > windowWidth ? chartWidth : undefined;
  };
  const chartWidth = calculateChartWidth();

  const openFeatureGroupDetails = (id: string): void => {
    const featureGroupToOpen = summaryRoadmap.featureGroups.find((fg) => fg.id === id) || null;
    setFGDetailsOpenInModal(featureGroupToOpen);
  };

  const chartLines = (
    <div
      id="chart"
      key="chartLines"
      className={styles.linesContainer}
      style={{
        gridTemplateColumns: `127px repeat(${chartWeeks}, minmax(0, 1fr))`,
      }}
    >
      <div className={styles.column} />
      {Array.from(Array(chartWeeks)).map((el, i) => (
        <div key={i} className={styles.column} />
      ))}
    </div>
  );

  const weeks = (
    <div
      key="weeks"
      className={styles.axisWeeks}
      style={{
        gridTemplateColumns: `127px repeat(${chartWeeks}, minmax(0, 1fr))`,
      }}
    >
      <div>Weeks</div>
      {Array.from(Array(chartWeeks).keys()).map((el) => (
        <div key={el} className={styles.weekNumber}>
          {el + 1}
        </div>
      ))}
    </div>
  );

  const displayNoChart = (
    <div className={styles.noChart}>
      <div>No chart to display</div>
      <Button
        color="secondary"
        label="Add more data to the roadmap"
        size="m"
        className={styles.roadmapActionBtn}
        onClick={() => organizationId && navigate(paths.roadmapUpdateName(organizationId, summaryRoadmap.id))}
      />
    </div>
  );

  const displayChart = (stage: string, tiles: Tile[]): JSX.Element => {
    return (
      <div
        key={stage}
        className={styles.axisX}
        style={{
          gridTemplateColumns: `127px repeat(${chartWeeks}, minmax(0, 1fr))`,
        }}
      >
        <div className={clsx(styles.stage, tiles.length === 1 && styles.low)}>{stage}</div>
        {tiles.map((tile, i) => {
          const isDragging = currentlyDraggedTileId === tile.id;
          const isNotActive =
            (hoverOnTile !== null && hoverOnTile !== tile.id) ||
            (currentlyDraggedTileId !== null && currentlyDraggedTileId !== tile.id);

          return (
            <FeatureGroupTile
              key={tile.id}
              index={i}
              featureGroup={tile.fg}
              className={styles.tile}
              isNotActive={isNotActive}
              isDragging={isDragging}
              onClick={() => openFeatureGroupDetails(tile.id)}
              onMouseEnter={() => setHoverOnTile(tile.id)}
              onMouseLeave={() => setHoverOnTile(null)}
              onRef={(tileEl: HTMLDivElement | null) => assignTileRef(tile.id, tileEl)}
              onStartDragging={tile.index === 0 || !isInteractive ? undefined : () => startDraggingTile(tile.id)}
            />
          );
        })}
      </div>
    );
  };

  const showRecommendationForFeature = (id: string): void => {
    setShowRecommendationForFeatureDisplay(id);
    setTimeout(() => {
      setShowRecommendationForFeatureOpacity(id);
    });
  };

  const hideFeatureRecommendation = (): void => {
    setShowRecommendationForFeatureOpacity(null);
    setShowRecommendationForFeatureDisplay(null);
  };

  const recommendation = (id: string): JSX.Element => {
    return (
      <div
        className={clsx(
          styles.recommendation,
          showRecommendationForFeatureDisplay &&
            showRecommendationForFeatureDisplay === id &&
            styles.showRecommendationDisplay,
          showRecommendationForFeatureOpacity &&
            showRecommendationForFeatureOpacity === id &&
            styles.showRecommendationOpacity
        )}
      >
        <h4>Recommendation</h4>
        <div className={styles.sectionContent}>
          <p>Think about the user value.</p>
          <p>
            If you haven’t grouped these items into user outcomes then you might want to reconsider why you have
            conceived of building them in the first place.
          </p>
        </div>
      </div>
    );
  };

  const displayTable = (title: string, f: Feature[] | FeatureGroup[]): JSX.Element => {
    const isFeatureGroup = (el: Feature | FeatureGroup): el is FeatureGroup => {
      return (el as FeatureGroup).chartPriority !== undefined;
    };

    return (
      <div className={styles.column}>
        <div className={styles.title}>{title}</div>
        <div className={styles.items}>
          {f.map((el: Feature | FeatureGroup, i: number) => (
            <div
              className={clsx(styles.item, isFeatureGroup(el) && styles.link)}
              key={el.id + i}
              onClick={() => (isFeatureGroup(el) ? openFeatureGroupDetails(el.id) : null)}
              onMouseEnter={() => (isFeatureGroup(el) ? null : showRecommendationForFeature(el.id))}
              onMouseLeave={() => (isFeatureGroup(el) ? null : hideFeatureRecommendation())}
            >
              {el.name}
              {recommendation(el.id)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const chart = (tilesDevelopment.length || tilesResearch.length) && (
    <div>
      <div className={clsx(styles.chartWrapper, chartWidth && styles.notAllFitsIn)}>
        <div
          className={styles.chart}
          style={{
            width: chartWidth,
          }}
        >
          {chartLines}
          {weeks}
          {displayChart('Development', tilesDevelopment)}
          {displayChart('Research', tilesResearch)}
        </div>
      </div>
      <div className={styles.chartFooter}>
        {isInteractive && isTilesPlacementChanged && (
          <div className={styles.resetBox}>
            <p className={styles.resetInformation}>
              You have modified the layout of the chart. Click the button to restore the default placement.
            </p>
            <Button
              color="secondary"
              label="Reset feature groups placement on chart"
              size="m"
              onClick={resetFeatureGroupsPlacementOnChart}
              loading={isReseting}
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {chart || displayNoChart}
      <div className={styles.table}>
        {featureGroupsDontBuild?.length ? displayTable("Don't build", featureGroupsDontBuild) : null}
        {featuresWithNoGroup?.length ? displayTable('No user need defined', featuresWithNoGroup) : null}
      </div>
      {FGDetailsOpenInModal && <FGDetails fg={FGDetailsOpenInModal} onClose={() => setFGDetailsOpenInModal(null)} />}

      <Snackbar type={snackbarConfig.type} message={snackbarConfig.message} onClose={closeSnackbar} autoclose={false} />
    </>
  );
}

export default observer(RoadmapSummary);
