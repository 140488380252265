import clsx from 'clsx';

import useScrollPosition from 'src/utils/hooks/useScrollPosition';
import useWindowSize from 'src/utils/hooks/useWindowSize';

import styles from './StickyRoadmapTopbar.module.scss';

interface StickyRoadmapTopbarProps {
  texts: JSX.Element;
  buttons: JSX.Element;
}

function StickyRoadmapTopbar({ texts, buttons }: StickyRoadmapTopbarProps): JSX.Element {
  const [windowWidth] = useWindowSize();
  const scrollPosition = useScrollPosition();

  const isActive = (): boolean => {
    if (windowWidth < 1100) return scrollPosition > 320;
    else return scrollPosition > 600;
  };

  return (
    <div className={clsx(styles.root, isActive() && styles.active)}>
      <div className={styles.textsBox}>{texts}</div>
      <div className={styles.buttonsBox}>{buttons}</div>
    </div>
  );
}

export default StickyRoadmapTopbar;
