import { makeAutoObservable } from 'mobx';

import { Feature } from 'src/utils/types/FeatureRelated';

import RoadmapEditionStore from '../RoadmapEdition.store';
import FeatureGroupUrgencyState from './FeatureGroupUrgencyState.store';

class FeatureGroupsUrgency {
  roadmapEditionStore: RoadmapEditionStore;
  featureGroupStates: FeatureGroupUrgencyState[] = [];

  constructor(roadmapEditionStore: RoadmapEditionStore) {
    this.roadmapEditionStore = roadmapEditionStore;
    this.featureGroupStates = roadmapEditionStore.featureGroups.map(
      (fg) => new FeatureGroupUrgencyState(roadmapEditionStore, this, fg)
    );

    makeAutoObservable(this);
  }

  get featureGroupCount(): number {
    return this.featureGroupStates.length;
  }

  get featureGroupForTodayCount(): number {
    return this.featureGroupStates.filter((featureGroupState) => featureGroupState.isForToday === true).length;
  }

  get featureGroupNotForTodayCount(): number {
    return this.featureGroupStates.filter((featureGroupState) => featureGroupState.isForToday === false).length;
  }

  get isNextStepDisabled(): boolean {
    let isDisabled = false;

    for (const featureGroupState of this.featureGroupStates) {
      if (featureGroupState.isForToday === undefined) {
        isDisabled = true;
        break;
      }

      if (featureGroupState.isForToday === true && !featureGroupState.productType) {
        isDisabled = true;
        break;
      }

      if (
        featureGroupState.isForToday === true &&
        featureGroupState.productType &&
        featureGroupState.productNames.length === 0
      ) {
        isDisabled = true;
        break;
      }
    }

    return isDisabled;
  }

  private doesFeatureGroupContainsFeature = (fgState: FeatureGroupUrgencyState, fId: string): boolean => {
    return fgState.featureStates.findIndex((fState) => fState.id === fId) !== -1;
  };

  updateFeature = (feature: Feature): void => {
    for (const fgState of this.featureGroupStates) {
      const containsFeature = this.doesFeatureGroupContainsFeature(fgState, feature.id);
      if (containsFeature) {
        fgState.updateFeature(feature);
      }
    }
  };
}

export default FeatureGroupsUrgency;
