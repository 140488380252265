import { AxiosResponse } from 'axios';
import api from 'src/api';

export async function isTutorialSeen(userId: string, key: string): Promise<boolean> {
  const res = await api.get(`users/${userId}/${key}`);
  return res.data.value === 'true';
}

export async function setTutorialSeen(userId: string, key: string): Promise<AxiosResponse<void>> {
  return api.post(`users/${userId}/${key}`, { value: 'true' });
}