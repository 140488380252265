import { AxiosResponse } from 'axios';

import api from 'src/api';

import { FetchOrganizationsParams, Organization, OrganizationUser } from './Organizations.types';

export function getOrganizations(params?: FetchOrganizationsParams): Promise<AxiosResponse<Organization[]>> {
  return api.get('/organizations', { params });
}

export function createOrganization(name: string): Promise<AxiosResponse<Organization>> {
  return api.put('/organizations', { name });
}

export function getOrganizationUsers(organizationId: string): Promise<AxiosResponse<OrganizationUser[]>> {
  return api.get(`/organizations/${organizationId}/users`);
}

export function assignUserToOrganization(organizationId: string, email: string): Promise<AxiosResponse<void>> {
  return api.post(`/organizations/${organizationId}/users/assign`, { email });
}

export function deassignUserFromOrganization(organizationId: string, email: string): Promise<AxiosResponse<void>> {
  return api.post(`/organizations/${organizationId}/users/deassign`, { email });
}

export function deleteOrganization(organizationId: string): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}`);
}
