import { paths } from 'src/app/routes/paths.const';
import Button from 'src/theme/atoms/buttons/Button/Button';
import ButtonLink from 'src/theme/atoms/buttons/ButtonLink/ButtonLink';
import PaymentLoader from 'src/theme/atoms/loaders/PaymentLoader/PaymentLoader';
import Modal from 'src/theme/atoms/modal/Modal';
import { BACK_TO_HOME_BTN } from 'src/utils/constants';

import { testThumbnail } from '../../Dashboard/assets';
import { useOrganization } from '../../organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import { PaymentStatus } from '../../plans/Plans.types';
import PurchaseCompletionLayout from '../PurchaseCompletionLayout';
import { useTest } from '../TestLayout';
import styles from './PurchaseConfirmationModal.module.scss';

const paymentText = {
  waiting: 'Waiting for payment to be confirmed',
  success:
    'You’ve successfully been charged and your test is running with our audience of testers. You should have results in 24 hours or less.',
  fail: 'Something went wrong',
  couldntContactChargebee: 'We couldn’t contact the billing service. Please try again.',
};

interface PurchaseConfirmationModalProps {
  paymentStatus: PaymentStatus;
  onClose?: () => void;
}

function PurchaseConfirmationModal({ paymentStatus, onClose }: PurchaseConfirmationModalProps): JSX.Element {
  const organization = useOrganization();
  const { test } = useTest();

  if (!test) throw new Error('No test');

  const imageUrl = test.imageUrl ? test.imageUrl : testThumbnail;

  return (
    <div className={styles.modalContainer}>
      <Modal className={styles.modalContent}>
        {paymentStatus === 'fail' ? (
          <PurchaseCompletionLayout
            imageUrl={imageUrl}
            text={paymentText.fail}
            button={
              <Button
                label="GO BACK"
                size="l"
                color="primary"
                className={styles.goBackButton}
                onClick={() => onClose && onClose()}
              />
            }
          />
        ) : paymentStatus === 'couldntContactChargebee' ? (
          <PurchaseCompletionLayout
            imageUrl={imageUrl}
            text={paymentText.couldntContactChargebee}
            button={
              <Button
                label="GO BACK"
                size="l"
                color="primary"
                className={styles.goBackButton}
                onClick={() => onClose && onClose()}
              />
            }
          />
        ) : paymentStatus === 'success' ? (
          <PurchaseCompletionLayout
            imageUrl={imageUrl}
            text={paymentText.success}
            button={
              <ButtonLink
                className={styles.gotItButton}
                label={BACK_TO_HOME_BTN}
                to={paths.organizationDashboard(organization.id)}
                size="l"
                color="primary"
              />
            }
          />
        ) : (
          <div>
            <PurchaseCompletionLayout imageUrl={imageUrl} text={paymentText.waiting} />
            <div className={styles.loaderBox}>
              {' '}
              <PaymentLoader />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default PurchaseConfirmationModal;
