import { AuthError } from '@firebase/auth';
import { observer } from 'mobx-react-lite';
import { useState, KeyboardEvent } from 'react';

import Header from 'src/Header';
import { useStores } from 'src/RootStore';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Container from 'src/theme/atoms/container/Container';
import Input from 'src/theme/atoms/inputs/Input/Input';

import { getAuthErrorMessage } from '../errors';
import styles from './RequestNewPassword.module.scss';

function RequestNewPassword(): JSX.Element {
  const { authStore } = useStores();

  const [email, setEmail] = useState('');

  const handleEmailChange = (value: string): void => setEmail(value);

  const requestNewPassword = (): void => {
    authStore
      .requestNewPassword(email)
      .then(() => alert('A password reset link was sent to your email. Follow the instructions in the message.'))
      .catch((err: AuthError) => {
        alert(getAuthErrorMessage(err.code));
      });
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (email) requestNewPassword();
  };

  return (
    <div className={styles.root}>
      <Header />
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className="title-signup">Reset your password</h1>
          <div className={styles.form}>
            <Input
              name="email"
              type="text"
              color="white"
              size="l"
              placeholder="Enter your email"
              onChange={handleEmailChange}
              onEnter={handleEnter}
              value={email}
            />
          </div>
          <Button
            label="Send"
            size="l"
            color="primary"
            className={styles.submitBtn}
            disabled={!email}
            onClick={requestNewPassword}
            maxWidth
          />
        </div>
      </Container>
    </div>
  );
}

export default observer(RequestNewPassword);
