import clsx from 'clsx';
import { useState, useEffect } from 'react';

import Button from '../buttons/Button/Button';
import styles from './ActionBottomBar.module.scss';

interface ActionBottomBarProps {
  labelForPrev?: string;
  labelForNext?: string;
  progressBarPercentage?: number;
  prevBtnDisabled?: boolean;
  nextBtnDisabled?: boolean;
  btnDisabled?: boolean;
  onPrev: () => void;
  onNext: () => void;
}

function ActionBottomBar({
  labelForPrev,
  labelForNext,
  progressBarPercentage,
  prevBtnDisabled,
  nextBtnDisabled,
  btnDisabled,
  onPrev,
  onNext,
}: ActionBottomBarProps): JSX.Element {
  const [progressBarFullfilled, setProgressBarFullfilled] = useState(false);

  useEffect(() => {
    if (progressBarPercentage !== 100) {
      setProgressBarFullfilled(false);
    } else {
      const delayProgressBarFullfilled = setTimeout(() => {
        setProgressBarFullfilled(true);
      }, 400);

      return () => clearTimeout(delayProgressBarFullfilled);
    }
  }, [progressBarPercentage]);

  return (
    <div className={styles.root}>
      <div className={styles.progressBarPlaceholder}>
        {progressBarPercentage !== undefined && (
          <div className={styles.progressBar}>
            <div
              className={clsx(styles.progressBarValue, progressBarFullfilled && styles.fullfilled)}
              style={{ width: `${progressBarPercentage}%` }}
            />
          </div>
        )}
      </div>
      <div className={styles.actionBar}>
        <div className={styles.prevWrapper}>
          <button className={styles.goBack} onClick={onPrev} disabled={btnDisabled || prevBtnDisabled} />
          {labelForPrev && <span className={styles.label}>{labelForPrev}</span>}
        </div>
        <Button
          className={styles.nextBtn}
          color="primary"
          label={labelForNext || 'Next'}
          size="l"
          onClick={onNext}
          disabled={btnDisabled || nextBtnDisabled}
        />
      </div>
    </div>
  );
}

export default ActionBottomBar;
