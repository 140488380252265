import { AxiosResponse } from 'axios';

import api from 'src/api';

import {
  FetchTestsParams,
  CreateTestDto,
  FetchIdeasParams,
  BasicTest,
  UpdateTestDto,
  Idea,
  CreateIdeaDto,
  UpdateIdeaDto,
  FetchIdeaGroupsParams,
  IdeaGroupWithoutIdeas,
  CreateIdeaGroupDto,
  IdeaGroup,
  Test,
  UpdateIdeaGroupDto,
  ReorderIdeaGroupsDto,
  ReorderIdeasDto,
  ShareLinkResponse,
  ExternalAudienceCostResponse,
  CintCategories,
  ProcessPaymentResponse,
} from './Test.types';

//Tests
export function fetchTests(organizationId: string, params?: FetchTestsParams): Promise<AxiosResponse<BasicTest[]>> {
  return api.get(`/organizations/${organizationId}/tests`, { params });
}

export function fetchTest(organizationId: string, testId: string): Promise<AxiosResponse<Test>> {
  return api.get(`/organizations/${organizationId}/tests/${testId}`);
}

export function createTest(organizationId: string, dto: CreateTestDto): Promise<AxiosResponse<BasicTest>> {
  return api.put(`/organizations/${organizationId}/tests`, dto);
}

export function duplicateTest(organizationId: string, testId: string, name: string): Promise<AxiosResponse<Test>> {
  return api.post(`/organizations/${organizationId}/tests/${testId}/duplicate`, { name });
}

export function updateTest(
  organizationId: string,
  testId: string,
  dto: UpdateTestDto
): Promise<AxiosResponse<BasicTest>> {
  return api.patch(`/organizations/${organizationId}/tests/${testId}`, dto);
}

export function deleteTest(organizationId: string, testId: string): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/tests/${testId}`);
}

//Ideas
export function fetchIdeas(
  organizationId: string,
  testId: string,
  params?: FetchIdeasParams
): Promise<AxiosResponse<Idea[]>> {
  return api.get(`/organizations/${organizationId}/tests/${testId}/ideas`, { params });
}

export function createIdea(organizationId: string, testId: string, dto: CreateIdeaDto): Promise<AxiosResponse<Idea>> {
  return api.put(`/organizations/${organizationId}/tests/${testId}/ideas`, dto);
}

export function createMultipleIdeas(
  organizationId: string,
  testId: string,
  dto: CreateIdeaDto[]
): Promise<AxiosResponse<Idea[]>> {
  return api.put(`/organizations/${organizationId}/tests/${testId}/ideas/batch`, dto);
}

export function updateIdea(
  organizationId: string,
  testId: string,
  ideaId: string,
  dto: UpdateIdeaDto
): Promise<AxiosResponse<Idea>> {
  return api.patch(`/organizations/${organizationId}/tests/${testId}/ideas/${ideaId}`, dto);
}

export function deleteIdea(organizationId: string, testId: string, ideaId: string): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/tests/${testId}/ideas/${ideaId}`);
}

export function reorderIdeas(
  organizationId: string,
  testId: string,
  items: ReorderIdeasDto
): Promise<AxiosResponse<Idea[]>> {
  return api.post(`/organizations/${organizationId}/tests/${testId}/ideas/reorder`, { items });
}

//TODO: BE needs to add this
export function fetchIdeaGroups(
  organizationId: string,
  testId: string,
  params?: FetchIdeaGroupsParams
): Promise<AxiosResponse<IdeaGroup[]>> {
  return api.get(`/organizations/${organizationId}/tests/${testId}/idea-groups`, { params });
}

export function createIdeaGroup(
  organizationId: string,
  testId: string,
  dto: CreateIdeaGroupDto
): Promise<AxiosResponse<IdeaGroupWithoutIdeas>> {
  return api.put(`/organizations/${organizationId}/tests/${testId}/idea-groups`, dto);
}

export function updateIdeaGroup(
  organizationId: string,
  testId: string,
  ideaGroupId: string,
  dto: UpdateIdeaGroupDto
): Promise<AxiosResponse<IdeaGroup>> {
  return api.patch(`/organizations/${organizationId}/tests/${testId}/idea-groups/${ideaGroupId}`, dto);
}

export function duplicateIdeaGroup(
  organizationId: string,
  testId: string,
  ideaGroupId: string
): Promise<AxiosResponse<IdeaGroup>> {
  return api.post(`/organizations/${organizationId}/tests/${testId}/idea-groups/${ideaGroupId}/duplicate`);
}

export function deleteIdeaGroup(
  organizationId: string,
  testId: string,
  ideaGroupId: string
): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/tests/${testId}/idea-groups/${ideaGroupId}`);
}

export function reassignIdeasToIdeaGroup(
  organizationId: string,
  testId: string,
  ideaGroupId: string,
  ideaIds: string[]
): Promise<AxiosResponse<void>> {
  return api.post(`/organizations/${organizationId}/tests/${testId}/idea-groups/${ideaGroupId}/ideas`, {
    ideaIds,
  });
}

export function assignIdeaToIdeaGroup(
  organizationId: string,
  testId: string,
  ideaGroupId: string,
  ideaId: string
): Promise<AxiosResponse<void>> {
  return api.put(`/organizations/${organizationId}/tests/${testId}/idea-groups/${ideaGroupId}/ideas/${ideaId}`);
}

export function deassignIdeaFromIdeaGroup(
  organizationId: string,
  testId: string,
  ideaGroupId: string,
  ideaId: string
): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/tests/${testId}/idea-groups/${ideaGroupId}/ideas/${ideaId}`);
}

export function reorderIdeaGroups(
  organizationId: string,
  testId: string,
  items: ReorderIdeaGroupsDto
): Promise<AxiosResponse<IdeaGroup[]>> {
  return api.post(`/organizations/${organizationId}/tests/${testId}/idea-groups/reorder`, { items });
}

export function getTest(organizationId: string, testId: string): Promise<AxiosResponse<Test>> {
  return api.get<Test>(`/organizations/${organizationId}/tests/${testId}`);
}

export function getShareLink(organizationId: string, testId: string): Promise<AxiosResponse<ShareLinkResponse>> {
  return api.post(`/organizations/${organizationId}/tests/${testId}/share-links`);
}

//Cint
export function getCintCategories(): Promise<AxiosResponse<CintCategories>> {
  return api.get('/cint/reference');
}

export function getExternalAudienceCost(
  organizationId: string,
  testId: string
): Promise<AxiosResponse<ExternalAudienceCostResponse>> {
  return api.get(`/organizations/${organizationId}/tests/${testId}/external-audience/cost`);
}

export function processPayment(organizationId: string, testId: string): Promise<AxiosResponse<ProcessPaymentResponse>> {
  return api.post(`/organizations/${organizationId}/tests/${testId}/external-audience/payment`);
}
