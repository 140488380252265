import clsx from 'clsx';
import { useRef, useState } from 'react';

import { useStores } from 'src/RootStore';
import DropdownMenu from 'src/theme/atoms/dropdownMenu/DropdownMenu';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import useOnClickOutside from 'src/utils/hooks/useOnClickOutside';

import styles from './Avatar.module.scss';

export interface MenuItem {
  key?: string;
  label: string;
  onClick: () => void;
  selected: boolean;
}

interface AvatarProps {
  title?: string;
  loading?: boolean;
  inDropdown?: boolean;
  className?: string;
  isOrganizationAvatar?: boolean;
  menuItems?: MenuItem[];
}

const Avatar = ({
  title,
  inDropdown,
  className,
  loading,
  isOrganizationAvatar,
  menuItems,
}: AvatarProps): JSX.Element => {
  const ref = useRef(null);
  const { authStore } = useStores();
  const { firebaseAuth } = authStore;
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  useOnClickOutside(ref, () => setMenuVisible(false));

  const picture = firebaseAuth?.currentUser?.photoURL;
  const isFallbackDisplayed = isOrganizationAvatar || !picture;

  return (
    <div className={clsx(styles.root, className)} ref={ref}>
      <div
        className={clsx(styles.wrapper, { pointer: menuItems, [styles.inDropdown]: inDropdown })}
        onClick={() => setMenuVisible(!menuVisible)}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div
              className={clsx(styles.picture, { [styles.fallback]: isFallbackDisplayed })}
              style={!isOrganizationAvatar && picture ? { backgroundImage: `url(${picture})` } : {}}
            >
              {isFallbackDisplayed && <span>{title?.charAt(0)}</span>}
            </div>
            <span className={styles.title}>{title}</span>
          </>
        )}
      </div>
      {menuItems && (
        <DropdownMenu visible={menuVisible}>
          <ul className={styles.dropdownMenuList}>
            {menuItems.map((menuItem) => (
              <li key={menuItem.key || menuItem.label} onClick={menuItem.onClick}>
                {menuItem.selected ? '> ' : ''}
                {menuItem.label}
              </li>
            ))}
          </ul>
        </DropdownMenu>
      )}
    </div>
  );
};

export default Avatar;
