import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import TestEditionStore from './TestEdition.store';
import { TestEditionContext } from './TestEditionContext';

function TestEdition(): JSX.Element {
  const { organizationId, testId } = useParams();
  if (!organizationId) throw new Error('No organization id');
  if (!testId) throw new Error('No test id');

  const navigate = useNavigate();
  const { testStore } = useStores();
  const [testEditionStore, setTestEditionStore] = useState<TestEditionStore | null>(() => null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    testStore.getTest(organizationId, testId).then((test) => {
      setTestEditionStore(new TestEditionStore(testStore, organizationId, test));
    });
  }, []);

  useEffect(() => {
    if (testEditionStore) {
      if (testEditionStore.test.state !== 'draft') {
        navigate(paths.organizationDashboard(organizationId));
      }

      setIsLoading(false);
    }
  }, [testEditionStore]);

  if (isLoading) return <Loader />;
  if (!testEditionStore) throw new Error('No testEditionStore');

  return (
    <TestEditionContext.Provider value={testEditionStore}>
      <Outlet />
    </TestEditionContext.Provider>
  );
}

export default observer(TestEdition);
