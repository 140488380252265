import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import IdeaState from 'src/Tests/IdeaState.store';
import TestStepHeader from 'src/Tests/TestStepHeader/TestStepHeader';

import { useTestEditionStore } from '../TestEditionContext';
import DragLayer from './DragLayer/DragLayer';
import IdeaCard from './IdeaCard/IdeaCard';
import IdeaEditionModal from './IdeaEditionModal/IdeaEditionModal';
import styles from './IdeasManagement.module.scss';
import IdeasManagementStore from './IdeasManagement.store';
import IdeasManagementHelperModal from './IdeasManagementHelperModal/IdeasManagementHelperModal';
import ImageUpload from './ImageUpload/ImageUpload';
import TestDataCard from './TestDataCard/TestDataCard';

function IdeasManagement(): JSX.Element {
  const testEditionStore = useTestEditionStore();
  const { test, changeIsNextStepDisabled, changeIsImageUploading, updateTest } = testEditionStore;

  const [store] = useState(() => new IdeasManagementStore(testEditionStore));
  const { ideaStates, ideasCount, moveIdea, saveIdeasOrder } = store;

  const [selectedIdeaState, setSelectedIdeaState] = useState<IdeaState | null>();
  const [imageUrl, setImageUrl] = useState(test.imageUrl ?? '');

  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);

  const openHelperModal = (): void => {
    setIsHelperModalOpen(true);
  };

  useEffect(() => {
    changeIsNextStepDisabled(ideasCount === 0 || !imageUrl);
  }, [ideasCount, imageUrl]);

  const handleImageUploadSuccess = async (url: string): Promise<void> => {
    setImageUrl(url);

    try {
      await updateTest({ imageUrl: url });
    } catch (e) {
      setImageUrl('');
    } finally {
      changeIsImageUploading(false);
    }
  };

  const helperModal = isHelperModalOpen && <IdeasManagementHelperModal onClose={() => setIsHelperModalOpen(false)} />;

  const handleImageUploadDelete = async (): Promise<void> => {
    await handleImageUploadSuccess('');
  };

  return (
    <div className={styles.root}>
      <TestStepHeader
        testName={test.name}
        title="Gather all your ideas."
        subtitle="Start brainstorming. Go broad."
        helpButtonConfig={{
          label: 'What should I do?',
          action: openHelperModal,
        }}
        isBottomDivider={false}
        showAddCollaborators
      />

      <div className={styles.divider} />

      <div className={styles.main}>
        <div className={styles.testDataColumn} id="test-data">
          <TestDataCard />
          <ImageUpload
            onUploadStart={() => changeIsImageUploading(true)}
            onSuccess={handleImageUploadSuccess}
            onDelete={handleImageUploadDelete}
            url={imageUrl}
          />
          <div className={styles.rule} />
          {/* debatable whether the rule should be included or not */}
        </div>

        <DndProvider backend={HTML5Backend}>
          <DragLayer />
          <div className={styles.ideasGrid}>
            {ideaStates.map((ideaState, i) => (
              <IdeaCard
                key={ideaState.id}
                index={i}
                ideaState={ideaState}
                onClick={(ideaState) => setSelectedIdeaState(ideaState)}
                onDrop={saveIdeasOrder}
                moveToDifferentSlot={moveIdea}
              />
            ))}
          </div>
        </DndProvider>
      </div>

      {selectedIdeaState && (
        <IdeaEditionModal ideaState={selectedIdeaState} onClose={() => setSelectedIdeaState(null)} />
      )}
      {helperModal}
    </div>
  );
}

export default observer(IdeasManagement);
