import { ReactNode } from 'react';

import ActionBottomBar from '../actionBottomBar/ActionBottomBar';
import styles from './Steps.module.scss';

interface StepLayoutProps {
  children: ReactNode;
  actionBottomBarConfig: {
    labelForPrev: string;
    labelForNext?: string;
    currentStep: number;
    stepsCount: number;
    prevStepDisabled?: boolean;
    nextStepDisabled?: boolean;
    prev: () => void;
    next: () => void;
  };
}

function StepLayout({ children, actionBottomBarConfig }: StepLayoutProps): JSX.Element {
  const { labelForPrev, labelForNext, currentStep, stepsCount, prevStepDisabled, nextStepDisabled, prev, next } =
    actionBottomBarConfig;

  return (
    <div className={styles.root}>
      <div className={styles.content}>{children}</div>
      <ActionBottomBar
        labelForPrev={labelForPrev}
        labelForNext={labelForNext}
        progressBarPercentage={(currentStep / stepsCount) * 100}
        prevBtnDisabled={prevStepDisabled}
        nextBtnDisabled={nextStepDisabled}
        onPrev={prev}
        onNext={next}
      />
    </div>
  );
}

export default StepLayout;
