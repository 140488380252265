import { makeAutoObservable } from 'mobx';

import handleRequestError from 'src/utils/handleRequestError';
import sortBySeq from 'src/utils/sortBySeq';
import { CustomError } from 'src/utils/types/CustomError';
import { Feature, FeatureGroup } from 'src/utils/types/FeatureRelated';

import * as requests from '../Roadmap.requests';
import RoadmapStore from '../Roadmap.store';
import {
  CreateFeatureDto,
  UpdateFeatureDto,
  UpdateFeatureGroupDto,
  Roadmap,
  BasicRoadmap,
  RoadmapDto,
} from '../Roadmap.types';

class RoadmapEditionStore {
  roadmapStore: RoadmapStore;
  organizationId: string;

  roadmap: BasicRoadmap;
  features: Feature[];
  featureGroups: FeatureGroup[];

  isUpdatingFeatureGroup = false;

  sendingRequest = false;

  isNextStepDisabled = false;
  isChangingStepsDisabled = false;

  featureGroupUpdateError: CustomError | null = null;

  constructor(roadmapStore: RoadmapStore, organizationId: string, roadmap: Roadmap) {
    this.roadmapStore = roadmapStore;
    this.organizationId = organizationId;

    this.roadmap = roadmap;
    this.features = sortBySeq(roadmap.features);
    this.featureGroups = sortBySeq(roadmap.featureGroups);

    makeAutoObservable(this);
  }

  get featuresCount(): number {
    return this.features.length;
  }

  get featureGroupsCount(): number {
    return this.featureGroups.length;
  }

  private fetchFeaturesAndFeatureGroups = (): Promise<void> => {
    const featuresPromise = this.roadmapStore.fetchFeatures(this.organizationId, this.roadmap.id);
    const featuresGroupsPromise = this.roadmapStore.fetchFeatureGroups(this.organizationId, this.roadmap.id);

    return Promise.all([featuresPromise, featuresGroupsPromise]).then(([features, featuresGroups]) => {
      this.features = features;
      this.featureGroups = featuresGroups;
    });
  };

  setSendingRequest = (val: boolean): void => {
    this.sendingRequest = val;
  };

  private fetchFeatureGroups = (): Promise<void> => {
    return this.roadmapStore.fetchFeatureGroups(this.organizationId, this.roadmap.id).then((featureGroups) => {
      this.featureGroups = sortBySeq(
        featureGroups.map((fg) => ({
          ...fg,
          features: sortBySeq(fg.features),
        }))
      );
    });
  };

  private fetchFeatures = (): Promise<void> => {
    return this.roadmapStore.fetchFeatures(this.organizationId, this.roadmap.id).then((features) => {
      this.features = sortBySeq(features);
    });
  };

  private fetchFeatureGroup = (featureGroupId: string): Promise<FeatureGroup> => {
    return this.roadmapStore
      .fetchFeatureGroup(this.organizationId, this.roadmap.id, featureGroupId)
      .then((featureGroup) => {
        const index = this.featureGroups.findIndex((fg) => fg.id === featureGroupId);

        if (index !== -1) {
          this.featureGroups[index] = {
            ...featureGroup,
            features: sortBySeq(featureGroup.features),
          };
        }

        return this.featureGroups[index];
      });
  };

  updateRoadmap = (dto: RoadmapDto): Promise<BasicRoadmap> => {
    return this.roadmapStore.updateRoadmap(this.organizationId, this.roadmap.id, dto).then((roadmap) => {
      this.roadmap = roadmap;
      return this.roadmap;
    });
  };

  createFeature = (dto: CreateFeatureDto): Promise<Feature> => {
    return this.roadmapStore.createFeature(this.organizationId, this.roadmap.id, dto).then((feature) => {
      this.features.push(feature);
      return feature;
    });
  };

  handleMultipleFeaturesCreate = (dto: CreateFeatureDto[]): Promise<Feature[]> => {
    return this.roadmapStore.createMultipleFeatures(this.organizationId, this.roadmap.id, dto).then((features) => {
      features.map((el) => this.features.push(el));
      return features;
    });
  };

  updateFeature = (featureId: string, dto: UpdateFeatureDto, featureGroupId?: string | null): Promise<Feature> => {
    return this.roadmapStore
      .updateFeature(this.organizationId, this.roadmap.id, featureId, dto)
      .then((updatedFeature) => {
        if (featureGroupId) {
          const featureGroupIndex = this.featureGroups.findIndex((fg) => fg.id === featureGroupId);

          if (featureGroupIndex !== -1) {
            const index = this.featureGroups[featureGroupIndex].features.findIndex(
              (feature) => feature.id === featureId
            );

            if (index !== -1) {
              this.featureGroups[featureGroupIndex].features[index] = updatedFeature;
            }
          }
        } else {
          const index = this.features.findIndex((feature) => feature.id === featureId);
          if (index !== -1) this.features[index] = updatedFeature;
        }

        return updatedFeature;
      });
  };

  deleteFeature = (featureId: string, featureGroupId?: string | null): Promise<void> => {
    return this.roadmapStore.deleteFeature(this.organizationId, this.roadmap.id, featureId).then(() => {
      const index = this.features.findIndex((feature) => feature.id === featureId);

      if (featureGroupId) {
        const fgIndex = this.featureGroups.findIndex((featureGroup) => featureGroup.id === featureGroupId);
        if (fgIndex !== -1) {
          const fIndex = this.featureGroups[fgIndex].features.findIndex((f) => f.id === featureId);

          if (fIndex !== -1) {
            this.featureGroups[fgIndex].features.splice(fIndex, 1);
          }
        }
      }

      if (index !== -1) this.features.splice(index, 1);
    });
  };

  getFeatureById = (id: string): Feature | undefined => {
    return this.features.find((feature) => feature.id === id);
  };

  //FeatureGroups
  createFeatureGroup = async (name: string, featureIds?: string[]): Promise<FeatureGroup> => {
    try {
      const createdFeatureGroup = await this.roadmapStore.createFeatureGroup(this.organizationId, this.roadmap.id, {
        name,
      });

      if (featureIds) {
        await this.roadmapStore.reassignFeaturesToFeatureGroup(
          this.organizationId,
          this.roadmap.id,
          createdFeatureGroup.id,
          featureIds
        );
      }

      await this.fetchFeatureGroups();

      const featureGroup = this.featureGroups.find((featureGroup) => featureGroup.id === createdFeatureGroup.id);
      if (!featureGroup) throw new Error('No feature group found');

      return featureGroup;
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't create feature group",
        config: {
          409: 'Feature group with this name already exists',
        },
      });

      throw error;
    }
  };

  duplicateFeatureGroup = async (fgId: string): Promise<FeatureGroup> => {
    try {
      if (!this.roadmap) throw new Error('No roadmap found');

      const duplicatedFeatureGroup = await this.roadmapStore.duplicateFeatureGroup(
        this.organizationId,
        this.roadmap.id,
        fgId
      );

      return this.fetchFeatureGroups().then(() => {
        const featureGroup = this.featureGroups.find((featureGroup) => featureGroup.id === duplicatedFeatureGroup.id);
        if (!featureGroup) throw new Error('No feature group found');

        return featureGroup;
      });
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't duplicate feature group",
      });

      throw error;
    }
  };

  updateFeatureGroupWithFeatures = async (id: string, name: string, featureIds: string[]): Promise<FeatureGroup> => {
    //////////////////////////////////////////////// UNUSED
    this.featureGroupUpdateError = null;

    try {
      this.isUpdatingFeatureGroup = true;

      const { data: createdFeatureGroup } = await requests.updateFeatureGroup(
        this.organizationId,
        this.roadmap.id,
        id,
        {
          name,
        }
      );

      const featureGroup = await this.reassignFeaturesToFeatureGroup(createdFeatureGroup.id, featureIds);

      return featureGroup;
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't update feature group",
        config: {
          409: 'Feature group with this name already exists',
        },
      });

      this.featureGroupUpdateError = error;

      throw error;
    } finally {
      this.isUpdatingFeatureGroup = false;
    }
  };

  assignFeaturesToFeatureGroups = async (
    featureGroupIds: string[],
    featureIdsToAssign: string[]
  ): Promise<FeatureGroup[]> => {
    const featureGroups = this.featureGroups.filter((featureGroup) => featureGroupIds.includes(featureGroup.id));

    for (const featureGroup of featureGroups) {
      const currentFeatureIds = featureGroup.features.map((feature) => feature.id);
      const featureIds = [...currentFeatureIds, ...featureIdsToAssign.filter((id) => !currentFeatureIds.includes(id))];

      await requests.reassignFeaturesToFeatureGroup(this.organizationId, this.roadmap.id, featureGroup.id, featureIds);
    }

    await this.fetchFeatureGroups();
    return this.featureGroups;
  };

  deleteFeatureGroup = async (featureGroupId: string): Promise<void> => {
    try {
      await this.roadmapStore.deleteFeatureGroup(this.organizationId, this.roadmap.id, featureGroupId);

      const index = this.featureGroups.findIndex((featureGroup) => featureGroup.id === featureGroupId);
      if (index !== -1) this.featureGroups.splice(index, 1);

      await this.fetchFeatures();
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't delete feature group",
      });

      throw error;
    }
  };

  clearFeatureGroupUpdateError = (): void => {
    this.featureGroupUpdateError = null;
  };

  updateFeatureGroup = (id: string, featureGroupDto: UpdateFeatureGroupDto): Promise<FeatureGroup> => {
    return requests
      .updateFeatureGroup(this.organizationId, this.roadmap.id, id, featureGroupDto)
      .then((res) => {
        const index = this.featureGroups.findIndex((featureGroup) => featureGroup.id === id);
        if (index !== -1) {
          this.featureGroups[index] = {
            ...this.featureGroups[index],
            ...res.data,
          };
        }

        return this.featureGroups[index];
      })
      .catch((err) => {
        const error = handleRequestError({
          error: err,
          defaultMessage: "Couldn't update feature group",
          config: {
            409: 'Feature group with this name already exists',
          },
        });

        throw error;
      });
  };

  deassignFeatureFromFeatureGroup = (featureGroupId: string, featureId: string): Promise<void> => {
    return this.roadmapStore
      .deassignFeatureFromFeatureGroup(this.organizationId, this.roadmap.id, featureGroupId, featureId)
      .then(() => {
        const index = this.featureGroups.findIndex((fg) => fg.id === featureGroupId);
        if (index !== -1) {
          const featureIndex = this.featureGroups[index].features.findIndex((feature) => feature.id === featureId);
          if (featureIndex !== -1) this.featureGroups[index].features.splice(featureIndex, 1);
        }
      })
      .catch((err) => {
        const error = handleRequestError({
          error: err,
          defaultMessage: "Couldn't deassign feature from feature group",
        });

        throw error;
      });
  };

  createFeatureAndAssignToFeatureGroup = async (featureGroupId: string, dto: CreateFeatureDto): Promise<Feature> => {
    try {
      const createdFeature = await this.roadmapStore.createFeature(this.organizationId, this.roadmap.id, dto);

      await this.roadmapStore.assignFeatureToFeatureGroup(
        this.organizationId,
        this.roadmap.id,
        featureGroupId,
        createdFeature.id
      );

      const featureGroup = this.featureGroups.find((fg) => fg.id === featureGroupId);
      if (!featureGroup) throw new Error("Couldn't find feature group");

      const orderedFeatureIds = [createdFeature.id, ...featureGroup.features.map((f) => f.id)];

      await this.updateFeaturesSequence(orderedFeatureIds, featureGroupId);

      return createdFeature;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  changeIsNextStepDisabled = (val: boolean): void => {
    this.isNextStepDisabled = val;
  };

  changeIsChangingStepsDisabled = (val: boolean): void => {
    this.isChangingStepsDisabled = val;
  };

  reassignFeaturesToFeatureGroup = async (featureGroupId: string, featureIds: string[]): Promise<FeatureGroup> => {
    try {
      await this.roadmapStore.reassignFeaturesToFeatureGroup(
        this.organizationId,
        this.roadmap.id,
        featureGroupId,
        featureIds
      );

      const featureGroup = await this.fetchFeatureGroup(featureGroupId);

      return featureGroup;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  updateFeaturesSequence = async (orderedFeatureIds: string[], featureGroupId?: string): Promise<void> => {
    try {
      const fIdsWithNewSequence = orderedFeatureIds.map((id, index) => ({
        id,
        seq: index,
      }));

      const reorderedFeatures = await this.roadmapStore.reorderFeatures(
        this.organizationId,
        this.roadmap.id,
        fIdsWithNewSequence
      );

      if (featureGroupId) {
        const fgIndex = this.featureGroups.findIndex((fg) => fg.id === featureGroupId);
        this.featureGroups[fgIndex].features = reorderedFeatures;
      } else {
        await this.fetchFeatures();
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  updateFeatureGroupsSequence = async (orderedFeatureGroupsIds: string[]): Promise<void> => {
    if (!this.roadmap) throw new Error('No roadmap found');

    try {
      this.changeIsChangingStepsDisabled(true);

      const fgIdsWithNewSequence = orderedFeatureGroupsIds.map((id, index) => ({
        id,
        seq: index,
      }));

      await this.roadmapStore.reorderFeatureGroups(this.organizationId, this.roadmap.id, fgIdsWithNewSequence);
      await this.fetchFeatureGroups();
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.changeIsChangingStepsDisabled(false);
    }
  };
}

export default RoadmapEditionStore;
