import Button from 'src/theme/atoms/buttons/Button/Button';

import styles from './Confirm.module.scss';

interface ConfirmProps {
  question: string;
  close: () => void;
  action: () => void;
}

function Confirm({ question, action, close }: ConfirmProps): JSX.Element {
  return (
    <div className={styles.root}>
      <p className={styles.text}>{question}</p>
      <div className={styles.btns}>
        <Button label="Yes" size="m" color="primary" onClick={action} />
        <Button label="No" size="m" color="secondary" onClick={close} />
      </div>
    </div>
  );
}

export default Confirm;
