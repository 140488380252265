import clsx from 'clsx';

import Button from 'src/theme/atoms/buttons/Button/Button';

import styles from './QuizElaborate.module.scss';

interface QuizElaborateProps {
  isApproving: boolean;
  isPreview?: boolean;
  answer: string;
  onChange: (str: string) => void;
}

function QuizElaborate({ isApproving, isPreview, answer, onChange }: QuizElaborateProps): JSX.Element {
  return (
    <div className={clsx(styles.root, isPreview && styles.preview)}>
      <p className={clsx(styles.header)}>
        {isApproving ? "What's interesting about it?" : 'Why are you not interested?'}
      </p>
      <textarea
        className={styles.answerInput}
        value={answer}
        name="answer"
        placeholder="Type here"
        spellCheck="false"
        onChange={(e) => onChange(e.target.value)}
        disabled={isPreview}
      />
      <Button type="submit" color="primary" size="l" label="send" disabled={isPreview || !answer.trim()} />
    </div>
  );
}

export default QuizElaborate;
