import clsx from 'clsx';
import { useState } from 'react';

import { CreateFeatureDto, FeatureData } from 'src/roadmap/Roadmap.types';
import { useRoadmapEditionStore } from 'src/roadmap/RoadmapEdition/RoadmapEditionContext';
import { csvExample } from 'src/theme/atoms/HelperModal/assets';
import Button from 'src/theme/atoms/buttons/Button/Button';
import CSVFileUpload from 'src/theme/atoms/inputs/CSVFileUpload/CSVFileUpload';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Modal from 'src/theme/atoms/modal/Modal';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';

import styles from './UploadFeaturesModal.module.scss';

interface UploadFeaturesModalProps {
  onClose: () => void;
  onSuccess: (uploadedFeaturesNumber: number, features) => void;
}

function UploadFeaturesModal({ onClose, onSuccess }: UploadFeaturesModalProps): JSX.Element {
  const { handleMultipleFeaturesCreate } = useRoadmapEditionStore();
  const [uploadedDataAsJSON, setUploadedDataAsJSON] = useState<CreateFeatureDto[] | null>(null);
  const [snackbarMsg, setSnackbarMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showingHelp, setShowingHelp] = useState(false);
  const headers = 'name,description';

  //Is it needed if comoponent is unmounted each time?
  // useEffect(() => {
  //   setFileState('');
  //   setFile(null);
  //   setUploadedDataAsJSON([]);
  // }, []);

  const handleFileDataChange = (data: FeatureData[]): void => {
    const modifiedData = data.map((d) => ({ ...d, valueProp: '' }));
    setUploadedDataAsJSON(modifiedData);
  };

  const uploadFeatures = (): void => {
    if (!uploadedDataAsJSON) return;

    setIsLoading(true);

    handleMultipleFeaturesCreate(uploadedDataAsJSON)
      .then((features) => {
        onSuccess(features.length, features);
      })
      .catch(() => {
        setSnackbarMsg('Uploading failed. Please try again.');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal>
      {isLoading && <Loader />}
      <div className={styles.modalInner}>
        <div className={styles.modalHeader}>
          <p className={styles.modalTitle}>Upload features in .csv file</p>
          <Button
            label={showingHelp ? 'Hide help' : 'Show help'}
            color={showingHelp ? 'primary' : 'secondary'}
            size="s"
            onClick={() => setShowingHelp(!showingHelp)}
          />
        </div>
        <div className={clsx(styles.helper, !showingHelp && styles.hideHelper)}>
          <p>.csv file is a text file where values are separeted by commas and new lines. Example:</p>
          <div className={styles.imageWrapper}>
            <img src={csvExample} alt="csv file example" />
          </div>
          <p>
            There are 4 features here. Every feature is in a new line. Their names are before comma, and their
            descriptions are after comma. <br />
          </p>
          <p>
            Because commas and new lines organize the content, please make sure you do not have any commas or new lines
            in your features names and descriptions. If you need to use commas or new lines in your feature name or
            description surround it with double quotes (e.g. "feature, with a, comma").
          </p>
          <p>
            Here you need to upload a file with {headers.split(',').length} column
            {headers.split(',').length === 1 ? '' : 's'}: "{headers.split(',').join(', ')}".
          </p>
        </div>
        <CSVFileUpload headers={headers} onFileChange={handleFileDataChange} />
        <div className={styles.modalActions}>
          <Button
            label="Upload"
            color="primary"
            size="l"
            disabled={!uploadedDataAsJSON}
            onClick={() => uploadFeatures()}
          />
          <Button label="Close" color="secondary" size="l" onClick={() => onClose()} />
        </div>
      </div>
      <Snackbar type={'error'} message={snackbarMsg} onClose={() => setSnackbarMsg('')} autoclose={true} />
    </Modal>
  );
}

export default UploadFeaturesModal;
