import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { ExternalAudienceState } from 'src/Tests/Test.types';
import { paths } from 'src/app/routes/paths.const';
import SubscriptionLimitsExceededModal from 'src/theme/atoms/SubscriptionLimitsExceededModal/SubscriptionLimitsExceededModal';
import ActionBottomBar from 'src/theme/atoms/actionBottomBar/ActionBottomBar';
import { TextArea } from 'src/theme/atoms/inputs';

import styles from './TestNameAudienceProblem.module.scss';
import AudienceDetailsEditable from './components/AudienceDetailsEditable/AudienceDetailsEditable';
import AudienceDetailsNoneditable from './components/AudienceDetailsNoneditable/AudienceDetailsNoneditable';
import WhoseAudience from './components/WhoseAudience/WhoseAudience';

interface TestNamingStep {
  element: JSX.Element;
  bar: {
    onPrev: () => void;
    onNext: () => void;
    label: string;
  };
  required: (string | undefined)[];
}

interface TestNameAudienceProblemProps {
  name: string | undefined;
  externalAudienceState: ExternalAudienceState | undefined;
  audienceName: string | undefined;
  audienceCountryId: string | undefined;
  audienceGenderId: string | undefined;
  audienceAgeMin: number | undefined;
  audienceAgeMax: number | undefined;
  audienceIncomeIds: string[] | undefined;
  problem: string;
  onNameChange: (name: string) => void;
  onAudienceNameChange: (v: string) => void;
  onAudienceCountryIdChange: (v: string | undefined) => void;
  onAudienceGenderIdChange: (v: string | undefined) => void;
  onAudienceAgeMinChange: (v: number | undefined) => void;
  onAudienceAgeMaxChange: (v: number | undefined) => void;
  onAudienceIncomeIdsChange: (v: string[]) => void;
  onExternalAudienceState: (externalAudienceState: ExternalAudienceState) => void;
  onProblemChange: (problem: string) => void;
  onSave: () => Promise<void>;
}

function TestNameAudienceProblem({
  name,
  audienceName,
  audienceCountryId,
  audienceGenderId,
  audienceAgeMin,
  audienceAgeMax,
  audienceIncomeIds,
  externalAudienceState,
  problem,
  onNameChange,
  onAudienceNameChange,
  onAudienceCountryIdChange,
  onAudienceGenderIdChange,
  onAudienceAgeMinChange,
  onAudienceAgeMaxChange,
  onAudienceIncomeIdsChange,
  onExternalAudienceState,
  onProblemChange,
  onSave,
}: TestNameAudienceProblemProps): JSX.Element {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const navigate = useNavigate();

  const [isSubscriptionLimitExceededModalOpen, setIsSubscriptionLimitExceededModalOpen] = useState(false);

  const { testStore } = useStores();
  const { isCreatingTest, isUpdatingTest, getCintCategories, cintCategories } = testStore;

  const [currStep, setCurrStep] = useState(0);

  const focusRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    getCintCategories();
  }, []);

  useEffect(() => {
    const el = focusRef.current;

    if (el) {
      el.focus();
      el.setSelectionRange(el.value.length, el.value.length); // put cursor at the end
    }
  }, [currStep]);

  const leaveTestNaming = (): void => {
    navigate(paths.organizationDashboard(organizationId));
  };

  const save = (): void => {
    onSave().catch((err) => {
      if (err.response.status === 402) {
        setIsSubscriptionLimitExceededModalOpen(true);
      }
    });
  };

  const testNamingSteps: TestNamingStep[] = [
    {
      element: (
        <WhoseAudience
          externalAudienceState={externalAudienceState}
          onExternalAudienceState={(val) => {
            onExternalAudienceState(val);
            setCurrStep(1);
          }}
        />
      ),
      bar: {
        onPrev: leaveTestNaming,
        onNext: () => setCurrStep(1),
        label: 'Whose audience',
      },
      required: [externalAudienceState],
    },
    {
      element: (
        <TextArea
          name="name"
          helperText="Name it something easy."
          placeholder="Test Name"
          label="Test Name"
          onEnter={[name].some((item) => !item) ? undefined : () => setCurrStep(2)}
          disableEnter
          color="white"
          size="l"
          className={styles.input}
          forwardRef={currStep === 1 ? focusRef : undefined}
          value={name || ''}
          onChange={onNameChange}
        />
      ),
      bar: {
        onPrev: () => setCurrStep(0),
        onNext: () => setCurrStep(2),
        label: 'Name it',
      },
      required: [name],
    },
    {
      element: (
        <AudienceDetailsEditable
          cintCategories={cintCategories}
          forwardRef={currStep === 2 ? focusRef : undefined}
          audienceName={audienceName}
          audienceCountryId={audienceCountryId}
          audienceGenderId={audienceGenderId}
          audienceAgeMin={audienceAgeMin}
          audienceAgeMax={audienceAgeMax}
          audienceIncomeIds={audienceIncomeIds}
          handleAudienceNameChange={onAudienceNameChange}
          handleAudienceCountryIdChange={onAudienceCountryIdChange}
          handleAudienceGenderIdChange={onAudienceGenderIdChange}
          handleAudienceAgeMinChange={onAudienceAgeMinChange}
          handleAudienceAgeMaxChange={onAudienceAgeMaxChange}
          handleAudienceIncomeIdsChange={onAudienceIncomeIdsChange}
        />
      ),
      bar: {
        onPrev: () => setCurrStep(1),
        onNext: () => setCurrStep(3),
        label: 'Audience',
      },
      required: [audienceName, audienceCountryId],
    },
    {
      element: (
        <>
          <AudienceDetailsNoneditable
            audienceName={audienceName}
            audienceCountryId={audienceCountryId}
            audienceGenderId={audienceGenderId}
            audienceAgeMin={audienceAgeMin}
            audienceAgeMax={audienceAgeMax}
            audienceIncomeIds={audienceIncomeIds}
            cintCategories={cintCategories}
            handleEditClick={() => setCurrStep(2)}
          />
          <TextArea
            name="problem"
            helperText="Keep it simple."
            placeholder="What problem do they have?"
            label="Problem state"
            onEnter={[problem].some((item) => !item) ? undefined : save}
            disableEnter
            color="white"
            size="l"
            className={styles.input}
            forwardRef={currStep === 3 ? focusRef : undefined}
            value={problem}
            onChange={onProblemChange}
          />
        </>
      ),
      bar: {
        onPrev: () => setCurrStep(2),
        onNext: save,
        label: 'Problem state',
      },
      required: [problem],
    },
  ];

  const currStepData = testNamingSteps[currStep === -1 ? 0 : currStep];

  const isNextBtnDisabled: boolean = useMemo(() => {
    return currStep === 2 && audienceAgeMin && audienceAgeMax
      ? audienceAgeMin > audienceAgeMax || currStepData.required.some((item) => !item)
      : currStepData.required.some((item) => !item);
  }, [currStep, name, audienceName, audienceCountryId, audienceAgeMin, audienceAgeMax, problem]);

  return (
    <div className={styles.root}>
      {testNamingSteps[currStep].element}
      <ActionBottomBar
        {...currStepData.bar}
        btnDisabled={isCreatingTest || isUpdatingTest}
        nextBtnDisabled={isNextBtnDisabled}
        progressBarPercentage={(100 * (currStep + 1)) / testNamingSteps.length}
        labelForPrev={`${currStepData.bar.label}: ${currStep + 1} of ${testNamingSteps.length}`}
      />

      <SubscriptionLimitsExceededModal
        isOpen={isSubscriptionLimitExceededModalOpen}
        onClose={() => setIsSubscriptionLimitExceededModalOpen(false)}
      />
    </div>
  );
}

export default observer(TestNameAudienceProblem);
