import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';

import { useStores } from 'src/RootStore';
import { ErrorEmailFormatIsInvalid } from 'src/auth/errors';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import OrganizationSettingsStore from 'src/organizations/OrganizationSettings/OrganizationSettings.store';
import ExplainTeamMember from 'src/theme/atoms/ExplainTeamMember/ExplainTeamMember';
import SubscriptionLimitsExceededModal from 'src/theme/atoms/SubscriptionLimitsExceededModal/SubscriptionLimitsExceededModal';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { Input } from 'src/theme/atoms/inputs';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Modal from 'src/theme/atoms/modal/Modal';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import { ADD_TEAM_MEMBERS_BTN } from 'src/utils/constants';

import styles from './AddCollabolatorModal.module.scss';

interface AddCollabolatorModalProps {
  onClose: () => void;
}

function AddCollabolatorModal({ onClose }: AddCollabolatorModalProps): JSX.Element {
  const { authStore } = useStores();
  const { firebaseAuth } = authStore;
  const organization = useOrganization();
  const [store] = useState(() => new OrganizationSettingsStore(organization));
  const {
    snackbarConfig,
    showModalUpgradeSubscription,
    closeModalUpgradeSubscription,
    isFetchingUsers,
    users,
    userEmailToDeassign,
    isAssigningUserToOrganization,
    handleGetOrganizationUsers,
    handleUserDeassignFromOrganization,
    handleAssignUserToOrganization,
    closeSnackbar,
  } = store;
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isSubmitBtnDisabled = !email || isAssigningUserToOrganization || !!errorMessage;

  useEffect(() => {
    handleGetOrganizationUsers();
  }, []);

  const handleEmailChange = (value: string): void => {
    setErrorMessage(authStore.isEmailValid(value) ? ErrorEmailFormatIsInvalid.message : '');
    setEmail(value);
  };

  const submitEmail = (): void => {
    handleAssignUserToOrganization(email);
    setEmail('');
  };

  const userList = !isFetchingUsers && (
    <div>
      <ul className={styles.userList}>
        {users.map((user) => {
          const isUserEmail = firebaseAuth.currentUser?.email === user.email;
          const isDisabled = userEmailToDeassign === user.email || isUserEmail;

          return (
            <li key={user.id} className={styles.userListItem}>
              <div>{user.email}</div>
              <Button
                color="secondary"
                label={isUserEmail ? 'Locked' : 'Deassign'}
                size="xs"
                className={styles.deleteBtn}
                onClick={() => handleUserDeassignFromOrganization(user.email)}
                disabled={isDisabled}
                loading={userEmailToDeassign === user.email}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <Modal>
      <div className={styles.modalInner}>
        <div className={styles.modalHeader}>
          <p className={styles.modalTitle}>Team</p>
          <div className={styles.modalCloseBtnBox}>
            <Button label="Close" color="secondary" size="m" onClick={onClose} />
          </div>
        </div>
        <ExplainTeamMember bulletMaxWidth={720} />
        <h3 className={styles.modalSubtitle1}>Organization's team members</h3>
        {isFetchingUsers && <Loader />}
        {userList}
        <h3 className={styles.modalSubtitle2}>{ADD_TEAM_MEMBERS_BTN}</h3>
        <div className={styles.modalForm}>
          <Input
            color="white"
            name="email"
            label="Email"
            placeholder="email"
            size="s"
            type="text"
            className={styles.inputField}
            inputClassName={styles.input}
            value={email}
            onChange={handleEmailChange}
            onEnter={isSubmitBtnDisabled ? undefined : submitEmail}
            error={{
              message: errorMessage,
            }}
          />
          <Button
            color="primary"
            label="Save"
            size="m"
            disabled={isSubmitBtnDisabled}
            onClick={submitEmail}
            loading={isAssigningUserToOrganization}
          />
        </div>
      </div>

      <Snackbar type={snackbarConfig.type} message={snackbarConfig.message} onClose={closeSnackbar} autoclose={false} />
      <SubscriptionLimitsExceededModal
        isOpen={showModalUpgradeSubscription}
        onClose={() => closeModalUpgradeSubscription()}
      />
    </Modal>
  );
}

export default observer(AddCollabolatorModal);
