import clsx from 'clsx';
import { useEffect, useState } from 'react';

import styles from './ColumnsScrollArrows.module.scss';

const scrollDistance = 300;
const scrollArrowVisibilityOffset = 100;

type ScrollArrowDirection = 'left' | 'right' | null;

interface ColumnsScrollArrowsProps {
  columnsRef: React.RefObject<HTMLDivElement>;
}

function ColumnsScrollArrows({ columnsRef }: ColumnsScrollArrowsProps): JSX.Element {
  const [scrollArrowDirection, setScrollArrowDirection] = useState<ScrollArrowDirection>(null);

  const scroll = (): void => {
    if (columnsRef.current) {
      columnsRef.current.scrollTo({
        left: columnsRef.current.scrollLeft + (scrollArrowDirection === 'left' ? -scrollDistance : scrollDistance),
        behavior: 'smooth',
      });
    }
  };

  const onMouseMove = (e: MouseEvent): void => {
    if (!columnsRef.current) return;
    let newScrollArrowDirection: ScrollArrowDirection = null;

    const columnsParams = columnsRef.current.getBoundingClientRect();

    const shouldShowLeftArrow = e.clientX < scrollArrowVisibilityOffset && columnsRef.current.scrollLeft !== 0;

    const scrollRight = columnsRef.current.scrollLeft + columnsParams.width;
    const scrollRightOffset = 10; //scrollWidth is a few pixels larger than scrollRight

    const shouldShowRightArrow =
      e.clientX > window.innerWidth - scrollArrowVisibilityOffset &&
      columnsRef.current.scrollWidth !== columnsParams.width &&
      columnsRef.current.scrollWidth - scrollRight > scrollRightOffset;

    if (shouldShowLeftArrow) {
      newScrollArrowDirection = 'left';
    } else if (shouldShowRightArrow) {
      newScrollArrowDirection = 'right';
    }

    setScrollArrowDirection(newScrollArrowDirection);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    return () => document.removeEventListener('mousemove', onMouseMove);
  }, []);

  return (
    <>
      <div
        className={clsx(
          styles.scrollArrowBox,
          styles.left,
          scrollArrowDirection === 'left' ? styles.show : styles.hide
        )}
        onClick={scroll}
      />
      <div
        className={clsx(
          styles.scrollArrowBox,
          styles.right,
          scrollArrowDirection === 'right' ? styles.show : styles.hide
        )}
        onClick={scroll}
      />
    </>
  );
}

export default ColumnsScrollArrows;
