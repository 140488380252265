import { useStores } from 'src/RootStore';
import Confirm from 'src/theme/atoms/confirm/Confirm';
import Modal from 'src/theme/atoms/modal/Modal';

interface DeleteProps {
  isModalOpen: boolean;
  organizationId: string;
  itemId: string;
  itemName: string;
  itemType: 'roadmap' | 'test';
  onModalClose: () => void;
  onSuccess: () => void;
  onFail: () => void;
}

function Delete({
  isModalOpen,
  organizationId,
  itemId,
  itemName,
  itemType,
  onModalClose,
  onSuccess,
  onFail,
}: DeleteProps): JSX.Element {
  const { roadmapStore, testStore } = useStores();
  const { deleteRoadmap } = roadmapStore;
  const { deleteTest } = testStore;

  const onDelete = (): void => {
    const req = itemType === 'roadmap' ? deleteRoadmap : deleteTest;

    req(organizationId, itemId)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFail();
      });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      <Confirm
        question={`Are you sure that you want to delete the ${itemName} project?`}
        action={onDelete}
        close={onModalClose}
      />
    </Modal>
  );
}

export default Delete;
