import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';



import getRandomId from '../../../utils/getRandomId';
import Snackbar from '../snackbar/Snackbar';
import { deleteAsset, getAllAssets, uploadAsset } from './ImageUpload.requests';
import UnsplashModal from './UnsplashModal/UnsplashModal';


export const acceptedExtensions = ['png', 'jpg', 'jpeg', 'webp'];

const isExtensionOk = (file: File): boolean => {
  const filenameDivided = file.name.split('.');
  const extension = filenameDivided && filenameDivided[filenameDivided.length - 1];
  return acceptedExtensions.includes(extension);
};

interface UseImageUploadProps {
  onUploadStart: () => void;
  onSuccess: (imageSrc: string) => void;
  onDelete: () => void | Promise<void>;
  url?: string;
}

interface ReturnValue {
  isLoading: boolean;
  imageSrc?: string;
  handleDelete: () => Promise<void>;
  triggerInput: () => void;
  showUnsplashModal: () => void;
  UtilElement: JSX.Element;
}

const useImageUpload = ({ onUploadStart, onSuccess, onDelete, url }: UseImageUploadProps): ReturnValue => {
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUnsplashModalOpen, setIsUnsplashModalOpen] = useState(false);

  const { organizationId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const uploadRef = useRef<HTMLInputElement>(null);

  const triggerInput = async (): Promise<void> => {
    if (uploadRef.current) uploadRef.current.click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!e.target.files || e.target.files.length != 1) return;

    const file = e.target.files[0];

    if (!isExtensionOk(file)) {
      setSnackbarMsg(
        'Image upload failed. Please upload a valid image. Accepted file extensions: ' +
          acceptedExtensions.join(', ') +
          '.'
      );
      return;
    }

    onUploadStart();
    setIsLoading(true);

    try {
      const uuid = getRandomId();
      await uploadAsset(organizationId, uuid, file);
      const { data } = await getAllAssets(organizationId);
      const asset = data.find((item) => item.name === uuid);

      if (asset) {
        if (url) await handleDelete();
        onSuccess(asset.url);
      } else {
        throw new Error('Image upload failed'); // go to catch block
      }
    } catch {
      setSnackbarMsg('Image upload failed. Please try again');
      if (uploadRef.current?.value) uploadRef.current.value = '';
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (): Promise<void> => {
    try {
      const { data } = await getAllAssets(organizationId);
      const asset = data.find((item) => item.url === url);
      if (asset) await deleteAsset(organizationId, asset.key);
      await onDelete();
      if (uploadRef.current?.value) uploadRef.current.value = '';
    } catch (err) {
      setSnackbarMsg("Couldn't delete the image. Please try again.");
      throw err;
    }
  };

  const handleError = (): void => {
    setSnackbarMsg('Image upload failed. Please try again');
    if (uploadRef.current?.value) uploadRef.current.value = '';
  };

  const handleUnsplashChange = async (url: string): Promise<void> => {
    onUploadStart();
    setIsLoading(true);
    onSuccess(url);
    setIsLoading(false);
  };

  const UtilElement: JSX.Element = (
    <>
      <input
        style={{display: 'none'}}
        ref={uploadRef}
        type="file"
        accept={acceptedExtensions.map((ext) => 'image/' + ext).join(', ')}
        onError={handleError}
        onChange={(e) => handleChange(e)}
        title=""
        alt="image upload"
      />
      <Snackbar type="error" message={snackbarMsg} onClose={() => setSnackbarMsg('')} autoclose />
      <UnsplashModal
        isOpen={isUnsplashModalOpen}
        onClose={() => setIsUnsplashModalOpen(false)}
        onChange={handleUnsplashChange}
      />
    </>
  );

  return {
    isLoading,
    imageSrc: url,
    showUnsplashModal: () => setIsUnsplashModalOpen(true),
    handleDelete,
    triggerInput,
    UtilElement,
  };
};

export default useImageUpload;