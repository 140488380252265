import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';

import StickyRoadmapTopbar from 'src/roadmap/RoadmapEdition/components/StickyRoadmapTopbar/StickyRoadmapTopbar';
import Button from 'src/theme/atoms/buttons/Button/Button';

import FeatureGroupCardAnimationWrapper from '../../FeatureGroupCardAnimationWrapper/FeatureGroupCardAnimationWrapper';
import RoadmapStepHeader from '../../RoadmapStepHeader/RoadmapStepHeader';
import RoadmapEditionContainer from '../RoadmapEditionContainer/RoadmapEditionContainer';
import { useRoadmapEditionStore } from '../RoadmapEditionContext';
import FeatureGroupTimeEstimationHelperModal from './FeatureGroupAdoptionHelperModal/FeatureGroupTimeEstimationHelperModal';
import styles from './FeatureGroupTimeEstimation.module.scss';
import FeatureGroupTimeEstimationStore from './FeatureGroupTimeEstimation.store';
import TimeEstimation from './TimeEstimation/TimeEstimation';

function FeatureGroupTimeEstimation(): JSX.Element {
  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);
  const openHelperModal = (): void => setIsHelperModalOpen(true);
  const closeHelperModal = (): void => setIsHelperModalOpen(false);

  const roadmapEditionStore = useRoadmapEditionStore();
  const { roadmap, changeIsNextStepDisabled } = roadmapEditionStore;

  const [store] = useState(() => new FeatureGroupTimeEstimationStore(roadmapEditionStore));
  const { featureGroupStates, isNextStepDisabled } = store;

  useEffect(() => {
    changeIsNextStepDisabled(isNextStepDisabled);
  }, [isNextStepDisabled]);

  return (
    <div>
      <RoadmapStepHeader
        roadmapName={roadmap.name}
        title="How long will it take you to build this feature set? "
        subtitle="Evaluate your solution on a scale of adoption"
        helpButtonConfig={{
          label: 'What should I do?',
          action: openHelperModal,
        }}
      />

      <StickyRoadmapTopbar
        texts={
          <div className={styles.stickyTopbarTexts}>
            <span>
              <span>{featureGroupStates.length}</span> User Needs Defined
            </span>
          </div>
        }
        buttons={
          <Button
            color="secondary"
            label="What should I do?"
            size="m"
            onClick={openHelperModal}
            className={styles.stickyTopbarHelpBtn}
          />
        }
      />

      <RoadmapEditionContainer>
        <div className={styles.featureGroupCardList}>
          {featureGroupStates.map((fgState) => (
            <FeatureGroupCardAnimationWrapper
              key={fgState.featureGroup.id}
              fgState={fgState}
              showExtendedContent={!!fgState.timeIntervalType}
            >
              <TimeEstimation
                type={fgState.timeIntervalType}
                count={fgState.timeIntervalCount}
                onTypeChange={(type) => fgState.changeTimeIntervalType(type)}
                onCountChange={(count) => fgState.changeTimeIntervalCount(count)}
              />
            </FeatureGroupCardAnimationWrapper>
          ))}
        </div>

        {isHelperModalOpen && <FeatureGroupTimeEstimationHelperModal onClose={closeHelperModal} />}
      </RoadmapEditionContainer>
    </div>
  );
}

export default observer(FeatureGroupTimeEstimation);
