import { makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';
import { SummaryTest } from 'src/theme/atoms/TestSummary/TestSummary.types';

import * as requests from './NoAuthTest.requests';

class NoAuthTestStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getTestFromShareLink = (testId: string): Promise<SummaryTest> => {
    return requests.getTestByLink(testId).then((res) => {
      return res.data;
    });
  };
}

export default NoAuthTestStore;
