import { makeAutoObservable } from 'mobx';

import handleRequestError from 'src/utils/handleRequestError';
import sortBySeq from 'src/utils/sortBySeq';
import { CustomError } from 'src/utils/types/CustomError';

import * as requests from '../Test.requests';
import TestStore from '../Test.store';
import {
  CreateIdeaDto,
  UpdateIdeaDto,
  UpdateIdeaGroupDto,
  Test,
  BasicTest,
  Idea,
  IdeaGroup,
  UpdateTestDto,
} from '../Test.types';

class TestEditionStore {
  testStore: TestStore;
  organizationId: string;

  test: BasicTest;
  ideas: Idea[];
  ideaGroups: IdeaGroup[];

  isUpdatingIdeaGroup = false;

  sendingRequest = false;

  isNextStepDisabled = false;
  isImageUploading = false;

  ideaGroupUpdateError: CustomError | null = null;

  constructor(testStore: TestStore, organizationId: string, test: Test) {
    this.testStore = testStore;
    this.organizationId = organizationId;

    this.test = test;
    this.ideas = sortBySeq(test.ideas);
    this.ideaGroups = sortBySeq(test.ideaGroups);

    makeAutoObservable(this);
  }

  get ideasCount(): number {
    return this.ideas.length;
  }

  get ideaGroupsCount(): number {
    return this.ideaGroups.length;
  }

  private fetchIdeasAndIdeaGroups = (): Promise<void> => {
    const ideasPromise = this.testStore.fetchIdeas(this.organizationId, this.test.id);
    const ideasGroupsPromise = this.testStore.fetchIdeaGroups(this.organizationId, this.test.id);

    return Promise.all([ideasPromise, ideasGroupsPromise]).then(([ideas, ideasGroups]) => {
      this.ideas = ideas;
      this.ideaGroups = ideasGroups;
    });
  };

  setSendingRequest = (val: boolean): void => {
    this.sendingRequest = val;
  };

  private fetchIdeaGroups = (): Promise<void> => {
    return this.testStore.fetchIdeaGroups(this.organizationId, this.test.id).then((ideaGroups) => {
      this.ideaGroups = sortBySeq(
        ideaGroups.map((fg) => ({
          ...fg,
          ideas: sortBySeq(fg.ideas),
        }))
      );
    });
  };

  private fetchIdeas = (): Promise<void> => {
    return this.testStore.fetchIdeas(this.organizationId, this.test.id).then((ideas) => {
      this.ideas = sortBySeq(ideas);
    });
  };

  private fetchIdeaGroup = (ideaGroupId: string): Promise<IdeaGroup> => {
    return this.testStore.fetchIdeaGroup(this.organizationId, this.test.id, ideaGroupId).then((ideaGroup) => {
      const index = this.ideaGroups.findIndex((fg) => fg.id === ideaGroupId);

      if (index !== -1) {
        this.ideaGroups[index] = {
          ...ideaGroup,
          ideas: sortBySeq(ideaGroup.ideas),
        };
      }

      return this.ideaGroups[index];
    });
  };

  updateTest = (dto: UpdateTestDto): Promise<void> => {
    return this.testStore
      .updateTest(this.organizationId, this.test.id, dto)
      .then((test) => {
        this.test = test;
      })
      .catch((err) => {
        alert('Something went wrong!');
        throw err;
      });
  };

  createIdea = (dto: CreateIdeaDto): Promise<Idea> => {
    return this.testStore.createIdea(this.organizationId, this.test.id, dto).then((idea) => {
      this.ideas.unshift(idea);
      return idea;
    });
  };

  handleMultipleIdeasCreate = (dto: CreateIdeaDto[]): Promise<Idea[]> => {
    return this.testStore.createMultipleIdeas(this.organizationId, this.test.id, dto).then((ideas) => {
      ideas.map((el) => this.ideas.push(el));
      return ideas;
    });
  };

  updateIdea = (ideaId: string, dto: UpdateIdeaDto): Promise<Idea> => {
    return this.testStore.updateIdea(this.organizationId, this.test.id, ideaId, dto).then((updatedIdea) => {
      const index = this.ideas.findIndex((idea) => idea.id === ideaId);
      if (index !== -1) this.ideas[index] = updatedIdea;
      return updatedIdea;
    });
  };

  deleteIdea = (ideaId: string, ideaGroupId?: string | null): Promise<void> => {
    return this.testStore.deleteIdea(this.organizationId, this.test.id, ideaId).then(() => {
      const index = this.ideas.findIndex((idea) => idea.id === ideaId);

      if (ideaGroupId) {
        const fgIndex = this.ideaGroups.findIndex((ideaGroup) => ideaGroup.id === ideaGroupId);
        if (fgIndex !== -1) {
          const fIndex = this.ideaGroups[fgIndex].ideas.findIndex((f) => f.id === ideaId);

          if (fIndex !== -1) {
            this.ideaGroups[fgIndex].ideas.splice(fIndex, 1);
          }
        }
      }

      if (index !== -1) this.ideas.splice(index, 1);
    });
  };

  getIdeaById = (id: string): Idea | undefined => {
    return this.ideas.find((idea) => idea.id === id);
  };

  //IdeaGroups
  createIdeaGroup = async (name: string, ideaIds?: string[]): Promise<IdeaGroup> => {
    try {
      const createdIdeaGroup = await this.testStore.createIdeaGroup(this.organizationId, this.test.id, {
        name,
      });

      if (ideaIds) {
        await this.testStore.reassignIdeasToIdeaGroup(this.organizationId, this.test.id, createdIdeaGroup.id, ideaIds);
      }

      await this.fetchIdeaGroups();

      const ideaGroup = this.ideaGroups.find((ideaGroup) => ideaGroup.id === createdIdeaGroup.id);
      if (!ideaGroup) throw new Error('No idea group found');

      return ideaGroup;
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't create idea group",
        config: {
          409: 'Idea group with this name already exists',
        },
      });

      throw error;
    }
  };

  duplicateIdeaGroup = async (fgId: string): Promise<IdeaGroup> => {
    try {
      if (!this.test) throw new Error('No test found');

      const duplicatedIdeaGroup = await this.testStore.duplicateIdeaGroup(this.organizationId, this.test.id, fgId);

      return this.fetchIdeaGroups().then(() => {
        const ideaGroup = this.ideaGroups.find((ideaGroup) => ideaGroup.id === duplicatedIdeaGroup.id);
        if (!ideaGroup) throw new Error('No idea group found');

        return ideaGroup;
      });
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't duplicate idea group",
      });

      throw error;
    }
  };

  updateIdeaGroupWithIdeas = async (id: string, name: string, ideaIds: string[]): Promise<IdeaGroup> => {
    //////////////////////////////////////////////// UNUSED
    this.ideaGroupUpdateError = null;

    try {
      this.isUpdatingIdeaGroup = true;

      const { data: createdIdeaGroup } = await requests.updateIdeaGroup(this.organizationId, this.test.id, id, {
        name,
      });

      const ideaGroup = await this.reassignIdeasToIdeaGroup(createdIdeaGroup.id, ideaIds);

      return ideaGroup;
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't update idea group",
        config: {
          409: 'Idea group with this name already exists',
        },
      });

      this.ideaGroupUpdateError = error;

      throw error;
    } finally {
      this.isUpdatingIdeaGroup = false;
    }
  };

  assignIdeasToIdeaGroups = async (ideaGroupIds: string[], ideaIdsToAssign: string[]): Promise<IdeaGroup[]> => {
    const ideaGroups = this.ideaGroups.filter((ideaGroup) => ideaGroupIds.includes(ideaGroup.id));

    for (const ideaGroup of ideaGroups) {
      const currentIdeaIds = ideaGroup.ideas.map((idea) => idea.id);
      const ideaIds = [...currentIdeaIds, ...ideaIdsToAssign.filter((id) => !currentIdeaIds.includes(id))];

      await requests.reassignIdeasToIdeaGroup(this.organizationId, this.test.id, ideaGroup.id, ideaIds);
    }

    await this.fetchIdeaGroups();
    return this.ideaGroups;
  };

  deleteIdeaGroup = async (ideaGroupId: string): Promise<void> => {
    try {
      await requests.deleteIdeaGroup(this.organizationId, this.test.id, ideaGroupId);

      const index = this.ideaGroups.findIndex((ideaGroup) => ideaGroup.id === ideaGroupId);
      if (index !== -1) this.ideaGroups.splice(index, 1);

      await this.fetchIdeas();
    } catch (err) {
      const error = handleRequestError({
        error: err,
        defaultMessage: "Couldn't delete idea group",
      });

      throw error;
    }
  };

  clearIdeaGroupUpdateError = (): void => {
    this.ideaGroupUpdateError = null;
  };

  updateIdeaGroup = (id: string, ideaGroupDto: UpdateIdeaGroupDto): Promise<IdeaGroup> => {
    return requests
      .updateIdeaGroup(this.organizationId, this.test.id, id, ideaGroupDto)
      .then((res) => {
        const index = this.ideaGroups.findIndex((ideaGroup) => ideaGroup.id === id);
        if (index !== -1) {
          this.ideaGroups[index] = {
            ...this.ideaGroups[index],
            ...res.data,
          };
        }

        return this.ideaGroups[index];
      })
      .catch((err) => {
        const error = handleRequestError({
          error: err,
          defaultMessage: "Couldn't update idea group",
          config: {
            409: 'Idea group with this name already exists',
          },
        });

        throw error;
      });
  };

  deassignIdeaFromIdeaGroup = (ideaGroupId: string, ideaId: string): Promise<void> => {
    return this.testStore
      .deassignIdeaFromIdeaGroup(this.organizationId, this.test.id, ideaGroupId, ideaId)
      .then(() => {
        const index = this.ideaGroups.findIndex((fg) => fg.id === ideaGroupId);
        if (index !== -1) {
          const ideaIndex = this.ideaGroups[index].ideas.findIndex((idea) => idea.id === ideaId);
          if (ideaIndex !== -1) this.ideaGroups[index].ideas.splice(ideaIndex, 1);
        }
      })
      .catch((err) => {
        const error = handleRequestError({
          error: err,
          defaultMessage: "Couldn't deassign idea from idea group",
        });

        throw error;
      });
  };

  createIdeaAndAssignToIdeaGroup = (ideaGroupId: string, dto: CreateIdeaDto): Promise<Idea> => {
    return this.testStore.createIdea(this.organizationId, this.test.id, dto).then((createdIdea) => {
      return this.testStore
        .assignIdeaToIdeaGroup(this.organizationId, this.test.id, ideaGroupId, createdIdea.id)
        .then(() => {
          this.fetchIdeasAndIdeaGroups();
          return createdIdea;
        });
    });
  };

  changeIsNextStepDisabled = (val: boolean): void => {
    this.isNextStepDisabled = val;
  };

  reassignIdeasToIdeaGroup = async (ideaGroupId: string, ideaIds: string[]): Promise<IdeaGroup> => {
    try {
      await this.testStore.reassignIdeasToIdeaGroup(this.organizationId, this.test.id, ideaGroupId, ideaIds);

      const ideaGroup = await this.fetchIdeaGroup(ideaGroupId);

      return ideaGroup;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  updateIdeasSequence = async (orderedIdeaIds: string[], ideaGroupId?: string): Promise<void> => {
    try {
      const fIdsWithNewSequence = orderedIdeaIds.map((id, index) => ({
        id,
        seq: index,
      }));

      const ideas = await this.testStore.reorderIdeas(this.organizationId, this.test.id, fIdsWithNewSequence);

      if (ideaGroupId) {
        const fgIndex = this.ideaGroups.findIndex((fg) => fg.id === ideaGroupId);
        this.ideaGroups[fgIndex].ideas = ideas;
      } else {
        await this.fetchIdeas();
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  updateIdeaGroupsSequence = async (orderedIdeaGroupsIds: string[]): Promise<void> => {
    if (!this.test) throw new Error('No test found');

    try {
      this.changeIsNextStepDisabled(true);

      const fgIdsWithNewSequence = orderedIdeaGroupsIds.map((id, index) => ({
        id,
        seq: index,
      }));

      await this.testStore.reorderIdeaGroups(this.organizationId, this.test.id, fgIdsWithNewSequence);
      await this.fetchIdeaGroups();
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.changeIsNextStepDisabled(false);
    }
  };

  changeIsImageUploading = (val: boolean): void => {
    this.isImageUploading = val;
  };
}

export default TestEditionStore;
