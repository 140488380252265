import { observer } from 'mobx-react-lite';

import styles from './ExplainTeamMember.module.scss';

const bullets = [
  'Edit any Plans or Tests already created in the account',
  'Create new Plans or Tests in the account',
  'Contact the account owner to purchase audiences for Test',
  'Contact the account owner to upgrade the account beyond 3 Users, 3 Tests, 1 Plan ',
  // 'Purchase audiences for Tests',
  // 'Upgrade the Foundations subscription plan to add more than 3 members to the account or in order to create more than 1 Plan or more than 3 Tests',
];

function ExplainTeamMember({ bulletMaxWidth }: { bulletMaxWidth?: number }): JSX.Element {
  return (
    <div className={styles.root}>
      <h3>A member of a Team may:</h3>
      <ul>
        {bullets.map((bullet) => (
          <li key={bullet.split(' ')[0]} className={styles.bullet} style={{ maxWidth: `${bulletMaxWidth}px` }}>
            {bullet}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default observer(ExplainTeamMember);
