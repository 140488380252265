import clsx from 'clsx';

import { PhotoDto } from '../UnsplashModal.types';
import styles from './PhotoItem.module.scss';

interface PhotoItemProps {
  index: number;
  photo: PhotoDto | null;
  isLoaded: boolean;
  onLoad: (index: number) => void;
  onClick: (photo: PhotoDto) => void;
}

const getUnsplashUserUrl = (name: string): string =>
  `https://unsplash.com/@${name}?utm_source=alignment_web&utm_medium=referral`;

const unsplashImgUrl = 'https://unsplash.com/?utm_source=alignment_web&utm_medium=referral';

function PhotoItem({ index, photo, isLoaded, onLoad, onClick }: PhotoItemProps): JSX.Element {
  const handleClick = (e): void => {
    e.stopPropagation();
  };

  return (
    <div className={styles.card} onClick={photo ? () => onClick(photo) : undefined}>
      <div className={clsx(styles.placeholder, isLoaded && styles.hidden)} />
      {photo && (
        <>
          <img src={photo.urls.regular} alt={photo.description} className={styles.photo} onLoad={() => onLoad(index)} />
          {photo.user && (
            <div className={styles.attribution}>
              {'Photo by '}
              <a
                href={getUnsplashUserUrl(photo.user.username)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleClick}
              >
                {photo.user.name}
              </a>
              {'\non '}
              <a href={unsplashImgUrl} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                Unsplash
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PhotoItem;
