const sortBySeq = <T extends { seq?: number; createdAt: string }>(items: T[]): T[] => {
  return [...items].sort((a, b) => {
    if (a?.seq !== undefined && b?.seq !== undefined) {
      return a.seq - b.seq;
    } else {
      return Date.parse(b.createdAt) - Date.parse(a.createdAt);
    }
  });
};

export default sortBySeq;
