import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { paths } from 'src/app/routes/paths.const';
import { ADD_TEAM_MEMBERS_BTN } from 'src/utils/constants';

import Header from '../Header';
import HeaderAccountActions from '../Header/HeaderAccountActions';
import { useOrganization } from '../organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import AddCollabolatorModal from '../theme/atoms/AddCollabolatorModal/AddCollabolatorModal';
import Button from '../theme/atoms/buttons/Button/Button';
import styles from './Dashboard.module.scss';

interface DashboardHeaderProps {
  isAdmin: boolean;
  isOwner: boolean;
}

function DashboardHeader({ isAdmin, isOwner }: DashboardHeaderProps): JSX.Element {
  const [isAddCollaboratorsModalOpen, setIsAddCollaboratorsModalOpen] = useState(false);
  // const [notificationsNo, setNotificationsNo] = useState<number>(1); // TODO by BE or Josh?
  const organization = useOrganization();

  return (
    <Header organization={organization}>
      {(isAdmin || isOwner) && (
        <div className={styles.actionsWrapper}>
          <div className={clsx(styles.actions, isAdmin && styles.withAdminPanelBtn)}>
            <Button
              label={ADD_TEAM_MEMBERS_BTN}
              size="s"
              color="secondary"
              onClick={() => setIsAddCollaboratorsModalOpen(true)}
            />
            {isAdmin && (
              <Link to={paths.admin()} className={styles.adminPanelLink} target="_blank" rel="noopener noreferrer">
                admin panel
              </Link>
            )}
          </div>
          <div className={styles.verticalDivider} />
        </div>
      )}
      {/*
        <div className={styles.notificationsNo} onClick={() => console.log('notifications')}>
          <span>{notificationsNo > 9 ? notificationsNo : '0' + notificationsNo}</span>
        </div>
      */}
      <HeaderAccountActions organization={organization} />

      {isAddCollaboratorsModalOpen && <AddCollabolatorModal onClose={() => setIsAddCollaboratorsModalOpen(false)} />}
    </Header>
  );
}

export default observer(DashboardHeader);
