import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from 'src/Header';
import { useStores } from 'src/RootStore';
import { SummaryFeatureGroup, SummaryRoadmap } from 'src/roadmap/RoadmapDetails/RoadmapDetails.types';
import RoadmapSummary from 'src/theme/atoms/RoadmapSummary/RoadmapSummary';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import env from 'src/utils/env';

import styles from './NoAuthRoadmap.module.scss';

const errorMessages = {
  err404: 'The requested resource could not be found. Please check if the link you are using is correct.',
  generalErr: "Couldn't fetch roadmap data. Please try again later.",
};

function NoAuthRoadmap(): JSX.Element {
  const { noAuthRoadmapStore } = useStores();
  const { getRoadmapFromShareLink } = noAuthRoadmapStore;

  const { roadmapId } = useParams();
  if (!roadmapId) throw new Error('No roadmap id');

  const [errMsg, setErrMsg] = useState<string>();

  const [isLoading, setIsLoading] = useState(true);
  const [summaryRoadmap, setSummaryRoadmap] = useState<SummaryRoadmap | null>(null);

  useEffect(() => {
    getRoadmapFromShareLink(roadmapId)
      .then((roadmap) => {
        const newSummaryRoadmap: SummaryRoadmap = {
          ...roadmap,
          featureGroups: roadmap.featureGroups.filter(
            (fg): fg is SummaryFeatureGroup => fg.chartPriority !== undefined
          ),
        };

        setSummaryRoadmap(newSummaryRoadmap);
      })
      .catch((err) => {
        setErrMsg(err.response.status === 404 ? errorMessages.err404 : errorMessages.generalErr);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <Loader />;

  if (errMsg || !summaryRoadmap) {
    return (
      <div>
        <Header />
        <div className={styles.error}>
          <h3>{errMsg || 'No roadmap'}</h3>
          <div>
            <a href={env.landingPage}>Go to Homepage</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.name}>{summaryRoadmap.name}</div>
      <div className={styles.content}>
        <RoadmapSummary summaryRoadmap={summaryRoadmap} isInteractive={false} />
      </div>
    </div>
  );
}

export default observer(NoAuthRoadmap);
