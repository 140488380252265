import styles from './Loader.module.scss';

function Loader(): JSX.Element {
  return (
    <div className={styles.root}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 18 200 173.2"
        xmlSpace="preserve"
        className={styles.svg}
      >
        <polygon className={styles.fill} points="22.2,174.9 100,40.3 177.8,174.9 " />
        <path className={styles.bg} d="M0,14.8V188h200V14.8H0z M0.8,187.3L100,15.5l99.2,171.8H0.8z" />
        <path
          className={styles.border}
          d="M0.8,187.3h198.4L100,15.5L0.8,187.3z M22.2,174.9L100,40.3l77.8,134.7H22.2z"
        />
      </svg>
    </div>
  );
}

export default Loader;
