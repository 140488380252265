import { Box } from './ColumnsBox';
import { CursorPosition } from './getCursorPosition';

const isInScrollingRange = (box: Box, cursorPosition: CursorPosition): boolean => {
  const inRangeHorizontally = cursorPosition.x >= box.x && cursorPosition.x <= box.x + box.w;
  const inRangeVertically = cursorPosition.y >= box.y && cursorPosition.y <= box.y + box.h;
  const inRange = inRangeHorizontally && inRangeVertically;

  return inRange;
};

export default isInScrollingRange;
