import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from 'src/theme/svg/close.svg';

import styles from './Snackbar.module.scss';

export interface SnackbarProps {
  type: 'success' | 'error' | 'info';
  message?: string | JSX.Element;
  duration?: number;
  autoclose?: boolean;
  onClose: () => void;
}

function Snackbar({ message, type, duration = 5000, autoclose = true, onClose }: SnackbarProps): JSX.Element | null {
  const [displaying, setDisplaying] = useState(false);

  const typeClass = (): string => {
    switch (type) {
      case 'success':
        return styles.success;
      case 'error':
        return styles.error;
      default:
        return styles.info;
    }
  };

  const handleClose = (): void => {
    setDisplaying(false);
    onClose();
  };

  useEffect(() => {
    if (message) {
      setDisplaying(true);

      if (autoclose) {
        const delayClose = setTimeout(() => {
          handleClose();
        }, duration);

        return () => clearTimeout(delayClose);
      }
    }
  }, [message]);

  if (message) {
    const component = (
      <div
        className={clsx(styles.snackbar, typeClass(), displaying ? styles.open : styles.closed)}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.snackbarInner}>
          <button onClick={handleClose} className={styles.closeBtn} type="button">
            <CloseIcon className={styles.closeIcon} />
          </button>
          <span>{message}</span>
        </div>
      </div>
    );

    return ReactDOM.createPortal(component, document.body);
  }

  return null;
}

export default Snackbar;
