import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Header from 'src/Header';
import HeaderAccountActions from 'src/Header/HeaderAccountActions';
import { paths } from 'src/app/routes/paths.const';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Container from 'src/theme/atoms/container/Container';
import Modal from 'src/theme/atoms/modal/Modal';

import { useOrganization } from '../OrganizationRouteLayout/OrganizationRouteLayout.context';
import styles from './OrganizationPageLayout.module.scss';
import OrganizationPageLayoutStore from './OrganizationPageLayout.store';

interface OrganizationPageLayoutProps {
  children: ReactNode;
}

function OrganizationPageLayout({ children }: OrganizationPageLayoutProps): JSX.Element | null {
  const organization = useOrganization();

  const [store] = useState(() => new OrganizationPageLayoutStore());
  const {
    isLoading,
    shouldNavigateToDashboard,
    isInactiveSubscriptionModalOpen,
    ensureOrganizationIsValid,
    closeInactiveSubscriptionModal,
  } = store;

  useEffect(() => {
    ensureOrganizationIsValid(organization);
  }, []);

  if (shouldNavigateToDashboard) return <Navigate to={paths.organizationDashboard(organization.id)} replace />;
  if (isLoading) return null;

  return (
    <div className={styles.root}>
      <Header organization={organization}>
        <HeaderAccountActions organization={organization} />
      </Header>
      <Container>{children}</Container>
      <Modal isOpen={isInactiveSubscriptionModalOpen} onClose={closeInactiveSubscriptionModal} shouldBePadding>
        <div className={styles.inactiveSubscriptionModalContent}>
          <h3>Subscription Expired</h3>
          <p>Your subscription plan has expired. Please renew your subscription to continue using the app.</p>
          <Button label="Close" color="secondary" size="m" onClick={closeInactiveSubscriptionModal} />
        </div>
      </Modal>
    </div>
  );
}

export default observer(OrganizationPageLayout);
