import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate, Outlet, useParams } from 'react-router-dom';

import { paths } from 'src/app/routes/paths.const';
import StepLayout from 'src/theme/atoms/steps/StepLayout';
import { ROADMAP_STEPS_COUNT } from 'src/utils/constants';

import { useRoadmapEditionStore } from './RoadmapEditionContext';

const actionBottomBarConfigLabelForPrev = [
  `List out your features: 1 of ${ROADMAP_STEPS_COUNT}`,
  `Who offers what you offer: 2 of ${ROADMAP_STEPS_COUNT}`,
  `Do they want it?: 3 of ${ROADMAP_STEPS_COUNT}`,
  `Level of effort: 4 of ${ROADMAP_STEPS_COUNT}`,
];

const steps = [
  'feature-groups-creation',
  'feature-groups-urgency',
  'feature-groups-adoption',
  'feature-groups-time-estimation',
];

const getPrevStep = (currentStep: number, organizationId: string, roadmapId: string): string => {
  switch (currentStep) {
    case 2:
      return paths.roadmapFeatureGroupsCreation(organizationId, roadmapId);
    case 3:
      return paths.roadmapFeatureGroupsUrgency(organizationId, roadmapId);
    default:
      return paths.roadmapFeatureGroupAdoption(organizationId, roadmapId);
  }
};

const getNextStep = (currentStep: number, organizationId: string, roadmapId: string): string => {
  switch (currentStep) {
    case 1:
      return paths.roadmapFeatureGroupsUrgency(organizationId, roadmapId);
    case 2:
      return paths.roadmapFeatureGroupAdoption(organizationId, roadmapId);
    default:
      return paths.roadmapFeatureGroupTimeEstimation(organizationId, roadmapId);
  }
};

function RoadmapStepsLayout(): JSX.Element {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const { roadmap, isNextStepDisabled, isChangingStepsDisabled } = useRoadmapEditionStore();

  const navigate = useNavigate();

  const location = useLocation();
  const stepName = location.pathname.split('/').pop() || '';

  if (!stepName || !steps.includes(stepName)) {
    return <Navigate to={paths.organizationDashboard(organizationId)} />;
  }

  const [currentStep, setCurrentStep] = useState<number>(steps.indexOf(stepName) + 1);

  useEffect(() => {
    setCurrentStep(steps.indexOf(stepName) + 1);
  }, [stepName]);

  const handlePrevStep = (): void => {
    return currentStep === 1
      ? navigate(paths.roadmapUpdateName(organizationId, roadmap.id))
      : navigate(getPrevStep(currentStep, organizationId, roadmap.id));
  };

  const handleNextStep = (): void => {
    return currentStep === ROADMAP_STEPS_COUNT
      ? navigate(paths.roadmapDetails(organizationId, roadmap.id), { state: { cameFromEdition: true } })
      : navigate(getNextStep(currentStep, organizationId, roadmap.id));
  };

  return (
    <StepLayout
      actionBottomBarConfig={{
        labelForPrev: actionBottomBarConfigLabelForPrev[currentStep - 1],
        currentStep,
        stepsCount: ROADMAP_STEPS_COUNT,
        prevStepDisabled: isChangingStepsDisabled,
        nextStepDisabled: isNextStepDisabled || isChangingStepsDisabled,
        prev: handlePrevStep,
        next: handleNextStep,
      }}
    >
      <Outlet />
    </StepLayout>
  );
}

export default observer(RoadmapStepsLayout);
