import { useEffect, useState } from 'react';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { PaymentSources } from 'src/plans/Plans.types';
import Button from 'src/theme/atoms/buttons/Button/Button';
import ButtonLink from 'src/theme/atoms/buttons/ButtonLink/ButtonLink';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Modal from 'src/theme/atoms/modal/Modal';

import { testThumbnail } from '../../Dashboard/assets';
import OrganizationPlansStore from '../../organizations/OrganizationPlans/OrganizationPlans.store';
import { useOrganization } from '../../organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import { PaymentStatus } from '../../plans/Plans.types';
import PurchaseCompletionLayout from '../PurchaseCompletionLayout';
import { ExternalAudienceCostResponse } from '../Test.types';
import { useTest } from '../TestLayout';
import styles from './PurchaseAudienceCintModal.module.scss';

interface PurchaseAudienceCintModalProps {
  onClose: () => void;
  processPayment: () => Promise<void>;
  paymentStatus: PaymentStatus;
}

function PurchaseAudienceCintModal({
  onClose,
  processPayment,
  paymentStatus,
}: PurchaseAudienceCintModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const { testStore, plansStore } = useStores();
  const organization = useOrganization();
  const { test, changeTest } = useTest();

  if (!test) throw new Error('No test');
  const [store] = useState(() => new OrganizationPlansStore(organization));

  const imageUrl = test.imageUrl ? test.imageUrl : testThumbnail;

  const purchaseText = {
    beforePaid: `We can help get you ${test.totalRespondentsCount}
    tester${test.totalRespondentsCount && test.totalRespondentsCount > 1 ? 's' : ''} by using our
            panel of users. It costs $${test.cost?.toFixed(2)} to test your
            ${test.inputParameters && test.inputParameters.ideasCount}
            idea${test.inputParameters && test.inputParameters.ideasCount > 1 ? 's' : ''}.`,
    afterPaid: 'Your test is running live with our audience of users. You should have results in 24 hours or less.',
  };

  useEffect(() => {
    const reqs: Promise<ExternalAudienceCostResponse | PaymentSources>[] = [];
    if (test.cost === undefined) {
      reqs.push(testStore.getExternalAudienceCost(organization.id, test.id));
    }
    if (test.cardsCount === undefined) {
      reqs.push(plansStore.getPaymentSources(organization.id));
    }

    if (reqs.length) {
      setIsLoading(true);

      Promise.all(reqs)
        .then((responses) => {
          const responsesObject = responses.reduce(function (result, current) {
            return Object.assign(result, current);
          }, {});
          changeTest({ ...test, ...responsesObject });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const onCloseManageSubscriptions = (): void => {
    setIsLoading(true);
    plansStore
      .getPaymentSources(organization.id)
      .then((response) => {
        changeTest({ ...test, cardsCount: response.cardsCount });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading)
    return (
      <>
        <Loader />
        <img src={imageUrl} className={styles.invisible} />
      </>
    );

  return (
    <div className={styles.modalContainer}>
      <Modal className={styles.modalContent}>
        {paymentStatus === 'success' ? (
          <PurchaseCompletionLayout
            imageUrl={imageUrl}
            text={purchaseText.afterPaid}
            button={
              <ButtonLink
                className={styles.gotItButton}
                label="GOT IT"
                to={paths.organizationDashboard(organization.id)}
                size="l"
                color="primary"
              />
            }
          />
        ) : (
          <PurchaseCompletionLayout
            imageUrl={imageUrl}
            text={purchaseText.beforePaid}
            button={
              test.cardsCount ? (
                <Button label="PURCHASE TESTERS" color="primary" size="l" onClick={() => processPayment()} />
              ) : (
                <Button
                  onClick={() => store.manageSubscriptions(onCloseManageSubscriptions)}
                  label="ADD CARD TO PURCHASE TESTERS"
                  color="secondary"
                  size="l"
                />
              )
            }
            extraLink={onClose}
            extraLinkText={'NO THANKS'}
          />
        )}
      </Modal>
    </div>
  );
}

export default PurchaseAudienceCintModal;
