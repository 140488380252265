import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { ExternalAudienceState } from 'src/Tests/Test.types';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';

import TestNameAudienceProblem from '../TestNameAudienceProblem/TestNameAudienceProblem';

function TestCreate(): JSX.Element {
  const navigate = useNavigate();

  const organization = useOrganization();

  const { testStore } = useStores();

  const [name, setName] = useState('');
  const [externalAudienceState, setExternalAudienceState] = useState<ExternalAudienceState>();
  const [audienceName, setAudienceName] = useState('');
  const [audienceCountryId, setAudienceCountryId] = useState<string | undefined>(undefined);
  const [audienceGenderId, setAudienceGenderId] = useState<string | undefined>(undefined);
  const [audienceAgeMin, setAudienceAgeMin] = useState<number | undefined>(undefined);
  const [audienceAgeMax, setAudienceAgeMax] = useState<number | undefined>(undefined);
  const [audienceIncomeIds, setAudienceIncomeIds] = useState<string[]>([]);
  const [problem, setProblem] = useState('');

  const handleSave = (): Promise<void> => {
    const dto = {
      name,
      externalAudienceState,
      audienceName,
      audienceCountryId,
      audienceGenderId,
      audienceAgeMin:
        audienceAgeMin &&
        !(audienceAgeMin === testStore.cintCategories?.ageMin && audienceAgeMax === testStore.cintCategories?.ageMax)
          ? audienceAgeMin
          : undefined,
      audienceAgeMax:
        audienceAgeMax &&
        !(audienceAgeMin === testStore.cintCategories?.ageMin && audienceAgeMax === testStore.cintCategories?.ageMax)
          ? audienceAgeMax
          : undefined,
      audienceIncomeIds,
      problem,
    };
    for (const item in dto) dto[item] = typeof dto[item] === 'string' ? dto[item].trim() : dto[item];

    return testStore.createTest(organization.id, { ...dto, state: 'draft' }).then((test) => {
      navigate(`/organizations/${test.organizationId}/tests/${test.id}/edition`, { replace: true }); // going back here by using 'back' in browser will move user to UpdateTest page so they can edit current test (instead of being moved to CreateTest view where they would create a new test instead of editing current one)
      navigate(paths.testIdeasManagement(organization.id, test.id));
    });
  };

  return (
    <div>
      <TestNameAudienceProblem
        name={name}
        externalAudienceState={externalAudienceState}
        audienceName={audienceName}
        audienceCountryId={audienceCountryId}
        audienceGenderId={audienceGenderId}
        audienceAgeMin={audienceAgeMin}
        audienceAgeMax={audienceAgeMax}
        audienceIncomeIds={audienceIncomeIds}
        problem={problem}
        onNameChange={(v) => setName(v)}
        onAudienceNameChange={(v) => setAudienceName(v)}
        onAudienceCountryIdChange={(v) => setAudienceCountryId(v)}
        onAudienceGenderIdChange={(v) => setAudienceGenderId(v)}
        onAudienceAgeMinChange={(v) => setAudienceAgeMin(v)}
        onAudienceAgeMaxChange={(v) => setAudienceAgeMax(v)}
        onAudienceIncomeIdsChange={(v) => setAudienceIncomeIds(v as string[])}
        onExternalAudienceState={(v) => setExternalAudienceState(v)}
        onProblemChange={(v) => setProblem(v)}
        onSave={handleSave}
      />
    </div>
  );
}

export default TestCreate;
