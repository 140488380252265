import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';
import { Organization } from 'src/organizations/Organizations.types';
import { FREE_PLAN_ID } from 'src/plans/Plans.store';
import { SnackbarProps } from 'src/theme/atoms/snackbar/Snackbar';
import handleRequestError from 'src/utils/handleRequestError';

const AUTO_REFRESH_TIMEOUT = 30000;

type Result = 'success' | 'fail' | null;

class OrganizationPlansStore {
  organization: Organization;

  isLoading = true;
  isInactiveSubscriptionModalOpen = false;
  checkoutPlanId: string | null = null;
  snackbarConfig: {
    message: string;
    type: SnackbarProps['type'];
  } = {
    message: '',
    type: 'success',
  };
  checkoutResult: Result = null;
  portalSessionResult: Result = null;
  autoRefreshTimeout: NodeJS.Timeout | null = null;

  constructor(organization: Organization) {
    this.organization = organization;
    makeAutoObservable(this);
  }

  handleInitialRender = (): void => {
    this.isLoading = true;
    rootStore.plansStore.getPlans().then(() => {
      this.isLoading = false;
    });
  };

  private handleCheckoutSuccess = (): void => {
    this.checkoutPlanId = null;
    this.checkoutResult = 'success';
  };

  private handleCheckoutError = (): void => {
    this.checkoutPlanId = null;
    this.checkoutResult = 'fail';
  };

  private handleCheckoutClose = (): void => {
    if (this.checkoutResult) {
      const isSuccess = this.checkoutResult === 'success';
      this.snackbarConfig = {
        message: isSuccess
          ? 'Your payment has been successfully processed. The site will auto refresh in about 30 seconds to reflect the changes'
          : 'Payment failed. Please try again later.',
        type: isSuccess ? 'success' : 'error',
      };

      if (isSuccess) {
        this.autoRefreshTimeout = setTimeout(() => {
          window.location.reload();
        }, AUTO_REFRESH_TIMEOUT);
      }

      this.checkoutResult = null;
    }

    this.checkoutPlanId = null;
  };

  handleCleanup = (): void => {
    if (this.autoRefreshTimeout) {
      clearInterval(this.autoRefreshTimeout);
    }
  };

  checkout = (planId: string): void => {
    if (!this.organization) throw new Error('No organization');

    this.checkoutPlanId = planId;

    rootStore.plansStore
      .checkoutPlan(this.organization.id, planId, {
        onSuccess: this.handleCheckoutSuccess,
        onClose: this.handleCheckoutClose,
        onError: this.handleCheckoutError,
      })
      .catch((err) => {
        const { message } = handleRequestError({
          error: err,
          defaultMessage: err.response.data.includes('chargebee: ')
            ? `There has been a payment or credit card processing error. The error code: ${
                err.response.data.split('chargebee: ')[1]
              }`
            : 'Something went wrong. Please try again later.',
          config: {
            409: 'You cannot change paid subscription plan for the free one',
          },
        });

        this.snackbarConfig = {
          message,
          type: 'error',
        };
        this.checkoutPlanId = null;
      });
  };

  private handlePortalSessionError = (): void => {
    this.snackbarConfig = {
      message: 'Cannot manage subscritions. Please try again later.',
      type: 'error',
    };
  };

  manageSubscriptions = (onClose?: () => void): void => {
    if (!this.organization) throw new Error('No organization');

    rootStore.plansStore
      .openPortalSession(this.organization.id, {
        onClose: () => {
          if (onClose) onClose();
        },
        onError: () => {
          this.handlePortalSessionError();
        },
      })
      .then(() => console.log('subscriptions done'))
      .catch(() => {
        this.handlePortalSessionError();
      });
  };

  getSubmitPlanBtnText = (planId: string): string => {
    if (!this.organization) throw new Error('No organization');

    if (this.checkoutPlanId === planId) {
      return 'Loading...';
    } else if (
      planId === FREE_PLAN_ID &&
      this.organization.subscriptionPricingId === planId &&
      this.organization.subscriptionStatus === 'Inactive'
    ) {
      return 'Blocked';
    } else if (
      this.organization.subscriptionPricingId === planId &&
      this.organization.subscriptionStatus === 'Active'
    ) {
      return 'Selected';
    } else {
      return 'Choose';
    }
  };

  isSubmitPlanBtnDisabled = (planId: string): boolean => {
    if (!this.organization) throw new Error('No organization');

    const isLoading = this.checkoutPlanId === planId;

    const isBlockedFreePlan =
      planId === FREE_PLAN_ID &&
      this.organization.subscriptionPricingId === planId &&
      this.organization.subscriptionStatus === 'Inactive';

    const isActivePaidPlan =
      this.organization.subscriptionPricingId === planId && this.organization.subscriptionStatus === 'Active';

    return isLoading || isBlockedFreePlan || isActivePaidPlan;
  };

  closeSnackbar = (): void => {
    this.snackbarConfig.message = '';
  };
}

export default OrganizationPlansStore;
