import clsx from 'clsx';
import { useMemo, useState } from 'react';

import { AnswerContent } from 'src/Tests/Test.types';
import { Test } from 'src/Tests/Test.types';
import { Idea } from 'src/Tests/Test.types';
import yesIcon from 'src/quiz/QuizIntroduction/assets/accept.png';
import noIcon from 'src/quiz/QuizIntroduction/assets/reject.png';
import IdeaDetails from 'src/theme/atoms/TestSummary/IdeaDetails/IdeaDetails';
import { SummaryTest } from 'src/theme/atoms/TestSummary/TestSummary.types';

import style from './TestResponsesSummary.module.scss';

interface TestResponsesSummaryProps {
  idea: Idea;
  summaryTest: SummaryTest | Test;
}

function TestResponsesSummary({ idea, summaryTest }: TestResponsesSummaryProps): JSX.Element {
  console.log(idea);

  const answers: AnswerContent[] = useMemo(
    () =>
      summaryTest.answers
        .filter((ans) => ans.ideaId === idea.id)
        .map((ans) => ({ value: ans.value, isApproving: ans.isApproving })),
    [summaryTest]
  );

  const [isApprovingViewed, setIsApprovingViewed] = useState(true);

  return (
    <div className={style.root}>
      <div className={style.content}>
        <IdeaDetails idea={idea} test={summaryTest} className={style.idea} />
      </div>

      <div className={style.tableHeader}>
        <div className={style.responseNumber}>
          {answers.length} written response{answers.length === 1 ? '' : 's'}
        </div>
        <div className={style.iconsScreenRegular}>
          <img src={noIcon} className={style.noIcon} alt="No icon" />
          <img src={yesIcon} className={style.yesIcon} alt="Yes icon" />
        </div>
        <div className={style.iconsScreenSmall}>
          <img
            src={noIcon}
            alt="No icon"
            className={clsx(style.noIcon, isApprovingViewed ? style.notDisplayed : '')}
            onClick={() => setIsApprovingViewed(false)}
          />
          <img
            src={yesIcon}
            alt="Yes icon"
            className={clsx(style.yesIcon, isApprovingViewed ? '' : style.notDisplayed)}
            onClick={() => setIsApprovingViewed(true)}
          />
        </div>
      </div>

      <div className={style.responsesScreenRegular}>
        <div className={style.columnWrapper}>
          <div className={style.column}>
            {answers
              .filter((ans) => !ans.isApproving)
              .map((ans, i) => (
                <div className={style.card} key={i}>
                  {ans.value}
                </div>
              ))}
          </div>
          {!answers.filter((ans) => !ans.isApproving).length ? (
            <div className={style.noResponses}>No negative responses</div>
          ) : null}
        </div>
        <div className={style.columnWrapper}>
          <div className={style.column}>
            {answers
              .filter((ans) => ans.isApproving)
              .map((ans, i) => (
                <div className={style.card} key={i}>
                  {ans.value}
                </div>
              ))}
          </div>
          {!answers.filter((ans) => ans.isApproving).length ? (
            <div className={style.noResponses}>No positive responses</div>
          ) : null}
        </div>
      </div>
      <div className={style.responsesScreenSmall}>
        <div className={style.columnWrapper}>
          <div className={style.column}>
            {answers.filter((ans) => ans.isApproving === isApprovingViewed).length ? (
              answers
                .filter((ans) => ans.isApproving === isApprovingViewed)
                .map((ans, i) => (
                  <div className={style.card} key={i}>
                    {ans.value}
                  </div>
                ))
            ) : (
              <div className={style.noResponses}>No {isApprovingViewed ? 'positive' : 'negative'} responses</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestResponsesSummary;
