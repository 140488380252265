import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import Header from 'src/Header';
import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Container from 'src/theme/atoms/container/Container';
import Input from 'src/theme/atoms/inputs/Input/Input';

import styles from './OrganizationCreation.module.scss';
import OrganizationCreationStore from './OrganizationCreation.store';

function OrganizationCreation(): JSX.Element {
  const [store] = useState(() => new OrganizationCreationStore());
  const { name, isSubmitButtonDisabled, getUserCreatedOrganizationId, changeName, handleSubmit } = store;
  const organizationId = getUserCreatedOrganizationId();
  if (organizationId) return <Navigate to={paths.organizationDashboard(organizationId)} />;

  const { authStore } = useStores();
  const { logout } = authStore;

  return (
    <div className={styles.root}>
      <Header>
        <Button label="Logout" size="m" color="secondary" onClick={logout} />
      </Header>
      <Container>
        <div className={styles.wrapper}>
          <h1>What's your company name?</h1>
          <div className={styles.form}>
            <Input
              name="companyName"
              type="text"
              color="white"
              size="l"
              placeholder="Type here"
              value={name}
              onChange={changeName}
              helperText="We use it for your profile only"
              onEnter={isSubmitButtonDisabled ? undefined : handleSubmit}
            />
          </div>
          <Button
            label="Submit"
            size="l"
            color="primary"
            className={styles.submitBtn}
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
          />
        </div>
      </Container>
    </div>
  );
}

export default observer(OrganizationCreation);
