import baseStyles, { HelperModalTopbar } from 'src/theme/atoms/HelperModal';
import { fgUrgencyHackProductImg, fgUrgencyMixProductImg, fgUrgencySingleProductImg } from 'src/theme/atoms/HelperModal/assets';
import Modal from 'src/theme/atoms/modal/Modal';

import styles from './FeatureGroupUrgencyProductsHelperModal.module.scss';

interface FeatureGroupUrgencyProductsHelperModalProps {
  onClose: () => void;
}

function FeatureGroupUrgencyProductsHelperModal({ onClose }: FeatureGroupUrgencyProductsHelperModalProps): JSX.Element {
  return (
    <Modal className={baseStyles.modal} onClose={onClose}>
      <div className={baseStyles.lightSection}>
        <HelperModalTopbar title="What does this mean?" onClose={onClose} />
        <div className={styles.header}>
          <h2 className={styles.title}>If the user is doing this activity today - how are they doing it?</h2>

          <p className={baseStyles.text}>
            Your users are either doing this activity today or they’re not. If they are doing this activity they have
            way of doing it - even if they’ve created it themselves.
          </p>
        </div>

        <div>
          <div className={styles.productItem}>
            <p className={baseStyles.text}>
              They might go to a single service or product that is already on the market. For example for the activity
              “A user can find a doctor and get health care” your users might be doing that today virtually through a
              telehealth provider or in IRL by going to an urgent care.
            </p>
            <img src={fgUrgencySingleProductImg} alt="Feature group urgency single product" className={styles.img} />
          </div>
          <div className={styles.productItem}>
            <p className={baseStyles.text}>
              They might be mixing and matching more than one product or service to achieve this activity. For example
              for the activity “User can join a support group to work on their health” your users might be doing that
              today by starting at specialist provider website that links to a group on Facebook and then using a
              calendar invite on Gcal/iCal to keep track of when the group meets live.
            </p>
            <img src={fgUrgencyMixProductImg} alt="Feature group urgency mix of products" className={styles.img} />
          </div>
          <div className={styles.productItem}>
            <p className={baseStyles.text}>
              Perhaps there aren’t products and services to solve this problem so they’re hacking it themselves. For
              example for the activity “User can track and maintain health over time” your users might be doing that
              today by aggregating historical health data from various medical systems and layering with Apple watch
              data in a spreadsheet for a primary care doctor to review.
            </p>
            <img
              src={fgUrgencyHackProductImg}
              alt="Feature group self-created solution or hack"
              className={styles.img}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FeatureGroupUrgencyProductsHelperModal;
