import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from 'src/Header';
import { useStores } from 'src/RootStore';
import { Idea } from 'src/Tests/Test.types';
import TestResponsesSummary from 'src/theme/atoms/TestSummary/TestResponsesSummary/TestResponsesSummary';
import TestSummary from 'src/theme/atoms/TestSummary/TestSummary';
import { SummaryTest } from 'src/theme/atoms/TestSummary/TestSummary.types';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import env from 'src/utils/env';

import styles from './NoAuthTest.module.scss';

const errorMessages = {
  err404: 'The requested resource could not be found. Please check if the link you are using is correct.',
  generalErr: "Couldn't fetch test data. Please try again later.",
};

function NoAuthTest(): JSX.Element {
  const { noAuthTestStore } = useStores();
  const { getTestFromShareLink } = noAuthTestStore;

  const { testId } = useParams();
  if (!testId) throw new Error('No roadmap id');

  const [errMsg, setErrMsg] = useState<string>();

  const [isLoading, setIsLoading] = useState(true);
  const [summaryTest, setSummaryTest] = useState<SummaryTest | null>(null);

  const [showIdea, setShowIdea] = useState<Idea | undefined>(undefined);

  useEffect(() => {
    getTestFromShareLink(testId)
      .then((test: SummaryTest) => {
        setSummaryTest(test);
      })
      .catch((err) => {
        setErrMsg(err.response.status === 404 ? errorMessages.err404 : errorMessages.generalErr);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showIdea]);

  if (isLoading) return <Loader />;

  if (errMsg || !summaryTest) {
    return (
      <div>
        <Header />
        <div className={styles.error}>
          <h3>{errMsg || 'No test'}</h3>
          <div>
            <a href={env.landingPage}>Go to Homepage</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.name}>
        {summaryTest.name}
        {showIdea && (
          <Button label={'Back to test resuts'} color="secondary" size="m" onClick={() => setShowIdea(undefined)} />
        )}
      </div>
      <div className={styles.content}>
        {!showIdea ? (
          <TestSummary summaryTest={summaryTest} onShowResponsesClick={setShowIdea} />
        ) : (
          <TestResponsesSummary summaryTest={summaryTest} idea={showIdea} />
        )}
      </div>
    </div>
  );
}

export default observer(NoAuthTest);
