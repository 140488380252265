import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import { useState, useRef, MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { roadmapThumbnail, testThumbnail } from 'src/Dashboard/assets';
import { BasicTest } from 'src/Tests/Test.types';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import TestStateIcon from 'src/theme/atoms/TestStateIcon/TestStateIcon';
import DropdownMenu from 'src/theme/atoms/dropdownMenu/DropdownMenu';
import { ReactComponent as DotsIcon } from 'src/theme/svg/dots.svg';
import { formatDate } from 'src/utils/helpers';
import useOnClickOutside from 'src/utils/hooks/useOnClickOutside';

import { ModalAction, Project } from '../ProjectsLayout';
import styles from './ProjectCard.module.scss';

export interface MenuAction {
  name: string;
  label: string;
  action: () => void;
}

const getFormattedDate = (project: Project): Dayjs => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const date = project.originalItem.modifiedAt || project.originalItem.createdAt;
  return dayjs.tz(dayjs(date), dayjs.tz.guess());
};

interface ProjectCardProps {
  project: Project;
  link: string;
  onMenuClick: (project: Project) => void;
  changeModalAction: (action: ModalAction) => void;
  showUneditableTestInfo: () => void;
}

function ProjectCard({
  project,
  link,
  onMenuClick,
  changeModalAction,
  showUneditableTestInfo,
}: ProjectCardProps): JSX.Element {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const dropdownMenuListRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(dropdownMenuListRef, () => onMenuClose());

  const { id: organizationId } = useOrganization();

  const formattedDate = formatDate(getFormattedDate(project));

  const onMenuClose = (): void => {
    if (menuVisible) setMenuVisible(false);
  };

  const getRoadmapMenuActions = (): MenuAction[] => {
    return [
      {
        name: 'delete',
        label: 'Delete',
        action: () => changeModalAction('delete'),
      },
      {
        name: 'edit',
        label: 'Edit',
        action: () => {
          navigate(paths.roadmapUpdateName(organizationId, project.originalItem.id));
        },
      },
      {
        name: 'duplicate',
        label: 'Duplicate',
        action: () => changeModalAction('duplicate'),
      },
    ];
  };

  const getTestMenuActions = (): MenuAction[] => {
    return [
      {
        name: 'delete',
        label: 'Delete',
        action: () => changeModalAction('delete'),
      },
      {
        name: 'edit',
        label: 'Edit',
        action: () => {
          if ((project.originalItem as BasicTest).state !== 'draft') {
            showUneditableTestInfo();
          } else {
            navigate(paths.testUpdate(organizationId, project.originalItem.id));
          }
        },
      },
    ];
  };

  const menuActions: MenuAction[] = project.type === 'roadmap' ? getRoadmapMenuActions() : getTestMenuActions();

  const onMenuOpen = (e: MouseEvent<HTMLElement>): void => {
    setMenuVisible(true);
    onMenuClick(project);
    e.preventDefault();
  };

  const thumbnailImg = project.type === 'roadmap' ? roadmapThumbnail : testThumbnail;

  return (
    <Link className={styles.root} to={link}>
      <div className={styles.img}>
        <img
          src={project.originalItem.imageUrl ? project.originalItem.imageUrl : thumbnailImg}
          alt="project illustration"
          className={clsx(!project.originalItem.imageUrl && styles.default)}
        />
        <div className={styles.labels}>
          {project.type === 'test' && (
            <div className={styles.label}>
              {project.originalItem.ideaCount} idea{project.originalItem.ideaCount === 1 ? '' : 's'}
            </div>
          )}
          <div className={styles.label}>{project.type === 'roadmap' ? 'plans' : 'tests'}</div>
        </div>
      </div>
      <div className={styles.details}>
        <div className={clsx(styles.line, styles.firstLine)}>
          <div className={styles.name}>{project.originalItem.name}</div>
          <div className={styles.topRightMenu}>
            <div className={styles.iconContainer} onClick={onMenuOpen}>
              <DotsIcon />
            </div>
            <DropdownMenu visible={menuVisible} className={styles.dropdownMenu}>
              <ul onClick={(e) => e.preventDefault()} ref={dropdownMenuListRef}>
                {menuActions.map(({ action, name, label }) => (
                  <li
                    key={name}
                    onClick={() => {
                      action();
                      setMenuVisible(false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={clsx(styles.line, styles.secondLine)}>
          <div className={styles.left}>
            <div className={styles.lastEdited}>last edited</div>
            <div className={styles.date}>{formattedDate.date}</div>
            <div className={styles.time}>{formattedDate.hour}</div>
          </div>
          <div className={styles.right}>
            {project.type === 'roadmap' ? (
              <div className={styles.author}>
                {project.originalItem.createdByDetails?.name || project.originalItem.createdByDetails?.email}
              </div>
            ) : (
              <>
                <div className={styles.status}>
                  {project.originalItem.state === 'finishedsuccessfully' ||
                  project.originalItem.state === 'finishedwithinvaliddistribution'
                    ? 'Finished'
                    : project.originalItem.state}
                </div>
                <TestStateIcon state={project.originalItem.state} className={styles.testStateIcon} />
              </>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default observer(ProjectCard);
