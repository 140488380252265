import { ReactComponent as PlusIcon } from 'src/theme/svg/plus.svg';

import styles from './HelperModalTopbar.module.scss';

interface HelperModalTopbarProps {
  title?: string;
  onClose: () => void;
}

function HelperModalTopbar({ title = 'What should I do?', onClose }: HelperModalTopbarProps): JSX.Element {
  return (
    <div className={styles.root}>
      <h6 className={styles.heading}>{title}</h6>
      <div className={styles.closeBtnBox}>
        <button onClick={onClose} className={styles.closeBtn}>
          <PlusIcon className={styles.closeIcon} />
        </button>
      </div>
    </div>
  );
}

export default HelperModalTopbar;
