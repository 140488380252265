import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FormEvent, useRef, useState, useEffect } from 'react';

import { useStores } from 'src/RootStore';
import DropdownAudience from 'src/theme/atoms/DropdownAudience/DropdownAudience';
import DropdownWithSlider from 'src/theme/atoms/DropdownWithSlider/DropdownWithSlider';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { TextArea } from 'src/theme/atoms/inputs';
import Spinner from 'src/theme/atoms/loaders/Spinner/Spinner';

import { useTestEditionStore } from '../../TestEditionContext';
import styles from './TestDataCard.module.scss';

function TestDataCard(): JSX.Element {
  const { testStore } = useStores();
  const { getCintCategories, cintCategories } = testStore;

  const testEditionStore = useTestEditionStore();
  const { test, updateTest } = testEditionStore;

  const audienceNameInputRef = useRef<HTMLTextAreaElement | null>(null);
  const problemStateInputRef = useRef<HTMLTextAreaElement | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [audienceName, setAudienceName] = useState(test.audienceName);
  const [audienceCountryId, setAudienceCountryId] = useState(test.audienceCountryId);
  const [audienceGenderId, setAudienceGenderId] = useState<string | undefined>(test.audienceGenderId);
  const [audienceAgeMin, setAudienceAgeMin] = useState<number | undefined>(test.audienceAgeMin);
  const [audienceAgeMax, setAudienceAgeMax] = useState<number | undefined>(test.audienceAgeMax);
  const [audienceIncomeIds, setAudienceIncomeIds] = useState<string[]>(test.audienceIncomeIds || []);

  const [problem, setProblem] = useState(test.problem);

  useEffect(() => {
    getCintCategories();
  }, []);

  const stopEditing = (): void => {
    setIsEditing(false);

    if (audienceNameInputRef.current) {
      audienceNameInputRef.current.scrollTo({
        top: 0,
      });
    }

    if (problemStateInputRef.current) {
      problemStateInputRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    stopEditing();
    setAudienceName(test.audienceName);
    setAudienceCountryId(test.audienceCountryId);
    setAudienceGenderId(test.audienceGenderId);
    setAudienceAgeMin(test.audienceAgeMin);
    setAudienceAgeMax(test.audienceAgeMax);
    setAudienceIncomeIds(test.audienceIncomeIds || []);
    setProblem(test.problem);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setIsSubmitting(true);

    setAudienceName(audienceName.trim());
    setProblem(problem.trim());

    const dto = {
      audienceName,
      audienceCountryId: audienceCountryId ? { value: audienceCountryId } : { delete: true },
      audienceGenderId: audienceGenderId ? { value: audienceGenderId } : { delete: true },
      audienceAgeMin:
        audienceAgeMin && !(audienceAgeMin === cintCategories?.ageMin && audienceAgeMax === cintCategories?.ageMax)
          ? { value: audienceAgeMin }
          : { delete: true },
      audienceAgeMax:
        audienceAgeMax && !(audienceAgeMin === cintCategories?.ageMin && audienceAgeMax === cintCategories?.ageMax)
          ? { value: audienceAgeMax }
          : { delete: true },
      audienceIncomeIds: audienceIncomeIds?.length ? { value: audienceIncomeIds } : { delete: true },
      problem,
    };
    for (const item in dto) dto[item] = typeof dto[item] === 'string' ? dto[item].trim() : dto[item]; // gotta do it bcs state changes arent reflected instantly

    updateTest(dto)
      .then(() => {
        stopEditing();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCountrySelection = (selectedId: string): void => {
    setAudienceCountryId(selectedId);
    setAudienceIncomeIds([]);
    handleEdit();
  };

  const handleIncomeSelection = (selectedId: string[]): void => {
    setAudienceIncomeIds(selectedId);
    handleEdit();
  };

  const handleGenderSelection = (selectedId: string | undefined): void => {
    setAudienceGenderId(selectedId);
    handleEdit();
  };

  const handleMinChange = (val: number | undefined): void => {
    setAudienceAgeMin(val);
    handleEdit();
  };

  const handleMaxChange = (val: number | undefined): void => {
    setAudienceAgeMax(val);
    handleEdit();
  };

  const isSaveDisabled =
    !audienceName ||
    !audienceCountryId ||
    !problem ||
    Boolean(audienceAgeMax && audienceAgeMin && audienceAgeMax < audienceAgeMin);

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <div className={styles.header}>
          <Button
            color="secondary"
            label={isEditing ? 'cancel' : 'edit'}
            size="xs"
            onClick={isEditing ? handleCancel : handleEdit}
            className={styles.editBtn}
          />
          {isEditing && (
            <Button
              type="submit"
              color="primary"
              label="save"
              size="xs"
              className={styles.saveBtn}
              disabled={isSaveDisabled}
              loading={isSubmitting}
            />
          )}
        </div>

        <div className={styles.audienceField}>
          <label className={styles.label}>Audience</label>
          <TextArea
            forwardRef={audienceNameInputRef}
            color="white"
            name="audienceName"
            label="Target audience"
            placeholder="Who are you trying to help?"
            size="s"
            textFieldClassName={clsx(styles.textArea, isEditing && styles.editing)}
            value={audienceName}
            onChange={(v) => setAudienceName(v)}
            onFocus={handleEdit}
            disableEnter
          />
        </div>

        <div className={styles.audienceInfoBox}>
          {cintCategories ? (
            <>
              <DropdownAudience
                title={'Country'}
                required={true}
                items={cintCategories.audienceCountry}
                selectedIds={audienceCountryId}
                handleItemSelection={(selectedId: string | string[] | undefined) =>
                  handleCountrySelection(selectedId as string)
                }
                dropdownHeight={310}
                isIdeaManagementView={true}
              />
              <DropdownAudience
                title={'Household income'}
                items={cintCategories.audienceCountry.find((el) => el.id === audienceCountryId)?.income || []}
                selectedIds={audienceIncomeIds}
                handleItemSelection={(selectedId: string | string[] | undefined) =>
                  handleIncomeSelection(selectedId as string[])
                }
                dropdownHeight={242}
                disabled={!audienceCountryId}
                isIdeaManagementView={true}
                multiple={true}
              />
              <DropdownWithSlider
                title={'Age'}
                unit="yrs"
                selectedMin={audienceAgeMin}
                selectedMax={audienceAgeMax}
                defaultMin={cintCategories.ageMin || 18}
                defaultMax={cintCategories.ageMax || 80}
                minAgeRange={cintCategories.minRangeLength || 10}
                handleMinChange={(val: number | undefined) => handleMinChange(val)}
                handleMaxChange={(val: number | undefined) => handleMaxChange(val)}
                isIdeaManagementView={true}
              />
              <DropdownAudience
                title={'Gender'}
                items={cintCategories.audienceGender}
                selectedIds={audienceGenderId || ''}
                handleItemSelection={(selectedId: string | string[] | undefined) =>
                  handleGenderSelection(selectedId as string | undefined)
                }
                dropdownHeight={160}
                isIdeaManagementView={true}
              />
            </>
          ) : (
            <div className={styles.spinnerWrapper}>
              <Spinner />
            </div>
          )}
        </div>

        <div className={styles.problemStateField}>
          <label className={styles.label}>Problem state</label>
          <TextArea
            forwardRef={problemStateInputRef}
            color="white"
            name="problemState"
            label="Problem state"
            placeholder="What problem do they have?"
            size="s"
            textFieldClassName={clsx(styles.textArea, isEditing && styles.editing)}
            value={problem}
            onChange={(v) => setProblem(v)}
            onFocus={handleEdit}
            disableEnter
          />
        </div>
      </form>
    </div>
  );
}

export default observer(TestDataCard);
