import clsx from 'clsx';
import React from 'react';

import useImageUpload from 'src/theme/atoms/ImageUpload/useImageUpload';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Spinner from 'src/theme/atoms/loaders/Spinner/Spinner';

import styles from './ImageUpload.module.scss';

interface ImageUploadProps {
  onUploadStart: () => void;
  onSuccess: (imageSrc: string) => void;
  onDelete: () => void;
  url?: string;
}

function ImageUpload({ onUploadStart, onSuccess, onDelete, url }: ImageUploadProps): JSX.Element {
  const { isLoading, imageSrc, showUnsplashModal, triggerInput, UtilElement } = useImageUpload({
    onUploadStart,
    onSuccess,
    onDelete,
    url,
  });

  const buttons = (
    <>
      <Button color="secondary" size="l" label="upload an image" className={styles.button} onClick={triggerInput} />
      <Button color="secondary" size="l" label="browse gallery" className={styles.button} onClick={showUnsplashModal} />
    </>
  );

  const loadingView = (
    <div className={clsx(styles.noImage, styles.content)}>
      <Spinner />
    </div>
  );

  const noImageLoadedView = (
    <div className={clsx(styles.noImage, styles.content)}>
      <p>Add an image</p>
      {buttons}
    </div>
  );

  const imageLoadedView = (
    <>
      <div className={styles.content}>
        <img src={imageSrc} alt="roadmap thumbnail" className={styles.image} />
      </div>
      <div className={styles.btnsGroup}>{buttons}</div>
    </>
  );

  return (
    <div className={styles.root}>
      {isLoading ? loadingView : imageSrc ? imageLoadedView : noImageLoadedView}
      {UtilElement}
    </div>
  );
}

export default ImageUpload;
