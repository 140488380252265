import { AxiosResponse } from 'axios';

import api from 'src/api';

import { PhotoDto } from './UnsplashModal.types';

export async function getPhotos(
  query: string,
  pageNumber: number,
  pageSize: number
): Promise<AxiosResponse<PhotoDto[]>> {
  return api.get('images', { params: { query, pageNumber, pageSize } });
}

export async function triggerPseudoDownload(url: string): Promise<AxiosResponse<void>> {
  return api.post('images/download', { url });
}

export async function getRecents(): Promise<AxiosResponse<PhotoDto[]>> {
  return api.get('images/recents');
}

export async function putRecent(dto: PhotoDto): Promise<AxiosResponse<void>> {
  return api.put('images/recents', dto);
}
