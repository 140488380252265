import clsx from 'clsx';

import styles from './FeatureStackIcon.module.scss';

interface FeatureStackIconProps {
  count: number;
  variant: 'filled' | 'outlined';
  color: 'primary' | 'white';
}

function FeatureStackIcon({ count, variant, color }: FeatureStackIconProps): JSX.Element {
  const variantClass = variant === 'filled' ? styles.filled : styles.outlined;
  const colorClass = color === 'primary' ? styles.colorPrimary : styles.colorWhite;

  return (
    <div className={clsx(styles.root, variantClass, colorClass)}>
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item}>
        <span>{count}</span>
      </div>
    </div>
  );
}

export default FeatureStackIcon;
