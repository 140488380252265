import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';

import RoadmapName from '../RoadmapName/RoadmapName';

function RoadmapCreateName(): JSX.Element {
  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const navigate = useNavigate();

  const organization = useOrganization();

  const { roadmapStore } = useStores();

  const handleSave = (): Promise<void> => {
    return roadmapStore.createRoadmap(organization.id, { name: name.trim(), imageUrl }).then((roadmap) => {
      navigate(paths.roadmapUpdateName(organization.id, roadmap.id), { replace: true }); // going back here by using 'back' in browser will move user to roadmapUpdateName page so they can edit current roadmap (instead of being moved to roadmapCreateName view where they would create a new roadmap instead of editing current one)
      navigate(paths.roadmapFeatureGroupsCreation(organization.id, roadmap.id));
    });
  };

  return (
    <div>
      <RoadmapName
        name={name}
        onNameChange={setName}
        imageUrl={imageUrl}
        onImageUrlChange={setImageUrl}
        onSave={handleSave}
      />
    </div>
  );
}

export default RoadmapCreateName;
