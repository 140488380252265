import axios, { AxiosResponse } from 'axios';

import api from 'src/api';

import { AssetDto, PutUrlDto } from './ImageUpload.types';

export async function getAssetPutURL(organizationId: string, name: string): Promise<AxiosResponse<PutUrlDto>> {
  return api.post(`organizations/${organizationId}/assets/generate-put-url`, { name });
}

export async function uploadAsset(organizationId: string, name: string, file: File): Promise<AxiosResponse<void>> {
  const putURL = await getAssetPutURL(organizationId, name);

  return axios({
    method: 'put',
    url: putURL.data.presignedUrl,
    data: file,
    headers: {
      'X-Amz-Acl': 'public-read',
    },
  });
}

export async function getAllAssets(organizationId: string): Promise<AxiosResponse<AssetDto[]>> {
  return api.get(`organizations/${organizationId}/assets`);
}

export async function deleteAsset(organizationId: string, objectKey: string): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/assets/${objectKey}`);
}
