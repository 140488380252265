import clsx from 'clsx';

import { ReactComponent as Spinner } from 'src/theme/svg/spinner.svg';

import styles from '../base.module.scss';
import { ButtonProps } from '../buttons.types';
import getButtonBaseClasses from '../getButtonBaseClasses';

function Button({
  loading,
  disabled,
  label,
  color,
  size,
  type = 'button',
  className,
  startIcon,
  endIcon,
  maxWidth,
  ignoreIconStyling,
  onClick,
}: ButtonProps): JSX.Element {
  const buttonBaseClasses = getButtonBaseClasses({
    color,
    size,
    className,
    maxWidth,
    ignoreIconStyling,
  });

  return (
    <button
      type={type}
      className={clsx(styles.root, buttonBaseClasses)}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && <Spinner className={styles.spinner} />}
      <div className={clsx(styles.content, loading && styles.loading)}>
        <span className={clsx(styles.icon, styles.startIcon, { visible: startIcon })}>{startIcon}</span>
        <span className={styles.label}>{label}</span>
        <span className={clsx(styles.icon, styles.endIcon, { visible: endIcon })}>{endIcon}</span>
      </div>
    </button>
  );
}

export default Button;
