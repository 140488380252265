import 'firebase/compat/auth';

import env from 'src/utils/env';

export const firebaseConfig = {
  apiKey: env.firebaseApiKey,
  authDomain: env.authDomain,
  projectId: env.projectId,
  storageBucket: env.storageBucket,
  messagingSenderId: env.messagingSenderId,
  appId: env.appId,
};
