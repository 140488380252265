import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import AddCollabolatorModal from 'src/theme/atoms/AddCollabolatorModal/AddCollabolatorModal';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { ReactComponent as Logo } from 'src/theme/svg/logo.svg';
import { ADD_TEAM_MEMBERS_BTN } from 'src/utils/constants';

import styles from './RoadmapStepHeader.module.scss';

const maxLengthOfRoadmapNameToDisplay = 70;

interface RoadmapStepHeaderProps {
  roadmapName: string;
  subtitle: string;
  title: string | JSX.Element;
  isBottomDivider?: boolean;
  helpButtonConfig?: {
    label: string;
    action: () => void;
  };
  actionButtonConfig?: {
    label: string;
    action: () => void;
  };
}

function RoadmapStepHeader({
  roadmapName,
  subtitle,
  title,
  helpButtonConfig,
  actionButtonConfig,
  isBottomDivider = true,
}: RoadmapStepHeaderProps): JSX.Element {
  const { authStore } = useStores();
  const organization = useOrganization();

  const [isAddCollaboratorsModalOpen, setIsAddCollaboratorsModalOpen] = useState(false);

  const { isAdmin } = authStore;
  const isOwner = organization?.createdBy === authStore.firebaseAuth.currentUser?.uid;

  return (
    <div className={styles.root}>
      <div className={clsx(isBottomDivider && styles.bottomDivider)}>
        <div className={styles.topbar}>
          <Link to={paths.organizationDashboard(organization.id)} className={styles.logoLink}>
            <Logo className={styles.logo} />
          </Link>
          <div className={styles.rightBox}>
            {(isOwner || isAdmin) && (
              <Button
                label={ADD_TEAM_MEMBERS_BTN}
                color="secondary"
                size="s"
                onClick={() => setIsAddCollaboratorsModalOpen(true)}
              />
            )}
            <ReactTooltip />
            <p
              className={styles.roadmapName}
              data-class={styles.reactTooltip}
              data-tip={
                roadmapName.length > maxLengthOfRoadmapNameToDisplay && window.innerWidth > 767 ? roadmapName : ''
              }
            >
              {roadmapName}
            </p>
          </div>
        </div>
        <div className={styles.intro}>
          <div className={styles.titlesBox}>
            <h1 className={styles.title}>{title}</h1>
            <h6>{subtitle}</h6>
          </div>
          <div className={styles.btnsBox}>
            {helpButtonConfig && (
              <Button
                label={helpButtonConfig.label}
                onClick={helpButtonConfig.action}
                color="secondary"
                size="l"
                className={clsx(styles.btn, styles.helpBtn)}
              />
            )}
            {actionButtonConfig && (
              <Button
                label={actionButtonConfig.label}
                onClick={actionButtonConfig.action}
                color="primary"
                size="l"
                className={styles.btn}
              />
            )}
          </div>
        </div>
      </div>
      {isAddCollaboratorsModalOpen && <AddCollabolatorModal onClose={() => setIsAddCollaboratorsModalOpen(false)} />}
    </div>
  );
}

export default RoadmapStepHeader;
