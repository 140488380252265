import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import styles from './DropdownMenu.module.scss';

type DropdownMenuProps = {
  children?: React.ReactNode;
  visible?: boolean;
  className?: string;
};

const DropdownMenu = ({ children, visible, className }: DropdownMenuProps): JSX.Element => {
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    listRef.current?.scrollTo(0, 0);
  }, [visible]);

  return (
    <div ref={listRef} className={clsx(styles.root, className, { [styles.visible]: visible })}>
      {children}
    </div>
  );
};

export default DropdownMenu;
