import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';

function OrganizationInitialize(): JSX.Element {
  const { organizationsStore, authStore } = useStores();
  const { organizations } = organizationsStore;
  const { firebaseAuth } = authStore;

  if (organizations.length === 0) return <Navigate to={paths.organizationCreate()} />;

  const userCreatedOrg = organizations.find((org) => org.createdBy === firebaseAuth.currentUser?.uid);
  const organization = userCreatedOrg || organizations[0];

  return <Navigate to={paths.organizationDashboard(organization.id)} replace />;
}

export default observer(OrganizationInitialize);
