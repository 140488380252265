import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';

class OrganizationCreationStore {
  name = '';
  shouldNavigateToDashboard = false;
  isSubmitting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isSubmitButtonDisabled(): boolean {
    return !this.name || this.isSubmitting;
  }

  getUserCreatedOrganizationId = (): string | null => {
    const { organizationsStore, authStore } = rootStore;

    const userCreatedOrg = organizationsStore.organizations.find(
      (org) => org.createdBy === authStore.firebaseAuth.currentUser?.uid
    );

    return userCreatedOrg ? userCreatedOrg.id : null;
  };

  handleSubmit = (): void => {
    this.isSubmitting = true;

    rootStore.organizationsStore
      .createOrganization(this.name)
      .then(() => {
        this.shouldNavigateToDashboard = true;
      })
      .catch(() => {
        alert('Something went wrong');
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  };

  changeName = (name: string): void => {
    this.name = name;
  };
}

export default OrganizationCreationStore;
