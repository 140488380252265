import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';

import RoadmapName from '../../RoadmapName/RoadmapName';
import { useRoadmapEditionStore } from '../RoadmapEditionContext';

function RoadmapUpdateName(): JSX.Element {
  const navigate = useNavigate();

  const organization = useOrganization();

  const { roadmap, updateRoadmap } = useRoadmapEditionStore();

  const [name, setName] = useState(roadmap.name);
  const [imageUrl, setImageUrl] = useState(roadmap.imageUrl);

  const handleSave = (): Promise<void> => {
    const path = paths.roadmapFeatureGroupsCreation(organization.id, roadmap.id);
    if (roadmap.name === name) {
      navigate(path);
      return Promise.resolve();
    }

    return updateRoadmap({ name: name.trim() }).then(() => {
      navigate(path);
    });
  };

  const handleImageUpdate = async (url: string): Promise<void> => {
    setImageUrl(url);
    updateRoadmap({ name: name.trim(), imageUrl: url });
  }

  return (
    <div>
      <RoadmapName
        name={name}
        onNameChange={setName}
        imageUrl={imageUrl}
        onImageUrlChange={handleImageUpdate}
        onSave={handleSave}
      />
    </div>
  );
}

export default RoadmapUpdateName;
