import axios from 'axios';
import 'dayjs/plugin/isSameOrBefore';
import qs from 'qs';

import { rootStore } from 'src/RootStore';
import { history } from 'src/app/routes/Router';
import { paths } from 'src/app/routes/paths.const';
import env from 'src/utils/env';

export const api = axios.create({
  baseURL: env.backendUrl,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

api.interceptors.request.use(
  async (config: any) => {
    if (rootStore.authStore.firebaseAuth.currentUser) {
      try {
        const token = await rootStore.authStore.firebaseAuth.currentUser.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
        return Promise.resolve(config);
      } catch (error) {
        rootStore.authStore.logout();
        return history.replace(paths.login());
      }
    } else {
      return Promise.resolve(config);
    }
  },
  (err: unknown) => Promise.reject(err)
);

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response?.status === 401) {
      rootStore.authStore.logout();
      return history.replace(paths.login());
    }

    return Promise.reject(error);
  }
);

export default api;
