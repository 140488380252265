import { autoUpdate, limitShift, offset, shift, useFloating } from '@floating-ui/react-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useEffect, useRef, useState } from 'react';

import TutorialTooltip from 'src/theme/atoms/TutorialTooltip/TutorialTooltip';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { ColumnBox } from 'src/theme/atoms/groupCreation';
import { TextArea } from 'src/theme/atoms/inputs';
import { ReactComponent as PlusIcon } from 'src/theme/svg/plus.svg';

import FeatureGroupState from '../FeatureGroupState.store';
import FeaturesDropArea from '../FeaturesDropArea/FeaturesDropArea';
import styles from './FeatureGroupColumn.module.scss';
import FeatureGroupColumnAnimationWrapper from './FeatureGroupColumnAnimationWrapper/FeatureGroupColumnAnimationWrapper';
import FeatureGroupColumnDnDWrapper from './FeatureGroupColumnDnDWrapper/FeatureGroupColumnDnDWrapper';

interface FeatureGroupColumnProps {
  featureGroupState: FeatureGroupState;
  columnIndex: number;
  className?: string;
  moveFeatureToDifferentColumn: (
    dragSourceIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ) => number;
  moveFeatureToDifferentSlot: (
    dragSourceIndex: number,
    dropTargetIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ) => void;
  moveFeatureGroupToDifferentSlot: (dragSourceIndex: number, dropTargetIndex: number) => void;
  onFeatureDrop: (dragSourceColumnIndex: number, dropTargetColumnIndex: number) => void;
  onFeatureGroupDrop?: (id: string) => void;
  currentTutorialStep: number;
  changeTutorialStep: (step: number) => void;
  endTutorial: () => void;
  horizontalScrollRef: React.RefObject<HTMLElement>;
}

function FeatureGroupColumn({
  featureGroupState: fgs,
  columnIndex,
  className,
  moveFeatureToDifferentColumn,
  moveFeatureToDifferentSlot,
  onFeatureDrop,
  moveFeatureGroupToDifferentSlot,
  onFeatureGroupDrop,
  currentTutorialStep,
  changeTutorialStep,
  endTutorial,
  horizontalScrollRef,
}: FeatureGroupColumnProps): JSX.Element {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [error, setError] = useState({ message: '' });

  const isSaved = !!fgs.id;
  const isNotSavedAndNotEditing = !fgs.id && !fgs.isEditing;

  useEffect(() => {
    if (fgs.error) {
      setError({ message: fgs.error.message });
      setTimeout(() => {
        setError({ message: '' });
      }, 5000);
    } else {
      setError({ message: '' });
    }
  }, [fgs.error]);

  // used for keeping the tutorial tooltip on top and anchored to an appropriate element
  const { x, y, reference, floating, strategy } = useFloating({
    strategy: 'fixed',
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 35,
      }),
      shift({
        limiter: limitShift(),
      }),
    ],
  });

  const cancelEdit = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void => {
    e.stopPropagation();
    fgs.stopEditing();
    clearName();
  };

  const clearName = (): void => {
    fgs.changeName('');
  };

  const handleCreate = (): void => {
    fgs.changeName(fgs.name.trim());
    fgs.create().then(() => {
      fgs.stopEditing();
    });
  };

  const handleNameInputFocus = (): void => {
    fgs.startEditing();
  };

  const handleNameInputBlur = (): void => {
    if (isSaved) {
      fgs.stopEditing();
    }
  };

  const handleEnter = (): void => {
    if (fgs.name && !isSaved) handleCreate();
  };

  const actionsBar = fgs.id && (
    <div className={clsx(styles.actionsBar, isHovered && styles.visible)}>
      <Button
        label="duplicate"
        size="xs"
        color="secondary"
        onClick={fgs.duplicate}
        loading={fgs.status === 'duplicating'}
      />
      <Button label="delete" size="xs" color="secondary" onClick={fgs.delete} loading={fgs.status === 'deleting'} />
    </div>
  );

  const nameActionsBar = (
    <div className={clsx(styles.nameActionsBar, !isSaved && fgs.isEditing && styles.open)}>
      <Button
        label="save user need"
        size="xs"
        color="primary"
        disabled={!fgs.name}
        onClick={handleCreate}
        loading={fgs.status === 'saving'}
      />
      <Button label="cancel" size="xs" color="secondary" onClick={cancelEdit} />
    </div>
  );

  const tutorialTooltip = (
    <TutorialTooltip
      currentStep={currentTutorialStep}
      ownStep={1}
      stepsCount={2}
      headerText={'Or you can start with ...'}
      mainText={
        'Adding in user outcomes. Things like “users can create a secure digital health profile.” These are your columns. You group your features into the columns.'
      }
      onClose={endTutorial}
      changeStep={changeTutorialStep}
      floatingRef={floating}
      floatingStyle={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      horizontalScrollRef={horizontalScrollRef}
    />
  );

  return (
    <FeatureGroupColumnAnimationWrapper featureGroupState={fgs}>
      <FeatureGroupColumnDnDWrapper
        index={columnIndex}
        featureGroupState={fgs}
        move={moveFeatureGroupToDifferentSlot}
        onDrop={onFeatureGroupDrop}
        isNameInputFocused={fgs.isEditing}
      >
        <div
          className={clsx(styles.root, className)}
          ref={rootRef}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <ColumnBox>
            <div className={styles.content}>
              {!isSaved && tutorialTooltip}
              {actionsBar}
              <div
                className={clsx(styles.header, isNotSavedAndNotEditing && styles.unsavedAndInactive)}
                onClick={isNotSavedAndNotEditing ? fgs.startEditing : undefined}
              >
                <div className={clsx(styles.headerInner, !isSaved && styles.unsaved)}>
                  <TextArea
                    color="white"
                    name="groupName"
                    label="Group Name"
                    placeholder="What can the user do?"
                    size="s"
                    className={styles.nameInputField}
                    textFieldClassName={clsx(
                      styles.nameInput,
                      isNotSavedAndNotEditing && styles.unsavedAndInactive,
                      fgs.isEditing && styles.editing
                    )}
                    error={error}
                    value={fgs.name}
                    disableEnter
                    onChange={fgs.changeName}
                    onFocus={handleNameInputFocus}
                    onBlur={handleNameInputBlur}
                    onEnter={handleEnter}
                  />
                  {!isSaved && (
                    <div className={styles.editOrClearIconBtnBox}>
                      <button
                        className={clsx(styles.editOrClearIconBtn, fgs.isEditing && styles.clear)}
                        onClick={fgs.isEditing ? clearName : undefined}
                        disabled={fgs.name === ''}
                        ref={reference}
                      >
                        <PlusIcon />
                      </button>
                    </div>
                  )}
                </div>
                {nameActionsBar}
              </div>
              {fgs.id && (
                <FeaturesDropArea
                  columnIndex={columnIndex}
                  featureStates={fgs.featureStates}
                  moveFeatureToDifferentColumn={moveFeatureToDifferentColumn}
                  moveFeatureToDifferentSlot={moveFeatureToDifferentSlot}
                  onDrop={onFeatureDrop}
                />
              )}
            </div>
          </ColumnBox>
        </div>
      </FeatureGroupColumnDnDWrapper>
    </FeatureGroupColumnAnimationWrapper>
  );
}

export default observer(FeatureGroupColumn);
