import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import BigArrowBtnsBox from 'src/theme/atoms/BigArrowBtnsBox/BigArrowBtnsBox';
import NeedHelp from 'src/theme/atoms/NeedHelp/NeedHelp';
import Container from 'src/theme/atoms/container/Container';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';

import CollectName from './CollectName/CollectName';
import styles from './Dashboard.module.scss';
import DashboardHeader from './DashboardHeader';
import { linkBuild, linkTest, spinningLogo } from './assets';
import ProjectsLayout from './components/ProjectsLayout/ProjectsLayout';
import HowToModal from './howto/HowToModal';

const widthOfCenteredElements = 1138;

function Dashboard(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const { authStore, roadmapStore, testStore } = useStores();
  const { firebaseAuth, isAdmin } = authStore;

  const organization = useOrganization();

  const isOwner = organization.createdBy === authStore.firebaseAuth.currentUser?.uid;

  const { roadmaps, getRoadmaps } = roadmapStore;
  const { tests, getTests } = testStore;

  const projects = [...tests, ...roadmaps];

  useEffect(() => {
    setIsLoading(true);

    Promise.all([getRoadmaps(organization.id), getTests(organization.id)])
      .catch(() => {
        alert("Couldn't fetch roadmaps and tests");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [organization.id]);

  if (
    organization.createdBy === authStore.firebaseAuth.currentUser?.uid &&
    organization.subscriptionStatus === 'Inactive'
  ) {
    return <Navigate to={paths.organizationPlans(organization.id)} />;
  }

  const handleReqSuccess = (): void => {
    setIsLoading(true);
    Promise.all([getRoadmaps(organization.id), getTests(organization.id)]).finally(() => {
      setIsLoading(false);
    });
  };

  if (isLoading) return <Loader />;

  return (
    <div className={styles.root}>
      <DashboardHeader isAdmin={Boolean(isAdmin)} isOwner={isOwner} />
      <Container width={widthOfCenteredElements}>
        <div className={styles.intro}>
          <div className={styles.copy}>
            <div className={styles.title}>Foundations</div>
            <div className={styles.subtitle}>
              <>
                Learn <span onClick={() => setIsHowToModalOpen(true)}>how to</span> build Foundations.
              </>
            </div>
          </div>
          <div className={styles.logo}>
            <img src={spinningLogo} alt="Foundations logo" className={styles.img} />
          </div>
        </div>
      </Container>
      <div className={styles.horizontalDivider} />
      <Container width={widthOfCenteredElements}>
        <div className={styles.startNew}>
          <BigArrowBtnsBox
            data={[
              {
                id: 'test',
                copy: 'I need to test new product ideas',
                img: linkTest,
                link: paths.testCreate(organization.id),
              },
              {
                id: 'plan',
                copy: 'I need to build a new plan',
                img: linkBuild,
                link: paths.roadmapCreateName(organization.id),
              },
            ]}
          />
        </div>
      </Container>
      <Container width={widthOfCenteredElements}>
        <div className={styles.projectsBox}>
          <div className={styles.title}>Projects</div>
          {!projects.length ? (
            <div className={styles.empty}>Your projects will show up here</div>
          ) : (
            <div className={styles.projects}>
              <ProjectsLayout organizationId={organization.id} items={projects} onReqSuccess={handleReqSuccess} />
            </div>
          )}
        </div>
      </Container>
      <NeedHelp width={widthOfCenteredElements} />

      {!firebaseAuth.currentUser?.displayName && (
        <CollectName
          onSuccess={() => window.location.reload()}
          onFail={() => setSnackbarMessage('Your name has not been saved. Please try again.')}
        />
      )}

      <HowToModal isOpen={isHowToModalOpen} onClose={() => setIsHowToModalOpen(false)} />

      <Snackbar type="error" message={snackbarMessage} onClose={() => setSnackbarMessage('')} autoclose={true} />
    </div>
  );
}

export default observer(Dashboard);
