import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useEffect, useRef, useState } from 'react';

import FeatureState from 'src/roadmap/FeatureState.store';
import ButtonGroup from 'src/theme/atoms/buttonGroup/ButtonGroup';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { TextArea } from 'src/theme/atoms/inputs';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';

import { FEATURES_COLUMN_ID } from '../../FeatureGroupsCreation/FeaturesColumn/FeaturesColumn';
import styles from './FeatureCard.module.scss';

const innerCardMaxHeightOnStateDefault = 538;

const featureDuplicatedMsg = (
  <>
    Feature duplicated! You can find it in
    <button
      className={styles.featureDuplicatedBtn}
      onClick={() => {
        document.getElementById(FEATURES_COLUMN_ID)?.scrollIntoView({
          block: 'start',
          inline: 'start',
          behavior: 'smooth',
        });
      }}
    >
      the Features column
    </button>
    .
  </>
);

interface FeatureCardProps {
  featureState: FeatureState;
  className?: string;
  editionStages234?: boolean;
}

function FeatureCard({ featureState: fs, className, editionStages234 = false }: FeatureCardProps): JSX.Element {
  const nameInputRef = useRef<HTMLTextAreaElement | null>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string | JSX.Element>('');

  const [contentHeight, setContentHeight] = useState<number | null>(null);

  const handleClick = (): void => {
    if (fs.state === 'edited') return;
    fs.edit();
  };

  useEffect(() => {
    if (fs.state === 'edited' && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!contentRef.current || !contentRef.current.offsetHeight) return;

    setContentHeight(contentRef.current.offsetHeight);
  }, [fs.state, fs.name, fs.description]);

  const handleNameFieldKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter' && fs.name && descriptionInputRef.current) {
      const desc = descriptionInputRef.current;
      const end = desc.value.length;
      desc.setSelectionRange(end, end);
      desc.focus();
    }
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void => {
    e.stopPropagation();
    fs.cancel();
  };

  const innerCardHeight = `${
    contentHeight && contentHeight > innerCardMaxHeightOnStateDefault && fs.state === 'default'
      ? `${innerCardMaxHeightOnStateDefault}px`
      : contentHeight
      ? `${contentHeight}px`
      : 'fit-content'
  }`;

  const duplicate = (): void => {
    fs.duplicate().then(() => setSnackbarMessage(featureDuplicatedMsg));
  };

  const handleSave = (): void => {
    if (!fs.name || !fs.description) return;
    fs.changeName(fs.name.trim());
    fs.changeDescription(fs.description.trim());
    fs.save();
  };

  const fgSavingActions = fs.isSaved ? (
    <>
      {fs.name && fs.description && (
        <ButtonGroup orientation="horizontal" dividerClass={styles.divider} className={styles.buttonGroup}>
          <Button label="Cancel" color="black" size="m" className={styles.cancelBtn} onClick={handleCancel} />
          <Button
            label="Save"
            color="secondary"
            size="m"
            className={styles.saveBtn}
            onClick={handleSave}
            loading={fs.actionStatus === 'saving'}
          />
        </ButtonGroup>
      )}
    </>
  ) : (
    <>
      {fs.name && fs.description && (
        <Button
          label="Add"
          color="black"
          size="m"
          className={styles.addBtn}
          onClick={handleSave}
          loading={fs.actionStatus === 'saving'}
        />
      )}
    </>
  );

  const fgBeingEdited = (
    <div>
      {fs.isSaved && (
        <div className={styles.actionsBtnBox}>
          {!editionStages234 && (
            <Button
              label="Duplicate"
              color="black"
              size="s"
              className={styles.duplicateBtn}
              onClick={duplicate}
              loading={fs.actionStatus === 'duplicating'}
            />
          )}
          <Button
            label={fs.actionOnDeletion === 'deassign' ? 'Remove' : 'Delete'}
            color="black"
            size="s"
            className={styles.deleteBtn}
            onClick={fs.actionOnDeletion === 'deassign' ? fs.deassign : fs.delete}
            loading={fs.actionStatus === 'deassigning' || fs.actionStatus === 'deleting'}
          />
        </div>
      )}
      <TextArea
        forwardRef={nameInputRef}
        name="name"
        color="black"
        size="s"
        label="Feature name"
        placeholder="What’s the feature called"
        maxLength={128}
        helperText="Keep it short, you’ll describe it next."
        value={fs.name}
        className={styles.nameInput}
        messageClassName={styles.inputMessage}
        onChange={(value) => fs.changeName(value)}
        onKeyUp={handleNameFieldKeyUp}
        disableEnter
      />
      {fs.name && (
        <TextArea
          forwardRef={descriptionInputRef}
          name="description"
          color="black"
          size="s"
          label="Feature description"
          placeholder="Describe the feature"
          value={fs.description}
          textFieldClassName={styles.descriptionInput}
          messageClassName={styles.inputMessage}
          onChange={(value) => fs.changeDescription(value)}
          onEnter={handleSave}
          disableEnter
        />
      )}
      {fgSavingActions}
    </div>
  );

  const fgNotBeingEdited = (
    <>
      <div className={styles.header}>
        <p className={styles.name}>{fs.name}</p>

        <button className={clsx(styles.editBtn, fs.state === 'selected' && styles.visible)} onClick={fs.edit}>
          edit
        </button>
      </div>
      <p className={styles.description}>{fs.description}</p>
    </>
  );

  return (
    <div className={clsx(styles.card, className)} onClick={handleClick}>
      <div
        className={clsx(
          styles.innerCard,
          fs.state === 'selected' && styles.selected,
          fs.state === 'edited' && styles.active,
          (fs.state === 'deleted' || fs.state === 'removed') && styles.isDeletingOrRemoving,
          contentHeight &&
            contentHeight > innerCardMaxHeightOnStateDefault &&
            fs.state === 'default' &&
            styles.canShowScrollbar
        )}
        style={{
          maxHeight: innerCardHeight,
          minHeight: innerCardHeight,
        }}
      >
        <div className={styles.content} ref={contentRef}>
          {fs.state === 'edited' ? fgBeingEdited : fgNotBeingEdited}
        </div>
      </div>
      <Snackbar type={'info'} message={snackbarMessage} onClose={() => setSnackbarMessage('')} />
    </div>
  );
}

export default observer(FeatureCard);
