import baseStyles from 'src/theme/atoms/HelperModal';
import { featureGroupUrgencyImg } from 'src/theme/atoms/HelperModal/assets';
import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';

import styles from './FeatureGroupUrgencyHelperModal.module.scss';

interface FeatureGroupUrgencyHelperModalProps {
  onClose: () => void;
}

function FeatureGroupUrgencyHelperModal({ onClose }: FeatureGroupUrgencyHelperModalProps): JSX.Element {
  return (
    <HelperModal
      onClose={onClose}
      lightSection={
        <div>
          <div className={styles.header}>
            <h2 className={styles.title}>
              Understand target <br />
              audience and market
            </h2>
            <h2 className={styles.subtitle}>Does the user do this today?</h2>
            <p className={baseStyles.text}>
              You’ve taken your features and grouped them into activities. Now you have to think critically if users are
              currently achieving these goals.
            </p>
            <p className={baseStyles.text}>
              If they are, how? Are they hacking to create a solution for themselves out, do they mix and match products
              and services, or do they have a single product or service they use.
            </p>
          </div>
        </div>
      }
      darkSection={
        <div className={styles.content}>
          <p className={styles.question}>Does the user do this today?</p>
          <img src={featureGroupUrgencyImg} alt="Feature group urgency" className={styles.img} />
        </div>
      }
    />
  );
}

export default FeatureGroupUrgencyHelperModal;
