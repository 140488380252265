import { Dictionary } from 'src/utils/types/Dictionary';

export interface SnackbarData {
  msg: string;
  type: 'success' | 'error';
}

export const snackbarCopyLinkData: Dictionary<string, SnackbarData> = {
  linkCopySuccess: { msg: 'Link copied successfully!', type: 'success' },
  linkCopyFail: { msg: 'Link copying failed', type: 'error' },
};

function fallbackCopyTextToClipboard(text): Promise<void> {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let successful;

  try {
    successful = document.execCommand('copy');
  } catch (err) {
    successful = false;
  }

  document.body.removeChild(textArea);

  return new Promise((resolve, reject) => (successful ? resolve() : reject()));
}

export default function copyTextToClipboard(text: string): Promise<void> {
  if (navigator.clipboard) return navigator.clipboard.writeText(text);
  else return fallbackCopyTextToClipboard(text);
}
