import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';

import StickyRoadmapTopbar from 'src/roadmap/RoadmapEdition/components/StickyRoadmapTopbar/StickyRoadmapTopbar';
import ButtonGroup from 'src/theme/atoms/buttonGroup/ButtonGroup';
import Button from 'src/theme/atoms/buttons/Button/Button';

import FeatureGroupCardAnimationWrapper from '../../FeatureGroupCardAnimationWrapper/FeatureGroupCardAnimationWrapper';
import RoadmapStepHeader from '../../RoadmapStepHeader/RoadmapStepHeader';
import RoadmapEditionContainer from '../RoadmapEditionContainer/RoadmapEditionContainer';
import { useRoadmapEditionStore } from '../RoadmapEditionContext';
import FeatureGroupUrgencyHelperModal from './FeatureGroupUrgencyHelperModal/FeatureGroupUrgencyHelperModal';
import FeatureGroupUrgencyProductsHelperModal from './FeatureGroupUrgencyProductsHelperModal/FeatureGroupUrgencyProductsHelperModal';
import styles from './FeatureGroupsUrgency.module.scss';
import FeatureGroupsUrgencyStore from './FeatureGroupsUrgency.store';
import Products from './Products/Products';

function FeatureGroupsUrgency(): JSX.Element {
  const roadmapEditionStore = useRoadmapEditionStore();
  const { roadmap, changeIsNextStepDisabled } = roadmapEditionStore;

  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);
  const openHelperModal = (): void => setIsHelperModalOpen(true);
  const closeHelperModal = (): void => setIsHelperModalOpen(false);

  const [isItemHelperModalOpen, setIsItemHelperModalOpen] = useState(false);
  const openItemHelperModal = (): void => setIsItemHelperModalOpen(true);
  const closeItemHelperModal = (): void => setIsItemHelperModalOpen(false);

  const [store] = useState(() => new FeatureGroupsUrgencyStore(roadmapEditionStore));
  const {
    isNextStepDisabled,
    featureGroupStates,
    featureGroupCount,
    featureGroupForTodayCount,
    featureGroupNotForTodayCount,
  } = store;

  useEffect(() => {
    changeIsNextStepDisabled(isNextStepDisabled);
  }, [isNextStepDisabled]);

  const info = (
    <>
      <span>
        <span>{featureGroupCount}</span> User Needs Defined
      </span>
      <span>
        <span>{featureGroupForTodayCount}</span> Yes
      </span>
      <span>
        <span>{featureGroupNotForTodayCount}</span> No
      </span>
    </>
  );

  return (
    <div>
      <RoadmapStepHeader
        roadmapName={roadmap.name}
        title="Does the user do this today?"
        subtitle="Articulate what you know about the target audience and market in relation to these activities."
        helpButtonConfig={{
          label: 'What should I do?',
          action: openHelperModal,
        }}
      />

      <StickyRoadmapTopbar
        texts={
          <div className={styles.stickyTopbarTexts}>
            <span>Does the user do this today?</span>
            {info}
          </div>
        }
        buttons={
          <Button
            color="secondary"
            label="What should I do?"
            size="m"
            onClick={openHelperModal}
            className={styles.stickyTopbarHelpBtn}
          />
        }
      />
      <RoadmapEditionContainer>
        <div className={styles.infoBox}>{info}</div>

        <div className={styles.featureGroupCardList}>
          {featureGroupStates.map((fgState) => (
            <FeatureGroupCardAnimationWrapper
              key={fgState.featureGroup.id}
              fgState={fgState}
              showExtendedContent={fgState.isForToday}
              extendedContent={
                <Products
                  featureGroupState={fgState}
                  onProductTypeChange={(productType) => fgState.changeProductType(productType)}
                  onProductsChange={(productNames) => fgState.changeProductNames(productNames)}
                  onHelp={openItemHelperModal}
                />
              }
            >
              <ButtonGroup
                orientation="horizontal"
                className={styles.isForTodayButtonGroup}
                dividerClass={styles.divider}
              >
                <Button
                  label="NO"
                  color="secondary"
                  size="m"
                  className={clsx(
                    styles.isForTodayBtn,
                    styles.notForTodayBtn,
                    fgState.isForToday === false && styles.selected
                  )}
                  onClick={() => fgState.changeIsForToday(false)}
                />
                <Button
                  label="YES"
                  color="secondary"
                  size="m"
                  className={clsx(
                    styles.isForTodayBtn,
                    styles.forTodayBtn,
                    fgState.isForToday === true && styles.selected
                  )}
                  onClick={() => fgState.changeIsForToday(true)}
                />
              </ButtonGroup>
            </FeatureGroupCardAnimationWrapper>
          ))}
        </div>
      </RoadmapEditionContainer>

      {isHelperModalOpen && <FeatureGroupUrgencyHelperModal onClose={closeHelperModal} />}
      {isItemHelperModalOpen && <FeatureGroupUrgencyProductsHelperModal onClose={closeItemHelperModal} />}
    </div>
  );
}

export default observer(FeatureGroupsUrgency);
