import { makeAutoObservable } from 'mobx';

import getDuplicatedName from 'src/utils/getDuplicatedName';
import { CustomError } from 'src/utils/types/CustomError';
import { Feature } from 'src/utils/types/FeatureRelated';

import RoadmapEditionStore from './RoadmapEdition/RoadmapEdition.store';

interface Callbacks {
  onCreate?: (feature: Feature) => void;
  onDuplicate?: (feature: Feature) => void;
  onUpdate?: (feature: Feature) => void;
  onDelete?: (id: string) => void;
  onDeassign?: (id: string) => void;
  onCancel?: (id: string) => void;
  onEdit?: (id: string) => void;
}

type ActionOnDeletion = 'deassign' | 'delete';

class FeatureState {
  roadmapEditionStore: RoadmapEditionStore;
  callbacks: Callbacks;
  featureGroupId: string | null = null;
  feature: Feature | null = null;

  id = '';
  name = '';
  description = '';

  state: 'default' | 'selected' | 'edited' | 'deleted' | 'removed' = 'default';
  actionStatus: 'default' | 'saving' | 'deleting' | 'deassigning' | 'duplicating' = 'default';
  isSaved = false;

  actionOnDeletion: ActionOnDeletion;

  constructor(
    roadmapEditionStore: RoadmapEditionStore,
    feature: Feature | null,
    featureGroupId: string | null,
    callbacks: Callbacks,
    actionOnDeletion: ActionOnDeletion
  ) {
    this.roadmapEditionStore = roadmapEditionStore;
    this.callbacks = callbacks;
    this.featureGroupId = featureGroupId;
    this.actionOnDeletion = actionOnDeletion;

    if (feature) {
      this.feature = feature;

      this.id = feature.id;
      this.name = feature.name;
      this.description = feature.description;

      this.isSaved = true;
    } else {
      // this.id = `${dayjs().valueOf()}`;
      this.state = 'edited';
    }

    makeAutoObservable(this);
  }

  changeName = (value: string): void => {
    this.name = value;
  };

  changeDescription = (value: string): void => {
    this.description = value;
  };

  toggleSelect = (): void => {
    if (this.state !== 'edited') {
      this.state = this.state === 'default' ? 'selected' : 'default';
    }
  };

  edit = (): void => {
    this.state = 'edited';

    this.callbacks.onEdit && this.callbacks.onEdit(this.id);
  };

  cancel = (): void => {
    if (!this.feature) throw new Error('No feature');

    this.name = this.feature.name;
    this.description = this.feature.description;
    this.state = 'default';
    this.actionStatus = 'default';

    this.callbacks.onCancel && this.callbacks.onCancel(this.id);
  };

  deassign = (): void => {
    if (!this.featureGroupId || !this.callbacks.onDeassign) {
      throw new Error(
        'deassign method requires featureGroupId and onDeassign callback to be provided by FeatureState constructor'
      );
    }

    this.actionStatus = 'deassigning';

    this.roadmapEditionStore
      .deassignFeatureFromFeatureGroup(this.featureGroupId, this.id)
      .then(() => {
        this.callbacks.onDeassign && this.callbacks.onDeassign(this.id);
      })
      .catch((err: CustomError) => {
        alert(err.message);
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };

  delete = (): void => {
    this.actionStatus = 'deleting';

    this.roadmapEditionStore
      .deleteFeature(this.id, this.featureGroupId)
      .then(() => {
        //It never fires for some reason
        // document.addEventListener(
        //   'animationend',
        //   () => {
        //     this.callbacks.onDelete && this.callbacks.onDelete(this.id);
        //   },
        //   { once: true }
        // );

        setTimeout(() => {
          this.callbacks.onDelete && this.callbacks.onDelete(this.id);
        }, 200);
      })
      .catch(() => {
        alert('Something went wrong');
      })
      .finally(() => {
        this.actionStatus = 'default';
        this.state = 'deleted';
      });
  };

  save = (): void => {
    if (this.isSaved) {
      this.update();
    } else {
      if (!this.featureGroupId) {
        this.create();
      } else {
        this.createAndAssignToGroup(this.featureGroupId);
      }
    }
  };

  duplicate = (): Promise<void> => {
    this.actionStatus = 'duplicating';

    const alreadyExistingFeaturesNames = this.roadmapEditionStore.features.map((el) => el.name);

    return this.roadmapEditionStore
      .createFeature({
        name: getDuplicatedName(alreadyExistingFeaturesNames, this.name),
        description: this.description,
      })
      .then((duplicatedFeature) => {
        this.callbacks.onDuplicate && this.callbacks.onDuplicate(duplicatedFeature);
      })
      .catch(() => {
        alert('Something went wrong');
        throw new Error('Something went wrong');
      })
      .finally(() => {
        this.actionStatus = 'default';
        this.state = 'default';
      });
  };

  updateByFeature = (feature: Feature): void => {
    this.name = feature.name;
    this.description = feature.description;
    this.actionStatus = 'default';
    this.state = 'default';
  };

  changeCallbacks = (callbacks: Callbacks): void => {
    this.callbacks = callbacks;
  };

  changeFeatureGroupId = (featureGroupId: string): void => {
    this.featureGroupId = featureGroupId;
  };

  private create = (): void => {
    this.actionStatus = 'saving';

    this.roadmapEditionStore
      .createFeature({
        name: this.name,
        description: this.description,
      })
      .then((createdFeature) => {
        this.feature = createdFeature;

        this.id = createdFeature.id;
        this.isSaved = true;
        this.state = 'default';

        this.callbacks.onCreate && this.callbacks.onCreate(createdFeature);
      })
      .catch(() => {
        alert('Something went wrong');
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };

  private createAndAssignToGroup = (featureGroupId: string): void => {
    this.actionStatus = 'saving';

    this.roadmapEditionStore
      .createFeatureAndAssignToFeatureGroup(featureGroupId, {
        name: this.name,
        description: this.description,
      })
      .then((createdFeature) => {
        this.feature = createdFeature;

        this.id = createdFeature.id;
        this.isSaved = true;
        this.state = 'default';

        this.callbacks.onCreate && this.callbacks.onCreate(createdFeature);
      })
      .catch(() => {
        alert('Something went wrong');
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };

  private update = (): void => {
    this.actionStatus = 'saving';

    this.roadmapEditionStore
      .updateFeature(
        this.id,
        {
          name: this.name,
          description: this.description,
        },
        this.featureGroupId
      )
      .then((updatedFeature) => {
        this.feature = updatedFeature;

        this.isSaved = true;
        this.state = 'default';

        this.callbacks.onUpdate && this.callbacks.onUpdate(updatedFeature);
      })
      .catch(() => {
        alert('Something went wrong');
      })
      .finally(() => {
        this.actionStatus = 'default';
      });
  };
}

export default FeatureState;
