import { observer } from 'mobx-react-lite';
import { RefObject } from 'react';

import { CintCategories } from 'src/Tests/Test.types';
import DropdownAudience from 'src/theme/atoms/DropdownAudience/DropdownAudience';
import DropdownWithSlider from 'src/theme/atoms/DropdownWithSlider/DropdownWithSlider';
import { TextArea } from 'src/theme/atoms/inputs';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import styles from './AudienceDetailsEditable.module.scss';

interface AudienceDetailsEditableProps {
  cintCategories: CintCategories | undefined;
  forwardRef: RefObject<HTMLTextAreaElement> | undefined;
  audienceName: string | undefined;
  audienceCountryId: string | undefined;
  audienceGenderId: string | undefined;
  audienceAgeMin: number | undefined;
  audienceAgeMax: number | undefined;
  audienceIncomeIds: string[] | undefined;
  handleAudienceNameChange: (v: string) => void;
  handleAudienceCountryIdChange: (v: string | undefined) => void;
  handleAudienceGenderIdChange: (v: string | undefined) => void;
  handleAudienceAgeMinChange: (v: number | undefined) => void;
  handleAudienceAgeMaxChange: (v: number | undefined) => void;
  handleAudienceIncomeIdsChange: (v: string[]) => void;
}

function AudienceDetailsEditable({
  cintCategories,
  forwardRef,
  audienceName,
  audienceCountryId,
  audienceGenderId,
  audienceAgeMin,
  audienceAgeMax,
  audienceIncomeIds,
  handleAudienceNameChange,
  handleAudienceCountryIdChange,
  handleAudienceGenderIdChange,
  handleAudienceAgeMinChange,
  handleAudienceAgeMaxChange,
  handleAudienceIncomeIdsChange,
}: AudienceDetailsEditableProps): JSX.Element {
  if (!cintCategories)
    return (
      <div className={styles.root}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.root}>
      <div className={styles.title}>Who are you solving for?</div>
      <TextArea
        name="audience"
        placeholder="Name the audience"
        label="Audience"
        disableEnter
        color="white"
        size="m"
        className={styles.audienceNameTextarea}
        forwardRef={forwardRef}
        value={audienceName || ''}
        onChange={(newName) => handleAudienceNameChange(newName)}
      />
      <div className={styles.dropdownsBox}>
        <div className={styles.country}>
          <DropdownAudience
            title={'Country'}
            required={true}
            items={cintCategories.audienceCountry}
            selectedIds={audienceCountryId || ''}
            handleItemSelection={(selectedId: string | string[] | undefined) => {
              handleAudienceCountryIdChange(selectedId as string | undefined);
              handleAudienceIncomeIdsChange([]);
            }}
            dropdownHeight={325}
          />
        </div>
        <div className={styles.income}>
          <DropdownAudience
            title={'Household income'}
            items={cintCategories.audienceCountry.find((el) => el.id === audienceCountryId)?.income || []}
            selectedIds={audienceIncomeIds || []}
            handleItemSelection={(selectedId: string | string[] | undefined) =>
              handleAudienceIncomeIdsChange(selectedId as string[])
            }
            dropdownHeight={325}
            multiple
            disabled={!audienceCountryId}
          />
        </div>
        <div className={styles.age}>
          <DropdownWithSlider
            title={'Age'}
            unit="yrs"
            selectedMin={audienceAgeMin}
            selectedMax={audienceAgeMax}
            defaultMin={cintCategories.ageMin || 18}
            defaultMax={cintCategories.ageMax || 80}
            minAgeRange={cintCategories.minRangeLength || 10}
            handleMinChange={(val: number | undefined) => handleAudienceAgeMinChange(val)}
            handleMaxChange={(val: number | undefined) => handleAudienceAgeMaxChange(val)}
          />
        </div>
        <div className={styles.gender}>
          <DropdownAudience
            title={'Gender'}
            items={cintCategories?.audienceGender}
            selectedIds={audienceGenderId || ''}
            handleItemSelection={(selectedId: string | string[] | undefined) =>
              handleAudienceGenderIdChange(selectedId as string | undefined)
            }
            dropdownHeight={160}
          />
        </div>
      </div>
      <a
        href="https://www.buildfoundations.co/i-need-help-finding-testers"
        target="_blank"
        rel="noreferrer"
        className={styles.whatElse}
      >
        What else do you want to filter by?
      </a>
    </div>
  );
}

export default observer(AudienceDetailsEditable);
