import clsx from 'clsx';
import { Children, cloneElement, isValidElement, ReactNode } from 'react';

import styles from './ButtonGroup.module.scss';

interface ButtonGroupProps {
  children: ReactNode[];
  orientation: 'horizontal' | 'vertical';
  dividerClass: string;
  className?: string;
}

function ButtonGroup({ children, orientation, dividerClass, className }: ButtonGroupProps): JSX.Element {
  const orientationClass = orientation === 'horizontal' ? styles.horizontal : styles.vertical;

  return (
    <div className={clsx(styles.root, orientationClass, className)}>
      {Children.map<ReactNode, ReactNode>(children, (child, i) => {
        if (isValidElement(child)) {
          return (
            <>
              {cloneElement(child, {
                ...child.props,
              })}
              {i !== children?.length - 1 && <div className={clsx(styles.divider, dividerClass)} />}
            </>
          );
        }
      })}
    </div>
  );
}

export default ButtonGroup;
