import baseStyles, { HelperModalTopbar } from './index';
import Modal from 'src/theme/atoms/modal/Modal';

interface HelperModalProps {
  onClose: () => void;
  lightSection: JSX.Element;
  darkSection?: JSX.Element;
}

function HelperModal({ onClose, lightSection, darkSection }: HelperModalProps): JSX.Element {
  return (
    <Modal className={baseStyles.modal} onClose={onClose}>
      <div className={baseStyles.lightSection}>
        <HelperModalTopbar onClose={onClose} />
        {lightSection}
      </div>
      {darkSection && <div className={baseStyles.darkSection}>{darkSection}</div>}
    </Modal>
  );
}

export default HelperModal;
