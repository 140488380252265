import { AuthError } from '@firebase/auth';
import { observer } from 'mobx-react-lite';
import { useState, KeyboardEvent } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Header from 'src/Header';
import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import Button from 'src/theme/atoms/buttons/Button/Button';
import ButtonLink from 'src/theme/atoms/buttons/ButtonLink/ButtonLink';
import Container from 'src/theme/atoms/container/Container';
import Input from 'src/theme/atoms/inputs/Input/Input';
import { ReactComponent as GoogleIcon } from 'src/theme/svg/google.svg';

import { getAuthErrorMessage } from '../errors';
import styles from './Login.module.scss';

function Login(): JSX.Element {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (value: string): void => setEmail(value);
  const handlePasswordChange = (value: string): void => setPassword(value);

  const signInWithEmailAndPassword = (): void => {
    authStore
      .signInWithEmail(email, password)
      .then(() => navigate(paths.organizationInitialize()))
      .catch((err: AuthError) => {
        alert(getAuthErrorMessage(err.code));
      });
  };

  const signInWithGoogle = (): void => {
    authStore
      .signInWithGoogle()
      .then(() => navigate(paths.organizationInitialize()))
      .catch((err: AuthError) => {
        alert(getAuthErrorMessage(err.code));
      });
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (email && password) signInWithEmailAndPassword();
  };

  return (
    <div className={styles.root}>
      <Header>
        <ButtonLink label="Create account" to={paths.signup()} size="m" color="secondary" />
      </Header>
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <h1>Welcome back</h1>
          <div className={styles.form}>
            <Input
              className={styles.input}
              name="email"
              type="email"
              color="white"
              size="s"
              placeholder="Email"
              helperText={<Link to={paths.requestNewPassword()}>Forgot my password?</Link>}
              onChange={handleEmailChange}
              onEnter={handleEnter}
              value={email}
            />
            {email.length >= 2 && (
              <Input
                className={styles.input}
                name="password"
                type="password"
                color="white"
                size="s"
                placeholder="Password"
                onChange={handlePasswordChange}
                onEnter={handleEnter}
                value={password}
              />
            )}
          </div>
          <Button
            label="Login"
            size="l"
            color="primary"
            className={styles.loginBtn}
            disabled={!email || !password}
            onClick={signInWithEmailAndPassword}
            maxWidth
          />
          <Button
            label="Sign in with google"
            size="l"
            color="secondary"
            className={styles.loginBtn}
            onClick={signInWithGoogle}
            startIcon={<GoogleIcon />}
            maxWidth
            ignoreIconStyling
          />
        </div>
      </Container>
    </div>
  );
}

export default observer(Login);
