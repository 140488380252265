import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';
import { Feature, FeatureGroup } from 'src/utils/types/FeatureRelated';

import * as requests from './Roadmap.requests';
import {
  BasicRoadmap,
  CreateFeatureDto,
  FeatureGroupDto,
  FeatureGroupWithoutFeatures,
  FetchFeatureGroupsParams,
  FetchFeaturesParams,
  ReorderFeatureGroupsDto,
  ReorderFeaturesDto,
  Roadmap,
  RoadmapDto,
  UpdateFeatureDto,
} from './Roadmap.types';

class RoadmapStore {
  rootStore: RootStore;

  roadmaps: BasicRoadmap[] = [];
  loading = false;

  isCreatingRoadmap = false;
  isUpdatingRoadmap = false;
  isDeletingRoadmap = false;
  isDuplicatingRoadmap = false;

  isCreatingFeature = false;
  isCreatingMultipleFeatures = false;
  isUpdatingFeature = false;
  isDeletingFeature = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getRoadmaps = (organizationId: string): Promise<BasicRoadmap[]> => {
    this.loading = true;
    return requests.fetchRoadmaps(organizationId).then((res) => {
      const sortedRoadmaps = res.data.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1));
      this.roadmaps = sortedRoadmaps;
      this.loading = false;

      return sortedRoadmaps;
    });
  };

  fetchRoadmap = (organizationId: string, roadmapId: string): Promise<BasicRoadmap> => {
    return requests.fetchRoadmaps(organizationId, { id: roadmapId }).then((res) => {
      if (res.data.length === 0) throw new Error('Roadmap not found');
      return res.data[0];
    });
  };

  createRoadmap = (organizationId: string, dto: RoadmapDto): Promise<BasicRoadmap> => {
    this.isCreatingRoadmap = true;

    return requests
      .createRoadmap(organizationId, dto)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isCreatingRoadmap = false;
      });
  };

  duplicateRoadmap = (organizationId: string, roadmapId: string, name: string): Promise<void> => {
    this.isDuplicatingRoadmap = true;

    return requests
      .duplicateRoadmap(organizationId, roadmapId, name)
      .then(() => {
        return;
      })
      .finally(() => {
        this.isDuplicatingRoadmap = false;
      });
  };

  updateRoadmap = (organizationId: string, roadmapId: string, dto: RoadmapDto): Promise<BasicRoadmap> => {
    this.isUpdatingRoadmap = true;

    return requests
      .updateRoadmap(organizationId, roadmapId, dto)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isUpdatingRoadmap = false;
      });
  };

  deleteRoadmap = (organizationId: string, roadmapId: string): Promise<void> => {
    this.isDeletingRoadmap = true;

    return requests.deleteRoadmap(organizationId, roadmapId).then(() => {
      this.isDeletingRoadmap = false;
    });
  };

  fetchFeatures = (organizationId: string, roadmapId: string, params?: FetchFeaturesParams): Promise<Feature[]> => {
    return requests.fetchFeatures(organizationId, roadmapId, params).then((res) => {
      return res.data;
    });
  };

  createFeature = (organizationId: string, roadmapId: string, dto: CreateFeatureDto): Promise<Feature> => {
    this.isCreatingFeature = true;

    return requests.createFeature(organizationId, roadmapId, dto).then((res) => {
      this.isCreatingFeature = false;
      return res.data;
    });
  };

  createMultipleFeatures = (organizationId: string, roadmapId: string, dto: CreateFeatureDto[]): Promise<Feature[]> => {
    this.isCreatingMultipleFeatures = true;

    return requests
      .createMultipleFeatures(organizationId, roadmapId, dto)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isCreatingMultipleFeatures = false;
      });
  };

  updateFeature = (
    organizationId: string,
    roadmapId: string,
    featureId: string,
    dto: UpdateFeatureDto
  ): Promise<Feature> => {
    this.isUpdatingFeature = true;

    return requests.updateFeature(organizationId, roadmapId, featureId, dto).then((res) => {
      this.isUpdatingFeature = false;
      return res.data;
    });
  };

  deleteFeature = (organizationId: string, roadmapId: string, featureId: string): Promise<void> => {
    this.isDeletingFeature = true;

    return requests.deleteFeature(organizationId, roadmapId, featureId).then(() => {
      this.isDeletingFeature = false;
    });
  };

  reorderFeatures = (organizationId: string, roadmapId: string, items: ReorderFeaturesDto): Promise<Feature[]> => {
    return requests.reorderFeatures(organizationId, roadmapId, items).then((res) => {
      return res.data;
    });
  };

  fetchFeatureGroups = (
    organizationId: string,
    roadmapId: string,
    params?: FetchFeatureGroupsParams
  ): Promise<FeatureGroup[]> => {
    return requests.fetchFeatureGroups(organizationId, roadmapId, params).then((res) => {
      return res.data.sort((a, b) => {
        if (a?.seq !== undefined && b?.seq !== undefined) {
          return a.seq - b.seq;
        } else {
          return Date.parse(a.createdAt) - Date.parse(b.createdAt);
        }
      });
    });
  };

  fetchFeatureGroup = (organizationId: string, roadmapId: string, id: string): Promise<FeatureGroup> => {
    return requests.fetchFeatureGroups(organizationId, roadmapId, { id }).then((res) => {
      if (!res.data[0]) throw new Error('No features group found');
      return res.data[0];
    });
  };

  createFeatureGroup = (
    organizationId: string,
    roadmapId: string,
    dto: FeatureGroupDto
  ): Promise<FeatureGroupWithoutFeatures> => {
    return requests.createFeatureGroup(organizationId, roadmapId, dto).then((res) => {
      return res.data;
    });
  };

  updateFeatureGroup = (
    organizationId: string,
    roadmapId: string,
    featureGroupId: string,
    dto: FeatureGroupDto
  ): Promise<FeatureGroupWithoutFeatures> => {
    return requests.updateFeatureGroup(organizationId, roadmapId, featureGroupId, dto).then((res) => {
      return res.data;
    });
  };

  deleteFeatureGroup = (organizationId: string, roadmapId: string, featureGroupId: string): Promise<void> => {
    return requests.deleteFeatureGroup(organizationId, roadmapId, featureGroupId).then(() => {
      return;
    });
  };

  duplicateFeatureGroup = (
    organizationId: string,
    roadmapId: string,
    fgId: string
  ): Promise<FeatureGroupWithoutFeatures> => {
    return requests.duplicateFeatureGroup(organizationId, roadmapId, fgId).then((res) => {
      return res.data;
    });
  };

  reassignFeaturesToFeatureGroup = (
    organizationId: string,
    roadmapId: string,
    featureGroupId: string,
    featureIds: string[]
  ): Promise<void> => {
    return requests.reassignFeaturesToFeatureGroup(organizationId, roadmapId, featureGroupId, featureIds).then(() => {
      return;
    });
  };

  assignFeatureToFeatureGroup = (
    organizationId: string,
    roadmapId: string,
    featureGroupId: string,
    featureId: string
  ): Promise<void> => {
    return requests.assignFeatureToFeatureGroup(organizationId, roadmapId, featureGroupId, featureId).then(() => {
      return;
    });
  };

  deassignFeatureFromFeatureGroup = (
    organizationId: string,
    roadmapId: string,
    featureGroupId: string,
    featureId: string
  ): Promise<void> => {
    return requests.deassignFeatureFromFeatureGroup(organizationId, roadmapId, featureGroupId, featureId).then(() => {
      return;
    });
  };

  reorderFeatureGroups = (
    organizationId: string,
    roadmapId: string,
    items: ReorderFeatureGroupsDto
  ): Promise<FeatureGroup[]> => {
    return requests.reorderFeatureGroups(organizationId, roadmapId, items).then((res) => {
      return res.data;
    });
  };

  getRoadmap = (organizationId: string, roadmapId: string): Promise<Roadmap> => {
    return requests.getRoadmap(organizationId, roadmapId).then((res) => {
      return res.data;
    });
  };

  getShareLink = (organizationId: string, roadmapId: string): Promise<string> => {
    return requests.getShareLink(organizationId, roadmapId).then((res) => {
      return res.data.id;
    });
  };
}

export default RoadmapStore;
