declare global {
  interface Window {
    env: { [key: string]: string };
  }
}

export type Env = {
  backendUrl: string;
  firebaseApiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  chargebeeSite: string;
  chargebeeDomain: string;
  landingPage: string;
  nodeEnv: string;
  howManyRespondentsPerIdeaNeeded;
};

function fetchEnv(name: string, defaultValue = '') {
  return process.env[name] ?? (window['env'] ? window['env'][name] : null) ?? defaultValue;
}

export const isInDevelopment = (): boolean => env.nodeEnv === 'development';

const env: Env = {
  backendUrl: fetchEnv('REACT_APP_BASE_API_URL'),
  firebaseApiKey: fetchEnv('REACT_APP_FIREBASE_API_KEY'),
  authDomain: fetchEnv('REACT_APP_AUTH_DOMAIN'),
  projectId: fetchEnv('REACT_APP_PROJECT_ID'),
  storageBucket: fetchEnv('REACT_APP_STORAGE_BUCKET'),
  messagingSenderId: fetchEnv('REACT_APP_MESSAGING_SENDER_ID'),
  appId: fetchEnv('REACT_APP_APP_ID'),
  chargebeeSite: fetchEnv('REACT_APP_CHARGEBEE_SITE'),
  chargebeeDomain: fetchEnv('REACT_APP_CHARGEBEE_DOMAIN'),
  landingPage: 'https://www.buildfoundations.co',
  nodeEnv: fetchEnv('NODE_ENV'),
  howManyRespondentsPerIdeaNeeded: fetchEnv('REACT_APP_RESPONDENTS_PER_IDEA_NEEDED'),
};

export default env;
