import { useNavigate, useLocation } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { Organization } from 'src/organizations/Organizations.types';
import Avatar, { MenuItem } from 'src/theme/atoms/avatar/Avatar';

import styles from './Header.module.scss';
import MobileMenu from './MobileMenu';

interface HeaderAccountActionsProps {
  organization: Organization;
}

function HeaderAccountActions({ organization }: HeaderAccountActionsProps): JSX.Element {
  const { organizationsStore, authStore } = useStores();
  const { firebaseAuth, isAdmin, logout } = authStore;
  const { organizations } = organizationsStore;
  const isOwner = organization.createdBy === authStore.firebaseAuth.currentUser?.uid;
  const navigate = useNavigate();
  const onOrganizationChange = (organization: Organization): void => {
    return navigate(paths.organizationDashboard(organization.id));
  };

  const getOrganizationMenuItems = (): MenuItem[] | undefined => {
    const newItems: MenuItem[] | undefined =
      organization.createdBy === firebaseAuth.currentUser?.uid && organizations.length === 1
        ? undefined
        : [
            ...organizations.map((org) => ({
              key: org.id,
              label: org.name,
              onClick: () => onOrganizationChange(org),
              selected: organization.id === org.id,
            })),
          ];

    if (newItems) {
      const userCreatedOrg = organizations.find((org) => org.createdBy === firebaseAuth.currentUser?.uid);

      if (!userCreatedOrg) {
        newItems.push({
          label: 'Create organization +',
          onClick: () => navigate(paths.organizationCreate()),
          selected: false,
        });
      }
    }

    return newItems?.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
  };

  const organizationMenuItems = getOrganizationMenuItems();
  return (
    <>
      <div className={styles.visibleOnWideScreen}>
        <Avatar
          className={styles.avatar}
          title={organization.name}
          loading={organizationsStore.isFetching}
          isOrganizationAvatar
          menuItems={organizationMenuItems}
        />
        <Avatar
          className={styles.avatar}
          title={firebaseAuth.currentUser?.displayName || firebaseAuth.currentUser?.email || ''}
          menuItems={
            isOwner || isAdmin
              ? [
                  {
                    label: 'your plan',
                    onClick: () => navigate(paths.organizationPlans(organization.id)),
                    selected: useLocation().pathname.split('/')[3] === 'plans',
                  },
                  {
                    label: 'account settings',
                    onClick: () => navigate(paths.organizationSettings(organization.id)),
                    selected: useLocation().pathname.split('/')[3] === 'settings',
                  },
                  { label: 'Logout', onClick: logout, selected: false },
                ]
              : [{ label: 'Logout', onClick: logout, selected: false }]
          }
        />
      </div>
      {firebaseAuth.currentUser && <MobileMenu organization={organization} />}
    </>
  );
}

export default HeaderAccountActions;
