import { useState, useEffect } from 'react';

import { useStores } from 'src/RootStore';
import { ErrorEmailFormatIsInvalid } from 'src/auth/errors';
import SubscriptionLimitsExceededModal from 'src/theme/atoms/SubscriptionLimitsExceededModal/SubscriptionLimitsExceededModal';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { Input } from 'src/theme/atoms/inputs';
import Modal from 'src/theme/atoms/modal/Modal';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';

import styles from './AssignUserModal.module.scss';

interface AssignUserModalProps {
  isSubmitting: boolean;
  onSubmit: (email: string) => void;
  onClose: () => void;
  snackbarMessage: string;
  onCloseSnackbar: () => void;
  showModalUpgradeSubscription: boolean;
  onCloseModalUpgradeSubscription: () => void;
}

function AssignUserModal({
  isSubmitting,
  onSubmit,
  onClose,
  snackbarMessage,
  onCloseSnackbar,
  showModalUpgradeSubscription,
  onCloseModalUpgradeSubscription,
}: AssignUserModalProps): JSX.Element {
  const { authStore } = useStores();

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (snackbarMessage || showModalUpgradeSubscription) {
      setEmail('');
    }
  }, [snackbarMessage, showModalUpgradeSubscription]);

  const handleEmailChange = (value: string): void => {
    setErrorMessage(authStore.isEmailValid(value) ? ErrorEmailFormatIsInvalid.message : '');
    setEmail(value);
  };

  const isSubmitBtnDisabled = !email || isSubmitting || !!errorMessage;

  const handleSave = (): void => {
    !isSubmitBtnDisabled && onSubmit(email);
  };

  return (
    <Modal>
      <div className={styles.modalInner}>
        <div className={styles.modalHeader}>
          <p className={styles.modalTitle}>Add team member to the organization</p>
          <div className={styles.modalCloseBtnBox}>
            <Button label="Close" color="secondary" size="m" onClick={onClose} />
          </div>
        </div>
        <div className={styles.modalForm}>
          <Input
            color="white"
            name="email"
            label="Email"
            placeholder="Email"
            size="s"
            type="text"
            className={styles.inputField}
            inputClassName={styles.input}
            value={email}
            onChange={handleEmailChange}
            onEnter={handleSave}
            error={{
              message: errorMessage,
            }}
          />
          <Button
            color="primary"
            label={isSubmitting ? 'Loading...' : 'Save'}
            size="m"
            disabled={isSubmitBtnDisabled}
            onClick={handleSave}
          />
        </div>
      </div>

      <Snackbar type={'error'} message={snackbarMessage} onClose={onCloseSnackbar} autoclose={false} />
      <SubscriptionLimitsExceededModal
        isOpen={showModalUpgradeSubscription}
        onClose={onCloseModalUpgradeSubscription}
      />
    </Modal>
  );
}

export default AssignUserModal;
