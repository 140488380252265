import clsx from 'clsx';
import { RefObject } from 'react';

import helperBaseStyles from 'src/theme/atoms/HelperModal';
import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';
import Button from 'src/theme/atoms/buttons/Button/Button';

import LinkToCopy from '../LinkToCopy/LinkToCopy';
import arrowIcon from '../assets/arrowWithCircle.svg';
import styles from './AudienceShareLinkHelperModal.module.scss';

interface AudienceShareLinkHelperModalProps {
  shareLinkInputRef: RefObject<HTMLInputElement>;
  shareLink: string;
  copyShareLink: () => void;
  onClose: () => void;
}

function AudienceShareLinkHelperModal({
  shareLinkInputRef,
  shareLink,
  copyShareLink,
  onClose,
}: AudienceShareLinkHelperModalProps): JSX.Element {
  const handleHelpBtnClick = (): void => {
    const url = 'https://www.buildfoundations.co/i-need-help-finding-testers';
    window.open(url, '_blank', 'noreferrer')?.focus();
  };

  return (
    <HelperModal
      lightSection={
        <>
          <div className={styles.header}>
            <div className={styles.copy}>
              <h2 className={styles.title}>What should I do?</h2>
              <p className={helperBaseStyles.text}>
                You are live! Copy / paste this link and text, email, post it anywhere to start getting real feedback
                from real people on your solution ideas!
              </p>
            </div>
            <div className={styles.action}>
              <Button
                label="I need help finding testers"
                color="secondary"
                size="m"
                onClick={handleHelpBtnClick}
                className={styles.btn}
              />
            </div>
          </div>
        </>
      }
      darkSection={
        <>
          <div className={styles.content}>
            <h2 className={styles.contentTitle}>Share broadly</h2>
            <LinkToCopy inputRef={shareLinkInputRef} shareLink={shareLink} onCopyClick={copyShareLink} />
            <div className={styles.arrows}>
              <div className={styles.arrowsLeft}>
                <img src={arrowIcon} alt="arrow" className={clsx(styles.arrowLeft)} />
                <img src={arrowIcon} alt="arrow" className={clsx(styles.arrowLeft, styles.arrowMiddle)} />
              </div>
              <div className={styles.arrowsRight}>
                <img src={arrowIcon} alt="arrow" className={clsx(styles.arrowRight, styles.arrowMiddle)} />
                <img src={arrowIcon} alt="arrow" className={clsx(styles.arrowRight)} />
              </div>
            </div>
            <div className={styles.means}>
              <div className={styles.meansSM}>SOCIAL MEDIA</div>
              <div className={styles.meansOthers}>
                <div className={styles.meansOther}>TEXT</div>
                <div className={styles.meansOther}>EMAIL</div>
              </div>
            </div>
            <div className={styles.shareWith}>
              <div className={styles.shareWithItem}>
                Share broadly with <br />
                prospective customers
              </div>
              <div className={styles.shareWithItem}>
                Share with <br />
                close connects
              </div>
            </div>
          </div>
        </>
      }
      onClose={onClose}
    />
  );
}

export default AudienceShareLinkHelperModal;
