import { CSSProperties } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import StaticIdeaCard from '../IdeaCard/StaticIdeaCard';
import { IdeaCardDnDItem } from '../IdeaCardDnDItem.type';
import styles from './DragLayer.module.scss';

const getItemStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

function DragLayer(): JSX.Element | null {
  const { initialOffset, currentOffset, isDragging, ...restDragLayerProps } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const renderItem = (): JSX.Element | null => {
    const ideasColumn = document.getElementById('test-data');
    if (!ideasColumn) throw new Error("Couldn't find ideas column element");

    if ('ideaState' in restDragLayerProps.item) {
      const item = restDragLayerProps.item as IdeaCardDnDItem;
      const { width } = ideasColumn.getBoundingClientRect();

      return (
        <div
          style={{
            width,
          }}
        >
          <StaticIdeaCard text={item.ideaState.text} className={styles.previewOverlay} />
        </div>
      );
    }

    return null;
  };

  if (!isDragging) return null;

  return (
    <div className={styles.root}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  );
}

export default DragLayer;
