import { useEffect } from 'react';

import RootStoreContext, { rootStore } from 'src/RootStore';
import { chargebee } from 'src/chargebee';

import Router from './routes/Router';

function App(): JSX.Element {
  useEffect(() => {
    chargebee.initialize();
  }, []);

  return (
    <RootStoreContext.Provider value={rootStore}>
      <Router />
    </RootStoreContext.Provider>
  );
}

export default App;
