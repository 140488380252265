import { observer } from 'mobx-react-lite';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams, Outlet } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import styles from './Roadmap.module.scss';
import { Roadmap } from './Roadmap.types';

interface RoadmapContextValue {
  roadmap: Roadmap | null;
  changeRoadmap: (roadmap: Roadmap) => void;
}

const RoadmapContext = createContext<RoadmapContextValue | undefined>(undefined);

export const useRoadmap = (): RoadmapContextValue => {
  const roadmap = useContext(RoadmapContext);
  if (roadmap === undefined) {
    throw new Error('useRoadmap must be used within RoadmapContext');
  }
  return roadmap;
};

function RoadmapLayout(): JSX.Element {
  const { organizationId, roadmapId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const { roadmapStore } = useStores();
  const [isLoading, setIsLoading] = useState(!!roadmapId);

  const [roadmap, setRoadmap] = useState<Roadmap | null>(null);

  useEffect(() => {
    if (roadmapId) {
      roadmapStore.getRoadmap(organizationId, roadmapId).then((roadmap) => {
        setRoadmap(roadmap);
        setIsLoading(false);
      });
    }
  }, []);

  const changeRoadmap = (roadmap: Roadmap): void => {
    setRoadmap(roadmap);
  };

  if (isLoading) return <Loader />;

  return (
    <div className={styles.root}>
      <RoadmapContext.Provider
        value={{
          roadmap,
          changeRoadmap,
        }}
      >
        <Outlet />
      </RoadmapContext.Provider>
    </div>
  );
}

export default observer(RoadmapLayout);
