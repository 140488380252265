import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import ButtonGroup from 'src/theme/atoms/buttonGroup/ButtonGroup';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Tags from 'src/theme/atoms/inputs/Tags/Tags';
import { ProductType } from 'src/utils/types/FeatureRelated';

import FeatureGroupUrgencyState from '../FeatureGroupUrgencyState.store';
import styles from './Products.module.scss';

interface ProductsProps {
  featureGroupState: FeatureGroupUrgencyState;
  onProductTypeChange: (productType: ProductType) => void;
  onProductsChange: (products: string[]) => void;
  onHelp: () => void;
}

function Products({ featureGroupState, onProductTypeChange, onProductsChange, onHelp }: ProductsProps): JSX.Element {
  const singleProductTags = featureGroupState.productType === 'single' && (
    <div className={styles.singleProductTags}>
      <Tags
        value={featureGroupState.productNames}
        placeholder="What single product or service?"
        onChange={(products) => onProductsChange(products)}
      />
    </div>
  );

  const mixedProductTags = featureGroupState.productType === 'mix' && (
    <div className={styles.mixProductTags}>
      <Tags
        value={featureGroupState.productNames}
        placeholder="What product or service?"
        onChange={(products) => onProductsChange(products)}
      />
    </div>
  );

  const selfCreatedProductTags = featureGroupState.productType === 'selfcreated' && (
    <div className={styles.selfCreatedProductTags}>
      <Tags
        value={featureGroupState.productNames}
        placeholder="What product or service?"
        onChange={(products) => onProductsChange(products)}
      />
    </div>
  );

  const productNames = featureGroupState.productType && (
    <div className={styles.productNamesBox}>
      {singleProductTags}
      {mixedProductTags}
      {selfCreatedProductTags}
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h6>How do they achieve this?</h6>
        <Button label="What does this mean?" color="secondary" size="m" onClick={onHelp} />
      </div>

      <div className={styles.main}>
        <div className={styles.mainContent}>
          <div>
            <ButtonGroup orientation="vertical" className={styles.buttonGroup} dividerClass={styles.divider}>
              <Button
                label="Single product or service"
                color="white"
                size="m"
                className={clsx(styles.productTypeBtn, featureGroupState.productType === 'single' && styles.selected)}
                onClick={() => onProductTypeChange('single')}
              />
              <Button
                label="Mix and match product and/or services "
                color="white"
                size="m"
                className={clsx(styles.productTypeBtn, featureGroupState.productType === 'mix' && styles.selected)}
                onClick={() => onProductTypeChange('mix')}
              />
              <Button
                label="Self-created solution or hack"
                color="white"
                size="m"
                className={clsx(
                  styles.productTypeBtn,
                  featureGroupState.productType === 'selfcreated' && styles.selected
                )}
                onClick={() => onProductTypeChange('selfcreated')}
              />
            </ButtonGroup>
          </div>
          {productNames}
        </div>
      </div>
    </div>
  );
}

export default observer(Products);
