import { AxiosResponse } from 'axios';

import api from 'src/api';
import { Feature, FeatureGroup } from 'src/utils/types/FeatureRelated';

import {
  Roadmap,
  FetchRoadmapsParams,
  RoadmapDto,
  FetchFeaturesParams,
  FeatureGroupDto,
  FetchFeatureGroupsParams,
  ShareLink,
  FeatureGroupWithoutFeatures,
  BasicRoadmap,
  CreateFeatureDto,
  UpdateFeatureDto,
  UpdateFeatureGroupDto,
  ReorderFeaturesDto,
  ReorderFeatureGroupsDto,
} from './Roadmap.types';

//Roadmaps
export function fetchRoadmaps(
  organizationId: string,
  params?: FetchRoadmapsParams
): Promise<AxiosResponse<BasicRoadmap[]>> {
  return api.get(`/organizations/${organizationId}/roadmaps`, { params });
}

export function createRoadmap(organizationId: string, dto: RoadmapDto): Promise<AxiosResponse<BasicRoadmap>> {
  return api.put(`/organizations/${organizationId}/roadmaps`, dto);
}

export function duplicateRoadmap(
  organizationId: string,
  roadmapId: string,
  name: string
): Promise<AxiosResponse<Roadmap>> {
  return api.post(`/organizations/${organizationId}/roadmaps/${roadmapId}/duplicate`, { name });
}

export function updateRoadmap(
  organizationId: string,
  roadmapId: string,
  dto: RoadmapDto
): Promise<AxiosResponse<BasicRoadmap>> {
  return api.patch(`/organizations/${organizationId}/roadmaps/${roadmapId}`, dto);
}

export function deleteRoadmap(organizationId: string, roadmapId: string): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/roadmaps/${roadmapId}`);
}

//Features
export function fetchFeatures(
  organizationId: string,
  roadmapId: string,
  params?: FetchFeaturesParams
): Promise<AxiosResponse<Feature[]>> {
  return api.get(`/organizations/${organizationId}/roadmaps/${roadmapId}/features`, { params });
}

export function createFeature(
  organizationId: string,
  roadmapId: string,
  dto: CreateFeatureDto
): Promise<AxiosResponse<Feature>> {
  return api.put(`/organizations/${organizationId}/roadmaps/${roadmapId}/features`, dto);
}

export function createMultipleFeatures(
  organizationId: string,
  roadmapId: string,
  dto: CreateFeatureDto[]
): Promise<AxiosResponse<Feature[]>> {
  return api.put(`/organizations/${organizationId}/roadmaps/${roadmapId}/features/batch`, dto);
}

export function updateFeature(
  organizationId: string,
  roadmapId: string,
  featureId: string,
  dto: UpdateFeatureDto
): Promise<AxiosResponse<Feature>> {
  return api.patch(`/organizations/${organizationId}/roadmaps/${roadmapId}/features/${featureId}`, dto);
}

export function deleteFeature(
  organizationId: string,
  roadmapId: string,
  featureId: string
): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/roadmaps/${roadmapId}/features/${featureId}`);
}

export function reorderFeatures(
  organizationId: string,
  roadmapId: string,
  items: ReorderFeaturesDto
): Promise<AxiosResponse<Feature[]>> {
  return api.post(`/organizations/${organizationId}/roadmaps/${roadmapId}/features/reorder`, { items });
}

//FeatureGroups
export function fetchFeatureGroups(
  organizationId: string,
  roadmapId: string,
  params?: FetchFeatureGroupsParams
): Promise<AxiosResponse<FeatureGroup[]>> {
  return api.get(`/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups`, { params });
}

export function createFeatureGroup(
  organizationId: string,
  roadmapId: string,
  dto: FeatureGroupDto
): Promise<AxiosResponse<FeatureGroupWithoutFeatures>> {
  return api.put(`/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups`, dto);
}

export function updateFeatureGroup(
  organizationId: string,
  roadmapId: string,
  featureGroupId: string,
  dto: UpdateFeatureGroupDto
): Promise<AxiosResponse<FeatureGroup>> {
  return api.patch(`/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups/${featureGroupId}`, dto);
}

export function duplicateFeatureGroup(
  organizationId: string,
  roadmapId: string,
  fgId: string
): Promise<AxiosResponse<FeatureGroup>> {
  return api.post(`/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups/${fgId}/duplicate`);
}

export function deleteFeatureGroup(
  organizationId: string,
  roadmapId: string,
  featureGroupId: string
): Promise<AxiosResponse<void>> {
  return api.delete(`/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups/${featureGroupId}`);
}

export function reassignFeaturesToFeatureGroup(
  organizationId: string,
  roadmapId: string,
  featureGroupId: string,
  featureIds: string[]
): Promise<AxiosResponse<void>> {
  return api.post(`/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups/${featureGroupId}/features`, {
    featureIds,
  });
}

export function assignFeatureToFeatureGroup(
  organizationId: string,
  roadmapId: string,
  featureGroupId: string,
  featureId: string
): Promise<AxiosResponse<void>> {
  return api.put(
    `/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups/${featureGroupId}/features/${featureId}`
  );
}

export function deassignFeatureFromFeatureGroup(
  organizationId: string,
  roadmapId: string,
  featureGroupId: string,
  featureId: string
): Promise<AxiosResponse<void>> {
  return api.delete(
    `/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups/${featureGroupId}/features/${featureId}`
  );
}
export function reorderFeatureGroups(
  organizationId: string,
  roadmapId: string,
  items: ReorderFeatureGroupsDto
): Promise<AxiosResponse<FeatureGroup[]>> {
  return api.post(`/organizations/${organizationId}/roadmaps/${roadmapId}/feature-groups/reorder`, { items });
}

export function getRoadmap(organizationId: string, roadmapId: string): Promise<AxiosResponse<Roadmap>> {
  return api.get<Roadmap>(`/organizations/${organizationId}/roadmaps/${roadmapId}`);
}

export function getShareLink(organizationId: string, roadmapId: string): Promise<AxiosResponse<ShareLink>> {
  return api.post(`/organizations/${organizationId}/roadmaps/${roadmapId}/share-links`);
}
