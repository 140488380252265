const getDuplicatedName = (alreadyExistingNames: string[], name: string, copyNo = 1): string => {
  const newNameSuggestion = `${name} (copy ${copyNo})`;
  if (!alreadyExistingNames.includes(newNameSuggestion)) {
    return newNameSuggestion;
  } else {
    return getDuplicatedName(alreadyExistingNames, name, copyNo + 1);
  }
};

export default getDuplicatedName;
