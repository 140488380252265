import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useStores } from 'src/RootStore';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { Input } from 'src/theme/atoms/inputs';
import Modal from 'src/theme/atoms/modal/Modal';

import styles from './Duplicate.module.scss';

interface DuplicateProps {
  isModalOpen: boolean;
  organizationId: string;
  itemId: string;
  itemName: string;
  itemType: 'roadmap' | 'test';
  onClose: () => void;
  onSuccess: () => void;
  onFail: () => void;
  onDuplicate402Error: () => void;
}

function Duplicate({
  isModalOpen,
  organizationId,
  itemId,
  itemName,
  itemType,
  onClose,
  onSuccess,
  onDuplicate402Error,
  onFail,
}: DuplicateProps): JSX.Element {
  const [newProjectName, setNewProjectName] = useState(itemName + ' (copy)');

  const { roadmapStore, testStore } = useStores();
  const { duplicateRoadmap } = roadmapStore;
  const { duplicateTest } = testStore;

  const onRoadmapDuplicate = (): void => {
    const req = itemType === 'roadmap' ? duplicateRoadmap : duplicateTest;

    req(organizationId, itemId, newProjectName)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        if (err.response.status === 402) {
          onDuplicate402Error();
        } else {
          onFail();
        }
      });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onClose}>
      <div className={styles.modalInner}>
        <div className={styles.modalHeader}>
          <p className={styles.modalTitle}>Name your duplicate project</p>
        </div>
        <div className={styles.modalForm}>
          <Input
            color="white"
            name="duplicate"
            label="Name your duplicate project"
            placeholder=""
            size="l"
            type="text"
            className={styles.groupNameInputField}
            inputClassName={styles.groupNameInput}
            onChange={(val) => setNewProjectName(val)}
            value={newProjectName}
          />
          <div className={styles.modalActions}>
            <Button label="Close" color="secondary" size="l" onClick={() => onClose()} />
            <Button label="Duplicate" color="primary" size="l" onClick={onRoadmapDuplicate} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default observer(Duplicate);
