import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';

import { Organization } from '../Organizations.types';

class OrganizationRouteLayoutStore {
  constructor() {
    makeAutoObservable(this);
  }

  getOrganization = (organizationId: string): Organization | null => {
    const { organizations } = rootStore.organizationsStore;

    if (organizations.length) {
      const organization = organizations.find((org) => org.id === organizationId);
      if (organization) {
        return organization;
      }
    }

    return null;
  };
}

export default OrganizationRouteLayoutStore;
