import clsx from 'clsx';
import React from 'react';

import s from './HowToModal.module.scss';
import { ListItem, SectionDataItem } from './const';

interface SectionProps {
  sectionDataItem: SectionDataItem;
}

function Section({ sectionDataItem }: SectionProps): JSX.Element {
  const renderListItem = (data: ListItem, index: number, last: boolean): JSX.Element => (
    <div className={clsx(s.listItem, last && s.footer)} key={index}>
      <div className={clsx(s.circle, last && s.lastCircle)}>
        <span>{index + 1}</span>
      </div>
      {data.text.map((item) => (
        <p key={item}>{item}</p>
      ))}
      <div className={s.imageBox}>
        <img src={sectionDataItem.images[index]} alt={data.altText} className={last ? s.footerImg : s.listImg} />
      </div>
    </div>
  );

  return (
    <div className={s.section}>
      <div className={s.entry}>
        <img className={s.icon} src={sectionDataItem.icon} alt="roadmap icon" />
        <div className={s.titles}>
          <p className={s.title1}>{sectionDataItem.title1}</p>
          <p className={s.title2}>{sectionDataItem.title2}</p>
        </div>
      </div>
      <p className={s.subtitle}>The process goes like this...</p>
      <div className={s.list}>
        {sectionDataItem.list.map((item, i) => renderListItem(item, i, i === sectionDataItem.list.length - 1))}
      </div>
    </div>
  );
}

export default Section;
