import clsx from 'clsx';

import styles from './base.module.scss';
import { ButtonBase } from './buttons.types';

interface GetButtonBaseClassesProps {
  color: ButtonBase['color'];
  size: ButtonBase['size'];
  className?: ButtonBase['className'];
  maxWidth: ButtonBase['maxWidth'];
  ignoreIconStyling: ButtonBase['ignoreIconStyling'];
}

const getButtonBaseClasses = ({
  color,
  size,
  className,
  maxWidth,
  ignoreIconStyling,
}: GetButtonBaseClassesProps): string => {
  const colorClass = (): string => {
    switch (color) {
      case 'secondary':
        return styles.secondary;
      case 'black':
        return styles.black;
      case 'white':
        return styles.white;
      default:
        return styles.primary;
    }
  };

  const sizeClass = (): string => {
    switch (size) {
      case 'xs':
        return styles.sizeXS;
      case 's':
        return styles.sizeS;
      case 'm':
        return styles.sizeM;
      case 'l':
        return styles.sizeL;
      default:
        return styles.sizeXL;
    }
  };

  return clsx(
    styles.root,
    colorClass(),
    sizeClass(),
    className,
    { maxWidth },
    ignoreIconStyling && styles.ignoreIconStyling
  );
};

export default getButtonBaseClasses;
