import * as csv from 'fast-csv';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { FeatureData } from 'src/roadmap/Roadmap.types';

import styles from './CSVFileUpload.module.scss';

interface FileDragEvent {
  files: File[];
}

const trimAndLowercaseHeader = (obj: FeatureData): FeatureData | Record<string, never> => {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key.toLowerCase().trim()] = obj[key];
    return accumulator;
  }, {});
};

const errorData = (errorType?: string, columns?: string): string => {
  switch (errorType) {
    case 'notCsv':
      return 'Only csv files are accepted.';
    case 'invalidFile':
      return `Invalid file content - the column${columns?.split(',').length === 1 ? '' : 's'} need to be: "${columns
        ?.split(',')
        .join(', ')}".`;
    default:
      return 'Oops, something went wrong! Please try again.';
  }
};

interface CSVFileUploadProps {
  headers: string;
  onFileChange: (data: FeatureData[]) => void;
}

function CSVFileUpload({ headers, onFileChange }: CSVFileUploadProps): JSX.Element {
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [, drop] = useDrop<FileDragEvent, void, { isOver: boolean; canDrop: boolean }>({
    accept: NativeTypes.FILE,
    drop: (item) => loadDraggedFile(item),
  });

  const transformUploadedDataAndSaveAsJSON = (uploadedFile: File): void => {
    setErrorMessage('');

    const data: FeatureData[] = [];

    new Promise((resolve) => {
      uploadedFile.text().then((text) => {
        let textWithHeaders = '';
        if (text.toLowerCase().replace(/\s/g, '').startsWith(headers)) {
          textWithHeaders = text;
        } else {
          textWithHeaders = [headers, text].join('\r\n');
        }
        csv
          .parseString(textWithHeaders, { headers: true })
          .on('data', (row) => {
            data.push(row);
            resolve(true);
          })
          .on('error', () => {
            setErrorMessage(errorData('invalidFile', headers));
          });
      });
    }).then(() => {
      const jsonWithLowerKeysHeaders = data.map((el) => trimAndLowercaseHeader(el));
      onFileChange(jsonWithLowerKeysHeaders as FeatureData[]);
    });
  };

  const isExtensionOk = (file: File): boolean => {
    const filenameDivided = file.name.split('.');
    const extension = filenameDivided && filenameDivided[filenameDivided.length - 1];
    return extension === 'csv';
  };

  const loadDraggedFile = (item: FileDragEvent): void => {
    if (!item.files || item.files.length !== 1) return;

    const file = item.files[0];

    if (!isExtensionOk(file)) {
      setErrorMessage(errorData('notCsv'));
      return;
    }

    transformUploadedDataAndSaveAsJSON(file);
    setFile(file);
  };

  const onFilesLoaded = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const files = ev.target.files;
    if (!files || files.length < 1) {
      ev.target.value = '';
      return;
    }

    const file = files[0];

    if (!isExtensionOk(file)) {
      ev.target.value = '';
      setErrorMessage(errorData('notCsv'));
      return;
    }

    transformUploadedDataAndSaveAsJSON(file);
    setFile(file);
    ev.target.value = '';
  };

  const handleError = (): void => {
    setErrorMessage(errorData());
  };

  const handleLoadStart = (): void => {
    setErrorMessage('');
  };

  const handleLoadedData = (): void => {
    setErrorMessage('');
  };

  return (
    <div className={styles.root}>
      {errorMessage ? (
        <div className={styles.error}>
          <div>{errorMessage}</div>
        </div>
      ) : (
        <>
          {file ? (
            <>
              <div className={styles.fileName}>{file.name}</div>
              <div className={styles.instruction}>To replace simply drag and drop a .csv file here or click</div>
            </>
          ) : (
            <div className={styles.instruction}>Simply drag and drop a .csv file here or click</div>
          )}
        </>
      )}
      <input
        ref={drop}
        type="file"
        accept=".csv"
        onError={handleError}
        onLoadStart={handleLoadStart}
        onLoadedData={handleLoadedData}
        onChange={(e) => onFilesLoaded(e)}
      />
    </div>
  );
}

export default CSVFileUpload;
