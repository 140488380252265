import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './Container.module.scss';

interface ContainerProps {
  children: ReactNode;
  className?: string;
  width?: number;
}

function Container({ children, className, width }: ContainerProps): JSX.Element {
  return (
    <div className={clsx(styles.container, className)} style={{ width }}>
      {children}
    </div>
  );
}

export default Container;
