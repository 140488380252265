import { createContext, useContext } from 'react';

import { TestStore } from 'src/Tests';
import { AuthStore } from 'src/auth';
import { NoAuthRoadmapStore } from 'src/noAuthRoadmap';
import { NoAuthTestStore } from 'src/noAuthTest';
import { OrganizationsStore } from 'src/organizations';
import { PlansStore } from 'src/plans';
import { QuizStore } from 'src/quiz';
import { RoadmapStore } from 'src/roadmap';

export class RootStore {
  authStore: AuthStore;
  roadmapStore: RoadmapStore;
  testStore: TestStore;
  organizationsStore: OrganizationsStore;
  noAuthRoadmapStore: NoAuthRoadmapStore;
  noAuthTestStore: NoAuthTestStore;
  plansStore: PlansStore;
  quizStore: QuizStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.roadmapStore = new RoadmapStore(this);
    this.testStore = new TestStore(this);
    this.organizationsStore = new OrganizationsStore(this);
    this.noAuthRoadmapStore = new NoAuthRoadmapStore(this);
    this.noAuthTestStore = new NoAuthTestStore(this);
    this.plansStore = new PlansStore(this);
    this.quizStore = new QuizStore(this);
  }
}

export const rootStore = new RootStore();

const RootStoreContext = createContext(rootStore);

export function useStores(): RootStore {
  const context = useContext(RootStoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }
  return context;
}

export default RootStoreContext;
