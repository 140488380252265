import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';
import FeatureState from 'src/roadmap/FeatureState.store';
import RoadmapEditionStore from 'src/roadmap/RoadmapEdition/RoadmapEdition.store';
import { TUTORIALS } from 'src/theme/atoms/TutorialTooltip/TutorialTooltip.const';
import { isTutorialSeen, setTutorialSeen } from 'src/theme/atoms/TutorialTooltip/TutorialTooltip.requests';
import { Feature, FeatureGroup } from 'src/utils/types/FeatureRelated';

import FeatureGroupState from './FeatureGroupState.store';

class FeatureGroupsCreationStore {
  roadmapEditionStore: RoadmapEditionStore;

  featureStates: FeatureState[] = [];
  featureGroupStates: FeatureGroupState[] = [];
  tutorialStep = -1;

  constructor(roadmapEditionStore: RoadmapEditionStore) {
    this.roadmapEditionStore = roadmapEditionStore;

    const featureIdsInsideFeatureGroups = this.getFeatureIdsInsideFeatureGroups();

    this.featureStates = roadmapEditionStore.features
      .filter((f) => !featureIdsInsideFeatureGroups.includes(f.id))
      .map((f) => this.initializeFeatureState(f));

    this.featureStates.unshift(this.initializeFeatureState(null));

    this.featureGroupStates = [
      ...roadmapEditionStore.featureGroups.map((fg) => this.initializeFeatureGroupState(fg)),
      this.initializeFeatureGroupState(null),
    ];

    const userId = rootStore.authStore.firebaseAuth.currentUser?.uid;
    if (userId) {
      isTutorialSeen(userId, TUTORIALS.roadmapFeatureGroupsCreation).then((seen) => {
        if (!seen) this.tutorialStep = 0;
      });
    }

    makeAutoObservable(this);
  }

  private initializeFeatureState = (feature: Feature | null): FeatureState => {
    return new FeatureState(
      this.roadmapEditionStore,
      feature,
      null,
      {
        onEdit: this.closeOtherFeatures,
        onDelete: this.deleteFeature,
        onCreate: this.addFeature,
        onDuplicate: this.addDuplicatedFeature,
      },
      'delete'
    );
  };

  private initializeFeatureGroupState = (featureGroup: FeatureGroup | null): FeatureGroupState => {
    return new FeatureGroupState(this.roadmapEditionStore, this, featureGroup, {
      onCreate: this.addFeatureGroup,
      onDuplicateFG: this.duplicateFeatureGroup,
      onDelete: this.deleteFeatureGroup,
    });
  };

  get canAddFeatureGroup(): boolean {
    return this.featureGroupStates.findIndex((fg) => fg.id === '') === -1;
  }

  get isNextStepDisabled(): boolean {
    const isAtLeastOneFeatureGroup = this.roadmapEditionStore.featureGroups.length > 0;
    const isAtLeastOneFeature = this.roadmapEditionStore.features.length > 0;

    return !isAtLeastOneFeatureGroup || !isAtLeastOneFeature;
  }

  private getFeatureStateIndexById = (id: string): number => {
    const index = this.featureStates.findIndex((fs) => fs.id === id);
    if (index === -1) throw new Error('No feature index found');
    return index;
  };

  private getFeatureGroupIndexById = (id: string): number => {
    const index = this.featureGroupStates.findIndex((feature) => feature.id === id);
    if (index === -1) throw new Error('No feature group index found');
    return index;
  };

  private getFeatureIdsInsideFeatureGroups = (): string[] => {
    return this.roadmapEditionStore.featureGroups.reduce<string[]>((prev, curr) => {
      return [...prev, ...curr.features.map((f) => f.id)];
    }, []);
  };

  private addFeatureGroup = (): void => {
    const newFg = this.initializeFeatureGroupState(null);
    this.featureGroupStates.push(newFg);
  };

  private duplicateFeatureGroup = (dfg: FeatureGroup, id: string): void => {
    const duplicatedFg = this.initializeFeatureGroupState(dfg);

    // const original = this.featureGroupStates.find((fg) => fg.id === id);
    // console.log({
    //   original: {
    //     name: original?.name,
    //     seq: original?.seq,
    //   },
    //   duplicated: {
    //     name: dfg.name,
    //     seq: dfg.seq,
    //   },
    // });

    this.featureGroupStates.forEach((el) => {
      el.seq !== undefined && dfg.seq !== undefined && el.seq >= dfg.seq && el.incrementSeq();
    });

    // console.log(this.featureGroupStates.map((f) => f.seq));

    const indexOfBiggerSeq = this.featureGroupStates
      .sort((a, b) => (a.seq !== undefined && b.seq !== undefined ? a.seq - b.seq : 0))
      .findIndex((el) => el.seq !== undefined && dfg.seq !== undefined && el.seq >= dfg.seq);

    // console.log(indexOfBiggerSeq);

    this.featureGroupStates.splice(
      indexOfBiggerSeq !== -1 ? indexOfBiggerSeq : this.featureGroupStates.length - 1,
      0,
      duplicatedFg
    );
  };

  deleteFeatureGroup = (id: string): void => {
    const index = this.getFeatureGroupIndexById(id);
    this.featureGroupStates.splice(index, 1);

    //Features that were inside deleted group need to be moved the features column
    const featureIdsInsideFeaturesColumn = this.featureStates.filter((fs) => fs.id).map((fs) => fs.id);
    const featureIdsInsideFeatureGroups = this.getFeatureIdsInsideFeatureGroups();
    const featuresToInitialize = this.roadmapEditionStore.features.filter(
      (f) => !featureIdsInsideFeaturesColumn.includes(f.id) && !featureIdsInsideFeatureGroups.includes(f.id)
    );

    featuresToInitialize.forEach((f) => {
      this.featureStates.push(this.initializeFeatureState(f));
    });
  };

  closeOtherFeatures = (id: string): void => {
    for (const fs of this.featureStates) {
      if (fs.id !== id && fs.state === 'edited' && fs.feature) {
        fs.cancel();
      }
    }
  };

  addFeature = (): void => {
    const newF = this.initializeFeatureState(null);
    this.featureStates.unshift(newF);
  };

  addMultipleFeatures = (features: Feature[]): void => {
    for (const feature of features) {
      const newF = this.initializeFeatureState(feature);
      this.featureStates.push(newF);
    }
  };

  addDuplicatedFeature = (duplicatedFeature: Feature): void => {
    const duplicatedF = this.initializeFeatureState(duplicatedFeature);
    this.featureStates.splice(1, 0, duplicatedF);
  };

  deleteFeature = (id: string): void => {
    const index = this.getFeatureStateIndexById(id);
    this.featureStates.splice(index, 1);
  };

  moveFeatureToDifferentSlot = (
    dragSourceIndex: number,
    dropTargetIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ): void => {
    let deletedFeature: FeatureState | null = null;

    //Drag source
    if (dragSourceColumnIndex === -1) {
      const splicedFeatures = this.featureStates.splice(dragSourceIndex, 1);
      deletedFeature = splicedFeatures[0];
    } else {
      deletedFeature = this.featureGroupStates[dragSourceColumnIndex].extractFeatureFromIndex(dragSourceIndex);
    }

    //Drop target
    if (dropTargetColumnIndex === -1) {
      this.featureStates.splice(dropTargetIndex, 0, deletedFeature);
    } else {
      this.featureGroupStates[dropTargetColumnIndex].insertFeatureOnIndex(dropTargetIndex, deletedFeature);
    }
  };

  moveFeatureToDifferentColumn = (
    dragSourceIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ): number => {
    let deletedFeature: FeatureState | null = null;

    //Drag source
    if (dragSourceColumnIndex === -1) {
      const splicedFeatures = this.featureStates.splice(dragSourceIndex, 1);
      deletedFeature = splicedFeatures[0];
    } else {
      deletedFeature = this.featureGroupStates[dragSourceColumnIndex].extractFeatureFromIndex(dragSourceIndex);
    }

    let newIndex: number | null = null;

    //Drop target
    if (dropTargetColumnIndex === -1) {
      this.featureStates.push(deletedFeature);
      newIndex = this.featureStates.length - 1;
    } else {
      this.featureGroupStates[dropTargetColumnIndex].pushFeature(deletedFeature);
      newIndex = this.featureGroupStates[dropTargetColumnIndex].featureStates.length - 1;
    }

    return newIndex;
  };

  saveFeaturesOrder = async (dragSourceColumnIndex: number, dropTargetColumnIndex: number): Promise<void> => {
    this.roadmapEditionStore.changeIsChangingStepsDisabled(true);

    const isDifferentColumn = dragSourceColumnIndex !== dropTargetColumnIndex;
    const isDragSourceFeaturesColumn = dragSourceColumnIndex === -1;
    const isDropTargetFeaturesColumn = dropTargetColumnIndex === -1;

    //Drag source
    if (isDifferentColumn && !isDragSourceFeaturesColumn) {
      const dragSourceFeatureGroup = this.featureGroupStates[dragSourceColumnIndex];

      const featureGroupId = dragSourceFeatureGroup.id;
      const featureIds = dragSourceFeatureGroup.featureStates.map((fs) => fs.id);

      await this.roadmapEditionStore.reassignFeaturesToFeatureGroup(featureGroupId, featureIds);
      await this.roadmapEditionStore.updateFeaturesSequence(featureIds, featureGroupId);
    }

    //Drop target
    let dropTargetFeatureIds: string[] = [];
    let dropTargetFeatureGroupId: string | undefined = undefined;

    if (isDropTargetFeaturesColumn) {
      dropTargetFeatureIds = this.featureStates.filter((fs) => fs.id).map((fs) => fs.id);
    } else {
      const dropTargetFeatureGroup = this.featureGroupStates[dropTargetColumnIndex];
      dropTargetFeatureIds = dropTargetFeatureGroup.featureStates.map((fs) => fs.id);
      dropTargetFeatureGroupId = dropTargetFeatureGroup.id;

      await this.roadmapEditionStore.reassignFeaturesToFeatureGroup(dropTargetFeatureGroup.id, dropTargetFeatureIds);
    }

    await this.roadmapEditionStore.updateFeaturesSequence(dropTargetFeatureIds, dropTargetFeatureGroupId);

    this.roadmapEditionStore.changeIsChangingStepsDisabled(false);
  };

  moveFeatureGroup = (dragSourceIndex: number, dropTargetIndex: number): void => {
    const deletedFeatureGroup = this.featureGroupStates.splice(dragSourceIndex, 1)[0];
    this.featureGroupStates.splice(dropTargetIndex, 0, deletedFeatureGroup);

    this.featureGroupStates.forEach((fgs, i) => {
      fgs.changeSeq(i);
    });
  };

  saveFeatureGroupOrder = (): void => {
    const featureGroupIds = this.featureGroupStates.filter((fgs) => fgs.id).map((fgs) => fgs.id);
    this.roadmapEditionStore.updateFeatureGroupsSequence(featureGroupIds);
  };

  changeTutorialStep = (step: number): void => {
    this.tutorialStep = step;
  };

  endTutorial = (): void => {
    this.tutorialStep = -1;
    const userId = rootStore.authStore.firebaseAuth.currentUser?.uid;
    if (userId) setTutorialSeen(userId, TUTORIALS.roadmapFeatureGroupsCreation);
  };
}

export default FeatureGroupsCreationStore;
