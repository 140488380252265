import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import OrgnizationsRouteLayoutStore from './OrganizationsRouteLayout.store';

function OrganizationsRouteLayout(): JSX.Element {
  const [store] = useState(() => new OrgnizationsRouteLayoutStore());
  const { isLoading, getOrganizations } = store;

  useEffect(() => {
    getOrganizations();
  }, []);

  if (isLoading) return <Loader />;
  return <Outlet />;
}

export default observer(OrganizationsRouteLayout);
