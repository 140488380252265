import clsx from 'clsx';

import Button from 'src/theme/atoms/buttons/Button/Button';
import { TextArea } from 'src/theme/atoms/inputs';
import { CustomError } from 'src/utils/types/CustomError';

import FeatureStackIcon from '../FeatureStackIcon/FeatureStackIcon';
import styles from './FeatureGroupCard.module.scss';

interface FeatureGroupCardProps {
  name: string;
  featuresCount: number;
  extendedContent?: React.ReactNode;
  featuresManagementContent?: React.ReactNode;
  children?: React.ReactNode;
  errors?: {
    name?: CustomError | null;
  };
  onEdit?: () => void;
  onClose?: () => void;
  onFeatureAdd?: () => void;
  onNameChange?: (name: string) => void;
}

function FeatureGroupCard({
  name,
  featuresCount,
  extendedContent,
  featuresManagementContent,
  children,
  errors,
  onEdit,
  onClose,
  onFeatureAdd,
  onNameChange,
}: FeatureGroupCardProps): JSX.Element {
  const groupNameText = (!onNameChange || !featuresManagementContent) && <p className={styles.name}>{name}</p>;

  const groupNameInput = onNameChange && featuresManagementContent && (
    <TextArea
      color="white"
      name="groupName"
      label="Group Name"
      placeholder="What can they do?"
      size="s"
      className={styles.groupNameInputField}
      textFieldClassName={styles.groupNameInput}
      error={errors?.name}
      value={name}
      onChange={onNameChange}
      disableEnter
    />
  );

  const addFeatureBtn = onFeatureAdd && featuresManagementContent && (
    <Button color="secondary" label="Add a feature" size="s" onClick={onFeatureAdd} />
  );

  return (
    <div className={styles.root}>
      <div className={clsx(styles.main, extendedContent && styles.mainExtended)}>
        <div className={styles.header}>
          <div className={styles.data}>
            <div className={styles.dataInner}>
              <div className={styles.nameBox}>{groupNameInput || groupNameText}</div>
              <div className={styles.featuresManagementActionBox}>
                <button
                  className={styles.featuresManagementActionBtn}
                  onClick={!featuresManagementContent ? onEdit : onClose}
                >
                  <FeatureStackIcon
                    count={featuresCount}
                    variant={featuresManagementContent ? 'outlined' : 'filled'}
                    color="primary"
                  />
                  <span className={styles.featuresManagementActionText}>
                    {!featuresManagementContent ? 'edit' : 'close'}
                  </span>
                </button>
                {addFeatureBtn}
              </div>
            </div>
          </div>
          {children && <div className={styles.slot}>{children}</div>}
        </div>
        {featuresManagementContent && <div className={styles.featuresManagement}>{featuresManagementContent}</div>}
      </div>

      {extendedContent && <div className={styles.extended}>{extendedContent}</div>}
    </div>
  );
}

export default FeatureGroupCard;
