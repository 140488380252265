import { RefObject } from 'react';

import Button from 'src/theme/atoms/buttons/Button/Button';

import styles from './LinkToCopy.module.scss';

interface LinkToCopyProps {
  inputRef: RefObject<HTMLInputElement>;
  shareLink: string;
  onCopyClick: () => void;
}

function LinkToCopy({ inputRef, shareLink, onCopyClick }: LinkToCopyProps): JSX.Element {
  return (
    <div className={styles.root}>
      <input ref={inputRef} color="white" name="shareLink" type="text" value={shareLink} disabled={true} />
      <Button label="Copy link" color="primary" size="l" onClick={onCopyClick} className={styles.copyBtn} />
    </div>
  );
}

export default LinkToCopy;
