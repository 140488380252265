import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { DATE_FORMAT, HOUR_FORMAT } from 'src/utils/constants';

dayjs.extend(utc);

export const formatDate = (date: Dayjs): { date: string; hour: string } => {
  return {
    date: date.format(DATE_FORMAT),
    hour: date.format(HOUR_FORMAT),
  };
};
