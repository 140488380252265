import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { ExternalAudienceState } from 'src/Tests/Test.types';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';

import TestNameAudienceProblem from '../../TestNameAudienceProblem/TestNameAudienceProblem';
import { useTestEditionStore } from '../TestEditionContext';

function TestUpdate(): JSX.Element {
  const navigate = useNavigate();

  const organization = useOrganization();

  const { testStore } = useStores();
  const { cintCategories } = testStore;

  const { test, updateTest } = useTestEditionStore();

  const [name, setName] = useState<string | undefined>(test.name);
  const [externalAudienceState, setExternalAudienceState] = useState<ExternalAudienceState>(test.externalAudienceState);
  const [audienceName, setAudienceName] = useState<string | undefined>(test.audienceName);
  const [audienceCountryId, setAudienceCountryId] = useState<string | undefined>(test.audienceCountryId);
  const [audienceGenderId, setAudienceGenderId] = useState<string | undefined>(test.audienceGenderId);
  const [audienceAgeMin, setAudienceAgeMin] = useState<number | undefined>(test ? test.audienceAgeMin : undefined);
  const [audienceAgeMax, setAudienceAgeMax] = useState<number | undefined>(test ? test.audienceAgeMax : undefined);
  const [audienceIncomeIds, setAudienceIncomeIds] = useState<string[] | undefined>(test.audienceIncomeIds);
  const [problem, setProblem] = useState(test ? test.problem : '');

  const handleSave = (): Promise<void> => {
    const dto = {
      name,
      externalAudienceState,
      audienceName,
      audienceCountryId: audienceCountryId ? { value: audienceCountryId } : { delete: true },
      audienceGenderId: audienceGenderId ? { value: audienceGenderId } : { delete: true },
      audienceAgeMin:
        audienceAgeMin && !(audienceAgeMin === cintCategories?.ageMin && audienceAgeMax === cintCategories?.ageMax)
          ? { value: audienceAgeMin }
          : { delete: true },
      audienceAgeMax:
        audienceAgeMax && !(audienceAgeMin === cintCategories?.ageMin && audienceAgeMax === cintCategories?.ageMax)
          ? { value: audienceAgeMax }
          : { delete: true },
      audienceIncomeIds: audienceIncomeIds?.length ? { value: audienceIncomeIds } : { delete: true },
      problem,
    };
    for (const item in dto) dto[item] = typeof dto[item] === 'string' ? dto[item].trim() : dto[item];

    return updateTest(dto).then(() => {
      navigate(paths.testIdeasManagement(organization.id, test.id));
    });
  };

  return (
    <div>
      <TestNameAudienceProblem
        name={name}
        externalAudienceState={externalAudienceState}
        audienceName={audienceName}
        audienceCountryId={audienceCountryId}
        audienceGenderId={audienceGenderId}
        audienceAgeMin={audienceAgeMin}
        audienceAgeMax={audienceAgeMax}
        audienceIncomeIds={audienceIncomeIds}
        problem={problem}
        onNameChange={(v) => setName(v)}
        onAudienceNameChange={(v) => setAudienceName(v)}
        onAudienceCountryIdChange={(v) => setAudienceCountryId(v)}
        onAudienceGenderIdChange={(v) => setAudienceGenderId(v)}
        onAudienceAgeMinChange={(v) => setAudienceAgeMin(v)}
        onAudienceAgeMaxChange={(v) => setAudienceAgeMax(v)}
        onAudienceIncomeIdsChange={(v) => setAudienceIncomeIds(v as string[])}
        onExternalAudienceState={(v) => setExternalAudienceState(v)}
        onProblemChange={(v) => setProblem(v)}
        onSave={handleSave}
      />
    </div>
  );
}

export default TestUpdate;
