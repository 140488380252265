import { makeAutoObservable } from 'mobx';

import { rootStore } from 'src/RootStore';
import { Organization } from 'src/organizations/Organizations.types';

class OrganizationPageLayoutStore {
  isLoading = true;
  shouldNavigateToDashboard = false;
  isInactiveSubscriptionModalOpen = false;
  organization: Organization | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  ensureOrganizationIsValid = (organization: Organization): void => {
    const isOwner = organization.createdBy === rootStore.authStore.firebaseAuth.currentUser?.uid;
    const { isAdmin } = rootStore.authStore;

    if (!isOwner && !isAdmin) {
      this.shouldNavigateToDashboard = true;
      this.isLoading = false;
      return;
    }

    this.organization = organization;

    if (organization.subscriptionStatus === 'Inactive') {
      this.isInactiveSubscriptionModalOpen = true;
    }

    this.isLoading = false;
  };

  closeInactiveSubscriptionModal = (): void => {
    this.isInactiveSubscriptionModalOpen = false;
  };
}

export default OrganizationPageLayoutStore;
