import Button from 'src/theme/atoms/buttons/Button/Button';
import Container from 'src/theme/atoms/container/Container';

import styles from './NeedHelp.module.scss';

function NeedHelp({ width }: { width?: number }): JSX.Element {
  const handleGuidesClick = (): void => {
    const url = 'https://www.buildfoundations.co/guides';
    window.open(url, '_blank')?.focus();
  };

  const handleHelpClick = (): void => {
    window.open('mailto:info@buildfoundations.co');
  };

  return (
    <div className={styles.root}>
      <Container width={width}>
        <div className={styles.title}>Need help</div>
        <div className={styles.actions}>
          {/*
            <Button label='view templates' size='l' color='secondary' onClick={() => console.log('view templates')} />
          */}
          <Button label="read our guides" size="l" color="secondary" onClick={handleGuidesClick} />
          <Button label="reach out for help" size="l" color="secondary" onClick={handleHelpClick} />
        </div>
      </Container>
    </div>
  );
}

export default NeedHelp;
