import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode, useRef } from 'react';

import FeatureGroupState from '../../FeatureGroupState.store';
import styles from './FeatureGroupColumnAnimationWrapper.module.scss';

interface FeatureGroupColumnAnimationWrapperProps {
  featureGroupState: FeatureGroupState;
  children: ReactNode;
}

function FeatureGroupColumnAnimationWrapper({
  featureGroupState: fgs,
  children,
}: FeatureGroupColumnAnimationWrapperProps): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={clsx(
        styles.root,
        fgs.state === 'deleted' && styles.close,
        (!fgs.id || fgs.state === 'duplicated') && styles.open
      )}
      ref={ref}
    >
      {children}
    </div>
  );
}

export default observer(FeatureGroupColumnAnimationWrapper);
