import { makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';

import * as requests from './Organizations.requests';
import { Organization, OrganizationUser } from './Organizations.types';

class OrganizationsStore {
  rootStore: RootStore;

  isFetchingOrganization = false;
  isFetching = true;
  isSubmitting = false;
  isDeletingOrganization = false;
  organizations: Organization[] = [];
  currentOrganization: Organization | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  resetOrganizationStore = (): void => {
    this.currentOrganization = null;
    this.organizations = [];
  };

  getOrganization = (id: string): Promise<Organization> => {
    this.isFetchingOrganization = true;

    return requests
      .getOrganizations({
        id,
      })
      .then((res) => {
        if (!res.data.length) throw new Error('Organization not found');
        return res.data[0];
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        this.isFetchingOrganization = false;
      });
  };

  getOrganizations = (): Promise<Organization[]> => {
    this.isFetching = true;
    return requests
      .getOrganizations()
      .then((res) => {
        this.organizations = res.data;
        return res.data;
      })
      .finally(() => {
        this.isFetching = false;
      });
  };

  getOrganizationUsers = (organizationId: string): Promise<OrganizationUser[]> => {
    return requests.getOrganizationUsers(organizationId).then((res) => {
      return res.data;
    });
  };

  createOrganization = async (name: string): Promise<Organization> => {
    this.isSubmitting = true;

    return requests.createOrganization(name).then(({ data: createdOrganization }) => {
      return this.getOrganizations()
        .then(() => {
          this.changeCurrentOrganization(createdOrganization);
          return createdOrganization;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    });
  };

  assignUserToOrganization = (organizationId: string, email: string): Promise<void> => {
    return requests.assignUserToOrganization(organizationId, email).then(() => {
      return;
    });
  };

  deassignUserFromOrganization = (organizationId: string, email: string): Promise<void> => {
    return requests.deassignUserFromOrganization(organizationId, email).then(() => {
      return;
    });
  };

  deleteOrganization = (organizationId: string): Promise<void> => {
    this.isDeletingOrganization = true;

    return requests
      .deleteOrganization(organizationId)
      .then(() => {
        return;
      })
      .finally(() => {
        this.isDeletingOrganization = false;
      });
  };

  changeCurrentOrganization = (organization: Organization | null): void => {
    this.currentOrganization = organization;
  };
}

export default OrganizationsStore;
