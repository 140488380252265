import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { ExternalAudienceState } from 'src/Tests/Test.types';

import styles from './WhoseAudience.module.scss';
import WhoseAudienceHelperModal from './WhoseAudienceHelperModal/WhoseAudienceHelperModal';

interface WhoseAudienceProps {
  externalAudienceState: ExternalAudienceState | undefined;
  onExternalAudienceState: (val: ExternalAudienceState) => void;
}

function WhoseAudience({ externalAudienceState, onExternalAudienceState }: WhoseAudienceProps): JSX.Element {
  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.title}>Who's audience would you like to use?</div>
      <div className={styles.options}>
        <button
          className={clsx(styles.optionItem, externalAudienceState === 'unused' && styles.selectedItem)}
          onClick={() => onExternalAudienceState('unused')}
        >
          <div className={styles.icon}>
            <div className={styles.theirAudienceIcon} />
          </div>
          <div className={styles.audienceType}>Your Audience</div>
          <div className={clsx(styles.audienceExplanation, styles.theirAudienceExplanation)}>
            You can embed a test or share a link
          </div>
        </button>
        <button
          className={clsx(
            styles.optionItem,
            externalAudienceState && externalAudienceState !== 'unused' && styles.selectedItem
          )}
          onClick={() =>
            onExternalAudienceState(
              externalAudienceState && externalAudienceState !== 'unused' ? externalAudienceState : 'requested'
            )
          }
        >
          <div className={styles.icon}>
            <div className={styles.ourAudienceIcon}>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                return <div key={el} className={clsx(styles.dot, styles['dot' + el])} />;
              })}
            </div>
          </div>
          <div className={styles.audienceType}>Our Audience</div>
          <div className={clsx(styles.audienceExplanation, styles.ourAudienceExplanation)}>
            You can run a test against our panel of users
          </div>
        </button>
      </div>
      <button className={styles.whatShoudIDo} onClick={() => setIsHelperModalOpen(true)}>
        WHAT SHOULD I DO?
      </button>
      {isHelperModalOpen && <WhoseAudienceHelperModal onClose={() => setIsHelperModalOpen(false)} />}
    </div>
  );
}

export default observer(WhoseAudience);
