import { observer } from 'mobx-react-lite';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams, Outlet, useSearchParams } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import getRandomId from '../utils/getRandomId';
import styles from './Quiz.module.scss';
import { QuizDto } from './Quiz.types';

export const RESPONDENT_ID_KEY = 'respondentId';
export const CINT_RESPONDENT_ID = 'cintRespondentId';

interface QuizContextValue {
  quiz: QuizDto;
  changeQuiz: (quiz: QuizDto) => void;
  respondentId: string;
  isRespondentFromCint?: boolean;
}

const QuizContext = createContext<QuizContextValue | undefined>(undefined);

export const useQuiz = (): QuizContextValue => {
  const Quiz = useContext(QuizContext);
  if (Quiz === undefined) {
    throw new Error('useQuiz must be used within QuizContext');
  }
  return Quiz;
};

function QuizLayout(): JSX.Element {
  const { testId } = useParams();
  if (!testId) throw new Error('no testId');

  const [searchParams] = useSearchParams();

  const { quizStore } = useStores();

  const [quiz, setQuiz] = useState<QuizDto | null>(null);
  const [respondentId, setRespondentId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let id = searchParams.get(CINT_RESPONDENT_ID) || localStorage.getItem(RESPONDENT_ID_KEY);

    if (!id) {
      id = getRandomId();
      localStorage.setItem(RESPONDENT_ID_KEY, id);
    }

    setRespondentId(id);
  }, []);

  useEffect(() => {
    if (respondentId) {
      quizStore
        .fetchQuiz(testId, respondentId)
        .then((quiz) => {
          setQuiz(quiz);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [respondentId]);

  const changeQuiz = (quiz: QuizDto): void => {
    setQuiz(quiz);
  };

  if (isLoading || !respondentId) return <Loader />;

  if (!quiz) {
    return (
      <div className={styles.root}>
        <p className={styles.noQuizInformation}>
          This test is not currently live. <br />
          Contact the test owner if you think this is a mistake.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <QuizContext.Provider
        value={{
          quiz,
          changeQuiz,
          respondentId,
          isRespondentFromCint: Boolean(searchParams.get(CINT_RESPONDENT_ID)),
        }}
      >
        <Outlet />
      </QuizContext.Provider>
    </div>
  );
}

export default observer(QuizLayout);
