import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useRoadmapEditionStore } from 'src/roadmap/RoadmapEdition/RoadmapEditionContext';
import RoadmapStepHeader from 'src/roadmap/RoadmapStepHeader/RoadmapStepHeader';
import { ColumnsScrollArrows } from 'src/theme/atoms/groupCreation';
import { ColumnsBox } from 'src/theme/atoms/groupCreation';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import { Feature } from 'src/utils/types/FeatureRelated';

import DragLayer from './DragLayer/DragLayer';
import FeatureGroupColumn from './FeatureGroupColumn/FeatureGroupColumn';
import FeatureGroupCreationHelperModal from './FeatureGroupCreationHelperModal/FeatureGroupCreationHelperModal';
import styles from './FeatureGroupsCreation.module.scss';
import FeatureGroupsCreationStore from './FeatureGroupsCreation.store';
import FeaturesColumn from './FeaturesColumn/FeaturesColumn';
import UploadFeaturesModal from './UploadFeaturesModal/UploadFeaturesModal';

function FeatureGroupsCreation(): JSX.Element {
  const roadmapEditionStore = useRoadmapEditionStore();
  const columnsRef = useRef<HTMLDivElement | null>(null);

  const { roadmap, featuresCount, featureGroupsCount, changeIsNextStepDisabled } = roadmapEditionStore;

  const [store] = useState(() => new FeatureGroupsCreationStore(roadmapEditionStore));
  const {
    featureStates,
    featureGroupStates,
    isNextStepDisabled,
    addMultipleFeatures,
    moveFeatureToDifferentSlot,
    moveFeatureToDifferentColumn,
    saveFeaturesOrder,
    moveFeatureGroup,
    saveFeatureGroupOrder,
    changeTutorialStep,
    endTutorial,
    tutorialStep,
  } = store;

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState<string>('');

  const openUploadModal = (): void => setIsUploadModalOpen(true);
  const closeUploadModal = (): void => setIsUploadModalOpen(false);

  const openHelperModal = (): void => setIsHelperModalOpen(true);
  const closeHelperModal = (): void => setIsHelperModalOpen(false);

  useEffect(() => {
    changeIsNextStepDisabled(isNextStepDisabled);
  }, [isNextStepDisabled]);

  const uploadModal = isUploadModalOpen && (
    <UploadFeaturesModal
      onSuccess={(uploadedFeaturesNumber: number, newFeatures: Feature[]) => {
        addMultipleFeatures(newFeatures);
        saveFeaturesOrder(-1, -1).then(() => {
          // update seq
          closeUploadModal();
          setSnackbarMsg(`${uploadedFeaturesNumber} feature${uploadedFeaturesNumber === 1 ? '' : 's'} uploaded`);
        });
      }}
      onClose={closeUploadModal}
    />
  );

  return (
    <div>
      <div className={styles.root}>
        <RoadmapStepHeader
          roadmapName={roadmap.name}
          title={
            <>
              Gather all your features.
              <br />
              Group by user outcomes.
            </>
          }
          subtitle="Start listing features or start with user outcomes."
          helpButtonConfig={{
            label: 'What should I do?',
            action: openHelperModal,
          }}
          actionButtonConfig={{
            label: 'Upload features',
            action: openUploadModal,
          }}
          isBottomDivider={false}
        />
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.infoBox}>
              <div className={styles.infoBoxTexts}>
                <span>
                  <span>{featuresCount}</span> Features
                </span>
                <span>
                  <span>{featureGroupsCount}</span> User Needs Defined
                </span>
              </div>
            </div>
          </div>

          <DndProvider backend={HTML5Backend}>
            <DragLayer />
            <ColumnsBox ref={columnsRef}>
              <ColumnsScrollArrows columnsRef={columnsRef} />
              <FeaturesColumn
                featureStates={featureStates}
                moveFeatureToDifferentColumn={moveFeatureToDifferentColumn}
                moveFeatureToDifferentSlot={moveFeatureToDifferentSlot}
                onDrop={saveFeaturesOrder}
                changeTutorialStep={changeTutorialStep}
                endTutorial={endTutorial}
                currentTutorialStep={tutorialStep}
                horizontalScrollRef={columnsRef}
              />
              {featureGroupStates.map((fgs, i) => (
                <FeatureGroupColumn
                  key={fgs.localId}
                  columnIndex={i}
                  featureGroupState={fgs}
                  moveFeatureToDifferentColumn={moveFeatureToDifferentColumn}
                  moveFeatureToDifferentSlot={moveFeatureToDifferentSlot}
                  onFeatureDrop={saveFeaturesOrder}
                  moveFeatureGroupToDifferentSlot={moveFeatureGroup}
                  onFeatureGroupDrop={saveFeatureGroupOrder}
                  changeTutorialStep={changeTutorialStep}
                  endTutorial={endTutorial}
                  currentTutorialStep={tutorialStep}
                  horizontalScrollRef={columnsRef}
                />
              ))}
            </ColumnsBox>
            {uploadModal}
          </DndProvider>
        </div>
      </div>
      <Snackbar type="success" message={snackbarMsg} onClose={() => setSnackbarMsg('')} autoclose />
      {isHelperModalOpen && <FeatureGroupCreationHelperModal onClose={closeHelperModal} />}
    </div>
  );
}

export default observer(FeatureGroupsCreation);
