import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import FeatureState from 'src/roadmap/FeatureState.store';
import { FEATURE_CARD_DND_ITEM } from 'src/utils/constants';

import FeatureCard from '../../components/FeatureCard/FeatureCard';
import { FeatureCardDnDItem } from '../FeatureCardDnDItem.type';
import styles from './FeatureCardDnD.module.scss';

interface FeatureCardDnDProps {
  index: number;
  columnIndex: number;
  featureState: FeatureState;
  move: (
    dragSourceIndex: number,
    dropTargetIndex: number,
    dragSourceColumnIndex: number,
    dropTargetColumnIndex: number
  ) => void;
  onDrop?: (index: number, columnIndex: number) => void;
}

function FeatureCardDnD({
  index,
  columnIndex,
  featureState: fs,
  move,
  onDrop,
}: FeatureCardDnDProps): JSX.Element | null {
  const ref = useRef<HTMLDivElement>(null);

  const { id } = fs;

  const [, drop] = useDrop<FeatureCardDnDItem, void, { isOver: boolean }>({
    accept: FEATURE_CARD_DND_ITEM,
    hover: (item, monitor) => {
      if (!ref.current) return;
      if (index === 0 && columnIndex === -1) return;
      if (id === item.id) return;

      const dragSourceIndex = item.index;
      const dropTargetIndex = index;

      const dropTargetParams = ref.current.getBoundingClientRect();
      const halfHeightOfDropTarget = (dropTargetParams.bottom - dropTargetParams.top) / 2;
      const mousePosition = monitor.getClientOffset();
      if (!mousePosition) return;

      const hoverClientY = mousePosition.y - dropTargetParams.top;

      if (columnIndex === item.columnIndex) {
        const shouldDropBelow = dragSourceIndex < dropTargetIndex && hoverClientY < halfHeightOfDropTarget;
        if (shouldDropBelow) return;

        const shouldDropAbove = dragSourceIndex > dropTargetIndex && hoverClientY > halfHeightOfDropTarget;
        if (shouldDropAbove) return;
      }

      const up = hoverClientY > halfHeightOfDropTarget;
      const isDifferentColumn = columnIndex !== item.columnIndex;
      const newDropTargetIndex = up && isDifferentColumn ? dropTargetIndex + 1 : dropTargetIndex;

      move(dragSourceIndex, newDropTargetIndex, item.columnIndex, columnIndex);

      if (item.prevIndex === null) item.prevIndex = item.index;
      if (item.prevColumnIndex === null) item.prevColumnIndex = item.columnIndex;
      item.index = newDropTargetIndex;
      item.columnIndex = columnIndex;
    },
    canDrop: () => !(index === 0 && columnIndex === -1),
    drop: (item) => {
      const isSameSlot = item.prevColumnIndex === item.columnIndex && item.prevIndex === item.index;

      if (onDrop && item.prevColumnIndex !== null && !isSameSlot) {
        onDrop(item.prevColumnIndex, columnIndex);
      }

      item.prevIndex = null;
      item.prevColumnIndex = null;
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag<FeatureCardDnDItem, void, { isDragging: boolean }>({
    type: FEATURE_CARD_DND_ITEM,
    item: () => ({
      id,
      index,
      prevIndex: null,
      columnIndex,
      prevColumnIndex: null,
      featureState: fs,
      ref,
    }),
    isDragging: (monitor) => monitor.getItem().id === id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !!fs.feature && fs.state !== 'edited',
  });

  useEffect(() => {
    //Fix for DnD bug on firefox - set draggable to false so the cursor can be put in the correct place (doesn't work for first click)
    if (ref.current) {
      ref.current.setAttribute('draggable', `${fs.state !== 'edited'}`);
    }
  }, [fs.state === 'edited']);

  useEffect(() => {
    drag(drop(ref));
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      className={clsx(styles.root, isDragging && styles.placeholder)}
      ref={ref}
      onClick={() => ref.current?.setAttribute('draggable', `${fs.state !== 'edited'}`)}
      //Fix for DnD bug on firefox - set draggable to false so the cursor can be put in the correct place
    >
      <div className={clsx(isDragging && styles.hidden)}>
        <FeatureCard featureState={fs} />
      </div>
    </div>
  );
}

export default observer(FeatureCardDnD);
