export const LOGIN_STORAGE_PREFIX = 'alignment__auth_';
export const DATE_FORMAT = 'MM/DD/YY';
export const HOUR_FORMAT = 'h:mmA';
export const ROADMAP_STEPS_COUNT = 4;
export const TEST_STEPS_COUNT = 2;
export const FEATURE_CARD_DND_ITEM = 'FEATURE_CARD_DND_ITEM';
export const FEATURE_GROUP_COLUMN_DND_ITEM = 'FEATURE_GROUP_COLUMN_DND_ITEM';
export const IDEA_CARD_DND_ITEM = 'IDEA_CARD_DND_ITEM';
export const IDEA_GROUP_COLUMN_DND_ITEM = 'IDEA_GROUP_COLUMN_DND_ITEM';
export const WEBSITE_TESTS_RESULTS_METHODOLOGY = 'https://www.buildfoundations.co/tests-results-methodology';
export const BACK_TO_HOME_BTN = 'back to home';
export const ADD_TEAM_MEMBERS_BTN = 'add team members';
