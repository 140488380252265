import debounce from 'lodash.debounce';
import { makeAutoObservable } from 'mobx';

import { CustomError } from 'src/utils/types/CustomError';
import { Feature, FeatureGroup, ProductType } from 'src/utils/types/FeatureRelated';

import FeatureState from '../../FeatureState.store';
import { FeatureGroupDto } from '../../Roadmap.types';
import RoadmapEditionStore from '../RoadmapEdition.store';
import FeatureGroupsUrgency from './FeatureGroupsUrgency.store';

class FeatureGroupUrgencyState {
  roadmapEditionStore: RoadmapEditionStore;
  store: FeatureGroupsUrgency;
  featureGroup: FeatureGroup;

  isFeaturesManagementOpen = false;

  isForToday: FeatureGroup['isForToday'];
  productType: FeatureGroup['productType'];
  productNames: string[];

  name = '';
  featureStates: FeatureState[] = [];

  errors: { name: CustomError | null } = {
    name: null,
  };

  constructor(roadmapEditionStore: RoadmapEditionStore, store: FeatureGroupsUrgency, featureGroup: FeatureGroup) {
    this.roadmapEditionStore = roadmapEditionStore;
    this.store = store;
    this.featureGroup = featureGroup;

    this.isForToday = featureGroup.isForToday;
    this.productType = featureGroup.productType;
    this.productNames = featureGroup.productNames || [];

    this.name = featureGroup.name;
    this.featureStates = featureGroup.features.map((f) => this.initializeFeatureState(f));

    makeAutoObservable(this);
  }

  private initializeFeatureState = (feature: Feature | null): FeatureState => {
    return new FeatureState(
      this.roadmapEditionStore,
      feature,
      this.featureGroup.id,
      {
        onUpdate: this.store.updateFeature,
        onDeassign: this.deleteFeature,
        onEdit: this.editFeature,
      },
      'deassign'
    );
  };

  private toSave = (): FeatureGroupDto => {
    return {
      name: this.name,
      isForToday: this.isForToday,
      productType: this.productType,
      productTypeClear: !this.productType,
      productNames: this.productNames,
    };
  };

  private cancelActiveFeature = (ignoreId?: string): void => {
    const editedFeatureStates = this.featureStates.filter(
      (fState) => fState.state === 'edited' && (ignoreId ? fState.id !== ignoreId : true)
    );

    for (const fState of editedFeatureStates) {
      if (fState.isSaved) {
        fState.cancel();
      } else {
        if (fState.name === '' || fState.description === '') {
          this.deleteFeature(fState.id);
        } else {
          fState.save();
        }
      }
    }
  };

  save = debounce((dto: FeatureGroupDto): void => {
    this.errors.name = null;

    if (!dto.name) {
      this.errors.name = {
        message: "Feature group name can't be empty",
      };
      return;
    }

    this.roadmapEditionStore
      .updateFeatureGroup(this.featureGroup.id, dto)
      .then((updatedFg) => {
        this.featureGroup = updatedFg;
      })
      .catch((err: CustomError) => {
        this.errors.name = err;
      })
      .finally(() => this.roadmapEditionStore.setSendingRequest(false));
  }, 500);

  openFeaturesManagement = (): void => {
    this.isFeaturesManagementOpen = true;
  };

  closeFeaturesManagement = (): void => {
    this.isFeaturesManagementOpen = false;
    this.errors.name = null;
    this.name = this.featureGroup.name;
    this.featureStates = this.featureStates.filter((fState) => fState.isSaved);
  };

  changeIsForToday = (isForToday: boolean): void => {
    this.roadmapEditionStore.setSendingRequest(true);

    this.isForToday = isForToday;
    this.productType = undefined;
    this.productNames = [];

    this.save(this.toSave());
  };

  changeProductType = (productType: ProductType): void => {
    this.roadmapEditionStore.setSendingRequest(true);

    this.productType = productType;
    this.productNames = [];

    this.save(this.toSave());
  };

  changeProductNames = (productNames: string[]): void => {
    this.roadmapEditionStore.setSendingRequest(true);

    this.productNames = productNames;

    this.save(this.toSave());
  };

  changeName = (value: string): void => {
    this.name = value;
    this.save(this.toSave());
  };

  addFeature = (): void => {
    this.cancelActiveFeature();
    const featureState = this.initializeFeatureState(null);
    this.featureStates.unshift(featureState);
  };

  updateFeature = (feature: Feature): void => {
    const featureIndex = this.featureStates.findIndex((f) => f.id === feature.id);
    if (featureIndex === -1) throw new Error('No feature');

    this.featureStates[featureIndex].updateByFeature(feature);
  };

  deleteFeature = (fId: string): void => {
    const index = this.featureStates.findIndex((fs) => fs.id === fId);
    if (index === -1) throw new Error("Couldn't find feature");

    this.featureStates.splice(index, 1);
  };

  editFeature = (id: string): void => {
    this.cancelActiveFeature(id);
  };
}

export default FeatureGroupUrgencyState;
