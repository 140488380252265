import { createContext, useContext } from 'react';

import { Organization } from '../Organizations.types';

export const OrganizationRouteLayoutContext = createContext<Organization | undefined>(undefined);

export const useOrganization = (): Organization => {
  const organization = useContext(OrganizationRouteLayoutContext);

  if (organization === undefined) {
    throw new Error('useOrganization must be used within OrganizationContext');
  }

  return organization;
};
