import clsx from 'clsx';
import { CSSProperties } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import FeatureCard from '../../components/FeatureCard/FeatureCard';
import { FeatureCardDnDItem } from '../FeatureCardDnDItem.type';
import { FeatureGroupColumnDnDItem } from '../FeatureGroupColumn/FeatureGroupColumnDnDItem.type';
import StaticFeatureGroupColumn from '../FeatureGroupColumn/StaticFeatureGroupColumn';
import { FEATURES_COLUMN_ID } from '../FeaturesColumn/FeaturesColumn';
import { FEATURES_DROP_AREA_CONTENT_ID } from '../FeaturesDropArea/FeaturesDropArea';
import styles from './DragLayer.module.scss';

const getItemStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

function DragLayer(): JSX.Element | null {
  const { initialOffset, currentOffset, isDragging, ...restDragLayerProps } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const item = restDragLayerProps.item as FeatureCardDnDItem | FeatureGroupColumnDnDItem;

  const renderItem = (): JSX.Element | null => {
    const featuresColumn = document.getElementById(FEATURES_COLUMN_ID);
    if (!featuresColumn) throw new Error("Couldn't find features column element");
    const featuresDropAreaContent = document.getElementById(FEATURES_DROP_AREA_CONTENT_ID);
    if (!featuresDropAreaContent) throw new Error("Couldn't find features drop area content element");

    if ('featureState' in item) {
      //Dragging feature card
      const { width } = featuresDropAreaContent.getBoundingClientRect();

      return (
        <div style={{ width }}>
          <FeatureCard featureState={item.featureState} className={styles.previewOverlay} />
        </div>
      );
    }

    if ('featureGroupState' in item) {
      //Dragging feature group column
      const { width, height } = featuresColumn.getBoundingClientRect();

      return (
        <div style={{ width, height }} className={clsx(styles.previewOverlay, styles.featureGroupPreview)}>
          <StaticFeatureGroupColumn featureGroupState={item.featureGroupState} />
        </div>
      );
    }

    return null;
  };

  if (!isDragging) return null;

  return (
    <div className={styles.root}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  );
}

export default DragLayer;
