import clsx from 'clsx';

import { QuizDto } from '../Quiz.types';
import styles from './QuizIntroduction.module.scss';
import acceptIcon from './assets/accept.png';
import rejectIcon from './assets/reject.png';

interface ButtonData {
  className: string;
  imgSrc: string;
  isApproving: boolean;
}

interface QuizIntroductionProps {
  onNext: (ans: boolean) => void;
  isPreview?: boolean;
  quiz: QuizDto;
}

function QuizIntroduction({ onNext, isPreview, quiz }: QuizIntroductionProps): JSX.Element {
  const buttons: ButtonData[] = [
    {
      className: styles.reject,
      imgSrc: rejectIcon,
      isApproving: false,
    },
    {
      className: styles.accept,
      imgSrc: acceptIcon,
      isApproving: true,
    },
  ];

  return (
    <div className={clsx(isPreview ? styles.preview : styles.root)}>
      <p className={styles.header}>Does this interest you?</p>
      <div className={styles.content}>
        <div className={styles.imageBox}>
          {quiz.imageUrl ? <img src={quiz.imageUrl} alt="quiz thumbnail" /> : <div className={styles.placeholder} />}
        </div>
        <p className={styles.quizIdeaText}>{quiz.idea.text}</p>
      </div>
      <div className={styles.buttonsGroup}>
        {buttons.map((btn, i) => (
          <div key={i} className={styles.buttonContainer} onClick={() => onNext(btn.isApproving)}>
            <button className={clsx(styles.btn, btn.className)} />
            <img src={btn.imgSrc} alt={btn.isApproving ? 'accept' : 'reject'} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuizIntroduction;
