import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { CintCategories, CintCategoryBasic } from 'src/Tests/Test.types';

import styles from './AudienceDetailsNoneditable.module.scss';

const selectedItem = (
  items: CintCategoryBasic[] | undefined,
  selectedIds: string | string[]
): CintCategoryBasic | CintCategoryBasic[] | undefined => {
  if (!items) return undefined;
  return Array.isArray(selectedIds)
    ? items.filter((item) => {
        return selectedIds.map((selectedId) => selectedId === item.id).filter((el) => el).length;
      })
    : items.find((item) => item.id === selectedIds);
};

interface AudienceDetailsNoneditableProps {
  cintCategories: CintCategories | undefined;
  handleEditClick: () => void;
  audienceName: string | undefined;
  audienceCountryId: string | undefined;
  audienceGenderId: string | undefined;
  audienceAgeMin: number | undefined;
  audienceAgeMax: number | undefined;
  audienceIncomeIds: string[] | undefined;
}

function AudienceDetailsNoneditable({
  cintCategories,
  handleEditClick,
  audienceName,
  audienceCountryId,
  audienceGenderId,
  audienceAgeMin,
  audienceAgeMax,
  audienceIncomeIds,
}: AudienceDetailsNoneditableProps): JSX.Element {
  return (
    <div className={styles.root}>
      <div className={styles.yourAudienceLine}>
        <div className={styles.yourAudience}>Your audience</div>
        <div className={styles.edit} onClick={handleEditClick}>
          Edit
        </div>
      </div>
      <div className={styles.audienceName}>{audienceName}</div>
      <div className={styles.audienceInfoBoxWrapper}>
        <div className={styles.audienceInfoBox}>
          <div className={styles.audienceInfoPiece}>
            <div className={styles.copy}>
              <div className={styles.title}>Country: </div>
              <div className={styles.selectedItems}>
                {!audienceCountryId || !cintCategories
                  ? 'any'
                  : cintCategories.audienceCountry.find((el: CintCategoryBasic) => el.id === audienceCountryId)?.name}
              </div>
            </div>
            {/* <div className={styles.reset} onClick={handleEditClick}>
                <CloseIcon />
              </div> */}
          </div>

          <div className={styles.audienceInfoPiece}>
            <div className={clsx(styles.copy, styles.copyIncome)}>
              <div className={styles.title}>Household income:</div>
              <div className={styles.selectedItems}>
                {!audienceIncomeIds?.length || !cintCategories
                  ? 'any'
                  : (
                      selectedItem(
                        cintCategories.audienceCountry.find((el) => el.id === audienceCountryId)?.income,
                        audienceIncomeIds
                      ) as CintCategoryBasic[]
                    )
                      ?.map((el) => el.name)
                      .join(', ')}
              </div>
            </div>
            {/* <div className={styles.reset} onClick={handleEditClick}>
            <CloseIcon />
          </div> */}
          </div>

          <div className={styles.audienceInfoPiece}>
            <div className={styles.copy}>
              Age:{' '}
              {(!audienceAgeMin && !audienceAgeMax) ||
              (audienceAgeMin === cintCategories?.ageMin && audienceAgeMax === cintCategories?.ageMax)
                ? 'any'
                : `${audienceAgeMin || ''} ${audienceAgeMax ? '- ' + audienceAgeMax : ''}`}
            </div>
            {/* <div className={styles.reset} onClick={handleEditClick}>
            <CloseIcon />
          </div> */}
          </div>

          <div className={styles.audienceInfoPiece}>
            <div className={styles.copy}>
              <div className={styles.title}>Gender: </div>
              <div className={styles.selectedItems}>
                {!audienceGenderId || !cintCategories
                  ? 'any'
                  : cintCategories.audienceGender.find((el: CintCategoryBasic) => el.id === audienceGenderId)?.name}
              </div>
            </div>
            {/* <div className={styles.reset} onClick={handleEditClick}>
                <CloseIcon />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default observer(AudienceDetailsNoneditable);
