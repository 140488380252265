import env from 'src/utils/env';

import { Chargebee } from './utils/types/Chargebee';

function initialize(): void {
  window.Chargebee?.init({
    site: env.chargebeeSite,
    domain: env.chargebeeDomain,
  });
}

function getChargebee(): Chargebee {
  if (!window.Chargebee) {
    const error = 'window.Chargebee is not available';
    throw error;
  }
  return window.Chargebee;
}

export const chargebee = {
  initialize,
  getChargebee,
};
