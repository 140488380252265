import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useStores } from 'src/RootStore';
import { Plan } from 'src/plans/Plans.types';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';

import OrganizationPageLayout from '../OrganizationPageLayout/OrganizationPageLayout';
import { useOrganization } from '../OrganizationRouteLayout/OrganizationRouteLayout.context';
import styles from './OrganizationPlans.module.scss';
import OrganizationPlansStore from './OrganizationPlans.store';

const perks: { text: string; propName: string; altPropName?: string }[] = [
  {
    text: 'Roadmaps you can create:',
    propName: 'roadmapQuota',
  },
  {
    text: 'Tests you can run:',
    propName: 'testQuota',
  },
  {
    text: 'Initial users:',
    propName: 'userQuota',
    altPropName: 'priceIncludeUserCount',
  },
];

const audienceData = [
  { text: 'Number of ideas', limit: 'You decide' },
  { text: 'Number of testers provided ', limit: '33 per idea' },
  { text: 'Price', limit: '$16.50 per idea' },
];

function OrganizationPlans(): JSX.Element {
  const organization = useOrganization();

  const { plansStore } = useStores();
  const { plans, isOpeningPortalSession } = plansStore;

  const [store] = useState(() => new OrganizationPlansStore(organization));
  const {
    isLoading,
    snackbarConfig,
    handleCleanup,
    handleInitialRender,
    checkout,
    getSubmitPlanBtnText,
    isSubmitPlanBtnDisabled,
    closeSnackbar,
    manageSubscriptions,
  } = store;

  const subscriptionName = plans.find((el) => el.id === organization.subscriptionPricingId)?.planName;
  const isSubscriptionMostExpensive = plans.find((el) => el.seq === plans.length)?.planName === subscriptionName;

  useEffect(() => {
    handleInitialRender();
    return () => handleCleanup();
  }, []);

  if (!plans || plans.length !== 3) return <></>;

  const renderPerkListItem = (left: string, right: string): JSX.Element => (
    <div className={styles.perk} key={left}>
      <span className={styles.left}>{left}</span>
      <span className={styles.right}>{right}</span>
    </div>
  );

  const renderPlan = (plan: Plan): JSX.Element => (
    <div className={styles.plan}>
      <div className={styles.header}>
        <span>{plan.planName}</span>
        {!!plan.price && <span>{'$' + plan.price / 100}</span>}
      </div>
      <ul className={styles.perksList}>
        {perks.map((perk) =>
          renderPerkListItem(
            perk.text,
            plan.limit[perk.propName] ?? (perk.altPropName ? plan[perk.altPropName] : 'unlimited')
          )
        )}
        {plan.priceForAdditionalUser && plan.priceIncludeUserCount && (
          <>
            <div className={styles.rule} />
            {renderPerkListItem(
              `for each additional\nuser past ${plan.priceIncludeUserCount}:`,
              `+ $${plan.priceForAdditionalUser / 100}`
            )}
          </>
        )}
      </ul>
      <Button
        label={getSubmitPlanBtnText(plan.id)}
        color="primary"
        size="m"
        className={styles.submitPlanBtn}
        disabled={isSubmitPlanBtnDisabled(plan.id)}
        onClick={() => checkout(plan.id)}
      />
    </div>
  );

  const audienceInfo = (
    <div className={styles.audienceWrapper}>
      <div className={styles.subheader}>
        <div className={styles.titlesWrapper}>
          <h2 className={styles.title}>À la carte audience</h2>
          <div className={clsx(styles.subtitle, styles.subtitle1)}>
            If you do not have enough real people to test your idea you can use our audience. This purchase is à la
            carte on a test by test basis.
          </div>
        </div>
        <Button
          label="Manage your payment method"
          color="secondary"
          onClick={() => manageSubscriptions()}
          size="m"
          disabled={isOpeningPortalSession}
        />
      </div>
      <div className={styles.planList}>
        <div className={clsx(styles.planBox, styles.audienceBox)}>
          <div className={styles.plan}>
            <div className={styles.header}>
              <span>How it works</span>
            </div>
            <ul className={styles.perksList}>
              {audienceData.map((perk) => renderPerkListItem(perk.text, perk.limit))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  const planList = (
    <div className={styles.planListWrapper}>
      <div className={styles.subheader}>
        <div className={styles.titlesWrapper}>
          <h2 className={styles.title}>Software subscription</h2>
          <div className={clsx(styles.subtitle, styles.subtitle2)}>
            You can try out Foundations software for free. Once you decide you want to use the software beyond the free
            plan you can subscribe to use it on a monthly or annual basis.
          </div>
        </div>
        <Button
          label="Manage your subscriptions"
          color="secondary"
          onClick={() => manageSubscriptions()}
          size="m"
          disabled={isOpeningPortalSession}
        />
      </div>
      <div className={styles.planList}>
        <div className={styles.planBox}>{renderPlan(plans[0])}</div>
        <div className={styles.planBox}>
          {renderPlan(plans[1])}
          <div className={styles.xsRule} />
          {renderPlan(plans[2])}
        </div>
      </div>
    </div>
  );

  return (
    <OrganizationPageLayout>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <div>
              <h2>
                Your current plan
                {subscriptionName ? ': ' + subscriptionName : ''} for {organization.name}
              </h2>
            </div>
          </div>
          <div className={styles.plans}>{isLoading ? <Loader /> : planList}</div>
          <div>{audienceInfo}</div>
        </div>

        <Snackbar
          type={snackbarConfig.type}
          message={snackbarConfig.message}
          onClose={closeSnackbar}
          autoclose={false}
        />
      </div>
    </OrganizationPageLayout>
  );
}

export default observer(OrganizationPlans);
