import { makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';
import { chargebee } from 'src/chargebee';

import * as requests from './Plans.requests';
import { Plan, PaymentSources } from './Plans.types';

export const FREE_PLAN_ID = 'Align-Free-USD-Every-3-months';
export const PAID_MONTHLY_PLAN_ID = 'Align-Paid-USD-Every-3-months';
export const PAID_YEARLY_PLAN_ID = 'Align-Paid-USD-Yearly';

// const plansOrder = [FREE_PLAN_ID, PAID_MONTHLY_PLAN_ID, PAID_YEARLY_PLAN_ID];

class PlansStore {
  rootStore: RootStore;

  plans: Plan[] = [];

  isFetchingPlans = false;
  isOpeningPortalSession = false;
  isCheckoutingPlan = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getPlans = (): Promise<Plan[]> => {
    this.isFetchingPlans = true;

    return requests
      .getPlans()
      .then((res) => {
        this.plans = res.data;
        return this.plans;
      })
      .catch((err) => {
        alert('Something went wrong');
        console.error(err);
        return err;
      })
      .finally(() => {
        this.isFetchingPlans = false;
      });
  };

  checkoutPlan = (
    organizationId: string,
    id: string,
    {
      onSuccess,
      onClose,
      onError,
    }: {
      onSuccess: () => void;
      onClose: () => void;
      onError: (error: any) => void;
    }
  ): Promise<void> => {
    this.isCheckoutingPlan = true;

    return requests
      .checkoutPlan(organizationId, id)
      .then(({ data }) => {
        new Promise((resolve, reject) => {
          chargebee
            .getChargebee()
            .getInstance()
            .openCheckout({
              hostedPage() {
                return Promise.resolve(data);
              },
              success() {
                onSuccess();
                resolve({});
              },
              close() {
                onClose();
                resolve({ cancelled: true });
              },
              error(error) {
                onError(error);
                reject(error);
              },
            });
        });
      })
      .catch((err) => {
        // alert('Something went wrong');
        console.error(err);
        throw err;
      })
      .finally(() => {
        this.isCheckoutingPlan = false;
      });
  };

  openPortalSession = (
    organizationId: string,
    {
      onClose,
      onError,
    }: {
      onClose?: () => void;
      onError: (error: any) => void;
    }
  ): Promise<void> => {
    this.isOpeningPortalSession = true;

    return requests
      .getPortalSession(organizationId)
      .then(({ data }) => {
        chargebee
          .getChargebee()
          .getInstance()
          .setPortalSession(() => {
            return Promise.resolve(data);
          });

        chargebee
          .getChargebee()
          .getInstance()
          .createChargebeePortal()
          .open({
            close: () => {
              onClose && onClose();
            },
            error: (error) => {
              onError(error);
            },
          });
      })
      .catch((err) => {
        alert('Something went wrong');
        console.error(err);
        throw err;
      })
      .finally(() => {
        this.isOpeningPortalSession = false;
      });
  };

  getPaymentSources = (organizationId: string): Promise<PaymentSources> => {
    return requests.getPaymentSources(organizationId).then(({ data }) => {
      return data;
    });
  };
}

export default PlansStore;
