import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react-lite';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom';

import Dashboard from 'src/Dashboard';
import { useStores } from 'src/RootStore';
import { TestLayout } from 'src/Tests';
import AudienceCint from 'src/Tests/AudienceCint';
import AudienceShareLink from 'src/Tests/AudienceShareLink';
import TestCreate from 'src/Tests/TestCreate/TestCreate';
import IdeasManagement from 'src/Tests/TestEdition/IdeasManagement';
import TestEdition from 'src/Tests/TestEdition/TestEdition';
import TestStepsLayout from 'src/Tests/TestEdition/TestStepsLayout';
import TestSummary from 'src/Tests/TestEdition/TestSummary';
import TestUpdate from 'src/Tests/TestEdition/TestUpdate/TestUpdate';
import TestResults from 'src/Tests/TestResults';
import Login from 'src/auth/login';
import RequestNewPassword from 'src/auth/requestNewPassword';
import Signup from 'src/auth/signup';
import NoAuthRoadmap from 'src/noAuthRoadmap/NoAuthRoadmap';
import NoAuthTest from 'src/noAuthTest/NoAuthTest';
import {
  OrganizationCreation,
  OrganizationInitialize,
  OrganizationSettings,
  OrganizationPlans,
  OrganizationsRouteLayout,
  OrganizationRouteLayout,
} from 'src/organizations';
import { QuizLayout } from 'src/quiz';
import QuizSummary from 'src/quiz/QuizSummary/QuizSummary';
import TakeQuiz from 'src/quiz/TakeQuiz';
import RoadmapCreateName from 'src/roadmap/RoadmapCreateName/RoadmapCreateName';
import RoadmapDetails from 'src/roadmap/RoadmapDetails/RoadmapDetails';
import FeatureGroupAdoption from 'src/roadmap/RoadmapEdition/FeatureGroupAdoption/FeatureGroupAdoption';
import FeatureGroupTimeEstimation from 'src/roadmap/RoadmapEdition/FeatureGroupTimeEstimation/FeatureGroupTimeEstimation';
import FeatureGroupsCreation from 'src/roadmap/RoadmapEdition/FeatureGroupsCreation/FeatureGroupsCreation';
import FeatureGroupsUrgency from 'src/roadmap/RoadmapEdition/FeatureGroupsUrgency/FeatureGroupsUrgency';
import RoadmapEdition from 'src/roadmap/RoadmapEdition/RoadmapEdition';
import RoadmapStepsLayout from 'src/roadmap/RoadmapEdition/RoadmapStepsLayout';
import RoadmapUpdateName from 'src/roadmap/RoadmapEdition/RoadmapUpdateName/RoadmapUpdateName';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import AuthChecker from './AuthChecker';
import ScrollToTop from './ScrollToTop';
import { paths } from './paths.const';

export const history = createBrowserHistory({ window });

function Router(): JSX.Element {
  const { authStore } = useStores();
  const { isCurrentUserFetched } = authStore;

  if (!isCurrentUserFetched) return <Loader />;

  return (
    <HistoryRouter history={history}>
      <ScrollToTop />
      <Routes>
        <Route
          element={
            <AuthChecker pageType={'public'}>
              <Login />
            </AuthChecker>
          }
          path={paths.login()}
        />
        <Route
          element={
            <AuthChecker pageType={'public'}>
              <Signup />
            </AuthChecker>
          }
          path={paths.signup()}
        />
        <Route
          element={
            <AuthChecker pageType={'public'}>
              <RequestNewPassword />
            </AuthChecker>
          }
          path={paths.requestNewPassword()}
        />
        <Route
          element={
            <AuthChecker pageType={'publicAndPrivate'}>
              <NoAuthRoadmap />
            </AuthChecker>
          }
          path={paths.noAuthRoadmap(':roadmapId')}
        />
        <Route
          element={
            <AuthChecker pageType={'publicAndPrivate'}>
              <NoAuthTest />
            </AuthChecker>
          }
          path={paths.noAuthTest(':testId')}
        />
        {/* organizations ---- */}
        <Route path="organizations" element={<OrganizationsRouteLayout />}>
          <Route
            element={
              <AuthChecker pageType={'private'}>
                <OrganizationInitialize />
              </AuthChecker>
            }
            path={paths.organizationInitialize()}
          />
          <Route
            element={
              <AuthChecker pageType={'private'}>
                <OrganizationCreation />
              </AuthChecker>
            }
            path={paths.organizationCreate()}
          />
          {/* :organizationId ---- */}
          <Route path=":organizationId" element={<OrganizationRouteLayout />}>
            <Route
              element={
                <AuthChecker pageType={'private'}>
                  <Dashboard />
                </AuthChecker>
              }
              path={paths.organizationDashboard(':organizationId')}
            />
            <Route
              element={
                <AuthChecker pageType={'private'}>
                  <OrganizationSettings />
                </AuthChecker>
              }
              path={paths.organizationSettings(':organizationId')}
            />
            <Route
              element={
                <AuthChecker pageType={'private'}>
                  <OrganizationPlans />
                </AuthChecker>
              }
              path={paths.organizationPlans(':organizationId')}
            />
            {/* roadmaps ---- */}
            <Route
              path={paths.roadmapDetails(':organizationId', ':roadmapId')}
              element={
                <AuthChecker pageType={'private'}>
                  <RoadmapDetails />
                </AuthChecker>
              }
            />
            <Route
              path={paths.roadmapCreateName(':organizationId')}
              element={
                <AuthChecker pageType={'private'}>
                  <RoadmapCreateName />
                </AuthChecker>
              }
            />
            <Route
              path="roadmaps/:roadmapId/edition"
              element={
                <AuthChecker pageType={'private'}>
                  <RoadmapEdition />
                </AuthChecker>
              }
            >
              <Route index element={<RoadmapUpdateName />} />
              <Route element={<RoadmapStepsLayout />}>
                <Route path="feature-groups-creation" element={<FeatureGroupsCreation />} />
                <Route path="feature-groups-urgency" element={<FeatureGroupsUrgency />} />
                <Route path="feature-groups-adoption" element={<FeatureGroupAdoption />} />
                <Route path="feature-groups-time-estimation" element={<FeatureGroupTimeEstimation />} />
              </Route>

              {/* TODO: in above names sometimes it's "FeatureGroup" ("FeatureGroupAdoption") and sometimes "FeatureGroups" ("FeatureGroupsUrgency") */}
            </Route>

            {/* tests ---- */}
            <Route
              path={paths.testCreate(':organizationId')}
              element={
                <AuthChecker pageType={'private'}>
                  <TestCreate />
                </AuthChecker>
              }
            />
            <Route
              path="tests/:testId/edition"
              element={
                <AuthChecker pageType={'private'}>
                  <TestEdition />
                </AuthChecker>
              }
            >
              <Route index element={<TestUpdate />} />
              <Route element={<TestStepsLayout />}>
                <Route path="ideas-management" element={<IdeasManagement />} />
                <Route path="summary" element={<TestSummary />} />
              </Route>
            </Route>
            <Route path={'tests'} element={<TestLayout />}>
              <Route
                path={paths.audienceShareLink(':organizationId', ':testId')}
                element={
                  <AuthChecker pageType={'private'}>
                    <AudienceShareLink />
                  </AuthChecker>
                }
              />
              <Route
                path={paths.testResults(':organizationId', ':testId')}
                element={
                  <AuthChecker pageType={'private'}>
                    <TestResults />
                  </AuthChecker>
                }
              />
              <Route
                path={paths.audienceCint(':organizationId', ':testId')}
                element={
                  <AuthChecker pageType={'private'}>
                    <AudienceCint />
                  </AuthChecker>
                }
              />
            </Route>
          </Route>
        </Route>
        {/* quizzes ---- */}
        <Route path={paths.quiz(':testId')} element={<QuizLayout />}>
          <Route index element={<TakeQuiz />} />
          <Route path="summary" element={<QuizSummary />} />
        </Route>
        {/* admin - anther app ---- */}
        <Route path={paths.admin()} />
        {/*  ---- */}
        <Route path="*" element={<AuthChecker isInvalid />} />
      </Routes>
    </HistoryRouter>
  );
}

export default observer(Router);
