import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { CintCategoryBasic } from 'src/Tests/Test.types';
import Input from 'src/theme/atoms/inputs/Input/Input';
import { ReactComponent as ArrowDownIcon } from 'src/theme/svg/arrow-down.svg';
import { ReactComponent as TickIcon } from 'src/theme/svg/tick.svg';
import { useDebounce } from 'src/utils/hooks';
import useOnClickOutside from 'src/utils/hooks/useOnClickOutside';

import styles from './DropdownAudience.module.scss';

const selectedItem = (
  items: CintCategoryBasic[],
  selectedIds: string | string[]
): CintCategoryBasic | CintCategoryBasic[] | undefined => {
  return Array.isArray(selectedIds)
    ? items.filter((item) => {
        return selectedIds.map((selectedId) => selectedId === item.id).filter((el) => el).length;
      })
    : items.find((item) => item.id === selectedIds);
};

const isSelected = (id: string, selectedIds: string | string[]): boolean => {
  return Array.isArray(selectedIds) ? Boolean(selectedIds.find((selectedId) => selectedId === id)) : id === selectedIds;
};

const isAnythingSelected = (selectedIds: string | string[]): boolean => {
  return Boolean(Array.isArray(selectedIds) ? selectedIds.length : selectedIds);
};

interface DropdownAudienceProps {
  title: string;
  selectedIds: string | string[];
  items: CintCategoryBasic[];
  handleItemSelection: (id: string | string[] | undefined) => void;
  dropdownHeight?: number;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  isIdeaManagementView?: boolean;
}

function DropdownAudience({
  title,
  required,
  selectedIds,
  items,
  handleItemSelection,
  dropdownHeight = 130,
  multiple,
  disabled,
  isIdeaManagementView,
}: DropdownAudienceProps): JSX.Element {
  const [menuVisible, setMenuVisible] = useState(false);

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);

  const dropdownRef = useRef(null);
  const listRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setMenuVisible(false));

  useEffect(() => {
    if (!menuVisible) {
      setSearchText('');
    }
  }, [menuVisible]);

  const handleClickOfAny = (): void => {
    handleItemSelection(multiple ? [] : undefined);
    setMenuVisible(false);
  };

  return (
    <div className={styles.root} ref={dropdownRef}>
      <ReactTooltip />
      <div
        className={clsx(
          styles.container,
          menuVisible && styles.isOpen,
          disabled && styles.isDisabled,
          isIdeaManagementView && styles.containerIdeasManagementView
        )}
        onClick={() => !disabled && setMenuVisible(!menuVisible)}
        data-tip={disabled && 'Household income' ? 'Select a country first' : ''}
      >
        <div className={styles.text}>
          <div className={styles.titleLine}>
            <div
              className={clsx(
                styles.title,
                (isAnythingSelected(selectedIds) || menuVisible || !required) &&
                  !isIdeaManagementView &&
                  styles.titleSmall,
                !(isAnythingSelected(selectedIds) || menuVisible) &&
                  isIdeaManagementView &&
                  styles.titleIdeasManagementView,
                (isAnythingSelected(selectedIds) || menuVisible || !required) &&
                  isIdeaManagementView &&
                  styles.titleSmallIdeasManagementView
              )}
            >
              {title}
            </div>
            {required && !isAnythingSelected(selectedIds) && !menuVisible && (
              <div className={clsx(styles.required, isIdeaManagementView && styles.requiredIdeasManagementView)}>
                (required)
              </div>
            )}
          </div>
          {(menuVisible || isAnythingSelected(selectedIds) || !required) && (
            <div
              className={clsx(
                styles.selectedItemLine,
                isIdeaManagementView && styles.selectedItemLineIdeasManagementView
              )}
            >
              {menuVisible && multiple
                ? 'Select one or multiple'
                : menuVisible && !multiple
                ? 'Select one'
                : isAnythingSelected(selectedIds) && Array.isArray(selectedIds)
                ? (selectedItem(items, selectedIds) as CintCategoryBasic[])?.map((el) => el.name).join(', ')
                : isAnythingSelected(selectedIds) && !Array.isArray(selectedIds)
                ? isAnythingSelected(selectedIds) && (selectedItem(items, selectedIds) as CintCategoryBasic)?.name
                : !required && 'Any'}
            </div>
          )}
        </div>
        <div className={clsx(styles.arrow, menuVisible && styles.arrowUpsideDown)}>
          <ArrowDownIcon />
        </div>
      </div>
      <div
        ref={listRef}
        className={clsx(
          styles.list,
          { [styles.visible]: menuVisible },
          isIdeaManagementView && styles.listIdeasManagementView
        )}
        style={{ maxHeight: dropdownHeight + 'px' }}
      >
        <div className={styles.listInner}>
          {items && items.length > 16 && (
            <div className={styles.searchBox}>
              <Input
                inputClassName={styles.input}
                name="search"
                type="text"
                color="white"
                size="s"
                placeholder="Search..."
                onChange={(val) => setSearchText(val)}
                value={searchText}
                autoCompleteOff
              />
            </div>
          )}
          <ul onClick={(e) => e.preventDefault()}>
            {!required && !debouncedSearchText.length && (
              <li onClick={handleClickOfAny}>
                <div className={styles.item}>
                  Any
                  {multiple ? (
                    <div
                      className={clsx(
                        styles.tickWrapperMultiple,
                        !isAnythingSelected(selectedIds) && styles.itemSelected
                      )}
                    >
                      {!isAnythingSelected(selectedIds) && <TickIcon />}
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        styles.tickWrapperSingle,
                        !isAnythingSelected(selectedIds) && styles.itemSelected
                      )}
                    >
                      {!isAnythingSelected(selectedIds) && <TickIcon />}
                    </div>
                  )}
                </div>
              </li>
            )}
            {items &&
              items
                .filter((el) => el.name.toLowerCase().includes(debouncedSearchText.toLowerCase()))
                .map(({ id, name }) => (
                  <li
                    key={id}
                    onClick={() => {
                      handleItemSelection(
                        !Array.isArray(selectedIds)
                          ? id
                          : isSelected(id, selectedIds)
                          ? selectedIds.filter((el) => el !== id)
                          : [...selectedIds, id]
                      );
                      !multiple && setMenuVisible(false);
                    }}
                  >
                    <div className={styles.item}>
                      {name}
                      {multiple ? (
                        <div
                          className={clsx(
                            styles.tickWrapperMultiple,
                            isSelected(id, selectedIds) && styles.itemSelected
                          )}
                        >
                          {isSelected(id, selectedIds) && <TickIcon />}
                        </div>
                      ) : (
                        <div
                          className={clsx(styles.tickWrapperSingle, isSelected(id, selectedIds) && styles.itemSelected)}
                        >
                          {isSelected(id, selectedIds) && <TickIcon />}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default observer(DropdownAudience);
