import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as CloseIcon } from 'src/theme/svg/plus.svg';

import Spinner from '../../loaders/Spinner/Spinner';
import Modal from '../../modal/Modal';
import PhotoItem from './PhotoItem/PhotoItem';
import styles from './UnsplashModal.module.scss';
import { getPhotos, getRecents, putRecent, triggerPseudoDownload } from './UnsplashModal.requests';
import { PhotoDto } from './UnsplashModal.types';
import { ReactComponent as SearchIcon } from './search-icon.svg';

const PAGE_SIZE = 12;

interface Tab {
  name: 'Recents' | 'Free Photos';
  element: JSX.Element;
}

interface UnsplashModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (url: string) => void;
}

function UnsplashModal({ isOpen, onClose, onChange }: UnsplashModalProps): JSX.Element {
  const [openTabIndex, setOpenTabIndex] = useState(0);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [images, setImages] = useState<(PhotoDto | null)[]>([]);
  const [recents, setRecents] = useState<(PhotoDto | null)[]>([]);
  const [loaded, setLoaded] = useState<boolean[]>([]);
  const [page, setPage] = useState(0);

  const [recentsLoading, setRecentsLoading] = useState(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [freePhotosFetched, setFreePhotosFetched] = useState(false);

  const cardsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let controller: AbortController | null = new AbortController();
    setRecentsLoading(true);

    (async () => {
      const res = await getRecents();
      setLoaded(Array(res.data.length).fill(false));
      setRecents(res.data);
      setRecentsLoading(false);
      controller = null;
    })();

    return () => controller?.abort();
  }, []);

  const handleSearch = async (value: string): Promise<void> => {
    if (cardsRef.current) cardsRef.current.scrollTo({ top: 0 });

    if (!value) {
      setImages([]);
      setLoaded([]);
      setFreePhotosFetched(false);
      return;
    }

    setImages(Array(PAGE_SIZE).fill(null));
    setLoaded(Array(PAGE_SIZE).fill(false));
    const res = await getPhotos(value, 1, PAGE_SIZE);
    setImages([...res.data]);
    setPage(2); // next page to be loaded is no. 2
    setFreePhotosFetched(true);
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

  useEffect(() => {
    if (tabs[openTabIndex].name === 'Free Photos') debouncedSearch(searchBarValue);

    return () => debouncedSearch.cancel();
  }, [searchBarValue, openTabIndex]);

  const loadNextPage = async (): Promise<void> => {
    if (isFetchingNextPage) return;

    setIsFetchingNextPage(true);

    try {
      setLoaded([...loaded, ...Array(PAGE_SIZE).fill(false)]);
      setImages([...images, ...Array(PAGE_SIZE).fill(null)]);
      const res = await getPhotos(searchBarValue, page, PAGE_SIZE);
      setImages([...images, ...res.data]);
      setPage(page + 1);
    } catch (error) {
      alert('Something went wrong');
    } finally {
      setIsFetchingNextPage(false);
    }
  };

  const handleScroll = (): void => {
    const el = cardsRef.current;
    if (!el) return;

    const hasReachedBottom = el.scrollHeight - el.scrollTop === el.clientHeight;
    if (hasReachedBottom) loadNextPage();
  };

  const selectUnsplashImage = (dto: PhotoDto): void => {
    triggerPseudoDownload(dto.links.download_location);

    putRecent(dto);
    if (!recents.find((item) => JSON.stringify(item) === JSON.stringify(dto))) {
      setRecents([...recents, dto]);
    }

    onChange(dto.urls.regular);
    onClose();
  };

  const handleLoaded = (index: number): void => {
    setLoaded((prevLoaded) => prevLoaded.map((loaded, i) => i === index || loaded));
  };

  const recentsView = recentsLoading ? (
    <div className={styles.spinnerWrapper}>
      <Spinner className={styles.spinner} />
    </div>
  ) : (
    <div>
      {recents.length === 0 ? (
        <p className={styles.information}>No recents photos. Click on free photos tab to see photos library.</p>
      ) : (
        <div className={styles.cards}>
          {recents.map((photo, i) => (
            <PhotoItem
              key={i}
              index={i}
              photo={photo}
              isLoaded={loaded[i]}
              onLoad={handleLoaded}
              onClick={selectUnsplashImage}
            />
          ))}
        </div>
      )}
    </div>
  );

  const freePhotosView = (
    <>
      <div className={styles.searchBar}>
        <SearchIcon onClick={() => debouncedSearch(searchBarValue)} />
        <input
          placeholder={'Search'}
          value={searchBarValue}
          onChange={(e) => setSearchBarValue(e.target.value)}
          spellCheck={false}
        />
      </div>
      <div>
        {images.length === 0 ? (
          <p className={styles.information}>
            {!searchBarValue || !freePhotosFetched
              ? 'Type above to search for photos.'
              : "Couldn't find any photo. Try to type something else"}
          </p>
        ) : (
          <div className={styles.cards} ref={cardsRef} onScroll={handleScroll}>
            {images.map((photo, i) => (
              <PhotoItem
                key={i}
                index={i}
                photo={photo}
                isLoaded={loaded[i]}
                onLoad={handleLoaded}
                onClick={selectUnsplashImage}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );

  const tabs: Tab[] = [
    {
      name: 'Recents',
      element: recentsView,
    },
    {
      name: 'Free Photos',
      element: freePhotosView,
    },
  ];

  return (
    <Modal isOpen={isOpen} className={styles.root}>
      <div className={styles.topbar}>
        <div className={styles.itemsGroup}>
          {tabs.map((tab, index) => (
            <div
              className={clsx(styles.item, openTabIndex === index && styles.itemFocused)}
              onClick={() => setOpenTabIndex(index)}
              key={tab.name}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <CloseIcon className={styles.closeBtn} onClick={onClose} />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>{tabs[openTabIndex].element}</div>
      </div>
    </Modal>
  );
}

export default UnsplashModal;
