import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate, Outlet, useParams } from 'react-router-dom';

import { paths } from 'src/app/routes/paths.const';
import StepLayout from 'src/theme/atoms/steps/StepLayout';
import { TEST_STEPS_COUNT } from 'src/utils/constants';

import { useTestEditionStore } from './TestEditionContext';

const actionBottomBarConfigLabelForPrev = [
  `Get your ideas out: 1 of ${TEST_STEPS_COUNT}`,
  `Review your test: 2 of ${TEST_STEPS_COUNT}`,
];

const steps = ['ideas-management', 'summary'];

const getPrevStep = (currentStep: number, organizationId: string, testId: string): string => {
  switch (currentStep) {
    case 2:
      return paths.testIdeasManagement(organizationId, testId);
    default:
      return paths.testUpdate(
        organizationId,
        testId
        //, { step: '2' }
      );
  }
};

const getNextStep = (
  view: 'share-link' | 'cint',
  currentStep: number,
  organizationId: string,
  testId: string
): string => {
  if (currentStep === 1) {
    return paths.testSummary(organizationId, testId);
  } else if (view === 'cint') {
    return paths.audienceCint(organizationId, testId);
  } else {
    return paths.audienceShareLink(organizationId, testId);
  }
};

function TestStepsLayout(): JSX.Element {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const { test, isNextStepDisabled, isImageUploading, updateTest } = useTestEditionStore();

  const navigate = useNavigate();

  const location = useLocation();
  const stepName = location.pathname.split('/').pop() || '';

  if (!stepName || !steps.includes(stepName)) {
    return <Navigate to={paths.organizationDashboard(organizationId)} />;
  }

  const [currentStep, setCurrentStep] = useState<number>(steps.indexOf(stepName) + 1);

  useEffect(() => {
    setCurrentStep(steps.indexOf(stepName) + 1);
  }, [stepName]);

  const handlePrevStep = (): void => {
    return navigate(getPrevStep(currentStep, organizationId, test.id));
  };

  const handleNextStep = async (): Promise<void> => {
    try {
      if (currentStep === 2 && test.state !== 'running') {
        await updateTest({ state: 'running' });
      }

      return navigate(
        getNextStep(
          test.externalAudienceState === 'unused' ? 'share-link' : 'cint',
          currentStep,
          organizationId,
          test.id
        )
      );
    } catch (error) {
      alert('Something went wrong.');
    }
  };

  return (
    <StepLayout
      actionBottomBarConfig={{
        labelForPrev: actionBottomBarConfigLabelForPrev[currentStep - 1],
        labelForNext: currentStep === 2 ? 'Go live' : undefined,
        currentStep,
        stepsCount: TEST_STEPS_COUNT,
        nextStepDisabled: isNextStepDisabled || isImageUploading,
        prevStepDisabled: isImageUploading,
        prev: handlePrevStep,
        next: handleNextStep,
      }}
    >
      <Outlet />
    </StepLayout>
  );
}

export default observer(TestStepsLayout);
