import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useRef } from 'react';

import Button from 'src/theme/atoms/buttons/Button/Button';

import styles from './TutorialTooltip.module.scss';

export const tutorialLocalStorageKey = 'seenTutorial';

interface ProgressDotsProps {
  step: number;
  maxSteps: number;
  changeStep: (step: number) => void;
}

function ProgressDots({ step, maxSteps, changeStep }: ProgressDotsProps): JSX.Element {
  const stepsRange = Array.from(Array(maxSteps).keys()); // [0, ..., maxSteps - 1]

  return (
    <div className={styles.progressDotsGroup}>
      {stepsRange.map((i) => (
        <div
          key={i}
          className={i === step ? styles.progressDotOn : styles.progressDotOff}
          onClick={() => changeStep(i)}
        />
      ))}
    </div>
  );
}

interface getVisibilityRetVal {
  visible: boolean;
  offsets: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
  }
}

function getVisibility(el: HTMLElement): getVisibilityRetVal {
  const rect = el.getBoundingClientRect();

  const offsets = {
    ...(rect.top < 0 && { top: rect.top }),
    ...(rect.left < 0 && { left: rect.left }),
    ...(rect.bottom > window.innerHeight && { bottom: rect.bottom - window.innerHeight }),
    ...(rect.right > window.innerWidth && { right: rect.right - window.innerWidth }),
  };

  return { offsets, visible: offsets == {} };
}

interface TutorialTooltipProps {
  currentStep: number; // the actual current step, kept in the FeatureGroupsCreationStore.
  ownStep: number; // the step at which this tooltip appears (index of the dot which should be filled).
  stepsCount: number; // overall number of steps (number of dots in bottom-left corner).
  headerText: string;
  mainText: string;
  className?: string;
  onClose: () => void;
  changeStep: (step: number) => void;
  floatingRef: (node: HTMLElement | null) => void;
  floatingStyle: {
    position: 'absolute' | 'fixed';
    top: number;
    left: number;
  };
  horizontalScrollRef?: React.RefObject<HTMLElement>;
  verticalScrollRef?: React.RefObject<HTMLElement>;
}

function TutorialTooltip({
  currentStep,
  ownStep,
  stepsCount,
  headerText,
  mainText,
  className,
  onClose,
  changeStep,
  floatingRef,
  floatingStyle,
  horizontalScrollRef,
  verticalScrollRef,
}: TutorialTooltipProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    floatingRef(ref.current);
  }, [ref.current]);

  useEffect(() => {
    if (!isHidden && ref.current) {
      const visibility = getVisibility(ref.current);
      const adj = 100;

      if (!visibility.visible) {
        const offsets = visibility.offsets;
        let left, top;

        if (offsets.left) left = offsets.left - adj;
        if (offsets.right) left = offsets.right + adj;

        if (offsets.top) top = offsets.top - adj;
        if (offsets.bottom) top = offsets.bottom + adj;

        (verticalScrollRef?.current ?? window).scrollBy({ top, behavior: "smooth" });
        (horizontalScrollRef?.current ?? window).scrollBy({ left, behavior: "smooth" });
      }
    }
  }, [currentStep, horizontalScrollRef]);

  const isLastStep = ownStep === stepsCount - 1;
  const isHidden = currentStep !== ownStep;

  return (
    <div ref={ref} className={clsx(className, styles.tooltip, isHidden && styles.tooltipHidden)} style={floatingStyle}>
      <div className={styles.header}>
        <p className={styles.headerText}>{headerText}</p>
        <div onClick={onClose} className={styles.closeButton}>
          &#xd7;
        </div>
      </div>
      <p className={styles.main}>{mainText}</p>
      <div className={styles.footer}>
        <ProgressDots step={ownStep} maxSteps={stepsCount} changeStep={changeStep} />
        {isLastStep ? (
          <Button color={'primary'} size={'xs'} label={'DONE'} className={styles.doneButton} onClick={onClose} />
        ) : (
          <Button
            color={'white'}
            size={'xs'}
            label={'NEXT'}
            className={styles.nextButton}
            onClick={() => changeStep(currentStep + 1)}
          />
        )}
      </div>
      <div className={styles.corner} />
    </div>
  );
}

export default TutorialTooltip;
