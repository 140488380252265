import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import AddCollabolatorModal from 'src/theme/atoms/AddCollabolatorModal/AddCollabolatorModal';
import Button from 'src/theme/atoms/buttons/Button/Button';
import { ADD_TEAM_MEMBERS_BTN } from 'src/utils/constants';

import styles from './TestStepHeader.module.scss';
import { ReactComponent as Logo } from './logo.svg';

const maxLengthOfTestNameToDisplay = 70;

interface TestStepHeaderProps {
  testName: string;
  subtitle?: string;
  title?: string | JSX.Element;
  isBottomDivider?: boolean;
  showAddCollaborators?: boolean;
  helpButtonConfig?: {
    label: string;
    action: () => void;
  };
  actionButtonConfig?: {
    label: string;
    action: () => void;
  };
}

function TestStepHeader({
  testName,
  subtitle,
  title,
  helpButtonConfig,
  actionButtonConfig,
  isBottomDivider = true,
  showAddCollaborators = true,
}: TestStepHeaderProps): JSX.Element {
  const { authStore } = useStores();
  const organization = useOrganization();

  const { isAdmin } = authStore;
  const isOwner = organization?.createdBy === authStore.firebaseAuth.currentUser?.uid;

  const [isAddCollaboratorsModalOpen, setIsAddCollaboratorsModalOpen] = useState(false);

  return (
    <div className={styles.root}>
      <div className={clsx(isBottomDivider && styles.bottomDivider)}>
        <div className={styles.topbar}>
          <Link to={paths.organizationDashboard(organization.id)} className={styles.logoLink}>
            <Logo className={styles.logo} />
          </Link>
          <div className={styles.rightBox}>
            {showAddCollaborators && (isOwner || isAdmin) && (
              <Button
                label={ADD_TEAM_MEMBERS_BTN}
                color="secondary"
                size="s"
                onClick={() => setIsAddCollaboratorsModalOpen(true)}
              />
            )}
            <ReactTooltip />
            <p
              className={styles.testName}
              data-class={styles.reactTooltip}
              data-tip={testName.length > maxLengthOfTestNameToDisplay && window.innerWidth > 767 ? testName : ''}
            >
              {testName}
            </p>
          </div>
        </div>
        <div className={styles.intro}>
          <div className={styles.titlesBox}>
            <h1 className={styles.title}>{title}</h1>
            <h6>{subtitle}</h6>
          </div>
          <div className={styles.btnsBox}>
            {actionButtonConfig && (
              <Button
                label={actionButtonConfig.label}
                onClick={actionButtonConfig.action}
                color="secondary"
                size="l"
                className={styles.btn}
              />
            )}
            {helpButtonConfig && (
              <Button
                label={helpButtonConfig.label}
                onClick={helpButtonConfig.action}
                color="secondary"
                size="l"
                className={clsx(styles.btn, styles.helpBtn)}
              />
            )}
          </div>
        </div>
      </div>
      {isAddCollaboratorsModalOpen && <AddCollabolatorModal onClose={() => setIsAddCollaboratorsModalOpen(false)} />}
    </div>
  );
}

export default TestStepHeader;
