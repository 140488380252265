import { observer } from 'mobx-react-lite';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams, Outlet } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';

import styles from './Test.module.scss';
import { Test } from './Test.types';

interface TestContextValue {
  test: Test | null;
  changeTest: (test: Test) => void;
}

const TestContext = createContext<TestContextValue | undefined>(undefined);

export const useTest = (): TestContextValue => {
  const test = useContext(TestContext);
  if (test === undefined) {
    throw new Error('useTest must be used within TestContext');
  }
  return test;
};

function TestLayout(): JSX.Element {
  const { organizationId, testId } = useParams();
  if (!organizationId) throw new Error('No organization id');

  const { testStore } = useStores();
  const [isLoading, setIsLoading] = useState(!!testId);

  const [test, setTest] = useState<Test | null>(null);

  useEffect(() => {
    if (testId) {
      testStore.getTest(organizationId, testId).then((test) => {
        setTest(test);
        setIsLoading(false);
      });
    }
  }, []);

  const changeTest = (test: Test): void => {
    setTest(test);
  };

  if (isLoading) return <Loader />;

  return (
    <div className={styles.root}>
      <TestContext.Provider
        value={{
          test,
          changeTest,
        }}
      >
        <Outlet />
      </TestContext.Provider>
    </div>
  );
}

export default observer(TestLayout);
