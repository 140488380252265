import { Link } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { Organization } from 'src/organizations/Organizations.types';
import env from 'src/utils/env';

import styles from './Header.module.scss';
import { ReactComponent as Logo } from './logo.svg';

interface HeaderProps {
  organization?: Organization;
  children?: React.ReactNode;
}

function Header({ organization, children }: HeaderProps): JSX.Element {
  const { authStore } = useStores();
  const { firebaseAuth } = authStore;

  return (
    <div className={styles.root}>
      {firebaseAuth.currentUser && organization ? (
        <Link to={paths.organizationDashboard(organization.id)} className={styles.logo}>
          <Logo />
        </Link>
      ) : (
        <a href={env.landingPage} className={styles.logo}>
          <Logo />
        </a>
      )}

      <div className={styles.slot}>{children}</div>
    </div>
  );
}

export default Header;
