import { ReactComponent as SpinnerIcon } from 'src/theme/svg/spinner.svg';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  className?: string;
}

function Spinner({ className = '' }: SpinnerProps): JSX.Element {
  return <SpinnerIcon className={`${styles.spinner} ${className}`} />;
}

export default Spinner;
