import clsx from 'clsx';

import baseStyles from 'src/theme/atoms/HelperModal';
import { featureGroupTimeEstimationImg } from 'src/theme/atoms/HelperModal/assets';
import HelperModal from 'src/theme/atoms/HelperModal/HelperModal';

import styles from './FeatureGroupTimeEstimationHelperModal.module.scss';

interface FeatureGroupTimeEstimationHelperModalProps {
  onClose: () => void;
}

function FeatureGroupTimeEstimationHelperModal({ onClose }: FeatureGroupTimeEstimationHelperModalProps): JSX.Element {
  return (
    <HelperModal
      onClose={onClose}
      lightSection={
        <div>
          <div className={styles.header}>
            <h2 className={styles.title}>Define ability to build</h2>
            <h2 className={styles.subtitle}>How long will it take you to build this feature set?</h2>
            <p className={baseStyles.text}>
              Consider whether each feature set will take your team days, weeks, or months to spec and build.
            </p>
            <p className={baseStyles.text}>
              Your team here includes software engineering and any other functions required to get the feature set
              built.
            </p>
          </div>
        </div>
      }
      darkSection={
        <div>
          <p className={clsx(baseStyles.smallText, baseStyles.question)}>
            How long will it take you to build <br /> this feature set?
          </p>
          <img src={featureGroupTimeEstimationImg} alt="Feature group time estimation" className={styles.img} />
        </div>
      }
    />
  );
}

export default FeatureGroupTimeEstimationHelperModal;
