import { NAME_REGEX, EMAIL_REGEX } from 'src/utils/regex';
import { Dictionary } from 'src/utils/types/Dictionary';

export const authErrors: Dictionary<string, string> = {
  'auth/email-already-in-use': 'Email already exists. Please login.',
  'auth/user-not-found': 'User not found. Please sign up.',
  'auth/wrong-password': 'Wrong password. Please try again.',
  'auth/invalid-email': 'Invalid email. Please try again.',
  'auth/too-many-requests':
    'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
};

export function getAuthErrorMessage(code: string): string {
  return authErrors[code] ?? 'Authorization error';
}

export class ErrorEmailIsTaken extends Error {
  constructor() {
    super('Email is already taken');
    Object.setPrototypeOf(this, ErrorEmailIsTaken.prototype);
  }
}

export class ErrorPasswordIsTooShort extends Error {
  private static minRequiredLength = 6;
  public static message = `Password should be at least ${ErrorPasswordIsTooShort.minRequiredLength} characters long`;

  static isSatisfiedBy(password: string): boolean {
    if (!password) return false;
    return password.length < this.minRequiredLength;
  }

  constructor() {
    super(ErrorPasswordIsTooShort.message);
    Object.setPrototypeOf(this, ErrorPasswordIsTooShort.prototype);
  }
}

export class ErrorConfirmPassword extends Error {
  public static message = `Passwords don't match`;

  static isSatisfiedBy(password: string, confirmPassword: string): boolean {
    if (!confirmPassword) return true;
    return password !== confirmPassword;
  }

  constructor() {
    super(ErrorConfirmPassword.message);
    Object.setPrototypeOf(this, ErrorConfirmPassword.prototype);
  }
}

export class ErrorEmailFormatIsInvalid extends Error {
  private static mailFormat = EMAIL_REGEX;
  public static message = 'Invalid email format';

  static isSatisfiedBy(email: string): boolean {
    if (!email) return true;
    return !!email.match(this.mailFormat);
  }

  constructor() {
    super(ErrorEmailFormatIsInvalid.message);
    Object.setPrototypeOf(this, ErrorEmailFormatIsInvalid.prototype);
  }
}

export class NameFormatIsInvalid extends Error {
  private static nameFormat = NAME_REGEX;
  public static message = 'Invalid name format';

  static isSatisfiedBy(name: string): boolean {
    if (!name) return true;
    return !!name.match(this.nameFormat);
  }

  constructor() {
    super(NameFormatIsInvalid.message);
    Object.setPrototypeOf(this, NameFormatIsInvalid.prototype);
  }
}
