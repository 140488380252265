import { makeAutoObservable } from 'mobx';

import { RootStore } from 'src/RootStore';

import * as requests from './Quiz.requests';
import { QuizAnswerDto, QuizDto } from './Quiz.types';

class QuizStore {
  rootStore: RootStore;

  isFetchingQuiz = false;
  isSendingAnswer = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchQuiz(testId: string, respondentId: string): Promise<QuizDto> {
    this.isFetchingQuiz = true;

    return requests
      .fetchQuiz(testId, respondentId)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isFetchingQuiz = false;
      });
  }

  sendQuizAnswer(testId: string, dto: QuizAnswerDto): Promise<void | { redirectUrl: string }> {
    this.isSendingAnswer = true;

    return requests
      .sendQuizAnswer(testId, dto)
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        this.isSendingAnswer = false;
      });
  }
}

export default QuizStore;
