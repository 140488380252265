import { useState } from 'react';

interface UseModalProps {
  modalVisible: boolean;
  modalName: string;
  openModal: (name?: string) => void;
  closeModal: () => void;
}

export const useModal = (): UseModalProps => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalName, setModalName] = useState('');

  const openModal = (name?: string): void => {
    setModalVisible(true);
    if (name !== undefined) setModalName(name);
  };

  const closeModal = (): void => {
    setModalVisible(false);
    setModalName('');
  };

  return {
    modalVisible,
    modalName,
    openModal,
    closeModal,
  };
};
