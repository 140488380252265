// import { createSearchParams } from 'react-router-dom';

export const paths = {
  /* public */
  login: (): string => '/login',
  signup: (): string => '/signup',
  requestNewPassword: (): string => '/request-new-password',
  noAuthRoadmap: (roadmapId: string): string => `/no-auth-roadmap/${roadmapId}`,
  noAuthTest: (testId: string): string => `/no-auth-test/${testId}`,
  /* organizations */
  organizationCreate: (): string => `/organizations/create`,
  organizationInitialize: (): string => '/organizations/initialize',
  organizationSettings: (organizationId: string): string => `/organizations/${organizationId}/settings`,
  organizationPlans: (organizationId: string): string => `/organizations/${organizationId}/plans`,
  organizationDashboard: (organizationId: string): string => `/organizations/${organizationId}/dashboard`,
  /* roadmaps */
  roadmapCreateName: (organizationId: string): string => `/organizations/${organizationId}/roadmaps/create`,
  roadmapUpdateName: (organizationId: string, roadmapId: string): string =>
    `/organizations/${organizationId}/roadmaps/${roadmapId}/edition`,
  roadmapDetails: (organizationId: string, roadmapId: string): string =>
    `/organizations/${organizationId}/roadmaps/${roadmapId}/details`,
  roadmapFeatureGroupsCreation: (organizationId: string, roadmapId: string): string =>
    `/organizations/${organizationId}/roadmaps/${roadmapId}/edition/feature-groups-creation`,
  roadmapFeatureGroupsUrgency: (organizationId: string, roadmapId: string): string =>
    `/organizations/${organizationId}/roadmaps/${roadmapId}/edition/feature-groups-urgency`,
  roadmapFeatureGroupAdoption: (organizationId: string, roadmapId: string): string =>
    `/organizations/${organizationId}/roadmaps/${roadmapId}/edition/feature-groups-adoption`,
  roadmapFeatureGroupTimeEstimation: (organizationId: string, roadmapId: string): string =>
    `/organizations/${organizationId}/roadmaps/${roadmapId}/edition/feature-groups-time-estimation`,
  /* tests */
  testCreate: (organizationId: string): string => `/organizations/${organizationId}/tests/create`,
  testUpdate: (organizationId: string, testId: string): string =>
    `/organizations/${organizationId}/tests/${testId}/edition`,
  // testUpdate: (organizationId: string, testId: string, searchParams?: { step: '0' | '1' | '2' }): string =>
  // `/organizations/${organizationId}/tests/${testId}/edition${
  //   searchParams ? `?${createSearchParams(searchParams).toString()}` : ''
  // }`,
  testIdeasManagement: (organizationId: string, testId: string): string =>
    `/organizations/${organizationId}/tests/${testId}/edition/ideas-management`,
  testSummary: (organizationId: string, testId: string): string =>
    `/organizations/${organizationId}/tests/${testId}/edition/summary`,
  audienceShareLink: (organizationId: string, testId: string): string =>
    `/organizations/${organizationId}/tests/${testId}/share-link`,
  audienceCint: (organizationId: string, testId: string): string =>
    `/organizations/${organizationId}/tests/${testId}/audience-cint`,
  testResults: (organizationId: string, testId: string): string =>
    `/organizations/${organizationId}/tests/${testId}/results`,
  /* quizzes */
  quiz: (testId: string): string => `/quizzes/${testId}`,
  quizSummary: (testId: string): string => `/quizzes/${testId}/summary`,
  /* admin - another app */
  admin: (): string => '/admin',
};
