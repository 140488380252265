import throttle from 'lodash.throttle';
import { forwardRef, ReactNode, useEffect, useRef } from 'react';
import { useDragDropManager } from 'react-dnd';

import styles from './ColumnsBox.module.scss';
import getCursorPositionBasedOnEvent, { CursorPosition } from './getCursorPosition';
import isInScrollingRange from './isInScrollingRange';

export type Box = {
  x: number;
  y: number;
  w: number;
  h: number;
};

const VERTICAL_BUFFER = 100;
const HORIZONTAL_BUFFER = 200;
const BOTTOM_ACTION_BAR = 120;

interface ColumnsBoxProps {
  children: ReactNode;
  strengthMultiplier?: number;
}

const calculateScaleX = (boxParams: Box, cursorPosition: CursorPosition, _buffer: number): number => {
  const buffer = Math.min(boxParams.w / 2, _buffer);
  const inRange = isInScrollingRange(boxParams, cursorPosition);

  if (inRange) {
    if (cursorPosition.x < boxParams.x + buffer) {
      return (cursorPosition.x - boxParams.x - buffer) / buffer;
    } else if (cursorPosition.x > boxParams.x + boxParams.w - buffer) {
      return -(boxParams.x + boxParams.w - cursorPosition.x - buffer) / buffer;
    }
  }

  return 0;
};

const calculateScaleY = (boxParams: Box, cursorPosition: CursorPosition, _buffer: number): number => {
  const buffer = _buffer;
  const inRange = isInScrollingRange(boxParams, cursorPosition);

  if (inRange) {
    if (cursorPosition.y < buffer) {
      return (cursorPosition.y - buffer) / buffer;
    } else if (cursorPosition.y > window.innerHeight - buffer - BOTTOM_ACTION_BAR) {
      return -(window.innerHeight - cursorPosition.y - buffer - BOTTOM_ACTION_BAR) / buffer;
    }
  }

  return 0;
};

function intBetween(min: number, max: number, val: number): number {
  return Math.floor(Math.min(max, Math.max(min, val)));
}

function ColumnsBox({ children, strengthMultiplier = 20 }: ColumnsBoxProps, ref): JSX.Element {
  const scaleXRef = useRef(0);
  const scaleYRef = useRef(0);
  const requestAnimationFrameIdRef = useRef<number | null>(null);
  const isDraggingRef = useRef(false);
  const clearMonitorSubscriptionRef = useRef<any>(null);

  const dragDropManager = useDragDropManager();

  const updateScrollingParams = throttle(
    (e: DragEvent | TouchEvent): void => {
      if (!ref.current) return;
      if (!isDraggingRef.current) return;

      const { left: x, top: y, width: w, height: h } = ref.current.getBoundingClientRect();
      const box: Box = { x, y, w, h };
      const cursorPosition = getCursorPositionBasedOnEvent(e);

      scaleXRef.current = calculateScaleX(box, cursorPosition, HORIZONTAL_BUFFER);
      scaleYRef.current = calculateScaleY(box, cursorPosition, VERTICAL_BUFFER);

      if (!requestAnimationFrameIdRef.current && (scaleXRef.current || scaleYRef.current)) {
        startScrolling();
      }
    },
    100,
    { trailing: false }
  );

  const startScrolling = (): void => {
    const tick = (): void => {
      if (!ref.current) return;
      const scaleX = scaleXRef.current;
      const scaleY = scaleYRef.current;

      if (strengthMultiplier === 0 || scaleX + scaleY === 0) {
        stopScrolling();
        return;
      }

      const { scrollLeft, scrollWidth, clientWidth } = ref.current;

      ref.current.scrollLeft = intBetween(0, scrollWidth - clientWidth, scrollLeft + scaleX * strengthMultiplier);
      document.documentElement.scrollTop = intBetween(
        0,
        document.documentElement.scrollHeight - document.documentElement.clientHeight,
        document.documentElement.scrollTop + scaleY * strengthMultiplier
      );

      requestAnimationFrameIdRef.current = window.requestAnimationFrame(tick);
    };

    tick();
  };

  const stopScrolling = (): void => {
    scaleXRef.current = 0;
    scaleYRef.current = 0;

    if (requestAnimationFrameIdRef.current) {
      window.cancelAnimationFrame(requestAnimationFrameIdRef.current);
      requestAnimationFrameIdRef.current = null;
    }
  };

  const handleMonitorChange = (): void => {
    const isDragging = dragDropManager.getMonitor().isDragging();

    if (!isDraggingRef.current && isDragging) {
      isDraggingRef.current = true;
    } else if (isDraggingRef.current && !isDragging) {
      isDraggingRef.current = false;
      stopScrolling();
    }
  };

  useEffect(() => {
    clearMonitorSubscriptionRef.current = dragDropManager
      .getMonitor()
      .subscribeToStateChange(() => handleMonitorChange());

    document.body.addEventListener('dragover', updateScrollingParams);
    document.body.addEventListener('touchmove', updateScrollingParams);

    return () => {
      document.body.removeEventListener('dragover', updateScrollingParams);
      document.body.removeEventListener('touchmove', updateScrollingParams);
      clearMonitorSubscriptionRef.current = null;
      stopScrolling();
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.scrollable} ref={ref}>
        {children}
        <div className={styles.paddingRightBugFix} />
      </div>
    </div>
  );
}

export default forwardRef<HTMLDivElement, ColumnsBoxProps>(ColumnsBox);
