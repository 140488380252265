import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { ColumnBox } from 'src/theme/atoms/groupCreation';
import { TextArea } from 'src/theme/atoms/inputs';

import FeatureCard from '../../components/FeatureCard/FeatureCard';
import FeatureGroupState from '../FeatureGroupState.store';
import styles from './FeatureGroupColumn.module.scss';

interface StaticFeatureGroupColumnProps {
  featureGroupState: FeatureGroupState;
  className?: string;
}

function StaticFeatureGroupColumn({ featureGroupState: fgs, className }: StaticFeatureGroupColumnProps): JSX.Element {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const nameRef = useRef<HTMLTextAreaElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (nameRef.current && !fgs.id && isEditing) {
      nameRef.current.focus();
    }
  }, [isEditing]);

  const isSaved = !!fgs.id;
  const isNotSavedAndNotEditing = !fgs.id && !isEditing;

  const startEdit = (): void => {
    setIsEditing(true);
  };

  const handleBlur = (): void => {
    if (isSaved) {
      setIsEditing(false);
    }
  };

  return (
    <div className={clsx(styles.root, className)} ref={rootRef}>
      <ColumnBox>
        <div className={styles.content}>
          <div
            className={clsx(styles.header, isNotSavedAndNotEditing && styles.unsavedAndInactive)}
            onClick={isNotSavedAndNotEditing ? startEdit : undefined}
          >
            <div className={clsx(styles.headerInner, !isSaved && styles.unsaved)}>
              <TextArea
                color="white"
                name="groupName"
                label="Group Name"
                placeholder="What can the user do?"
                size="s"
                className={styles.nameInputField}
                textFieldClassName={clsx(
                  styles.nameInput,
                  isNotSavedAndNotEditing && styles.unsavedAndInactive,
                  isEditing && styles.editing
                )}
                error={fgs.error}
                value={fgs.name}
                onChange={fgs.changeName}
                onBlur={handleBlur}
                disableEnter
                forwardRef={nameRef}
              />
            </div>
          </div>
          <div className={styles.features}>
            {fgs.featureStates.length > 0 && (
              <div className={styles.featuresBox}>
                {fgs.featureStates.map((fs) => (
                  <div key={fs.id} className={styles.featureCardBox}>
                    <FeatureCard featureState={fs} />
                  </div>
                ))}
              </div>
            )}
            <div className={styles.dropTarget} />
          </div>
        </div>
      </ColumnBox>
    </div>
  );
}

export default observer(StaticFeatureGroupColumn);
