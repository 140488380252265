import { writeToString } from '@fast-csv/format';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { useStores } from 'src/RootStore';
import { paths } from 'src/app/routes/paths.const';
import { useOrganization } from 'src/organizations/OrganizationRouteLayout/OrganizationRouteLayout.context';
import RoadmapSummary from 'src/theme/atoms/RoadmapSummary/RoadmapSummary';
import SubscriptionLimitsExceededModal from 'src/theme/atoms/SubscriptionLimitsExceededModal/SubscriptionLimitsExceededModal';
import Button from 'src/theme/atoms/buttons/Button/Button';
import Duplicate from 'src/theme/atoms/duplicate/Duplicate';
import Loader from 'src/theme/atoms/loaders/Loader/Loader';
import Snackbar from 'src/theme/atoms/snackbar/Snackbar';
import { BACK_TO_HOME_BTN } from 'src/utils/constants';
import copyTextToClipboard, { snackbarCopyLinkData, SnackbarData } from 'src/utils/copyToClipboard';
import { Dictionary } from 'src/utils/types/Dictionary';
import { Feature, FeatureGroup, TransformedFeatureGroup } from 'src/utils/types/FeatureRelated';

import RoadmapStepHeader from '../RoadmapStepHeader/RoadmapStepHeader';
import styles from './RoadmapDetails.module.scss';
import { SummaryFeatureGroup, SummaryRoadmap } from './RoadmapDetails.types';
import RoadmapDetailsHelperModal from './RoadmapDetailsHelperModal/RoadmapDetailsHelperModal';

// import ShareLink from './ShareLink/ShareLink';

const snackbarData: Dictionary<string, SnackbarData> = {
  ...snackbarCopyLinkData,
  roadmapDuplicateSuccess: { msg: 'Roadmap duplicated successfully!', type: 'success' },
  roadmapDuplicateFail: { msg: 'Roadmap has not been duplicated. Please try again.', type: 'error' },
};

const renameFeatureGroupKeys = (fg: FeatureGroup): TransformedFeatureGroup => {
  const transformedFg: any = {};
  Object.assign(transformedFg, fg);
  delete transformedFg.features;
  Object.keys(transformedFg).forEach((key) => {
    delete Object.assign(transformedFg, { ['FG' + key]: transformedFg[key] })[key];
  });
  transformedFg.FGproductNames = transformedFg.FGproductNames.join(' // ');
  return transformedFg;
};

function RoadmapDetails(): JSX.Element {
  const { roadmapId } = useParams();
  if (!roadmapId) throw new Error('No roadmap id');

  const organization = useOrganization();

  const navigate = useNavigate();

  const { roadmapStore } = useStores();
  const { getRoadmap, getShareLink } = roadmapStore;

  const cameFromEdition = (useLocation().state as any)?.cameFromEdition;

  const [summaryRoadmap, setSummaryRoadmap] = useState<SummaryRoadmap>();
  const [isModalOpen, setIsModalOpen] = useState({
    // shareLink: false,
    duplicate: false,
    subscriptionLimitExceeded: false,
    helper: false,
  });
  const [snackbar, setSnackbar] = useState<{ msg: string; type: 'success' | 'error' }>();
  const [shareLinkId, setShareLinkId] = useState('');
  const [isCopyingLink, setIsCopyingLink] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const screenWidth = window.innerWidth;

  const getSummaryRoadmap = (): Promise<SummaryRoadmap> => {
    return getRoadmap(organization.id, roadmapId)
      .then((roadmap) => {
        const newSummaryRoadmap: SummaryRoadmap = {
          ...roadmap,
          featureGroups: roadmap.featureGroups.filter(
            (fg): fg is SummaryFeatureGroup => fg.chartPriority !== undefined
          ),
        };

        setSummaryRoadmap(newSummaryRoadmap);

        return newSummaryRoadmap;
      })
      .catch((err) => {
        alert("Couldn't fetch roadmap data");
        throw new Error(err);
      });
  };

  useEffect(() => {
    getSummaryRoadmap();
  }, []);

  const downloadFile = (data: string, type: string, filename: string): boolean | undefined => {
    const blob = new Blob([data], { type });
    const file = (window.URL || window.webkitURL).createObjectURL(blob);

    if (window.navigator['msSaveOrOpenBlob']) {
      window.navigator['msSaveOrOpenBlob'](blob, filename);
    } else {
      const link = document.createElement('a');
      link.href = file;
      if (link.download != undefined) {
        link.download = filename;
      }
      if (document.createEvent) {
        const e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        link.dispatchEvent(e);
        return true;
      }
    }
  };

  if (!summaryRoadmap) return <Loader />;

  const onDownloadRoadmapClick = (): void => {
    setIsDownloading(true);

    getSummaryRoadmap().then((newSummaryRoadmap) => {
      const featureGroupsWithCalculatedStartAndEndWeeks = newSummaryRoadmap.featureGroups.map(
        (fg): SummaryFeatureGroup => {
          let newFg: SummaryFeatureGroup = fg;

          if (fg.chartWeekStart !== undefined && fg.chartWeekEnd !== undefined && fg.chartWeeksForward !== undefined) {
            newFg = {
              ...fg,
              chartWeekStart: fg.chartWeekStart - fg.chartWeeksForward,
              chartWeekEnd: fg.chartWeekEnd - fg.chartWeeksForward,
            };
          }

          delete newFg.chartWeeksForward;
          return newFg;
        }
      );

      const featuresWithFeatureGroups: [Feature, FeatureGroup[]][] = newSummaryRoadmap.features.map((f) => {
        const featureGroupsWhichContainThisFeature: FeatureGroup[] = featureGroupsWithCalculatedStartAndEndWeeks.filter(
          (fg) => fg.features.findIndex((fgf) => fgf.id === f.id) !== -1
        );

        return [f, featureGroupsWhichContainThisFeature];
      });

      const featuresWithFeatureGroupsFlat = featuresWithFeatureGroups.flatMap((fwfg: [Feature, FeatureGroup[]]) => {
        return fwfg[1].map((fg: FeatureGroup) => {
          return { ...fwfg[0], ...renameFeatureGroupKeys(fg) };
        });
      });

      const featuresWithoutFeaturesGroup = newSummaryRoadmap.features.filter((f) => {
        return featuresWithFeatureGroupsFlat.findIndex((fwfg) => fwfg.id === f.id) === -1;
      });

      const featuresGroupsWithoutFeatures = featureGroupsWithCalculatedStartAndEndWeeks
        .filter((fg) => fg.features.length === 0)
        .map((el) => renameFeatureGroupKeys(el));

      const allFeatures = [
        ...featuresWithFeatureGroupsFlat,
        ...featuresWithoutFeaturesGroup,
        ...featuresGroupsWithoutFeatures,
      ];

      setIsDownloading(false);

      writeToString(allFeatures, { headers: true }).then((csv) => {
        downloadFile(csv, 'text/csv', newSummaryRoadmap.name || 'roadmap');
      });
    });
  };

  const handleShareLinkCopy = async (): Promise<void> => {
    let shareLink = '';

    if (!shareLinkId) {
      setIsCopyingLink(true);
      await getShareLink(organization.id, roadmapId)
        .then((resShareLinkId) => {
          shareLink = `${window.location.host}${paths.noAuthRoadmap(resShareLinkId)}`;
          setShareLinkId(resShareLinkId);
        })
        .finally(() => setIsCopyingLink(false));
    } else {
      shareLink = `${window.location.host}${paths.noAuthRoadmap(shareLinkId)}`;
    }

    if (shareLink) {
      try {
        await copyTextToClipboard(shareLink);
        setSnackbar(snackbarData.linkCopySuccess);
      } catch (e) {
        setSnackbar(snackbarData.linkCopyFail);
      }
    } else {
      setSnackbar(snackbarData.linkCopyFail);
    }
  };

  const headerAndInfobox = (
    <>
      <RoadmapStepHeader
        roadmapName={summaryRoadmap.name}
        title="Your roadmap"
        subtitle="Results"
        actionButtonConfig={{
          label: 'What do I do now?',
          action: () => setIsModalOpen({ ...isModalOpen, helper: true }),
        }}
      />
      <div className={styles.infoBox}>
        <div className={styles.infoBoxTexts}>
          <span>
            <span>{summaryRoadmap.featureGroups.length}</span> User Needs Defined
          </span>
          <span>
            <span>{summaryRoadmap.features.length}</span> Features
          </span>
        </div>
        <div className={styles.infoBoxBtns}>
          <Button
            color="secondary"
            label={`Edit${screenWidth > 1550 ? ' roadmap' : ''}`}
            size="m"
            className={styles.roadmapActionBtn}
            onClick={() => navigate(paths.roadmapUpdateName(organization.id, roadmapId))}
          />
          <Button
            color="secondary"
            label={`Duplicate${screenWidth > 1550 ? ' roadmap' : ''}`}
            size="m"
            className={styles.roadmapActionBtn}
            onClick={() => setIsModalOpen({ ...isModalOpen, duplicate: true })}
          />
          <Button
            color="secondary"
            label={`Share${screenWidth > 1550 ? ' roadmap' : ''}`}
            size="m"
            className={styles.roadmapActionBtn}
            onClick={handleShareLinkCopy}
            loading={isCopyingLink}
          />
          <Button
            color="secondary"
            label={`Download${screenWidth > 1550 ? ' roadmap' : ''}`}
            size="m"
            className={styles.roadmapActionBtn}
            onClick={() => onDownloadRoadmapClick()}
            loading={isDownloading}
          />
        </div>
      </div>
    </>
  );

  const bottomButtons = (
    <div className={styles.actionBar}>
      {cameFromEdition ? (
        <div className={styles.prevWrapper}>
          <div
            className={styles.goBack}
            onClick={() => {
              navigate(paths.roadmapFeatureGroupTimeEstimation(organization.id, roadmapId));
            }}
          />
          <span className={styles.label}>{'YOUR ROADMAP'}</span>
        </div>
      ) : (
        <div />
      )}
      <Button
        className={styles.nextBtn}
        color="primary"
        label={BACK_TO_HOME_BTN}
        size="l"
        onClick={() => navigate(paths.organizationDashboard(organization.id))}
      />
    </div>
  );

  const modals = (
    <>
      {/* <ShareLink
        isModalOpen={isModalOpen.shareLink}
        onClose={() => setIsModalOpen({ ...isModalOpen, shareLink: false })}
        onSuccess={() => {
          setIsModalOpen({ ...isModalOpen, shareLink: false });
          setSnackbar(snackbarData.linkCopySuccess);
        }}
        onFail={() => setSnackbar(snackbarData.linkCopyFail)}
      /> */}
      {summaryRoadmap && (
        <Duplicate
          isModalOpen={isModalOpen.duplicate}
          organizationId={organization.id}
          itemId={summaryRoadmap.id}
          itemName={summaryRoadmap.name}
          itemType={'roadmap'}
          onClose={() => setIsModalOpen({ ...isModalOpen, duplicate: false })}
          onSuccess={() => {
            setSnackbar(snackbarData.roadmapDuplicateSuccess);
            setTimeout(() => navigate(paths.organizationDashboard(organization.id)), 1500);
          }}
          onFail={() => setSnackbar(snackbarData.roadmapDuplicateFail)}
          onDuplicate402Error={() => setIsModalOpen({ ...isModalOpen, subscriptionLimitExceeded: true })}
        />
      )}
      {isModalOpen.helper && (
        <RoadmapDetailsHelperModal onClose={() => setIsModalOpen({ ...isModalOpen, helper: false })} />
      )}
      <SubscriptionLimitsExceededModal
        isOpen={isModalOpen.subscriptionLimitExceeded}
        onClose={() => setIsModalOpen({ ...isModalOpen, subscriptionLimitExceeded: false })}
      />
      <Snackbar
        type={snackbar?.type || 'success'}
        message={snackbar?.msg}
        onClose={() => setSnackbar(undefined)}
        autoclose={true}
      />
    </>
  );

  return (
    <div className={styles.root}>
      {headerAndInfobox}
      <div className={styles.content}>
        <RoadmapSummary summaryRoadmap={summaryRoadmap} isInteractive />
      </div>
      {bottomButtons}
      {modals}
    </div>
  );
}

export default observer(RoadmapDetails);
