import clsx from 'clsx';
import { ChangeEvent, forwardRef } from 'react';

import baseStyles from '../base.module.scss';
import { InputProps } from '../types';

function Input(
  {
    value,
    label,
    color,
    size,
    name,
    type,
    autoCompleteOff,
    placeholder,
    error,
    helperText,
    advanceText,
    disabled,
    className,
    messageClassName,
    inputClassName,
    maxLength,
    min,
    max,
    onChange,
    onKeyDown,
    onKeyUp,
    onEnter,
    onFocus,
  }: InputProps,
  ref
): JSX.Element {
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && onEnter && value) onEnter(e);
    onKeyUp && onKeyUp(e);
  };

  const sizeClass = size === 's' ? baseStyles.sizeS : baseStyles.sizeL;
  const colorClass = color === 'white' ? baseStyles.white : baseStyles.black;

  const controlledProps = onChange
    ? { value, onChange: (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value) }
    : {};

  const message = error ? error.message : helperText ? helperText : '';

  return (
    <div className={clsx(baseStyles.field, sizeClass, colorClass, error && baseStyles.error, className)}>
      <input
        ref={ref}
        id={name}
        aria-label={label}
        aria-invalid={error ? 'true' : 'false'}
        spellCheck="false"
        type={type}
        className={clsx(baseStyles.input, inputClassName)}
        autoComplete={autoCompleteOff ? 'off' : 'on'}
        placeholder={placeholder}
        maxLength={maxLength}
        min={min}
        max={max}
        onFocus={() => onFocus && onFocus()}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e)}
        onKeyUp={handleKeyUp}
        disabled={disabled}
        {...controlledProps}
      />
      {message && <p className={clsx(baseStyles.message, error && baseStyles.error, messageClassName)}>{message}</p>}
      {advanceText && <span className={clsx(baseStyles.advanceText, 'smallLabel')}>{advanceText}</span>}
    </div>
  );
}

export default forwardRef(Input);
